import { createSlice } from "@reduxjs/toolkit";
import {
  getAllClassDropdownThunk,
  getAllSubjectByClassDocId,
  getApprovedExamListByClass,
} from "./thunk";

const initialState = {
  classDropDownLoading: false,
  classDropDownList: [],
  isError: false,
  classDropDownError: "",
  subjectListLoading: false,
  subjectList: [],
  subjectListError: false,
  examOptionsLoading: false,
  examOptions: [],
  examOptionsError: false,
};

const examControlSlice = createSlice({
  name: "examControl",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllClassDropdownThunk.pending, (state) => {
        state.classDropDownLoading = true;
      })
      .addCase(getAllClassDropdownThunk.fulfilled, (state, action) => {
        state.classDropDownList = action.payload;
        state.classDropDownLoading = false;
      })
      .addCase(getAllClassDropdownThunk.rejected, (state, action) => {
        state.isError = true;
        state.classDropDownError = action.payload;
      })
      .addCase(getAllSubjectByClassDocId.pending, (state) => {
        state.subjectListLoading = true;
      })
      .addCase(getAllSubjectByClassDocId.fulfilled, (state, action) => {
        state.subjectList = action.payload;
        state.subjectListLoading = false;
      })
      .addCase(getAllSubjectByClassDocId.rejected, (state) => {
        state.subjectListError = true;
      })
      .addCase(getApprovedExamListByClass.pending, (state) => {
        state.examOptionsLoading = true;
      })
      .addCase(getApprovedExamListByClass.fulfilled, (state, action) => {
        const examNameAndDocIdList = action.payload?.map((item) => {
          const object = {
            examName: item?.examName,
            examDocId: item?._id,
          };

          return object;
        });
        state.examOptions = examNameAndDocIdList;
        state.examOptionsLoading = false;
      })
      .addCase(getApprovedExamListByClass.rejected, (state) => {
        state.examOptionsError = true;
      });
  },
});

export default examControlSlice.reducer;
