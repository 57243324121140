import Fallback from "app/components/Fallback";
import React from "react";
import Certificate from "./Certificate";

const StudentManagement = React.lazy(() =>
  import("app/modules/student/pages/StudentManagment")
);

const StudentIdCard = React.lazy(() =>
  import("app/modules/student/pages/IdCard")
);
const StudentReport = React.lazy(() =>
  import("app/modules/student/pages/StudentReport")
);

const CreateStudent = React.lazy(() =>
  import("app/modules/student/pages/CreateStudent")
);

const StudentProfile = React.lazy(() =>
  import("app/modules/student/pages/StudentProfile")
);
const Migration = React.lazy(() =>
  import("app/modules/student/pages/PromotionAndMigration/Migration")
);
const ExistStudent = React.lazy(() =>
  import("app/modules/student/components/ExistStudent/index")
);
const EditMigration = React.lazy(() =>
  import("app/modules/student/pages/PromotionAndMigration/EditMigration")
);

export const ExistStudentPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <ExistStudent {...props} />
  </React.Suspense>
);
export const StudentReportPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <StudentReport {...props} />
  </React.Suspense>
);
export const StudentManagementPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <StudentManagement {...props} />
  </React.Suspense>
);

export const CreateStudentPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <CreateStudent {...props} />
  </React.Suspense>
);

export const StudentProfilePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <StudentProfile {...props} />
  </React.Suspense>
);

export const StudentIdCardPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <StudentIdCard {...props} />
  </React.Suspense>
);
export const MigrationPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Migration {...props} />
  </React.Suspense>
);
export const EditMigrationPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <EditMigration {...props} />
  </React.Suspense>
);
export const CertificatePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Certificate {...props} />
  </React.Suspense>
);
