import { Select, styled, TextField, Box } from "@mui/material";

export const CustomTextField2 = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "8px",
  "& label": {
    marginTop: "-3px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1.5px solid #B6C8D6",
      borderRadius: "8px",
      boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary,
    },
  },
}));
export const AuthTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-root": {
    fontSize: "16px",
    "& fieldset": {
      border: "1.5px solid #B6C8D6",
      borderRadius: "8px",
      boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary,
    },
  },
}));
export const CustomTextArea = styled("textarea")`
  width: 100%;
  height: 130px;
  padding: 10px;
  font-size: 14px;
  resize: none;
`;
export const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "8px",
  "& label": {
    paddingTop: "3px",
  },
  "& .MuiOutlinedInput-root": {
    height: "40px",
    "& fieldset": {
      border: "1.5px solid #B6C8D6",
      borderRadius: "8px",
      boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary,
    },
  },
}));
export const CustomTextFieldDisabled = styled(TextField)(({ theme }) => ({
  backgroundColor: "rgba(208, 208, 226, 0.2)",
  borderRadius: "8px",
  pointerEvents: "none",
  "& label": {
    paddingTop: "3px",
  },
  "& .MuiOutlinedInput-root": {
    height: "40px",
    "& fieldset": {
      border: "1.5px solid #B6C8D6",
      borderRadius: "8px",
      boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary,
    },
  },
}));
export const CustomTextFieldMultiline = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1.5px solid #B6C8D6",
      borderRadius: "8px",
      boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary,
    },
  },
}));

export const CustomSelectBox = styled(Select)(({ theme }) => ({
  backgroundColor: "#fff",
  // lineHeight: "1.8em !important",
  height: "40px",
  boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
  borderRadius: "8px",
  "& .MuiInputBase-root": {
    "& fieldset": {
      border: "1.5px solid #B6C8D6",
    },
  },
}));
export const CustomSelectBoxDisabled = styled(Select)(({ theme }) => ({
  backgroundColor: "rgba(208, 208, 226, 0.2)",
  pointerEvents: "none",
  height: "40px",
  boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
  borderRadius: "8px",
  cursor: "none",
  "& .MuiInputBase-root": {
    "& fieldset": {
      border: "1.5px solid rgba(208, 208, 226, 0.2)",
    },
  },
}));
export const TabsWrapper = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(19, 115, 230, 0.2)",
  backgroundColor: "rgba(39, 137, 253, 0.05)",
  display: "inline-block",
  borderRadius: "10px",
  padding: "0 16px",
  "& .MuiTabs-indicator": {
    backgroundColor: "#2789FD",
  },
  "& .MuiTab-root": {
    fontSize: "15px",
    color: "#0C2F49",
    opacity: 0.75,
    textTransform: "capitalize",
  },
  "& .Mui-selected": {
    opacity: 1,
  },
}));
