import { Box, Stack, Typography } from "@mui/material";
import { THEME } from "app/config/constants";
import React from "react";
import styled from "styled-components";

const DeactivateContent = () => {
  return (
    <DeactivateWrapper>
      <Stack direction="column" alignItems="center" sx={{ p: 2 }}>
        <Box className="update_img__wrapper">
          <img
            src="/deactivate_account.png"
            className="update_img"
            alt="deactivate"
          />
        </Box>
        <Typography className="update_text">
          Your Account is Deactivated !
        </Typography>
        <Typography className="account_text">
          Due not to complete payment on scheduled time that is why your account
          is deactivated.
        </Typography>
        <Box className="account_contact__box">
          <Typography className="contact_text">Contact Detail</Typography>
          <Typography className="contact_number__text">
            support@ekalsutra.com
            <br /> +91 97926 69957
          </Typography>
        </Box>
      </Stack>
    </DeactivateWrapper>
  );
};

export default DeactivateContent;
const DeactivateWrapper = styled.div`
  width: 450px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: ${THEME.boxShadow1};
  .update_profile__text {
    font-size: 18px;
    color: #0d2553;
  }
  .update_img__wrapper {
    width: 97.81px;
    height: 97.81px;
    background: rgba(243, 168, 22, 0.15);
    border: 1px solid rgba(243, 168, 22, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .update_img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
  .update_text {
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0c2f49;
    margin: 10px auto;
  }
  .account_text {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #232360;
    opacity: 0.75;
  }
  .account_contact__box {
    background: rgba(39, 137, 253, 0.1);
    border: 1px solid #ffffff;
    border-radius: 20px;
    padding: 20px;
    width: 80%;
    margin-top: 10px;
  }
  .contact_text {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0c2f49;
  }
  .contact_number__text {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #232360;
    opacity: 0.5;
  }
`;
