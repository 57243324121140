import Fallback from "app/components/Fallback";
import React from "react";

const Complains = React.lazy(() =>
  import("app/modules/reception/pages/Complains")
);

export const ComplainsPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Complains {...props} />
  </React.Suspense>
);

const VisitorControls = React.lazy(() =>
  import("app/modules/reception/pages/VisitorControls")
);

export const VisitorControlPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <VisitorControls {...props} />
  </React.Suspense>
);

const StaffAttendance = React.lazy(() =>
  import("app/modules/reception/pages/StaffAttendance")
);

export const StaffAttendancePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <StaffAttendance {...props} />
  </React.Suspense>
);
