import { Box, styled } from "@mui/material";

import React from "react";

const ComingSoon = () => {
  return (
    <Wrapper>
      <h2>Something Exciting is Coming Your Way!</h2>
      <h3>We're working on something special. Stay tuned!</h3>
      <h4>
        Our team is hard at work crafting an experience that will delight and
        inspire you. We can't wait to share it with you. Check back soon for
        updates!
      </h4>
    </Wrapper>
  );
};

export default ComingSoon;
const Wrapper = styled(Box)`
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  color: #666;
  text-align: center;
  padding: 3rem 5rem;
  h2 {
    color: green;
    margin-bottom: 10px;
  }
  h4 {
    margin: 10px auto;
  }
`;
