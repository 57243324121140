import {
  Box,
  Button,
  DialogContent,
  Divider,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import PreviewDownloadImage from "app/components/common/PreviewDownloadImage";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import dayjs from "dayjs";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

const ViewNoticeDialog = ({
  open = false,
  handleClose = () => {},
  viewNotice,
}) => {
  const selectedSchool = useSelector(selectSelectedSchool);
  const printNoticeRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printNoticeRef.current,
    documentTitle: "Notice",
  });
  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        title="Notice"
        minWidth="595px"
      >
        <DialogContent sx={{ p: "0 !important" }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ pt: 1, px: "20px" }}
          >
            <Button
              color="secondary"
              onClick={handlePrint}
              variant="contained"
              sx={{
                width: "20%",
                textTransform: "capitalize",
                fontSize: "14px",
                letterSpacing: "1px",
              }}
            >
              Print
            </Button>
          </Stack>
          <NoticeDialogWrapper ref={printNoticeRef}>
            <Box>
              <Stack direction="row" alignItems="center">
                <Box
                  sx={{
                    ml: 1,
                    height: "80px",
                    width: "80px",
                    py: 1,
                  }}
                >
                  <img
                    src={selectedSchool?.schoolLogo}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt="schoolLogo"
                  />
                </Box>
                <Stack
                  direction="row"
                  sx={{ width: "calc(100% - 120px)" }}
                  justifyContent="center"
                >
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "17px",
                        textAlign: "center",
                      }}
                    >
                      {selectedSchool?.schoolName}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {selectedSchool?.schoolAddress?.address}
                      <br />
                      {selectedSchool?.schoolAddress?.dist},{" "}
                      {selectedSchool?.schoolAddress?.state},{" "}
                      {selectedSchool?.schoolAddress?.pinCode}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
              <Divider fullWidth sx={{ backgroundColor: "#000000" }} />
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ p: 1 }}
              >
                <Typography sx={{ fontSize: "15px" }}>
                  Ref No:{" "}
                  <span style={{ fontWeight: 700 }}>
                    {viewNotice?.noticeNumber}
                  </span>
                </Typography>

                <Typography sx={{ fontSize: "15px" }}>
                  Date:{" "}
                  <span style={{ fontWeight: 700 }}>
                    {viewNotice?.publishDate
                      ? dayjs(viewNotice?.publishDate).format("DD MMM, YYYY")
                      : "Draft"}
                  </span>
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="center">
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "900",
                    fontSize: "16px",
                    color: "#000000",
                    mt: 2,
                  }}
                >
                  Notice
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{ px: 2, pt: 2 }}
                justifyContent="center"
              >
                <Typography
                  style={{
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "15px",
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  Subject:{" "}
                  <span style={{ fontWeight: 700 }}>{viewNotice?.subject}</span>
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ px: 2, pt: 2 }}>
                <Typography
                  style={{
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16px",
                    textAlign: "justify",
                    whiteSpace: "pre-wrap",
                    color: "#000000",
                  }}
                >
                  {viewNotice?.message}
                </Typography>
              </Stack>
              <Stack flexWrap={"wrap"} gap={1} py={1} px={1}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Attachments
                </Typography>
                {viewNotice?.attachments?.length === 0 ? (
                  <Typography>No Attachments to display</Typography>
                ) : (
                  <Stack direction={"row"} gap={2}>
                    {viewNotice?.attachments?.map((item, i) => (
                      <Box sx={{ my: 0.5 }} key={i}>
                        <PreviewDownloadImage
                          src={item}
                          documentName="Notice"
                        />
                      </Box>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ px: 2, py: 1 }}
              alignItems="flex-end"
            >
              <Stack direction="column" gap={0.5}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Notice For:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "15px",
                    textTransform: "capitalize",
                  }}
                >
                  {viewNotice?.viewerType}
                </Typography>
              </Stack>
              <Stack direction="column" alignItems={"center"}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {viewNotice?.createdBy?.personName ??
                    viewNotice?.publishBy?.personName}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                >
                  {String(viewNotice?.publishPersonRole).toLowerCase()}
                </Typography>
              </Stack>
            </Stack>
          </NoticeDialogWrapper>
        </DialogContent>
      </CommonDialog>
    </>
  );
};

export default ViewNoticeDialog;
const ViewText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "20px",
  color: "#232360",
}));
const NoticeDialogWrapper = styled(Box)`
  border: 1px solid #000;
  margin: 10px 20px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
