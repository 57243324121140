import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import styled from "styled-components";
import { useRef, useState } from "react";

import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { toast } from "react-toastify";

import dayjs from "dayjs";
import { getInvoice } from "app/services/orgService/school.service";
import { IndianCurrency, NumberInWord } from "app/utils/helper";
import CustomLoader from "../common/CustomLoader";
export default function SessionOrderInvoice() {
  const invoicePrintRef = useRef(null);
  const [data, setData] = useState({});
  const { invoiceId } = useParams();
  const handlePrint = useReactToPrint({
    content: () => invoicePrintRef.current,
  });
  const { isLoading: getInvoiceLoading, isFetching: getInvoiceFetching } =
    useQuery({
      queryKey: ["getInvoice", invoiceId],
      queryFn: () =>
        getInvoice({ invoiceNumber: String(invoiceId).replace(/ /g, "/") }),
      onSuccess: (value) => {
        setData(value?.data?.obj);
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  const InvoiceDetail = () => {
    return (
      <LeftSideDiv>
        <InvoiceHeader>Invoice</InvoiceHeader>
        <InvoiceBody>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            p={1}
            alignItems={"center"}
          >
            <Stack>
              <Typography className="title">Sold By:</Typography>
              <Typography>Ekalsutra Edtech Private Limited</Typography>
              <Typography>Incubation Center, REC Ambedkar Nagar,</Typography>
              <Typography>Ambedkar Nagar, (UP), 224122</Typography>
              <Typography>GST : </Typography>
            </Stack>
            <Stack>
              <img
                alt="ekalsutra"
                src="/logo-blue.png"
                style={{ height: "70px", objectFit: "contain" }}
              />
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            p={1}
            mx={1}
            alignItems={"center"}
            className="gray_box"
          >
            <Stack>
              <Typography className="title">Shipping Address</Typography>
              <Typography>
                License Number:{" "}
                <span style={{ fontWeight: 600 }}>{data?.ekalRefNumber}</span>
              </Typography>
              <Typography>{data?.schoolName}</Typography>
              <Typography>{data?.shippingAddress?.address},</Typography>
              <Typography>
                {data?.shippingAddress?.dist}, ({data?.shippingAddress?.state}),{" "}
                {data?.shippingAddress?.pinCode}
              </Typography>

              <Typography>GST : </Typography>
            </Stack>
            <Stack>
              <Typography className="title">Billing Address</Typography>
              <Typography>
                Invoice No:{" "}
                <span style={{ fontWeight: 600 }}>{data?.invoiceNumber}</span>
              </Typography>
              <Typography>{data?.schoolName}</Typography>
              <Typography>{data?.billingAddress?.address},</Typography>
              <Typography>
                {data?.billingAddress?.dist}, ({data?.billingAddress?.state}),{" "}
                {data?.billingAddress?.pinCode}
              </Typography>
              <Typography>GST : </Typography>
            </Stack>
          </Stack>
          <Grid container className="orange_strip" columns={14} p={1}>
            <Grid item xs={4.5}>
              <Typography className="orange_strip__header">
                Description
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography className="orange_strip__header">
                HSN Detail
              </Typography>
            </Grid>
            {/* <Grid item xs={2}>
              <Typography className="orange_strip__header">Amount</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="orange_strip__header">Discount</Typography>
            </Grid> */}
            <Grid item xs={3}>
              <Typography className="orange_strip__header">
                Taxable Value
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="orange_strip__header">Tax</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="orange_strip__header">
                Total Amount
              </Typography>
            </Grid>
          </Grid>
          {data?.items?.map((item, ind) => (
            <Grid container columns={14} key={ind} px={1} mt={1}>
              <Grid item xs={4.5}>
                <Typography className="orange_strip__header">
                  {item?.productDetail?.productDescription ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography className="orange_strip__header">
                  {item?.hsnCode} | GST:
                  {Number(item?.sgst ?? 0) +
                    Number(item?.sgst ?? 0) +
                    Number(item?.sgst ?? 0)}
                  %
                </Typography>
              </Grid>
              {/* <Grid item xs={2}>
                <Typography className="orange_strip__header">
                  {IndianCurrency(
                    Number(item?.orderDetail?.amount ?? 0) +
                      Number(item?.orderDetail?.discountAtpurchage ?? 0)
                  )}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="orange_strip__header">
                  {IndianCurrency(item?.extraDiscountAmount)}
                </Typography>
              </Grid> */}
              <Grid item xs={3}>
                <Typography className="orange_strip__header">
                  {IndianCurrency(item?.taxableAmount)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="orange_strip__header">
                  {IndianCurrency(item?.taxAmount)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="orange_strip__header">
                  {IndianCurrency(item?.totalAmount)}
                </Typography>
              </Grid>
            </Grid>
          ))}

          <Box sx={{ backgroundColor: "#000", my: 0.3, height: "1px" }} />
          <Grid container sx={{ px: 1 }} columns={16}>
            <Grid item xs={12}>
              <Typography>
                <span style={{ fontWeight: 600 }}>Paid Amount:</span>{" "}
                {NumberInWord(data?.totalPaidAmountWithTax)} Rupees Only
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: 600 }}>
                {data?.paymentMode}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: 600 }}>
                {IndianCurrency(data?.totalPaidAmountWithTax)}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ backgroundColor: "#000", my: 0.3, height: "1px" }} />
          <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
            <Box>
              <Typography>
                Created By : {data?.createdBy?.personName}
              </Typography>
              <Typography>
                Created On : {dayjs(data?.createdOn).format("DD MMM YYYY")}
              </Typography>
            </Box>
            <Box>
              <Typography>For,</Typography>
              <Typography>Ekalsutra Edtech Private Limited</Typography>
              <Typography sx={{ fontSize: "10px", pt: 5, pb: 1 }}>
                Authorised Sign.
              </Typography>
            </Box>
          </Stack>
        </InvoiceBody>
      </LeftSideDiv>
    );
  };

  return (
    <>
      <CustomLoader show={getInvoiceLoading || getInvoiceFetching} />
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              handlePrint();
            }}
          >
            Print
          </Button>
        </Stack>

        <Stack
          sx={{ width: "60%", m: "auto" }}
          direction={"row"}
          justifyContent={"center"}
        >
          <InvoiceDetail />
        </Stack>

        <Box sx={{ display: "none" }}>
          <Stack ref={invoicePrintRef} m={1}>
            <InvoiceDetail />
          </Stack>
        </Box>
      </Box>
    </>
  );
}

const LeftSideDiv = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  border: 1px solid #00000073;
  background-color: #fff;
`;

const InvoiceHeader = styled.p`
  text-align: center;
  color: #fff;
  background-color: rgba(207, 54, 54, 1);
  margin: 0;
  color: #fff;
  font-size: 24px;
  font-weight: 800;
  text-transform: capitalize;
  padding: 5px;
`;

const InvoiceBody = styled.div`
  .gray_box {
    border-radius: 15px;
    border: 1px solid #d0d0e2;
    background: rgba(208, 208, 226, 0.08);
  }
  .title {
    font-size: 16px;
    font-weight: 600;
  }
  .orange_strip {
    margin-bottom: 5px;
    background-color: #ffaf0d;
    margin-top: 15px;
    .orange_strip__header {
      font-size: 14px;
      font-weight: 600;
    }
  }
`;
