import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TableProfilePic from "app/components/common/TableProfilePic";
import { selectActiveSession } from "app/modules/schools/selectors";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import styled from "styled-components";
import DialogHeader from "app/components/common/DialogHeader";
import StudentFilter from "./components/StudentFilter";
import CommonTableHorizontalScrollable from "app/components/Tables/CommonTableHorizontalScrollable";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import TeacherExperienceCertificatePrint from "./components/teacherExperience/TeacherExperienceCertificatePrint";
import appRoutes from "app/config/routes";
import dayjs from "dayjs";
import { DATE_FORMAT } from "app/config/constants";
import { useReactToPrint } from "react-to-print";
import { useQuery } from "react-query";
import { getActiveStaffBySchoolDocIdCopy } from "app/services/hr";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const StaffDrawer = ({ showStaff, setShowStaff, certificateName }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const selectedSession = useSelector(selectActiveSession);
  const [allCheck, setAllCheck] = useState(false);
  const [allPrintCheck, setAllPrintCheck] = useState(false);
  const [falseState, setFalseState] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [selectedStudentForPrint, setSelectedStudentForPrint] = useState([]);
  const [state, setState] = useState({
    studentList: [],
    checkExportOptions: false,
    template: "Template 1",
  });
  const handleClose = () => {
    setShowStaff(false);
  };

  const handleChangeId = (e, _id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setState((prev) => ({
        ...prev,
        studentList: prev.studentList.map((item) =>
          item._id === _id ? { ...item, checked2: true } : item
        ),
      }));
      setSelectedStudentForPrint((prevStudentIds) => [...prevStudentIds, _id]);
    } else {
      setState((prev) => ({
        ...prev,
        studentList: prev.studentList.map((item) =>
          item._id === _id ? { ...item, checked2: false } : item
        ),
      }));
      setSelectedStudentForPrint((prevStudentIds) =>
        prevStudentIds.filter((id) => id !== _id)
      );
    }
  };

  useEffect(() => {
    if (!allCheck) {
      state.studentList?.forEach((item, i) => {
        state.studentList[i]["checked"] = false;
      });
      setFalseState(!falseState);
    } else {
      state.studentList?.forEach((item, i) => {
        state.studentList[i]["checked"] = true;
      });
      setFalseState(!falseState);
    }
  }, [allCheck]);

  useEffect(() => {
    if (!allPrintCheck) {
      state.studentList?.forEach((item, ind) => {
        state.studentList[ind]["checked2"] = false;
      });
      setSelectedStudentForPrint([]);
      setFalseState(!falseState);
    } else {
      state.studentList?.forEach((item, ind) => {
        state.studentList[ind]["checked2"] = true;
      });
      setFalseState(!falseState);
    }
  }, [allPrintCheck]);

  const {
    isLoading: loadingActiveStaff,
    isFetching: fetchingActiveStaff,
    data: ActiveStaffResponse,
    refetch: fetchActiveStaff,
  } = useQuery({
    queryKey: ["getActiveStaffBySchoolDocIdCopy"],
    queryFn: () => getActiveStaffBySchoolDocIdCopy(),
    onSuccess: ({ data }) => {
      // toast.success(data?.message);
      if (data?.list?.length === 0) {
        setState((prev) => ({
          ...prev,
          studentList: [],
        }));
      } else {
        handleSetData(data?.list);
      }
    },
    onError: ({ response: { data } }) => {
      toast.success(data?.message);
    },
  });
  const handleSetData = (res) => {
    const arr = [];
    if (res && res?.length > 0) {
      res?.forEach((item) => {
        arr.push({
          ...item,
          checked: false,
        });
      });
    }
    setState((prev) => ({
      ...prev,
      studentList: arr,
    }));
  };

  const columns = [
    {
      header: "Sr.No",
      Cell: ({ cell }) => (
        <p
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {parseInt(cell.row.id) + 1}
        </p>
      ),
      size: 30,
    },
    {
      header: "Primary Role",
      accessorKey: "primaryRole",
      Cell: ({ cell }) => {
        return (
          <>
            <span>{cell?.row?.original?.primaryRole}</span>
          </>
        );
      },
      size: 80,
    },
    {
      header: "Employee ID",
      accessorKey: "employeeId",
      Cell: ({ cell }) => {
        return (
          <>
            <span>{cell?.row?.original?.employeeId}</span>
          </>
        );
      },
      size: 50,
    },
    {
      header: "Name",
      accessorKey: "firstName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Link
            to={`/${appRoutes.staffProfile}/${original?.staffDocId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Stack direction="row" alignItems="center" gap={0.6}>
              <TableProfilePic image={original?.profilePic} />
              <Typography>
                {`${original?.firstName} ${original?.middleName} ${original?.lastName}`}
              </Typography>
            </Stack>
          </Link>
        );
      },
      size: 80,
      enableColumnOrdering: true,
    },
    {
      header: "Designation",
      accessorKey: "designation",
      Cell: ({ cell }) => {
        return (
          <>
            <span>{cell?.row?.original?.designation}</span>
          </>
        );
      },
      size: 80,
      enableColumnOrdering: true,
    },
    {
      header: "Department",
      accessorKey: "departmentName",
      Cell: ({ cell }) => {
        return (
          <>
            <span>{cell?.row?.original?.departmentName}</span>
          </>
        );
      },
      size: 80,
      enableColumnOrdering: true,
    },
    {
      header: "Contact",
      accessorKey: "mobileNumber",

      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            <Typography>{original?.mobileNumber}</Typography>
          </>
        );
      },
      size: 60,
      enableColumnOrdering: true,
    },
    {
      header: "Gender",
      accessorKey: "gender",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            <Typography>{original?.gender}</Typography>
          </>
        );
      },
      size: 60,
      enableColumnOrdering: true,
    },

    {
      header: "Actions",
      Header: () => {
        return (
          <Stack direction="row" alignItems="center" gap={0.8}>
            <input
              type="checkbox"
              id="checkdiv"
              checked={allCheck}
              style={{ cursor: "pointer", width: "20px", height: "20px" }}
              onChange={(e) => setAllCheck((prev) => !prev)}
            />
            <Typography>Actions</Typography>
          </Stack>
        );
      },
      size: 60,
      Cell: ({ cell, row }) => (
        <div
          style={{
            width: "35px",
            height: "35px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          <input
            type="checkbox"
            id="checkdiv"
            checked={row?.original?.checked}
            style={{ cursor: "pointer", width: "20px", height: "20px" }}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                studentList: prev.studentList.map((item) =>
                  item.staffDocId === row.original.staffDocId
                    ? { ...item, checked: !item.checked }
                    : item
                ),
              }));

              setFalseState((prev) => !prev);
            }}
          />
        </div>
      ),
      size: isMobile ? 80 : 60,
    },
  ];

  const template_2_Ref = useRef(null);

  const handlePrintDialog = () => {
    setIsPrinting(true);
  };

  const handleClosePrintDialog = () => {
    setIsPrinting(false);
  };

  const handlePrint = useReactToPrint({
    content: () => template_2_Ref.current,
    documentTitle: `Character Certificate -${new Date().getTime()}`,
    onAfterPrint: () => {
      setSelectedStudentForPrint([]);
      setAllPrintCheck(false);
      handleClosePrintDialog();
    },
  });
  return (
    <Drawer
      open={showStaff}
      onClose={() => {
        handleClose();
      }}
      anchor="right"
      PaperProps={{
        sx: { minWidth: { xs: "98%", md: "80vw" } },
      }}
    >
      <DialogHeader handleClose={() => handleClose()} title="Staff List" />

      <StyledWrapper
        sx={{
          width: { xs: window.innerWidth - 10, md: "100%" },
          overflow: "auto",
        }}
      >
        {/* table for student showing */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <LoadingButton
            loading={loadingActiveStaff || fetchingActiveStaff}
            onClick={() => {
              setIsPrinting(true);
              handlePrint();
            }}
            variant="contained"
            color="secondary"
            size="large"
            sx={{ textTransform: "capitalize" }}
          >
            Print
          </LoadingButton>
          <CommonTableHorizontalScrollable
            columns={columns}
            data={state?.studentList}
            initialState={{
              columnPinning: {
                left: ["Sr.No"],
                right: ["Actions"],
              },
            }}
          />
        </Box>

        <Dialog
          open={isPrinting}
          onClose={handleClosePrintDialog}
          PaperProps={{
            sx: { minWidth: "90%" },
          }}
        >
          <DialogTitle sx={{ p: "0 !important" }}>
            <DialogHeader
              title="Print Certificate"
              handleClose={handleClosePrintDialog}
            />
          </DialogTitle>
          <DialogTitle>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <div>
                <TextFieldLabel title={"Choose Template"} />
                <CustomSelectBox
                  size="small"
                  value={state.template}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      template: e.target.value,
                    }))
                  }
                  sx={{ width: "250px" }}
                >
                  <MenuItem value="Template 1">Template 1</MenuItem>
                </CustomSelectBox>
              </div>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={handlePrint}
                sx={{ textTransform: "capitalize", width: "200px" }}
              >
                Print
              </Button>
            </Stack>
          </DialogTitle>
          <DialogContent>
            {state?.template === "Template 1" &&
              certificateName === "Teacher Experience Certificate" && (
                <>
                  <Box ref={template_2_Ref}>
                    {state?.studentList
                      ?.filter((item) => item?.checked)
                      .map((res) => (
                        <Box
                          sx={{
                            m: 0,
                            mb: 12.2,
                            "@media print": {
                              m: 0,
                              mb: 0,
                            },
                            pageBreakInside: "avoid",
                          }}
                        >
                          <TeacherExperienceCertificatePrint
                            key={res?._id}
                            studentData={res}
                            session={selectedSession}
                          />
                        </Box>
                      ))}
                  </Box>
                </>
              )}
          </DialogContent>
        </Dialog>
      </StyledWrapper>
    </Drawer>
  );
};

export default StaffDrawer;

const StyledWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 95vh;
  padding: 30px 15px 15px 15px;
`;

const PageBreak = styled.div`
  @media all {
    display: none;
  }

  @media print {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  @media print {
    margin-top: 0;
    display: block;
    page-break-before: always;
  }

  @page {
    size: auto;
    margin: 3mm;
  }
`;
