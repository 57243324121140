import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllClassDropdowns,
  getSubjectByClassDocIdSection,
} from "app/services/management";
import { getAllApprovedExamList } from "app/services/schoolService/exam.service";

export const getAllClassDropdownThunk = createAsyncThunk(
  "examControl/getAllClassDropdowns",
  async (payload, { dispatch }) => {
    try {
      const res = await getAllClassDropdowns(
        payload.schoolDocId,
        payload.session,
        payload.medium
      );
      const data = res?.data?.list;
      return data;
    } catch (e) {}
  }
);

export const getAllSubjectByClassDocId = createAsyncThunk(
  "examControl/getAllSubjectByClassDocId",
  async (payload) => {
    try {
      const res = await getSubjectByClassDocIdSection(
        payload.streamValue,
        payload.section
      );
      const data = res?.data?.list;
      return data;
    } catch (e) {}
  }
);

export const getApprovedExamListByClass = createAsyncThunk(
  "examControl/getAllApprovedExamList",
  async (payload) => {
    try {
      const res = await getAllApprovedExamList({
        session: payload.session,
        classDocId: payload.classValue,
        section: payload.section,
      });
      const data = res?.data?.list;
      return data;
    } catch (e) {}
  }
);
