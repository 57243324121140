import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let student_url = `${SCHOOL_BASE}/student`;

export const deleteTransferCertificatePartially = (payload) => {
  return schoolTokenAxios.delete(
    `${student_url}/deleteTransferCertificatePartially/${payload}`
  );
};
