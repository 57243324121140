import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DialogHeader from "../DialogHeader";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import { toast } from "react-toastify";
import { getStudentByName } from "app/services/schoolService/studentReport.service";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";

const StudentSearchDialog = ({ open = false, handleClose = () => {} }) => {
  const [state, setState] = useState({
    studentName: "",
    studentData: {},
    page: 1,
  });
  const selectedSession = useSelector(selectActiveSession);

  // get all student for name search
  const {
    isFetching: getStudentByNameFetching,
    isLoading: getStudentByNameLoading,
  } = useQuery({
    queryKey: ["getStudentByName", state.studentName, state.page],
    queryFn: () =>
      getStudentByName({
        // session: selectedSession,
        studentName: state.studentName,
        page: state.page,
      }),
    onSuccess: (data) => {
      if (state.page !== 1) {
        setState((prev) => ({
          ...prev,
          studentData: { ...prev.studentData, ...data?.data },
        }));
      } else {
        setState((prev) => ({ ...prev, studentData: data?.data }));
      }
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: state.studentName ? true : false,
  });

  const handleCloseDialog = () => {
    setState((prev) => ({ ...prev, studentData: {}, studentName: "" }));
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: {
          width: "300px",
          height: "40vh",
        },
      }}
    >
      <DialogTitle sx={{ p: "0 !important" }}>
        <DialogHeader title="Search Student" handleClose={handleCloseDialog} />
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          sx={{ py: 1, px: 2 }}
        >
          <CustomTextField
            autoFocus
            fullWidth
            size="small"
            type="text"
            placeholder="Type student name"
            value={state.studentName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                studentName: e.target.value,
                ...(state.page !== 1 && { page: 1 }),
                ...(!e.target.value && { studentData: {} }),
              }))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {(getStudentByNameFetching || getStudentByNameLoading) && (
                    <CircularProgress size={20} />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: "0 !important" }}>
        {state.studentData?.list?.map((item, i) => (
          <Box key={i}>
            <Box
              sx={{
                px: 2,
                "&:hover": {
                  backgroundColor: "#f6f6f6",
                },
              }}
            >
              <Link
                style={{ textDecoration: "none" }}
                to={`/${appRoutes.student_profile}/${item?.studentDocId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Stack direction={"row"} gap={2}>
                  <Stack>
                    <img
                      src={item?.profilePic ?? "/assets/images/avatars/1.png"}
                      alt="student"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "15px",
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                        width: "80%",
                      }}
                    >
                      {item?.name}
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                      S/O:- {item?.fatherName}
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                      Reg No:- {item?.registrationNumber}
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                      Adm No:- {item?.admissionNumber}
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                      Class Name:- {item?.classInfo}
                    </Typography>
                  </Stack>
                </Stack>
              </Link>
            </Box>
            <Divider
              sx={{
                my: 0.5,
                backgroundColor: "#3b3939",
              }}
            />
          </Box>
        ))}
        {state.studentData?.pages !== state.studentData?.page && (
          <Stack direction={"row"} justifyContent={"center"}>
            <Typography
              sx={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
                textAlign: "center",
                display: "inline-block",
              }}
              onClick={() =>
                setState((prev) => ({ ...prev, page: prev.page + 1 }))
              }
            >
              more
            </Typography>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default StudentSearchDialog;
