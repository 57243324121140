import { studentManagementSlice } from "./slice";

export const {
  setSelectedStudent,
  setSelectedStudentRegNo,
  setrequestDocId,
  setExistentStudent,
  setApprovalStudent,
  setAddressFormik,
  setAddmissionFormik,
  setHouseFormik,
  setParentFormik,
  setPersonalFormik,
  setPerviousSchoolFormik,
  setTransportFormik,
  setSubmittingStudentForm,
  setStudentFormErrors,
} = studentManagementSlice.actions;
