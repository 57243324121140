import { Close } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Chip = ({
  title = "chip",
  handleClose = () => {},
  deleteIcon = true,
}) => {
  return (
    <Wrapper>
      <Typography className="text">{title}</Typography>
      {deleteIcon && (
        <Close color="error" onClick={handleClose} sx={{ cursor: "pointer" }} />
      )}
    </Wrapper>
  );
};

export default Chip;
const Wrapper = styled.div`
  background: rgba(43, 81, 177, 0.05);
  border: 1px solid #b6c8d6;
  border-radius: 500px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  .text {
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    color: #0c2f49;
  }
`;
