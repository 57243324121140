import {
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import CharacterCertificatePrint from "./components/character/CharacterCertificatePrint";
import DOBCertificatePrint from "./components/DOB/DOBCertificatePrint";
import SchoolLeavingCertificatePrint from "./components/schoolLeaving/SchoolLeavingCertificatePrint";
import ParticipationCertificate from "./components/participation/ParticipationCertificate";
import BonafideCertificatePrint from "./components/bonafied/BonafideCertificate";
import FeeCertificatePrint from "./components/fee/FeeCertificatePrint";
import A4CharacterCertificatePrint from "./components/character/A4CharacterCertificatePrint";
import TeacherExperienceCertificatePrint from "./components/teacherExperience/TeacherExperienceCertificatePrint";

const PreviewCertificate = ({
  state,
  setState,
  handleProceed = () => {},
  certificateName,
}) => {
  const [template, setTemplate] = useState(1);

  const handleChangeTemplate = (value) => {
    setTemplate(value);
  };
  return (
    <>
      <Stack
        sx={{ m: 1 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button variant="contained" color="secondary" onClick={handleProceed}>
          Proceed
        </Button>
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
            Select Template :
          </Typography>
          <CustomSelectBox
            label="Select Template"
            value={template}
            onChange={(e) => handleChangeTemplate(e.target.value)}
          >
            <MenuItem value={1}>Template 1</MenuItem>
            <MenuItem value={2}>Template 2</MenuItem>
          </CustomSelectBox>
        </Stack>
      </Stack>
      <Divider
        sx={{
          backgroundColor: "#000",
          height: "1px",
          width: "100%",
          margin: "0 auto",
        }}
      />

      <Box
        sx={{
          marginTop: "40px",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {certificateName === "Character Certificate" && template === 1 && (
          <CharacterCertificatePrint />
        )}
        {certificateName === "Character Certificate" && template === 2 && (
          // <A4CharacterCertificatePrint />
          <Typography>Template 2 is not available</Typography>
        )}
        {certificateName === "Birth Certificate" && template === 1 && (
          <DOBCertificatePrint />
        )}
        {certificateName === "School Leaving Certificate" && template === 1 && (
          <SchoolLeavingCertificatePrint />
        )}
        {certificateName === "Participation Certificate" && template === 1 && (
          <ParticipationCertificate />
        )}
        {certificateName === "Bonafide Certificate" && template === 1 && (
          <BonafideCertificatePrint />
        )}
        {certificateName === "Fee Certificate" && template === 1 && (
          <FeeCertificatePrint />
        )}
        {certificateName === "Teacher Experience Certificate" &&
          template === 1 && <TeacherExperienceCertificatePrint />}
      </Box>
    </>
  );
};

export default PreviewCertificate;
const ColorBox = styled(Box)`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid gray;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;
