import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStudentsByClassDocId } from "app/services/student-management";
import { getAdmissionRequests } from "../../services/student-management";

export const getStudentListForPromotion = createAsyncThunk(
  "promotionAndMigration/studentListForPromotion",
  async (payload) => {
    try {
      const { data } = await getStudentsByClassDocId(
        payload.classDocId,
        payload.section,
        payload.session
      );
      return data;
    } catch (err) {}
  }
);

export const getAdmissionRequestsThunk = createAsyncThunk(
  "getAdmissionRequest",
  async (payload) => {
    try {
      const { data } = await getAdmissionRequests(payload);
      return data;
    } catch (err) {}
  }
);
