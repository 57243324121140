import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";

import { Form, Formik, useFormik } from "formik";

import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";

import "react-notifications/lib/notifications.css";
import { LoadingButton } from "@mui/lab";
import SelectComponent from "../../../../fee-management/old-component/components/FormUi/SelectComponent";
import TextFieldComponent from "../../../../fee-management/old-component/components/FormUi/TextfieldComponent";
import DatePickerComponent from "../../../../fee-management/old-component/components/FormUi/DatePickerComponent";
import SelectComponentDpt from "./SelectComponentDpt";
import { useState } from "react";
import { selectActiveSession } from "../../../../schools/selectors";
import { approveJoiningRequest } from "../../../../../services/hr";
import { useMutation } from "react-query";
import {
  primaryRoles,
  primaryRolesForJoiningApproval,
} from "../../../../../config/administrativeConstants";
import { toast } from "react-toastify";
import { queryClient } from "App";
import DialogHeader from "app/components/common/DialogHeader";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { MenuProps } from "app/utils/helper";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const VALIDATION_SCHEMA = Yup.object().shape({
  primaryRole: Yup.string().required("Primary Role Required"),
  employmentType: Yup.string().required("employmentType Required"),
  department: Yup.string().required("department Required"),
  designation: Yup.string().required("designation Required"),
  otherDesignation: Yup.string().when("designation", {
    is: "Other",
    then: Yup.string().required("Other Designation Required"),
    otherwise: Yup.string(),
  }),
  joiningDate: Yup.date().required("joiningDate Required"),

  plAvailable: Yup.number().typeError("Enter Numbers Only"),
  clAvailable: Yup.number().typeError("Enter Numbers Only"),
  slAvailable: Yup.number().typeError("Enter Numbers Only"),
});

export default function EmploymentModal({
  open,
  setOpen,
  requestDocId,
  departmentList,
  setParentOpen,
  staffDocId,
}) {
  const session = useSelector(selectActiveSession);
  const dt = new Date();
  const {
    values,
    setValues,
    resetForm,
    touched,
    errors,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      primaryRole: "",
      employmentType: "",
      department: "",
      designation: "",
      otherDesignation: "",
      joiningDate: dt,
      plAvailable: 0,
      clAvailable: 0,
      slAvailable: 0,
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values, { resetForm }) => {
      const finalObj = {
        requestDocId: requestDocId,
        employmentInfo: {
          joiningDate: values.joiningDate,
          departmentDocId: values.department,
          designation:
            values.designation === "Other"
              ? values.otherDesignation
              : values.designation,
          employmentType: values.employmentType,
          primaryRole: values.primaryRole,
        },
        staffLeave: {
          session: session,
          clAvailable: parseInt(values.clAvailable),
          plAvailable: parseInt(values.plAvailable),
          slAvailable: parseInt(values.slAvailable),
        },
      };

      handleApprove(finalObj);
      if (approvalSuccess) {
        resetForm();
      }
    },
  });
  const {
    mutate,
    isLoading: approving,
    isSuccess: approvalSuccess,
  } = useMutation(approveJoiningRequest, {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      queryClient.invalidateQueries({ queryKey: ["getAllJoiningRequest"] });
      setOpen(false);
      setParentOpen(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleApprove = (finalObj) => {
    mutate(finalObj);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { minWidth: "500px" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Employent Information"
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <form onSubmit={handleSubmit} id="joining-approval-form-approve">
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title="Primary Role" />
                  <CustomSelectBox
                    MenuProps={MenuProps}
                    size="small"
                    name="primaryRole"
                    value={values.primaryRole}
                    fullWidth
                    displayEmpty
                    onChange={(e) =>
                      setValues((prv) => ({
                        ...prv,
                        primaryRole: e.target.value,
                        designation: "",
                      }))
                    }
                    error={touched.primaryRole && errors.primaryRole}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {Object.keys(primaryRolesForJoiningApproval)?.map(
                      (item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      )
                    )}
                  </CustomSelectBox>
                  {touched.primaryRole && errors.primaryRole && (
                    <Typography color="error">{errors.primaryRole}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title="Designation" />
                  <CustomSelectBox
                    MenuProps={MenuProps}
                    size="small"
                    name="designation"
                    value={values.designation}
                    fullWidth
                    displayEmpty
                    onChange={handleChange}
                    error={touched.designation && errors.designation}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {values.primaryRole &&
                      primaryRolesForJoiningApproval[values.primaryRole]?.map(
                        (item, i) => (
                          <MenuItem value={item} key={i}>
                            {item}
                          </MenuItem>
                        )
                      )}
                    <MenuItem value="Other">Other</MenuItem>
                  </CustomSelectBox>
                  {touched.designation && errors.designation && (
                    <Typography color="error">{errors.designation}</Typography>
                  )}
                </Grid>
                {values.designation === "Other" && (
                  <Grid item xs={12} sm={6}>
                    <TextFieldLabel title="Other Designation" />
                    <CustomTextField
                      size="small"
                      fullWidth
                      name="otherDesignation"
                      value={values.otherDesignation}
                      onChange={handleChange}
                      placeholder="type designation"
                      error={
                        Boolean(errors.otherDesignation) &&
                        touched.otherDesignation
                      }
                      helperText={
                        touched.otherDesignation && errors.otherDesignation
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title="Employment Type" />
                  <CustomSelectBox
                    MenuProps={MenuProps}
                    size="small"
                    name="employmentType"
                    value={values.employmentType}
                    fullWidth
                    displayEmpty
                    onChange={handleChange}
                    error={touched.employmentType && errors.employmentType}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {["Full Time", "Part Time"].map((item, i) => (
                      <MenuItem value={item} key={i}>
                        {item}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                  {touched.employmentType && errors.employmentType && (
                    <Typography color="error">
                      {errors.employmentType}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title="Department" />
                  <CustomSelectBox
                    MenuProps={MenuProps}
                    size="small"
                    name="department"
                    value={values.department}
                    fullWidth
                    displayEmpty
                    onChange={handleChange}
                    error={touched.department && errors.department}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {departmentList?.data?.list?.map((item, i) => (
                      <MenuItem value={item?._id} key={i}>
                        {item?.departmentName}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                  {touched.department && errors.department && (
                    <Typography color="error">{errors.department}</Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title="Joining Date" />
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      inputFormat="DD MMM, YYYY"
                      onChange={(e) =>
                        setValues((prev) => ({ ...prev, joiningDate: e }))
                      }
                      value={values.joiningDate}
                      renderInput={(params) => (
                        <CustomTextField {...params} size="small" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12}>
                  <Box sx={{ mt: 0.5 }}>
                    <Typography
                      color="primar.main"
                      fontSize="16px"
                      fontWeight={600}
                    >
                      Leave Details
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextFieldLabel title="Privilege Leave" />
                  <CustomTextField
                    name="plAvailable"
                    size="small"
                    value={values.plAvailable}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextFieldLabel title="Casual Leave" />

                  <CustomTextField
                    name="clAvailable"
                    size="small"
                    value={values.clAvailable}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextFieldLabel title="Sick Leave" />

                  <CustomTextField
                    name="slAvailable"
                    size="small"
                    value={values.slAvailable}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              resetForm();
              setOpen(false);
            }}
            size="large"
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            size="large"
            loading={approving}
            variant="contained"
            color="secondary"
            form="joining-approval-form-approve"
          >
            Approve
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
