import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";

const NameComponent = ({ label, text, flex = 1 }) => {
  return (
    <Box>
      <Typography fontSize={14} color="primary" sx={{ opacity: 0.75 }}>
        {label}
      </Typography>
      <Typography fontSize={16} fontWeight={600} color="primary">
        {text && text !== "undefined" && text !== undefined
          ? String(text).replace(/undefined/g, "")
          : "--"}
      </Typography>
    </Box>
  );
};
export default function PersonalInfo({ data }) {
  return (
    <Grid container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12} sm={4}>
        <NameComponent
          label="Name"
          text={`${data.firstName} ${data?.middleName && data?.middleName} ${
            data?.lastName
          }`}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NameComponent label="Gender" text={data.gender} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NameComponent
          label="Date Of Birth"
          text={
            data?.DOB ? dayjs(new Date(data?.DOB)).format("DD MMM, YYYY") : "--"
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NameComponent
          label="Father's Name"
          text={`${data?.fatherInfo?.firstName} ${
            data?.fatherInfo?.middleName && data?.fatherInfo?.middleName
          } ${data?.motherInfo?.lastName}`}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NameComponent
          label="Mother's Name"
          text={`${data?.motherInfo?.firstName} ${
            data?.motherInfo?.middleName && data?.motherInfo?.middleName
          } ${data?.motherInfo?.lastName}`}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NameComponent label="Mobile Number" text={data?.mobileNumber} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <NameComponent label="Email Id" text={data.email} />
      </Grid>
    </Grid>
  );
}
