import React from "react";
import CommonDialog from "../../CommonDialog";
import {
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { IndianCurrency } from "app/utils/helper";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { approveExpense, rejectExpense } from "app/services/ledgerAccount";
import dayjs from "dayjs";
const ApproveRejectDialog = ({
  open = false,
  onClose = () => {},
  data = {},
  refetch = () => {},
}) => {
  const arFormik = useFormik({
    initialValues: {
      approvedAmount: "",
      remarks: "",
    },
    validationSchema: yup.object({
      approvedAmount: yup.number().required("Amount is required"),
      remarks: yup.string().required("Remarks is required"),
    }),
    onSubmit: (values) => {
      approveExpenseMutate({ ...values, expenseDocId: data?._id });
    },
  });
  const handleClose = () => {
    onClose();
    arFormik.resetForm();
  };
  const { mutate: approveExpenseMutate, isLoading: approveExpenseLoading } =
    useMutation(approveExpense, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        refetch();
        handleClose();
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  const { mutate: rejectExpenseMutate, isLoading: rejectExpenseLoading } =
    useMutation(rejectExpense, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        handleClose();
        refetch();
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  return (
    <CommonDialog
      open={open}
      onClose={handleClose}
      minWidth="700px"
      title="Approve / Reject Expense"
    >
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}
      >
        <form onSubmit={arFormik.handleSubmit} id="arForm">
          <Stack direction="column" gap={2}>
            <Stack direction="row" gap={2}>
              <Stack flex={1}>
                <Label>Expense Head Name</Label>
                <Content>{data?.expenseHeadName || "--"}</Content>
              </Stack>
              <Stack flex={1}>
                <Label>Sub Head Name</Label>
                <Content>{data?.expenseSubHeadName || "--"}</Content>
              </Stack>
              <Stack flex={1}>
                <Label>Recipient Person Name</Label>
                <Content>{data?.createdBy?.personName || "--"}</Content>
              </Stack>
              <Stack flex={1}>
                <Label>Requested Amount</Label>
                <Content>{IndianCurrency(data?.requestedAmount)}</Content>
              </Stack>
            </Stack>
            <Stack>
              <Label>Purpose</Label>
              <Content>{data?.purpose || "--"}</Content>
            </Stack>
            <Stack direction="row" gap={2}>
              <Stack flex={1}>
                <Label>Approval Amount</Label>
                <CustomTextField
                  name="approvedAmount"
                  onBlur={arFormik.handleBlur}
                  placeholder="Enter Amount"
                  value={arFormik.values.approvedAmount}
                  onChange={arFormik.handleChange}
                />
                {arFormik.touched.approvedAmount &&
                  arFormik.errors.approvedAmount && (
                    <Typography sx={{ color: "red" }}>
                      {arFormik.errors.approvedAmount}
                    </Typography>
                  )}
              </Stack>
              <Stack flex={3}>
                <Label>Approval/Rejection Remarks</Label>
                <CustomTextField
                  name="remarks"
                  onBlur={arFormik.handleBlur}
                  placeholder="Enter Remarks"
                  value={arFormik.values.remarks}
                  onChange={arFormik.handleChange}
                />
                {arFormik.touched.remarks && arFormik.errors.remarks && (
                  <Typography sx={{ color: "red" }}>
                    {arFormik.errors.remarks}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </form>
        <Stack direction="row" gap={2}>
          <Stack flex={1}>
            <Label>
              Created By: <span>{data?.createdBy?.personName || "--"}</span>
            </Label>
            <Label>
              Created On:{" "}
              <span>
                {data?.createdOn
                  ? dayjs(data?.createdOn).format("DD MMM, YYYY")
                  : "--"}
              </span>
            </Label>
          </Stack>
          <Stack flex={1}>
            <Label>
              Action Taken By:{" "}
              <span>{data?.actionTakenBy?.personName || "--"}</span>
            </Label>
            <Label>
              Action Taken On:{" "}
              <span>
                {data?.actionTakenOn
                  ? dayjs(data?.actionTakenOn).format("DD MMM, YYYY")
                  : "--"}
              </span>
            </Label>
          </Stack>
          <Stack flex={1}>
            <Label>
              Paid By: <span>{data?.paidBy?.personName || "--"}</span>
            </Label>
            <Label>
              Paid On:{" "}
              <span>
                {data?.paidOn
                  ? dayjs(data?.paidOn).format("DD MMM, YYYY")
                  : "--"}
              </span>
            </Label>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={rejectExpenseLoading}
          onClick={() => rejectExpenseMutate(data?._id)}
          variant="outlined"
          color="error"
          size="large"
        >
          Reject
        </LoadingButton>
        <LoadingButton
          type="submit"
          form="arForm"
          loading={approveExpenseLoading}
          variant="contained"
          color="secondary"
          size="large"
        >
          Approve
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

export default ApproveRejectDialog;

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 500,
  color: "#0C2F49",
}));

const Content = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  fontWeight: 700,
  color: "#0C2F49",
}));
