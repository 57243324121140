import { Typography, Grid } from "@mui/material";

const NameComponent = ({ label, text }) => {
  return (
    <Grid item xs={4}>
      <Typography fontSize={12} color="primary" sx={{ opacity: 0.75 }}>
        {label}
      </Typography>
      <Typography fontSize={14} fontWeight={600} color="primary">
        {text && text !== "undefined" && text !== undefined
          ? String(text).replace(/undefined/g, "")
          : "--"}
      </Typography>
    </Grid>
  );
};
export default function PrevEdDetail({ data }) {
  return (
    <>
      <Grid container spacing={1}>
        <NameComponent
          label="Institute Name"
          text={data?.educationInfo?.instituteName}
        />
        <NameComponent
          label="Board/University"
          text={data?.educationInfo?.board}
        />
        <NameComponent label="Class" text={data?.educationInfo?.class} />
        <NameComponent label="Stream" text={data?.educationInfo?.stream} />
        <NameComponent label="Marks/Grade" text={data?.educationInfo?.grade} />
        <NameComponent
          label="Passing Year"
          text={data?.educationInfo?.passingYear}
        />
      </Grid>
    </>
  );
}
