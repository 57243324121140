import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Drawer,
  TextField,
  CircularProgress,
  Skeleton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import styled from "styled-components";

import { X } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import "react-notifications/lib/notifications.css";

//our file imports
import { mobile } from "app/modules/reception/components/responsive";
import CheckBoxComponent from "./CheckBoxComponent";
import { paymentsListSelector, totalSumSelector } from "./selectors";
import { resetFeeCollectionModalState } from "./operations";
import PaymentModal from "./PaymentModal";
import { CustomTextFieldDisabled } from "assets/styles/globalStyledComponent";

export default function FeeCollectionModal({
  show,
  setShow,
  studentFeeDetails,
  handleInvoiceDetails,
  fetchFeeCollectionByRegNo,
}) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const paymentList = useSelector(paymentsListSelector);
  const [totalSum, setTotalSum] = useState(0);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const calculateTotalSum = (paymentList) => {
    if (paymentList?.length > 0) {
      // check if Student has any current year due
      let current_year_due = parseInt(studentFeeDetails?.studentDetail?.dues);

      let sum = 0;
      Object.entries(paymentList)?.map(([key, value]) => {
        sum += value.totalSum;
      });

      if (current_year_due > 0) {
        sum = sum - Math.abs(current_year_due);
      } else {
        sum = sum + Math.abs(current_year_due);
      }

      setTotalSum(sum);
    } else {
      setTotalSum(0);
    }
  };

  useEffect(() => {
    calculateTotalSum(paymentList);
  }, [paymentList]);

  const handleClose = () => {
    dispatch(resetFeeCollectionModalState());
    setShow(false);
  };
  return (
    <>
      <PaymentModal
        open={openPaymentModal}
        setOpen={setOpenPaymentModal}
        totalSum={totalSum}
        studentDocId={studentFeeDetails?.studentDetail?._id}
        parentModalSetOpen={setShow}
        fetchFeeCollectionByRegNo={fetchFeeCollectionByRegNo}
      />
      <Drawer
        open={show}
        onClose={() => {
          handleClose();
        }}
        anchor="right"
        PaperProps={{
          sx: { minWidth: "80vw", maxWidth: isMobile && "80vw" },
        }}
      >
        <>
          {!studentFeeDetails ? (
            <Stack direction="column" gap={2} sx={{ p: 2 }}>
              <Skeleton variant="rectangular" width={"100%"} height={60} />
              <Skeleton variant="rectangular" width={"100%"} height={100} />
              <Skeleton variant="rectangular" width={"100%"} height={300} />
              <Skeleton variant="rectangular" width={"100%"} height={100} />
            </Stack>
          ) : (
            <MainWrapper>
              <FormWrapper>
                <FormHeaderWrapper>
                  <Typography fontSize={18} fontWeight={600}>
                    Fee Collection Form
                  </Typography>
                  <button
                    style={{
                      width: "25px",
                      height: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0",
                      margin: "0",
                      borderRadius: "4px",
                      background: "transparent",
                    }}
                  >
                    <X onClick={() => handleClose()} />
                  </button>
                </FormHeaderWrapper>
                <hr
                  style={{
                    width: "100%",
                  }}
                />

                <ThirdContainer>
                  <ThirdContainerInnerWrapper
                    style={{
                      flexWrap: isMobile && "wrap",
                      gap: isMobile && "30px",
                    }}
                  >
                    <ThirdContainerItems style={{ width: isMobile && "30%" }}>
                      <Typography fontWeight="semi-bold" fontSize={12}>
                        Student's Name
                      </Typography>
                      <Typography fontWeight="bold" fontSize={14}>
                        {`${studentFeeDetails?.studentDetail?.firstName} ${studentFeeDetails?.studentDetail?.lastName}`}
                      </Typography>
                    </ThirdContainerItems>
                    <ThirdContainerItems style={{ width: isMobile && "70%" }}>
                      <Typography fontWeight="semi-bold" fontSize={12}>
                        Class-Stream-Section
                      </Typography>
                      <Typography fontWeight="bold" fontSize={14}>
                        {`${studentFeeDetails?.studentDetail?.className}-${studentFeeDetails?.studentDetail?.stream}-${studentFeeDetails?.studentDetail?.section}`}
                      </Typography>
                    </ThirdContainerItems>
                    <ThirdContainerItems style={{ width: isMobile && "30%" }}>
                      <Typography fontWeight="semi-bold" fontSize={12}>
                        Mobile Number
                      </Typography>
                      <Typography fontWeight="bold" fontSize={14}>
                        {studentFeeDetails?.studentDetail?.mobileNumber}
                      </Typography>
                    </ThirdContainerItems>
                    <ThirdContainerItems style={{ width: isMobile && "30%" }}>
                      <Typography fontWeight="semi-bold" fontSize={12}>
                        Father's Name
                      </Typography>
                      <Typography fontWeight="bold" fontSize={14}>
                        {`${
                          studentFeeDetails?.studentDetail?.fatherInfo
                            ?.firstName
                        } ${
                          studentFeeDetails?.studentDetail?.fatherInfo
                            ?.middleName ?? ""
                        } ${
                          studentFeeDetails?.studentDetail?.fatherInfo
                            ?.lastName ?? ""
                        }`}
                      </Typography>
                    </ThirdContainerItems>
                    <ThirdContainerItems style={{ width: isMobile && "30%" }}>
                      <Typography fontWeight="semi-bold" fontSize={12}>
                        Any Siblings
                      </Typography>
                      <Typography fontWeight="bold" fontSize={14}>
                        No
                      </Typography>
                    </ThirdContainerItems>
                  </ThirdContainerInnerWrapper>

                  <hr style={{ width: "100%" }} />

                  <ThirdContainerInnerWrapper
                    style={{
                      flexWrap: isMobile && "wrap",
                      gap: isMobile && "30px",
                    }}
                  >
                    <ThirdContainerItems style={{ width: isMobile && "30%" }}>
                      <Typography fontWeight="semi-bold" fontSize={12}>
                        Last Year Arrear
                      </Typography>
                      <Typography fontWeight="bold" fontSize={14}>
                        {`INR ${studentFeeDetails?.studentDetail?.arrearAmount}`}
                      </Typography>
                    </ThirdContainerItems>
                    <ThirdContainerItems style={{ width: isMobile && "30%" }}>
                      <Typography fontWeight="semi-bold" fontSize={12}>
                        Total To Be Paid this year
                      </Typography>
                      <Typography fontWeight="bold" fontSize={14}>
                        {studentFeeDetails?.studentDetail?.totalBalance}
                      </Typography>
                    </ThirdContainerItems>
                    <ThirdContainerItems style={{ width: isMobile && "30%" }}>
                      <Typography fontWeight="semi-bold" fontSize={12}>
                        Total Paid this year
                      </Typography>
                      <Typography fontWeight="bold" fontSize={14}>
                        {studentFeeDetails?.studentDetail?.totalPaid}
                      </Typography>
                    </ThirdContainerItems>
                    <ThirdContainerItems style={{ width: isMobile && "30%" }}>
                      <Typography fontWeight="semi-bold" fontSize={12}>
                        Previous Dues
                      </Typography>
                      <Typography fontWeight="bold" fontSize={14}>
                        {studentFeeDetails?.studentDetail?.dues}
                      </Typography>
                    </ThirdContainerItems>
                  </ThirdContainerInnerWrapper>
                </ThirdContainer>

                {/* SHow Fee Collection table */}

                <TableContainer>
                  {/* <Table columns={columns} data={studentFeeDetails} /> */}

                  <table style={{ width: "100%" }}>
                    <TableHeader>
                      {/* table header */}
                      <TableHeaderRow
                        style={{
                          flexDirection: isMobile && "column",
                          height: isMobile && "auto",
                        }}
                      >
                        <TableHeadCell style={{ width: "7%" }}>
                          Sr.No
                        </TableHeadCell>
                        <TableHeadCell>Month Name</TableHeadCell>
                        <TableHeadCell>Due Date</TableHeadCell>
                        <TableHeadCell style={{ width: "13%" }}>
                          Fee Type
                        </TableHeadCell>
                        <TableHeadCell>Fee Structure</TableHeadCell>
                        <TableHeadCell>Amount</TableHeadCell>
                        <TableHeadCell>Total Fine</TableHeadCell>
                        <TableHeadCell>Total Fee</TableHeadCell>
                        <TableHeadCell>Invoice Detail</TableHeadCell>
                        <TableHeadCell></TableHeadCell>
                      </TableHeaderRow>
                    </TableHeader>

                    {/* Table Body */}
                    <TableBodyStyle>
                      {studentFeeDetails &&
                        studentFeeDetails?.list?.map((feeObject, index) => {
                          return (
                            <TableRow
                              style={{ flexDirection: isMobile && "column" }}
                              key={index}
                            >
                              <TableCellStyle style={{ width: "5%" }}>
                                {index + 1}
                              </TableCellStyle>
                              <TableCellStyle>
                                {feeObject?.monthYear}
                              </TableCellStyle>
                              <TableCellStyle>Due Date</TableCellStyle>
                              <CheckBoxComponent
                                feeCollObject={feeObject}
                                totalAmount={feeObject?.total_FeeAmount}
                                monthName={feeObject?.monthYear}
                                handleInvoiceDetails={handleInvoiceDetails}
                              />
                            </TableRow>
                          );
                        })}
                    </TableBodyStyle>
                  </table>
                </TableContainer>

                {/* Total Fee Calculation */}
                <ButtonContainer
                  style={{
                    flexDirection: isMobile && "column",
                    height: isMobile && "auto",
                    alignItems: isMobile && "flex-start",
                  }}
                >
                  <div
                    style={{
                      width: isMobile ? "100%" : "20%",
                    }}
                  >
                    <Typography
                      sx={{ mt: 1 }}
                      // marginBottom={2}
                      // marginTop={5}
                      fontWeight={500}
                      fontSize={isMobile ? 12 : 14}
                    >
                      Total Fee Amount
                    </Typography>
                    <CustomTextFieldDisabled
                      fullWidth
                      size="small"
                      value={totalSum}
                    />
                  </div>
                  <div
                    style={{
                      width: isMobile ? "100%" : "20%",
                    }}
                  >
                    <Typography
                      sx={{ mt: 1 }}
                      // marginBottom={2}
                      // marginTop={2}
                      fontWeight={500}
                      fontSize={14}
                    >
                      Late Fine
                    </Typography>
                    <CustomTextFieldDisabled fullWidth size="small" />
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      height: "35px",
                      width: isMobile ? "100%" : "15%",
                    }}
                    onClick={() => setOpenPaymentModal(true)}
                  >
                    Pay Now
                  </Button>
                </ButtonContainer>
              </FormWrapper>
            </MainWrapper>
          )}
        </>
      </Drawer>
    </>
  );
}

const ButtonContainer = styled.div`
  width: 100%;
  height: 8rem;
  border: 1px solid #b6c8d6;
  border-radius: 15px;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
`;

const FormHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  line-height: 22px;
  color: #0c2f49;
  margin-top: 20px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  color: #0c2f49;
  gap: 0.5rem;
  ${mobile({ width: "100%", paddingLeft: "2vw", paddingRight: "2vw" })}
`;

const MainWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  ${mobile({ width: "100%" })}
`;

const SecondContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ThirdContainer = styled.div`
  width: 100%;
  background: rgba(43, 81, 177, 0.05);
  border: 1px solid rgba(27, 50, 60, 0.05);
  border-radius: 10px;
  padding: 2rem;
`;

const ThirdContainerInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ThirdContainerItems = styled.div`
  width: 20%;
  padding-right: 2rem;
`;

const TableContainer = styled.div`
  width: 100%;
  max-height: 43vh;
  overflow: scroll;
  margin-top: 0.5rem;
`;

const TableHeader = styled.thead`
  width: 100%;
  height: 3rem;
  z-index: 100;
`;

const TableHeaderRow = styled.tr`
  width: 100%;
  height: 55px;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f6f8fc;
  border: 1px solid #b6c8d6;
  border-radius: 12px 12px 0px 0px;
`;

const TableRow = styled.tr`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-top: 1px solid black;
  padding: 1rem;
`;

const TableBodyStyle = styled.tbody`
  width: 100%;
  border-left: 1px solid #b6c8d6;
  border-right: 1px solid #b6c8d6;
`;

const TableCellStyle = styled.td`
  width: 10%;
  min-height: 40px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const TableHeadCell = styled.th`
  width: 10%;
  text-align: left;
`;

const InnerDiv = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 0;
`;
