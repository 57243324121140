import axios from "axios";

const AUTH_BASE = process.env.REACT_APP_AUTH_BASE;

let auth_url = `${AUTH_BASE}/auth`;

export const getOtpPhone = (mobileNo) => {
  return axios.post(`${auth_url}/getOtpPhone/${mobileNo}`);
};

export const registrationOTPonEmail = (email) => {
  return axios.post(`${auth_url}/registrationOTPonEmail/${email}`);
};

export const adminSignup = (data) => {
  return axios.post(`${auth_url}/adminSignup`, data);
};

export const staffSignup = (data) => {
  return axios.post(`${auth_url}/staffSignup`, data);
};

export const verifyOTP = (data) => {
  return axios.put(`${auth_url}/verifyOTP`, data);
};

export const forgetPassword = (email) => {
  return axios.get(`${auth_url}/forgetPassword/${email}`);
};

export const changePassword = (data) => {
  return axios.put(`${auth_url}/changePassword`, data);
};
