import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
  FileEarmarkExcelFill,
  FiletypePdf,
  FilePdf,
} from "react-bootstrap-icons";
import styled from "styled-components";

const ExportButtonGroup = ({
  handleClickPdf = () => {},
  handleClickExcel = () => {},
  handleClickPdfSummary = () => {},
  showExcel = true,
  showPdf = true,
  showPdfSummary = false,
  mt = 1.5,
  justifyContent = "flex-end",
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Wrapper>
      <Stack
        direction="row"
        alignItems="center"
        gap={1.5}
        sx={{ mt }}
        justifyContent={justifyContent}
      >
        <Typography
          className={isMobile ? "export_text__mobile" : "export_text"}
        >
          Export
        </Typography>
        {showExcel && (
          <div
            onClick={handleClickExcel}
            title="Excel"
            className={isMobile ? "icon_box__mobile" : "icon_box"}
            style={{
              background: "rgba(41, 82, 226, 0.1)",
              border: "1px solid rgba(41, 82, 226, 0.2)",
            }}
          >
            <FileEarmarkExcelFill size={isMobile ? 16 : 20} />
          </div>
        )}
        {showPdf && (
          <div
            onClick={handleClickPdf}
            title="Pdf"
            className={isMobile ? "icon_box__mobile" : "icon_box"}
            style={{
              background: "rgba(255, 186, 35, 0.1)",
              border: "1px solid rgba(255, 186, 35, 0.2)",
            }}
          >
            <FiletypePdf size={isMobile ? 16 : 20} />
          </div>
        )}
        {showPdfSummary && (
          <div
            onClick={handleClickPdfSummary}
            title="PdfSummary"
            className={isMobile ? "icon_box__mobile" : "icon_box"}
            style={{
              background: "rgba(41, 219, 91, 0.1)",
              border: "1px solid rgba(255, 186, 35, 0.2)",
            }}
          >
            <FilePdf size={isMobile ? 16 : 20} />
          </div>
        )}
      </Stack>
    </Wrapper>
  );
};

export default ExportButtonGroup;
const Wrapper = styled.div`
  .export_text {
    font-weight: 600;
    font-size: 20px;
    color: #0c2f49;
  }
  .export_text__mobile {
    font-weight: 600;
    font-size: 14px;
    color: #0c2f49;
  }
  .icon_box {
    width: 35px;
    height: 35px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .icon_box__mobile {
    width: 25px;
    height: 25px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;
