import API_ENDPOINTS from "app/config/endpoints";
import { schoolTokenAxios } from "../axios";

export const addNewNotice = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.communication.addNewNotice}`,
    payload
  );
};
export const updateNotice = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.communication.updateNotice}`,
    payload
  );
};
export const publishNotice = (id) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.communication.publishNotice}/${id}`
  );
};
export const revertNotice = (id) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.communication.revertNotice}/${id}`
  );
};
export const getNotice = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.communication.getNotice}`, {
    params: {
      ...params,
    },
  });
};
export const getNoticeByDocId = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.communication.getNotice}`,
    payload
  );
};
export const getSMSAccountDetail = () => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.communication.getSMSAccountDetail}`
  );
};

export const getApprovedTemplateBySenderId = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.communication.getApprovedTemplateBySenderId}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const sendSMS = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.communication.sendSMS}`,
    payload
  );
};
export const getAllTemplateStatus = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.communication.getAllTemplateStatus}`,
    {
      params,
    }
  );
};

export const getMyEntity = () => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.communication.getMyEntity}`);
};
export const addEntity = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.communication.addEntity}`,
    payload
  );
};
export const addNewSenderId = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.communication.addNewSenderId}`,
    payload
  );
};
export const addSMStemplate = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.communication.addSMStemplate}`,
    payload
  );
};
export const editSMStemplate = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.communication.editSMStemplate}/${payload}`
  );
};
export const getSmsAccess = (payload) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.communication.getSmsAccess}`);
};
export const updateSmsAccess = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.communication.updateSmsAccess}`,
    payload
  );
};
