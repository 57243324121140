import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  ListItemText,
  Divider,
  Checkbox,
  Collapse,
  MenuItem,
  Drawer,
} from "@mui/material";
import { useFormik } from "formik";
import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import {
  selectSelectedSchoolId,
  selectedMediumSelector,
  userPrimaryRoleSelector,
} from "app/modules/schools/selectors";
import { ArrowForwardIos } from "@mui/icons-material";
import { getClassDropdown } from "app/services/student-management";
import * as yup from "yup";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { addNewNotice, updateNotice } from "app/services/communication";
import DialogHeader from "app/components/common/DialogHeader";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  CustomSelectBox,
  CustomTextField,
  CustomTextField2,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { LoadingButton } from "@mui/lab";
import PreviewDownloadImage from "app/components/common/PreviewDownloadImage";
import uploadFile from "app/utils/uploadFile";
const names = [
  "ROLE_TEACHER",
  "ROLE_PRINCIPAL",
  "ROLE_ACCOUNTANT",
  "ROLE_RECEPTIONIST",
  "ROLE_LIBRARIAN",
  "ROLE_HUMAN_RESOURCE",
  "ROLE_INVENTORY_MANAGER",
  "ROLE_HOSTEL_WARDEN",
  "ROLE_OTHER_STAFF",
  // "ROLE_CLASS_TEACHER",
];
const validationSchema = yup.object({
  subject: yup.mixed().required("Subject required"),
  category: yup.string().required("Category required"),
  otherCategoryName: yup.string().when("category", {
    is: "Other",
    then: yup.string().required("Category name required"),
  }),
  message: yup.mixed().required("Message required"),
  postClassList: yup.array().when("active", {
    is: "classNotice",
    then: yup.array().min(1, "Atleast one class required"),
  }),
  groupList: yup.array().when("active", {
    is: "groupNotice",
    then: yup.array().min(1, "Atleast one group required"),
  }),
});
const ClassDropDown = ({ item, formikState, index, formikSetState }) => {
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState(item);
  const [update, setUpdate] = useState(false);
  const isMounted = useRef(false);
  const handleCheckClass = () => {
    if (data?.checked) {
      data["checked"] = false;
      data?.sections?.forEach((sec) => {
        sec["checked"] = false;
      });
      setUpdate(!update);
    } else {
      data?.sections?.forEach((sec) => {
        sec["checked"] = true;
      });
      data["checked"] = true;
    }
    setUpdate(!update);
  };
  const handleCheckSection = () => {
    let allSectionChecked = true;
    data?.sections?.forEach((sec) => {
      if (!sec.checked) {
        allSectionChecked = false;
      }
      data["checked"] = allSectionChecked;
      setUpdate(!update);
    });
  };

  useEffect(() => {
    if (isMounted.current) {
      const filteredByClassId = formikState?.postClassList?.filter(
        (res) => res?.classDocId !== data?.classDocId
      );
      const newArray = [];
      data?.sections?.forEach((res) => {
        if (res.checked) {
          newArray.push({
            classDocId: data?.classDocId,
            section: res?.section,
            display: `${data?.className}-${res?.section}`,
          });
        }
      });

      formikSetState((prev) => ({
        ...prev,
        postClassList: [...filteredByClassId, ...newArray],
      }));
    } else {
      isMounted.current = true;
    }
  }, [update]);

  return (
    <Box key={data?.classDocId}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ px: 1, py: 0.5, width: "100%" }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Checkbox
            color="secondary"
            checked={data?.checked}
            onChange={handleCheckClass}
          />
          <Typography>{data?.className}</Typography>
          <Typography
            sx={{
              color: "blue",
              fontWeight: 600,
              fontSize: "14px",
              mt: "-1px",
            }}
          >
            {data?.sections
              ?.filter((item) => item.checked)
              .map((item) => item?.section)
              .join(", ")}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          onClick={() => setExpand(!expand)}
          sx={{ cursor: "pointer", flexGrow: 1 }}
        >
          <ArrowForwardIos
            sx={{
              fontSize: "14px",
              transform: expand
                ? "rotate(270deg) !important"
                : "rotate(0deg) !important",
              cursor: "pointer",
            }}
          />
        </Stack>
      </Stack>
      <Divider sx={{ background: "#B6C8D6" }} />
      <Collapse in={expand}>
        <Stack direction="row" gap={1} sx={{ pl: 5 }}>
          {data?.sections?.map((sec) => (
            <Stack direction="row" alignItems="center" key={sec?.section}>
              <Checkbox
                color="secondary"
                checked={sec?.checked}
                onChange={(e) => {
                  sec["checked"] = !sec?.checked;
                  handleCheckSection();
                }}
              />
              <Typography>{sec?.section}</Typography>
            </Stack>
          ))}
        </Stack>
        <Divider sx={{ background: "#B6C8D6" }} />
      </Collapse>
    </Box>
  );
};
const categories = [
  "Results",
  "Holiday",
  "ExamFee",
  "Exams",
  "Timetable",
  "Ocassion",
  "Admissions",
  "Other",
];
const AddNewNoticeDrawer = ({
  show,
  handleCloseDrawer,
  isEdit,
  editData = {},
  refetchAllNotice = () => {},
}) => {
  const [state, setState] = useState({
    from: dayjs(new Date()).subtract(1, "day"),
    to: new Date(),
    draft: false,
    classData: [],
    publishNoticeDocId: "",
    revertNoticeDocId: "",
  });
  const dt = new Date();
  const selectedMedium = useSelector(selectedMediumSelector);

  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const [loading, setLoading] = useState(false);

  const primaryRole = useSelector(userPrimaryRoleSelector);
  const addNoticeFormik = useFormik({
    initialValues: {
      edit: false,
      show: false,
      noticeDocId: "",
      active: "wholeSchool",
      category: "",
      otherCategoryName: "",
      subject: "",
      message: "",
      classList: [],
      groupList: [],
      exiparyDate: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + 7),
      postClassList: [],
      editNoticeData: null,
      attachments: [],
    },
    validationSchema,
    onSubmit: (values) => {
      const newNotice = {
        noticeFor: values.active,
        active: state.draft,
        expiryDate: values.exiparyDate,
        category:
          values.category === "Other"
            ? values.otherCategoryName
            : values.category,
        subject: values.subject,
        message: values.message,
        publishPersonRole: String(primaryRole).split("_")[1],
        attachments: values.attachments,
      };
      if (values.active === "groupNotice") {
        newNotice["accessLevelForStaff"] = values.groupList;
      }
      if (values.active === "classNotice") {
        const newArray = [];
        values.postClassList.forEach((item) =>
          newArray.push({
            classDocId: item?.classDocId,
            section: item?.section,
          })
        );
        newNotice["classList"] = newArray;
      }
      if (values.edit) {
        newNotice["noticeDocId"] = values.noticeDocId;
        updateNoticeMutate(newNotice);
      } else {
        addNewNoticeMutate(newNotice);
      }
    },
  });
  const handleDeleteImage = (img) => {
    const filteredData = addNoticeFormik.values.attachments.filter(
      (res) => res !== img
    );
    addNoticeFormik.setValues((prev) => ({
      ...prev,
      attachments: filteredData,
    }));
  };

  const handleUpload = async (e) => {
    setLoading(true);
    try {
      let s3FilePath = `notice/${selectedSchoolId}/${e.target.files[0].name}`;
      let attachmentUrl = await uploadFile(
        e.target.files[0],
        s3FilePath,
        e.target.files[0].type
      );
      addNoticeFormik.setValues((prev) => ({
        ...prev,
        attachments: [
          ...addNoticeFormik.values.attachments,
          attachmentUrl.fileURL,
        ],
      }));
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  const handleAddRemoveGroup = (item) => {
    const list = addNoticeFormik.values.groupList;
    if (list.includes(item)) {
      const filteredData = list.filter((res) => res !== item);
      addNoticeFormik.setValues((prev) => ({
        ...prev,
        groupList: filteredData,
      }));
    } else {
      addNoticeFormik.setValues((prev) => ({
        ...prev,
        groupList: [...list, item],
      }));
    }
  };

  const handleCloseDrawerAddEdit = () => {
    addNoticeFormik.setValues((prev) => ({
      ...prev,
      show: false,
      postClassList: [],
      edit: false,
    }));
    addNoticeFormik.resetForm();
    handleCloseDrawer();
  };
  const {
    isFetched,
    isFetching: fetchingClass,
    isLoading: fetchClassLoading,
  } = useQuery({
    queryKey: ["getClassess", selectedSchoolId, selectedMedium, show],
    queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
    onSuccess: ({ data }) => {
      setState((prev) => ({ ...prev, classData: data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: show ? true : false,
  });
  // add new notice mutate
  const { isLoading: addNewNoticeLoading, mutate: addNewNoticeMutate } =
    useMutation(addNewNotice, {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        handleCloseDrawerAddEdit();
        refetchAllNotice();
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    });
  // update notice mutate
  const { isLoading: updateNoticeLoading, mutate: updateNoticeMutate } =
    useMutation(updateNotice, {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        handleCloseDrawerAddEdit();
        refetchAllNotice();
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    });

  useEffect(() => {
    if (isFetched && !addNoticeFormik.values.edit) {
      try {
        if (state.classData && state.classData?.length > 0) {
          const arr = [];
          state.classData?.forEach((item) => {
            const body = {
              classDocId: item?.classDocId,
              className: item?.className_stream,
              sections: [],
              checked: false,
            };
            item?.sections?.forEach((res) => {
              body.sections.push({
                section: res,
                checked: false,
              });
            });
            arr.push(body);
          });
          addNoticeFormik.setValues((prev) => ({ ...prev, classList: arr }));
        }
      } catch (error) {}
    }
  }, [addNoticeFormik.values.show, isFetched]);
  useEffect(() => {
    if (addNoticeFormik.values.edit) {
      if (editData?.noticeFor === "classNotice") {
        const arr = [];
        const postList = [];
        state.classData?.forEach((item) => {
          const body = {
            classDocId: item?.classDocId,
            className: item?.className_stream,
            sections: [],
            checked: false,
          };
          item?.sections?.forEach((res) => {
            body.sections.push({
              section: res,
              checked: false,
            });
          });
          const filteredData = editData?.classList
            ?.filter((res) => res.classDocId === item?.classDocId)
            .sort((a, b) => (a.section > b.section ? 1 : -1));

          filteredData?.forEach((cls) => {
            postList.push({
              classDocId: cls?.classDocId,
              section: cls?.section,
              display: `${item?.className_stream}-${cls?.section}`,
            });

            body?.sections
              ?.sort((a, b) => (a.section > b.section ? 1 : -1))
              .forEach((x, i) => {
                if (x.section === cls?.section) {
                  x["checked"] = true;
                }
              });
          });
          let allSecTrue = true;
          body?.sections.forEach((check) => {
            if (!check?.checked) {
              allSecTrue = false;
            }
          });
          body["checked"] = allSecTrue;
          arr.push(body);
        });
        addNoticeFormik.setValues((prev) => ({
          ...prev,
          classList: arr,
          postClassList: postList,
        }));
      }
    }
  }, [addNoticeFormik.values.edit]);
  useEffect(() => {
    if (isEdit) {
      addNoticeFormik.setValues((prev) => ({
        ...prev,
        edit: true,
        noticeDocId: editData?._id,
        active: editData?.noticeFor,
        category: editData?.category,
        subject: editData?.subject,
        message: editData?.message,
        exiparyDate: editData?.expiryDate,
        groupList: editData?.accessLevelForStaff,
        editNoticeData: editData,
        attachments: editData?.attachments,
      }));
    }
  }, [isEdit]);
  return (
    <Drawer
      open={show}
      onClose={handleCloseDrawerAddEdit}
      anchor="right"
      PaperProps={{
        sx: { width: { xs: "98%", md: "50vw" } },
      }}
    >
      <DialogHeader title="Add Notice" handleClose={handleCloseDrawerAddEdit} />
      <Box sx={{ p: 2 }}>
        <form>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap={1.2}
          >
            <Box>
              <TextFieldLabel title="Notice For" />
              <Stack direction="row" alignItems="center" gap={1}>
                <Button
                  size="small"
                  variant={
                    addNoticeFormik.values.active === "wholeSchool"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={(e) => {
                    addNoticeFormik.setValues((prev) => ({
                      ...prev,
                      active: "wholeSchool",
                    }));
                  }}
                  color="secondary"
                  sx={{ textTransform: "capitalize", fontSize: "14px" }}
                >
                  Whole School
                </Button>
                <Button
                  size="small"
                  variant={
                    addNoticeFormik.values.active === "groupNotice"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={(e) => {
                    addNoticeFormik.setValues((prev) => ({
                      ...prev,
                      active: "groupNotice",
                    }));
                  }}
                  color="secondary"
                  sx={{ textTransform: "capitalize", fontSize: "14px" }}
                >
                  Group
                </Button>
                <Button
                  size="small"
                  variant={
                    addNoticeFormik.values.active === "classNotice"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={(e) => {
                    addNoticeFormik.setValues((prev) => ({
                      ...prev,
                      active: "classNotice",
                    }));
                  }}
                  color="secondary"
                  sx={{ textTransform: "capitalize", fontSize: "14px" }}
                >
                  Class
                </Button>
              </Stack>
            </Box>

            <Box>
              <TextFieldLabel title="Expires On" />
              <DatePicker
                inputFormat="DD-MM-YYYY"
                value={addNoticeFormik.values.exiparyDate}
                onChange={(e) =>
                  addNoticeFormik.setValues((prev) => ({
                    ...prev,
                    exiparyDate: e,
                  }))
                }
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    placeholder="Select"
                    size="small"
                  />
                )}
              />
            </Box>
          </Stack>

          {addNoticeFormik.values.active === "groupNotice" && (
            <Box sx={{ mt: 2 }}>
              <TextFieldLabel title="Select Group" required />
              <CustomSelectBox
                displayEmpty
                multiple
                value={addNoticeFormik.values.groupList}
                size="small"
                fullWidth
                MenuProps={MenuProps}
                error={
                  addNoticeFormik.touched.groupList &&
                  Boolean(addNoticeFormik.errors.groupList)
                }
                renderValue={() =>
                  addNoticeFormik.values.groupList?.length > 0 ? (
                    <Typography
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {addNoticeFormik.values.groupList.join(", ")}
                    </Typography>
                  ) : (
                    <Typography>Select</Typography>
                  )
                }
              >
                <MenuItem value="">Select</MenuItem>
                {names.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    onClick={() => handleAddRemoveGroup(item)}
                  >
                    <Checkbox
                      checked={
                        addNoticeFormik.values.groupList.indexOf(item) > -1
                      }
                    />
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </CustomSelectBox>
              {addNoticeFormik.touched.groupList &&
                addNoticeFormik.errors.groupList && (
                  <Typography color="error">
                    {addNoticeFormik.errors.groupList}
                  </Typography>
                )}
            </Box>
          )}
          {addNoticeFormik.values.active === "classNotice" && (
            <Box sx={{ mt: 2 }}>
              <TextFieldLabel
                title="Class-Stream-sec"
                sx={{ mb: 0.5, mt: 1.5 }}
                required
              />
              <CustomSelectBox
                size="small"
                fullWidth
                multiple
                MenuProps={MenuProps}
                value={addNoticeFormik.values.postClassList}
                error={
                  addNoticeFormik.touched.postClassList &&
                  Boolean(addNoticeFormik.errors.postClassList)
                }
                renderValue={() =>
                  addNoticeFormik.values?.postClassList?.length !== 0 ? (
                    <Stack direction="row" gap={0.5}>
                      <Typography
                        sx={{
                          width: "90%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {addNoticeFormik.values.postClassList
                          .map((cls) => cls?.display)
                          .join(", ")}
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography>Select</Typography>
                  )
                }
              >
                {addNoticeFormik.values.classList?.map((item, ind) => (
                  <ClassDropDown
                    item={item}
                    index={ind}
                    formikSetState={addNoticeFormik.setValues}
                    formikState={addNoticeFormik.values}
                    key={ind}
                  />
                ))}
              </CustomSelectBox>
              {addNoticeFormik.touched.postClassList &&
                addNoticeFormik.errors.postClassList && (
                  <Typography color="error">
                    {addNoticeFormik.errors.postClassList}
                  </Typography>
                )}
            </Box>
          )}
          <Stack direction="row" gap={1.5} sx={{ pt: 2 }}>
            <Box sx={{ flex: 1 }}>
              <TextFieldLabel
                title="Category"
                sx={{ mb: 0.5, mt: 1.5 }}
                required
              />
              <CustomSelectBox
                value={addNoticeFormik.values.category}
                fullWidth
                displayEmpty
                onChange={addNoticeFormik.handleChange}
                name="category"
                MenuProps={MenuProps}
                error={
                  addNoticeFormik.touched.category &&
                  Boolean(addNoticeFormik.errors.category)
                }
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {categories?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </CustomSelectBox>
              {addNoticeFormik.touched.category &&
                addNoticeFormik.errors.category && (
                  <Typography color="error">
                    {addNoticeFormik.errors.category}
                  </Typography>
                )}
            </Box>
            {addNoticeFormik.values.category === "Other" && (
              <Box sx={{ flex: 1 }}>
                <TextFieldLabel
                  title="Other Category Name"
                  sx={{ mb: 0.5, mt: 1.5 }}
                  required
                />
                <CustomTextField
                  placeholder="Type other category name"
                  value={addNoticeFormik.values.otherCategoryName}
                  fullWidth
                  displayEmpty
                  onChange={addNoticeFormik.handleChange}
                  name="otherCategoryName"
                  MenuProps={MenuProps}
                  error={
                    addNoticeFormik.touched.otherCategoryName &&
                    Boolean(addNoticeFormik.errors.otherCategoryName)
                  }
                />

                {addNoticeFormik.touched.otherCategoryName &&
                  addNoticeFormik.errors.otherCategoryName && (
                    <Typography color="error">
                      {addNoticeFormik.errors.otherCategoryName}
                    </Typography>
                  )}
              </Box>
            )}
          </Stack>
          <Box sx={{ mt: 2 }}>
            <TextFieldLabel title="Subject" required />
            <CustomTextField
              size="small"
              placeholder="Type Subject of Notice"
              name="subject"
              value={addNoticeFormik.values.subject}
              onChange={addNoticeFormik.handleChange}
              fullWidth
              error={
                addNoticeFormik.touched.subject &&
                Boolean(addNoticeFormik.errors.subject)
              }
            />
            {addNoticeFormik.touched.subject &&
              addNoticeFormik.errors.subject && (
                <Typography color="error">{addNoticeFormik.errors}</Typography>
              )}
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextFieldLabel title="Message" required />
            <textarea
              style={{
                resize: "none",
                width: "100%",
                height: "100px",
                padding: "10px",
                fontSize: "14px",
                outlineColor:
                  addNoticeFormik.touched.message &&
                  Boolean(addNoticeFormik.errors.message) &&
                  "red",
                borderColor:
                  addNoticeFormik.touched.message &&
                  Boolean(addNoticeFormik.errors.message) &&
                  "red",
              }}
              placeholder="Type message"
              onChange={addNoticeFormik.handleChange}
              fullWidth
              value={addNoticeFormik.values.message}
              name="message"
            />
            {addNoticeFormik.touched.message &&
              addNoticeFormik.errors.message && (
                <Typography color="error">
                  {addNoticeFormik.errors.message}
                </Typography>
              )}
          </Box>

          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
            sx={{ mt: 2 }}
          >
            <Stack gap={2}>
              <Typography
                className="customLabels"
                style={{ fontSize: "12px" }}
                sx={{ mt: 2 }}
              >
                Attachment (if any)
              </Typography>
              <Stack
                direction="row"
                gap={2}
                flexWrap="wrap"
                alignItems="center"
              >
                {addNoticeFormik.values.attachments?.map((item, ind) => (
                  <Box sx={{ my: 1 }} key={ind}>
                    <PreviewDownloadImage
                      src={item}
                      deleteIcon={true}
                      handleDelete={() => handleDeleteImage(item)}
                    />
                  </Box>
                ))}
                <LoadingButton
                  variant="outlined"
                  component="label"
                  color="secondary"
                  size="large"
                  sx={{
                    mt: 1,
                    textTransform: "capitalize",
                    px: 2,
                    py: 0.5,
                    borderRadius: "20px",
                  }}
                  loading={loading}
                >
                  Upload
                  <input
                    hidden
                    type="file"
                    onChange={handleUpload}
                    accept="image/*"
                  />
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={"row"} gap={1.5} justifyContent={"flex-end"}>
            <LoadingButton
              loading={addNewNoticeLoading || updateNoticeLoading}
              variant="outlined"
              size="large"
              color="secondary"
              onClick={() => {
                setState((prev) => ({ ...prev, draft: false }));
                addNoticeFormik.handleSubmit();
              }}
              disabled={!!Object.keys(addNoticeFormik.errors).length}
            >
              Save As Draft
            </LoadingButton>
            <LoadingButton
              loading={addNewNoticeLoading || updateNoticeLoading}
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => {
                setState((prev) => ({ ...prev, draft: true }));
                addNoticeFormik.handleSubmit();
              }}
              disabled={!!Object.keys(addNoticeFormik.errors).length}
            >
              {addNoticeFormik.values.edit ? "Update" : "Submit"}
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
};

export default AddNewNoticeDrawer;
