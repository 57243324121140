import Fallback from "app/components/Fallback";
import React from "react";

const UserProfile = React.lazy(() =>
  import("app/modules/userProfile/pages/userProfile")
);

export const UserProfilePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <UserProfile {...props} />
  </React.Suspense>
);
