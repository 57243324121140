import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;
const firm_route = `${SCHOOL_BASE}/firm`;

export const addFirm = (body) => {
  return schoolTokenAxios.post(`${firm_route}/addFirm`, body);
};
export const updateFirmProfile = (body) => {
  return schoolTokenAxios.put(`${firm_route}/updateFirmProfile`, body);
};
export const deleteFirm = (id) => {
  return schoolTokenAxios.delete(`${firm_route}/deleteFirm/${id}`);
};
export const getAllBusinessCategory = (params) => {
  return schoolTokenAxios.get(`${firm_route}/getAllBusinessCategory`, {
    params: params,
  });
};
export const getAllFirm = (params) => {
  return schoolTokenAxios.get(`${firm_route}/getAllFirm`, {
    params: params,
  });
};
