import { Storage } from "aws-amplify";

const uploadFile = async (filePath, s3Location, contentType) => {
  try {
    let file_s3_response = await Storage.put(s3Location, filePath, {
      level: "public",
      contentType: contentType,
    });

    return {
      fileS3Path: `s3://ekalsutra/public/` + file_s3_response?.key,
      fileURL: `https://ekalsutra.s3.ap-south-1.amazonaws.com/public/${file_s3_response?.key}`,
    };
  } catch (err) {
    console.error(err);
    return { error: err };
  }
};

export default uploadFile;
