import { Close } from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";

const DialogHeader = ({ title = "Modal", handleClose = () => {} }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 1, px: 2 }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }} color="primary">
          {title}
        </Typography>
        <Box
          onClick={handleClose}
          sx={{
            cursor: "pointer",
            height: "25px",
            width: "25px",
            border: "2px solid #0C2F49",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Close sx={{ color: "#0C2F49" }} />
        </Box>
      </Stack>
      <Divider sx={{ backgroundColor: "#0C2F49" }} />
    </>
  );
};

export default DialogHeader;
