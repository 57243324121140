import { selectSelectedSchool } from "app/modules/schools/selectors";
import React from "react";
import Image from "react-bootstrap/Image";
import { useSelector } from "react-redux";
import { ReactComponent as AppLogo } from "assets/media/images/logo.svg";
import styled from "styled-components";
import { Typography } from "@mui/material";

const MobileInstituteBrand = () => {
  const selectedSchool = useSelector(selectSelectedSchool);
  return selectedSchool ? (
    <StyledWrapper>
      <Image
        src={selectedSchool.schoolLogo}
        roundedCircle={true}
        thumbnail={true}
      />
      <div>
        <Typography className="institute-name">
          {selectedSchool.schoolName}
        </Typography>
        <Typography className="institute-info text-truncate">{`${selectedSchool.schoolAddress.address}, ${selectedSchool.schoolAddress.dist}, ${selectedSchool.schoolAddress.state} ${selectedSchool.schoolAddress.pinCode}`}</Typography>
      </div>
    </StyledWrapper>
  ) : (
    <AppLogo />
  );
};

export default MobileInstituteBrand;

const StyledWrapper = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 5rem;
    height: 5rem;
  }
  .institute-name {
    font-size: 14px;

    width: calc(100vw - 185px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .institute-info {
    font-size: 10px;
    width: calc(100vw - 185px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
