import { Email, Language, Phone } from "@mui/icons-material";
import { Box, Stack, Typography, styled } from "@mui/material";
import { THEME } from "app/config/constants";
import { selectSelectedSchool } from "app/modules/schools/selectors";

import React from "react";
import { useSelector } from "react-redux";

const A4SchoolCertificateHeader = () => {
  const selectedSchool = useSelector(selectSelectedSchool);
  return (
    <Stack direction="row" alignItems="center" gap={1} margin={1} flex={1}>
      <Stack direction="row" alignItems="center" gap={1.2} flex={1}>
        {/* school logo */}
        <Box
          sx={{
            height: "80px",
            width: "80px",
          }}
        >
          <img
            src={selectedSchool?.schoolLogo}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            alt="school-logo"
          />
        </Box>
        {/* school name and address */}
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              color: THEME.primary,
            }}
          >
            {selectedSchool?.schoolName}
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "14px",
              color: THEME.primary,
            }}
          >
            {selectedSchool?.schoolAddress?.address},{" "}
            {selectedSchool?.schoolAddress?.dist},{" "}
            {selectedSchool?.schoolAddress?.state},{" "}
            {selectedSchool?.schoolAddress?.pinCode}
          </Typography>
        </Box>
      </Stack>

      <Box>
        {selectedSchool?.mobileNumber && (
          <Stack direction={"row"} gap={1}>
            <IconBox>
              <Phone sx={{ fontSize: "10px" }} />
            </IconBox>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
                color: THEME.primary,
                flex: 1,
              }}
            >
              Contact :{" "}
              <span style={{ fontWeight: 600 }}>
                {selectedSchool?.mobileNumber}
              </span>
            </Typography>
          </Stack>
        )}
        {selectedSchool?.mobileNumber && (
          <Stack direction={"row"} gap={1} sx={{ py: 0.5 }}>
            <IconBox>
              <Phone sx={{ fontSize: "10px" }} />
            </IconBox>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: THEME.primary,
                flex: 1,
              }}
            >
              Contact :{" "}
              <span style={{ fontWeight: 600 }}>
                {selectedSchool?.mobileNumber}
              </span>
            </Typography>
          </Stack>
        )}
        {selectedSchool?.email && (
          <Stack direction={"row"} gap={1} sx={{ py: 0.5 }}>
            <IconBox>
              <Email sx={{ fontSize: "15px" }} />
            </IconBox>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: THEME.primary,
                flex: 1,
              }}
            >
              Email :{" "}
              <span style={{ fontWeight: 600 }}>{selectedSchool?.email}</span>
            </Typography>
          </Stack>
        )}
        {selectedSchool?.website && (
          <Stack direction={"row"} gap={1}>
            <IconBox>
              <Language sx={{ fontSize: "15px" }} />
            </IconBox>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: THEME.primary,
                flex: 1,
              }}
            >
              {/* Website : {selectedSchool?.affiliatedTo} */}
              Website :{" "}
              <span style={{ fontWeight: 600 }}>{selectedSchool?.website}</span>
            </Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default A4SchoolCertificateHeader;
const IconBox = styled("div")`
  border: 1px solid #b6c8d6;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
