import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import "react-notifications/lib/notifications.css";

//import for our files
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { IndianCurrency, NumberInWord } from "app/utils/helper";
import dayjs from "dayjs";
import {
  getFeeInvoice,
  getFeeInvoiceTemplate2,
} from "app/services/schoolService/newFee.service";
import CustomLoader from "../common/CustomLoader";
import { THEME } from "app/config/constants";
import InvoiceTemplate1 from "./InvoiceTemplate1";
import InvoiceTemplate2 from "./InvoiceTemplate2";
import { updateDefaultFeeInvoice } from "app/services/schoolService/school.service";

export default function InvoiceComponent2() {
  const invoicePrintRef = useRef(null);
  const [data, setData] = useState({});
  const [data2, setData2] = useState({});
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoiceItems2, setInvoiceItems2] = useState([]);
  const { invoiceId } = useParams();
  const selectedSchool = useSelector(selectSelectedSchool);
  const [state, setState] = useState({
    school: true,
    student: true,
    template: selectedSchool?.feeInvoiceTemplate ?? "template1",
    default: selectedSchool?.feeInvoiceTemplate ?? "template1",
  });

  const handlePrint = useReactToPrint({
    content: () => invoicePrintRef.current,
  });
  const { isLoading: getFeeInvoiceLoading, isFetching: getFeeInvoiceFetching } =
    useQuery({
      queryKey: ["getFeeInvoiceByInvoiceNumber", invoiceId],
      queryFn: () =>
        getFeeInvoice({ invoiceNumber: String(invoiceId).replace(/ /g, "/") }),
      onSuccess: (value) => {
        const feeTypeArray = [];
        value.data?.obj?.invoiceItems?.forEach((res) => {
          if (
            !feeTypeArray.map((item) => item.feeType).includes(res?.feeType)
          ) {
            feeTypeArray.push({
              feeType: res?.feeType,
              totalAmount: 0,
              months: [],
              extraDiscountAmount: 0,
              paidFineAmount: 0,
              balanceAmount: 0,
            });
          }
        });
        feeTypeArray?.forEach((res) => {
          value.data?.obj?.invoiceItems?.forEach((invoice) => {
            if (invoice?.feeType === res?.feeType) {
              res["months"] = [...res?.months, invoice?.monthYear];
              res["totalAmount"] =
                res?.totalAmount +
                Number(invoice?.thisInvoiceObject?.trasactionAmount ?? 0);
              res["extraDiscountAmount"] =
                res?.extraDiscountAmount +
                Number(invoice?.thisInvoiceObject?.extraDiscountAmount ?? 0);
              res["paidFineAmount"] =
                res?.paidFineAmount +
                Number(invoice?.thisInvoiceObject?.paidFineAmount ?? 0);
              res["balanceAmount"] =
                res?.balanceAmount +
                Number(invoice?.thisInvoiceObject?.balanceAmount ?? 0);
            }
          });
        });
        setData(value?.data?.obj);
        setInvoiceItems(feeTypeArray);
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: state.template === "template1" ? true : false,
    });
  const {
    isLoading: getFeeInvoiceLoading2,
    isFetching: getFeeInvoiceFetching2,
  } = useQuery({
    queryKey: ["getFeeInvoiceByInvoiceNumber2", invoiceId],
    queryFn: () =>
      getFeeInvoiceTemplate2({
        invoiceNumber: String(invoiceId).replace(/ /g, "/"),
      }),
    onSuccess: (value) => {
      const feeTypeArray = [];
      value.data?.obj?.invoiceItems?.forEach((res) => {
        if (!feeTypeArray.map((item) => item.feeType).includes(res?.feeType)) {
          feeTypeArray.push({
            feeType: res?.feeType,
            totalAmount: 0,
            months: [],
            extraDiscountAmount: 0,
            paidFineAmount: 0,
            balanceAmount: 0,
          });
        }
      });
      feeTypeArray?.forEach((res) => {
        value.data?.obj?.invoiceItems?.forEach((invoice) => {
          if (invoice?.feeType === res?.feeType) {
            res["months"] = [...res?.months, invoice?.monthYear];
            res["totalAmount"] =
              res?.totalAmount +
              Number(invoice?.thisInvoiceObject?.trasactionAmount ?? 0);
            res["extraDiscountAmount"] =
              res?.extraDiscountAmount +
              Number(invoice?.thisInvoiceObject?.extraDiscountAmount ?? 0);
            res["paidFineAmount"] =
              res?.paidFineAmount +
              Number(invoice?.thisInvoiceObject?.paidFineAmount ?? 0);
            res["balanceAmount"] =
              res?.balanceAmount +
              Number(invoice?.thisInvoiceObject?.balanceAmount ?? 0);
          }
        });
      });
      setData2(value?.data?.obj);
      setInvoiceItems2(value.data?.obj?.invoiceItems);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: state.template === "template2" ? true : false,
  });

  const { mutate, isLoading } = useMutation(
    "updateDefaultFeeInvoice",
    updateDefaultFeeInvoice,
    {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (state.default) {
      mutate({
        feeInvoiceTemplate: state.default,
      });
    }
  }, [state.default]);

  const calculateGrandTotal = () => {
    let total = 0;
    invoiceItems?.forEach((item) => {
      total += item?.totalAmount;
    });
    return total;
  };
  const calculateTotalDiscount = () => {
    let total = 0;
    invoiceItems?.forEach((item) => {
      total += item?.extraDiscountAmount;
    });
    return total;
  };
  const calculateTotalFine = () => {
    let total = 0;
    invoiceItems?.forEach((item) => {
      total += item?.paidFineAmount;
    });
    return total;
  };
  const calculateTotalBalance = () => {
    let total = 0;
    invoiceItems?.forEach((item) => {
      total += item?.balanceAmount;
    });
    return total;
  };

  return (
    <>
      <CustomLoader
        show={
          getFeeInvoiceLoading ||
          getFeeInvoiceFetching ||
          getFeeInvoiceLoading2 ||
          getFeeInvoiceFetching2 ||
          isLoading
        }
      />
      <Container maxWidth="xl">
        {/* Select Template  */}
        <Stack direction={"row"} spacing={2}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              pt: 1,
              pb: 1,
            }}
          >
            Select Template :
          </Typography>
          <Stack>
            <FormControlLabel
              value={state.template}
              control={
                <Radio
                  color="secondary"
                  onChange={(event) => {
                    setState({ ...state, template: event.target.value });
                  }}
                  value="template1"
                  checked={state.template === "template1"}
                />
              }
              label={"Template 1"}
            />
            <FormControlLabel
              value={state.default}
              control={
                <Radio
                  color="success"
                  onChange={(event) => {
                    setState({ ...state, default: event.target.value });
                  }}
                  value="template1"
                  checked={state.default === "template1"}
                />
              }
              label={"Default"}
            />
          </Stack>
          <Stack>
            <FormControlLabel
              value={state.template}
              control={
                <Radio
                  color="secondary"
                  onChange={(event) => {
                    setState({ ...state, template: event.target.value });
                  }}
                  value="template2"
                  checked={state.template === "template2"}
                />
              }
              label={"Template 2"}
            />
            <FormControlLabel
              value={state.default}
              control={
                <Radio
                  color="success"
                  onChange={(event) => {
                    setState({ ...state, default: event.target.value });
                  }}
                  value="template2"
                  checked={state.default === "template2"}
                />
              }
              label={"Default"}
            />
          </Stack>
        </Stack>

        <Box
          sx={{
            background: "#F6F8FC",
            m: 2,
            p: 2,
            borderRadius: "10px",
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
          >
            <Stack
              direction={"row"}
              justifyContent={"center"}
              gap={1}
              sx={{
                background: "#fff",
                px: 2,
                py: 0.5,
                borderRadius: "10px",
                boxShadow: THEME.boxShadow1,
                mb: 1,
              }}
            >
              <FormControlLabel
                control={<Checkbox color="secondary" />}
                checked={state.school}
                onChange={() =>
                  setState((prev) => ({ ...prev, school: !prev.school }))
                }
                label="School Invoice"
                componentsProps={{
                  typography: {
                    fontSize: "14px",
                    fontWeight: 600,
                  },
                }}
              />
              <FormControlLabel
                control={<Checkbox color="secondary" />}
                checked={state.student}
                onChange={() =>
                  setState((prev) => ({ ...prev, student: !prev.student }))
                }
                label="Student Invoice"
                componentsProps={{
                  typography: {
                    fontSize: "14px",
                    fontWeight: 600,
                  },
                }}
              />
            </Stack>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ mb: 1 }}
              onClick={() => {
                handlePrint();
              }}
            >
              Print
            </Button>
          </Stack>

          <Stack direction="row" sx={{ width: "60%", m: "auto" }}>
            {state.template === "template1" && (
              <InvoiceTemplate1
                invoiceText={"Invoice"}
                data={data}
                invoiceItems={invoiceItems}
                calculateGrandTotal={calculateGrandTotal}
                calculateTotalDiscount={calculateTotalDiscount}
                calculateTotalFine={calculateTotalFine}
                calculateTotalBalance={calculateTotalBalance}
              />
            )}
            {state.template === "template2" && (
              <InvoiceTemplate2
                invoiceText={"Invoice"}
                data2={data2}
                invoiceItems2={invoiceItems2}
              />
            )}
          </Stack>
          {state.template === "template1" && (
            <Box sx={{ display: "none" }}>
              <Stack
                direction="row"
                sx={{ p: 1 }}
                gap={0.5}
                ref={invoicePrintRef}
              >
                {state.school && (
                  <InvoiceTemplate1
                    invoiceText={"Invoice"}
                    data={data}
                    invoiceItems={invoiceItems}
                    calculateGrandTotal={calculateGrandTotal}
                    calculateTotalDiscount={calculateTotalDiscount}
                    calculateTotalFine={calculateTotalFine}
                    calculateTotalBalance={calculateTotalBalance}
                  />
                )}
                {state.student && (
                  <InvoiceTemplate1
                    invoiceText={"Student Invoice"}
                    data={data}
                    invoiceItems={invoiceItems}
                    calculateGrandTotal={calculateGrandTotal}
                    calculateTotalDiscount={calculateTotalDiscount}
                    calculateTotalFine={calculateTotalFine}
                    calculateTotalBalance={calculateTotalBalance}
                  />
                )}
              </Stack>
            </Box>
          )}
          {state.template === "template2" && (
            <Box sx={{ display: "none" }}>
              <Stack
                direction="row"
                sx={{ p: 1 }}
                gap={0.5}
                ref={invoicePrintRef}
              >
                {state.school && (
                  <InvoiceTemplate2
                    invoiceText={"School Invoice"}
                    data2={data2}
                    invoiceItems2={invoiceItems2}
                  />
                )}
                {state.student && (
                  <InvoiceTemplate2
                    invoiceText={"Student Invoice"}
                    data2={data2}
                    invoiceItems2={invoiceItems2}
                  />
                )}
              </Stack>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
}

const LeftSideDiv = styled.div`
  margin: 5px;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: #fff;
`;

const InvoiceHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const StudentDetailBox = styled.div`
  border-radius: 8px;
  border: 1px solid #d0d0e2;
  background: rgba(208, 208, 226, 0.08);
  padding: 10px;
  width: 100%;
  margin: 10px 0;
`;
