import { FilterAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import DialogHeader from "app/components/common/DialogHeader";
import { useQuery } from "react-query";
import { getSoldBook } from "app/services/ledgerAccount";
import { toast } from "react-toastify";
import {
  selectActiveSession,
  selectSelectedSchool,
  selectSelectedSchoolId,
  selectedMediumSelector,
  userNameSelector,
} from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  IndianCurrency,
  MenuProps,
  calculateTotalsAndAppendSummaryRowPdf,
} from "app/utils/helper";

import CustomLoader from "app/components/common/CustomLoader";

import { getClassDropdown } from "app/services/student-management";
import * as XLSX from "xlsx";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";

import CommonTable1 from "app/components/Tables/CommonTable1";
import CommonFilterDialog from "app/components/common/Dialogs/CommonFilterDialog";
import SaleBookPaymentDetail from "app/components/common/Dialogs/ledger/books/SaleBookPaymentDetail";
import SaleBookDialog from "app/components/common/Dialogs/ledger/books/SaleBookDialog";
import FilterButton from "app/components/common/FilterButton";
import jsPDF from "jspdf";
import reportHeader from "app/components/common/pdfReport/reportHeader";
import autoTable from "jspdf-autotable";
import Footer from "app/components/common/pdfReport/ReportFooter";
import PdfSummaryPreviewDialog from "app/components/common/Dialogs/PdfSummaryPreviewDialog";
const SaleBook = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const selectedSession = useSelector(selectActiveSession);
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const selectedMedium = useSelector(selectedMediumSelector);

  const [state, setState] = useState({
    tableData: [],
    dialogData: {},
    installmentData: {},
    add: {
      class: "",
      section: "",
      student: "",
      bookType: "",
      bookTitle: "",
      collectionDate: new Date(),
      entityArr: [],
      payment: {
        totalAmount: 0,
        discountRemark: "",
        taxableAmount: 0,
        balance: 0,
        balanceRemark: "",
      },
    },
    book: "",
    selectedClass: "",
    section: "",
    classData: [],
    checkExportOptionsPdf: false,
    filterData: {},
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogs, setDialogs] = useState({
    add: false,
    tPaid: false,
    installment: false,
    payment: false,
    export: false,
  });

  const [checkboxes, setCheckboxes] = useState({
    one: false,
  });

  const columns = [
    {
      header: "Sr.No",
      Cell: ({
        cell: {
          row: { id },
        },
      }) => (
        <Typography>
          {parseInt(id) + 1 < 10 ? `0${parseInt(id) + 1}` : parseInt(id) + 1}
        </Typography>
      ),
      maxSize: isMobile ? 40 : 30,
    },
    {
      header: "Class-Stream-Sec",
      accessorKey: "stream",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.stream ?? "--"}</Typography>;
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Student Name",
      accessorKey: "name",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.name}</Typography>;
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "First Invoice Number",
      accessorKey: "number",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>
            <Link
              to={`/ledger-n-account/books/pay-slip/${String(
                original?.number
              ).replace(/\//g, " ")}`}
              target="_blank"
              rel="noreferrer onopener"
            >
              {original?.number}
            </Link>
          </Typography>
        );
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Total Amount",
      accessorKey: "amount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>{IndianCurrency(Number(original?.amount))}</Typography>
        );
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Total Paid",
      accessorKey: "paid",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography
            sx={{
              cursor: "pointer",
              fontWeight: "500",
              color: "blue",
              textDecoration: "underline",
              display: "inline",
            }}
            onClick={() => {
              setDialogs((prev) => ({ ...prev, tPaid: original?.number }));
            }}
          >
            {IndianCurrency(Number(original?.paid))}
          </Typography>
        );
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Discount",
      accessorKey: "discount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>
            {original?.discount ? IndianCurrency(original?.discount) : "--"}
          </Typography>
        );
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Balance",
      accessorKey: "balance",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>{IndianCurrency(Number(original?.balance))}</Typography>
        );
      },
      maxSize: isMobile ? 60 : 40,
    },
  ];

  // get Class dropdown
  useQuery({
    queryKey: "getClassess",
    queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
    onSuccess: ({ data }) => {
      setState((prev) => ({ ...prev, classData: data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  const classes = state?.classData?.map((item) => ({
    name: item?.className_stream,
    classDocId: item?.classDocId,
  }));

  const sections = (data) => {
    return state?.classData?.filter((item) => item.classDocId === data)[0]
      ?.sections;
  };

  const {
    refetch: getSoldBookRefetch,
    isLoading: getSoldBookLoading,
    isFetching: getSoldBookFetching,
  } = useQuery({
    queryFn: () =>
      getSoldBook({
        session: selectedSession,
        ...(state.selectedClass && { classDocId: state.selectedClass }),
        ...(state.section && { section: state.section }),
        ...(state.collectionFrom && { collectionFrom: state.collectionFrom }),
        ...(state.collectionTo && { collectionTo: state.collectionTo }),
      }),
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, tableData: res?.data?.list }));
      setModalOpen(false);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const tableData = state?.tableData?.map((item) => ({
    stream: item?.class_stream_sec || "--",
    name: item?.studentName || "--",
    number: item?.firstInvoiceNumber || "--",
    amount: item?.totalAmount || "--",
    paid: item?.paidAmount || "--",
    discount: item?.discount,
    balance: item?.balance || "--",
    invoiceDocId: item?.invoiceDocId,
  }));

  let exportArray = state.tableData?.map((item) => {
    return {
      Class_Stream_Sec: item.class_stream_sec || "",
      Student_Name: item.studentName || "",
      First_Invoice_Number: item.firstInvoiceNumber || "",
      Total_Amount: IndianCurrency(item.totalAmount) || "",
      Total_Paid: IndianCurrency(item.paidAmount) || "",
      Discount: IndianCurrency(item.discount) || "",
      Balance: IndianCurrency(item.balance) || "",
    };
  });

  let header = [
    "Class_Stream_Sec",
    "Student_Name",
    "First_Invoice_Number",
    "Total_Amount",
    "Total_Paid",
    "Discount",
    "Balance",
  ];

  const [selectedOptions, setSelectedOptions] = useState(header);

  const handleExport = () => {
    const filteredExportArray = exportArray.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Book Sale");
    XLSX.writeFile(workbook, "BookSale.xlsx");
  };

  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };

  // pdf building start here************************
  //*************************************** */
  let headerPdf = [
    "Class_Stream_Sec",
    "Student_Name",
    "First_Invoice_Number",
    "Total_Amount",
    "Total_Paid",
    "Discount",
    "Balance",
  ];
  const [selectedOptionsForPdf, setSelectedOptionsForPdf] = useState(headerPdf);

  let exportArrayPdf = state.tableData?.map((item) => {
    return {
      Class_Stream_Sec: item.class_stream_sec || "",
      Student_Name: item.studentName || "",
      First_Invoice_Number: item.firstInvoiceNumber || "",
      Total_Amount: item.totalAmount || "",
      Total_Paid: item.paidAmount || "",
      Discount: item.discount || "",
      Balance: item.balance || "",
      CreatedBy: item.createdBy || "",
    };
  });

  const getClassName = (classDocId) => {
    return state?.classData?.filter((item) => item.classDocId === classDocId)[0]
      ?.className_stream;
  };
  const userName = useSelector(userNameSelector);
  const selectedSchool = useSelector(selectSelectedSchool);

  const handleExportPdf = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const rows = [];
    rows.push(selectedOptionsForPdf);
    exportArrayPdf.forEach((item) => {
      const row = selectedOptionsForPdf.map((option) => item[option]);
      rows.push(row);
    });
    const tableColumn = selectedOptionsForPdf.map((col) => ({
      title: col,
      dataKey: col,
    }));
    const tableStyles = {
      headStyles: {
        fillColor: [182, 200, 214],
        textColor: 0,
        fontSize: 9,
        cellPadding: { top: 1.5, right: 1.5, bottom: 1.5, left: 1.5 },
        cellWidth: "auto", // 'auto'|'auto'|'fixed'
      },
      bodyStyles: {
        fontSize: 8,
        cellPadding: { top: 1.5, right: 1.5, bottom: 1.5, left: 1.5 },
        cellWidth: "auto",
      },
      theme: "grid",
    };

    const totalPagesExp = "{total_pages_count_string}";

    let appliedFiltery_axis = 34;

    // report header
    reportHeader({
      doc: doc,
      selectedSchool: selectedSchool,
      state: state,
      extraData: state?.filterExtraData,
    });

    doc.text(`Applied Filter:`, 15, appliedFiltery_axis);
    doc.text(
      "_______________________________________",
      15,
      appliedFiltery_axis + 2
    );
    let filterTexts = [];
    if (state?.selectedClass) {
      filterTexts.push(`Class: ${getClassName(state?.selectedClass)}`);
    }
    if (state?.section) {
      filterTexts.push(`Section: ${state?.section}`);
    }

    filterTexts.push(`Session: ${selectedSession}`);

    let xaxis = 15;
    let yaxis = appliedFiltery_axis + 6;
    let difference = 0;
    let lineHeight = 4; // Line height
    let maxLineWidth = 180;

    filterTexts.forEach((filterText, index) => {
      let textWidth = doc.getStringUnitWidth(filterText) * doc.getFontSize();
      if (xaxis + textWidth > maxLineWidth) {
        xaxis = 15;
        yaxis += lineHeight;
        difference += lineHeight;
      }
      doc.text(filterText, xaxis, yaxis);
      xaxis += textWidth;
      if (index < filterTexts.length - 1) {
        doc.text(",", xaxis, yaxis);
        xaxis += doc.getStringUnitWidth(",") * doc.getFontSize();
        doc.text(" ", xaxis, yaxis);
        xaxis += doc.getStringUnitWidth(" ") * doc.getFontSize();
      }
    });

    let textheight = appliedFiltery_axis + 12 + difference;
    doc.text(
      "______________________________________________________________________________________",
      15,
      textheight - 4
    );

    const totalColumns = ["Total_Amount", "Total_Paid", "Discount", "Balance"];

    rows.push(
      calculateTotalsAndAppendSummaryRowPdf(
        rows,
        selectedOptionsForPdf,
        totalColumns
      )
    );

    // Draw the table with the updated rows
    autoTable(doc, {
      head: [tableColumn],
      body: rows,
      startY: 100,
      didDrawPage: (data) => {
        Footer({
          doc: doc,
          data: data,
          userName: userName,
          totalPagesExp: totalPagesExp,
        });
      },
      ...tableStyles,
    });

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save("SaleBook.pdf");
  };

  return (
    <>
      <CustomLoader show={getSoldBookLoading || getSoldBookFetching} />
      {/* Filteres Modal */}
      <CommonFilterDialog
        handleSaveAndView={() => {
          getSoldBookRefetch();
        }}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Filter"
        minWidth="450px"
      >
        <DialogContent>
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.one}
                  onClick={() => {
                    setCheckboxes((prev) => ({ ...prev, one: !prev.one }));
                  }}
                />
              }
              label="Class info"
            />
            {checkboxes.one && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                marginRight={2}
              >
                <CustomSelect
                  MenuProps={MenuProps}
                  displayEmpty
                  value={state?.selectedClass}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      selectedClass: e.target.value,
                      sections: sections(e.target.value),
                    }));
                  }}
                >
                  <MenuItem value={""} disabled>
                    Select
                  </MenuItem>
                  {classes?.map((item) => (
                    <MenuItem value={item.classDocId}>{item.name}</MenuItem>
                  ))}
                </CustomSelect>

                <CustomSelect
                  MenuProps={MenuProps}
                  displayEmpty
                  value={state?.section}
                  disabled={!state.selectedClass}
                  onChange={(e) =>
                    setState((prev) => ({ ...prev, section: e.target.value }))
                  }
                >
                  <MenuItem value={""} disabled>
                    Select
                  </MenuItem>
                  {state?.sections?.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </CustomSelect>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </CommonFilterDialog>

      {/* add new book dialog */}
      <SaleBookDialog
        open={dialogs?.add}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, add: false }));
        }}
        refetch={getSoldBookRefetch}
        classData={state.classData}
      />

      {/* Pdf Summary Dialog  */}
      <PdfSummaryPreviewDialog
        open={state.checkExportOptionsPdf}
        handleClose={() =>
          setState((prev) => ({ ...prev, checkExportOptionsPdf: false }))
        }
        data={headerPdf}
        setSelectedOptionsForPdf={setSelectedOptionsForPdf}
        selectedOptionsForPdf={selectedOptionsForPdf}
        handleExportPdf={handleExportPdf}
      />

      {/* payment summary dialog */}
      <SaleBookPaymentDetail
        open={dialogs?.tPaid}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, tPaid: false }));
        }}
        refetch={getSoldBookRefetch}
      />

      {/* export dialog */}
      <Dialog
        open={dialogs.export}
        onClose={() =>
          setDialogs((prev) => ({
            ...prev,
            export: false,
          }))
        }
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to export"
          handleClose={() =>
            setDialogs((prev) => ({
              ...prev,
              export: false,
            }))
          }
        />
        <DialogContent>
          <Box>
            {header.map((option) => (
              <Stack direction="row">
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChange(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptions(header);
              handleExport();
              setDialogs((prev) => ({
                ...prev,
                export: false,
              }));
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FilterButton
          count={
            Object.values(checkboxes).filter((item) => item === true).length
          }
          hanldeClick={() => setModalOpen(true)}
        />

        <Stack direction="row" gap={2} ml={isMobile && 2} alignItems="center">
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              mb: 0.5,
            }}
          >
            Total Student :{" "}
            <span style={{ fontSize: "18px" }}>{tableData?.length || 0}</span>
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ fontSize: isMobile ? "8px" : "1.2rem" }}
            onClick={() => {
              setDialogs((prev) => ({ ...prev, add: true }));
            }}
          >
            + Sale Book
          </Button>
          <Box sx={{ mt: "-10px" }}>
            <ExportButtonGroup
              showPdf={true}
              handleClickPdf={() => {
                setState((prev) => ({
                  ...prev,
                  checkExportOptionsPdf: true,
                }));
              }}
              handleClickExcel={() =>
                setDialogs((prev) => ({ ...prev, export: true }))
              }
            />
          </Box>
        </Stack>
      </Stack>
      <Stack mt={3}>
        {/* <STable columns={columns} data={tableData || []} /> */}
        <CommonTable1 columns={columns} data={tableData} maxHeight="67vh" />
      </Stack>
    </>
  );
};

export default SaleBook;
const CustomSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  width: "200px",
  backgroundColor: "#fff",
  color: "#333",
  borderRadius: "7px",
}));
