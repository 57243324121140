import { createAsyncThunk } from "@reduxjs/toolkit";
import API_ENDPOINTS from "app/config/endpoints";
import { getAdvancedPaymentDropDown } from "app/services/management";
import axios from "axios";

export const getExtraFeeStructureDropdown = createAsyncThunk(
  "fee_collection/extraFeeStructureDropdown",
  async (payload, { dispatch }) => {
    try {
      const { data } = await getAdvancedPaymentDropDown(
        payload.session,
        payload.medium,
        payload.classDocId,
        payload.section
      );
      return data;
    } catch (e) {}
  }
);
