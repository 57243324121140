import { createSlice } from "@reduxjs/toolkit";
import { getAdmissionRequestsThunk, getStudentListForPromotion } from "./thunk";

const initialState = {
  selectedStudent: "",
  selectedStudentRegNo: "",
  promotionStudentListLoading: false,
  promotionStudentListError: false,
  promotionStudentList: [],
  admissionApprovalList: [],
  admissionApprovalLoading: false,
  admissionApprovalError: "",
  requestDocId: "",
  existentStudent: {},
  approvalStudent: {},
  studentAdmissionState: {
    addressFormik: {},
    addmissionFormik: {},
    houseFormik: {},
    parentFormik: {},
    personalInfoFormik: {},
    previousSchoolFormik: {},
    transportFormik: {},
    formSubmitting: false,
    errors: {
      address: {},
      admission: {},
      parent: {},
      personal: {},
      transport: {},
    },
  },
};

export const studentManagementSlice = createSlice({
  name: "studentManagement",
  initialState,
  reducers: {
    setStudentFormErrors: (state, action) => {
      switch (action.payload.type) {
        case "address":
          state.studentAdmissionState.errors.address = action.payload.value;
          break;
        case "admission":
          state.studentAdmissionState.errors.admission = action.payload.value;
          break;
        case "parent":
          state.studentAdmissionState.errors.parent = action.payload.value;
          break;
        case "personal":
          state.studentAdmissionState.errors.personal = action.payload.value;
          break;
        case "transport":
          state.studentAdmissionState.errors.transport = action.payload.value;
          break;
        default:
          break;
      }

      state.studentAdmissionState.formSubmitting = false;
    },
    setSubmittingStudentForm: (state, action) => {
      state.studentAdmissionState.formSubmitting = action.payload;
    },
    setAddressFormik: (state, action) => {
      state.studentAdmissionState.addressFormik = action.payload;
    },
    setAddmissionFormik: (state, action) => {
      state.studentAdmissionState.addmissionFormik = action.payload;
    },
    setHouseFormik: (state, action) => {
      state.studentAdmissionState.houseFormik = action.payload;
    },
    setParentFormik: (state, action) => {
      state.studentAdmissionState.parentFormik = action.payload;
    },
    setPersonalFormik: (state, action) => {
      state.studentAdmissionState.personalInfoFormik = action.payload;
    },
    setPerviousSchoolFormik: (state, action) => {
      state.studentAdmissionState.previousSchoolFormik = action.payload;
    },
    setTransportFormik: (state, action) => {
      state.studentAdmissionState.transportFormik = action.payload;
    },
    setrequestDocId: (state, action) => {
      state.requestDocId = action.payload;
    },
    setSelectedStudentRegNo: (state, action) => {
      state.selectedStudentRegNo = action.payload.regNo;
    },
    setExistentStudent: (state, action) => {
      state.existentStudent = action.payload;
    },
    setApprovalStudent: (state, action) => {
      state.approvalStudent = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getStudentListForPromotion.pending, (state) => {
        state.promotionStudentListLoading = true;
      })
      .addCase(getStudentListForPromotion.fulfilled, (state, action) => {
        const data = action.payload?.list;
        state.promotionStudentList = data;
        state.promotionStudentListLoading = false;
      })
      .addCase(getStudentListForPromotion.rejected, (state) => {
        state.promotionStudentListError = true;
      })

      .addCase(getAdmissionRequestsThunk.pending, (state) => {
        state.admissionApprovalLoading = true;
      })

      .addCase(getAdmissionRequestsThunk.fulfilled, (state, action) => {
        const data = action.payload?.list;
        state.admissionApprovalList = data;
        state.admissionApprovalLoading = false;
      })
      .addCase(getAdmissionRequestsThunk.rejected, (state) => {
        state.admissionApprovalError = true;
      });
  },
});

export default studentManagementSlice.reducer;
