import { Box, colors, Divider, Grid, styled, Typography } from "@mui/material";
import { IndianCurrency } from "app/utils/helper";
import React from "react";

const PaymentSummaryComponent = ({
  showUpperSection = false,
  showLowerSection = false,
  t_CashMode,
  t_NEFTMode,
  t_UpiMode,
  t_ChequeMode,
  t_CardMode,
  t_Razorpay,
  totalDiscount,
  totalNetpaid,
}) => {
  return (
    <>
      <FeeBox>
        {showUpperSection && (
          <Grid container spacing={2} columns={14}>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                CASH Mode
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_CashMode)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                NEFT
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_NEFTMode)}
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                UPI
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_UpiMode)}
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Cheque
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_ChequeMode)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Card
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_CardMode)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Razor Pay
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_Razorpay)}
              </Typography>
            </Grid>
          </Grid>
        )}
        {showLowerSection && (
          <>
            <Divider sx={{ backgroundColor: "#0C2F49" }} />
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Total Discount
                </Typography>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "15px",
                    color: "#29DB5B",
                  }}
                >
                  {IndianCurrency(totalDiscount)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Total Net Paid
                </Typography>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "15px",
                    color: colors.blue[600],
                  }}
                >
                  {IndianCurrency(totalNetpaid)}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </FeeBox>
    </>
  );
};

export default PaymentSummaryComponent;

const FeeBox = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(19, 115, 229, 0.2);
  background: rgba(39, 137, 253, 0.02);
  padding: 5px;
  display: inline-block;
  margin-top: 10px;
  width: 50%;
`;
