import React, { memo, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import DialogHeader from "app/components/common/DialogHeader";

import { BackgroundBox } from "../style";
import FormSectionTitle from "./FormSectionTitle";
const DocumentInfo = ({ collectedDocumentList, setCollectedDocumentList }) => {
  const [documentName, setDocumentName] = useState("");
  const handleCloseDocumentDialog = () => {
    setCollectedDocumentList((prev) => ({ ...prev, dialog: false }));
  };

  return (
    <>
      {/* insert more document dialog */}
      <Dialog
        PaperProps={{ sx: { minWidth: "400px" } }}
        open={collectedDocumentList.dialog}
        onClose={handleCloseDocumentDialog}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Add More Document"
            handleClose={handleCloseDocumentDialog}
          />
        </DialogTitle>
        <DialogContent>
          <Stack direction={"row"} gap={2} py={2}>
            <CustomTextField
              fullWidth
              size="small"
              placeholder="Type Document Name"
              value={documentName}
              name="documentName"
              onChange={(e) => setDocumentName(e.target.value)}
            />
            <Button
              variant="contained"
              color="secondary"
              disabled={String(documentName).trim() ? false : true}
              onClick={() => {
                setCollectedDocumentList((prev) => ({
                  ...prev,
                  dialog: false,
                  list: [...prev.list, { label: documentName, checked: true }],
                }));
                setDocumentName("");
              }}
            >
              Add
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <BackgroundBox>
        <FormSectionTitle title={"Collected Document"} />
        <Typography sx={{ fontWeight: 600, fontSize: "14px", py: 1 }}>
          Hard Copy Submitted
        </Typography>

        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={2}
          flexWrap={"wrap"}
        >
          {collectedDocumentList?.list?.map((item, i) => (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  color="secondary"
                  checked={item?.checked}
                  onChange={(e) => {
                    item.checked = !item.checked;
                    setCollectedDocumentList((prev) => ({ ...prev }));
                  }}
                />
              }
              label={item?.label}
            />
          ))}
          <Typography
            sx={{
              textDecoration: "underline",
              fontSize: "14px",
              fontStyle: "italic",
              color: "#0078D1",
              cursor: "pointer",
            }}
            onClick={() =>
              setCollectedDocumentList((prev) => ({
                ...prev,
                dialog: true,
              }))
            }
          >
            + Add More
          </Typography>
        </Stack>
      </BackgroundBox>
    </>
  );
};

export default memo(DocumentInfo);
