import React from "react";

const TableProfilePic = ({ image }) => {
  return (
    <div>
      <img
        src={image ?? "/image-placeholder.jpeg"}
        style={{
          height: "45px",
          width: "45px",
          objectFit: "cover",
          borderRadius: "50%",
          border: "1px solid rgba(0, 0, 0, 0.4)",
        }}
        alt=""
      />
    </div>
  );
};

export default TableProfilePic;
