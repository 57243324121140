import API_ENDPOINTS from "app/config/endpoints";
import { schoolTokenAxios, authTokenAxios } from "../axios";
import { PanoramaFishEyeTwoTone } from "@mui/icons-material";

// collection
export const editCollectionHeadLogic = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.ledgerAccount.editCollectionHead}`,
    payload
  );
};
export const addCollectionHeadLogic = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addCollectionHead}`,
    payload
  );
};
export const getAllCollectionHead = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getAllCollectionHead}`
  );
};

export const getAllCollectionSubHead = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getAllCollectionSubHead}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const getAllCollectionLogic = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getAllCollection}?from=${payload.from}&to=${payload.to}&status=ACTIVE`
  );
};
export const addCollectionLogic = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addCollection}`,
    payload
  );
};
export const deleteCollectionLogic = (payload) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.ledgerAccount.deleteCollection}/${payload}`
  );
};

// Expense
export const editExpenseHeadLogic = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.ledgerAccount.editExpenseHead}`,
    payload
  );
};
export const addExpenseHeadLogic = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addExpenseHead}`,
    payload
  );
};
export const getAllExpenseHead = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getAllExpenseHead}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const getAllExpenseSubHead = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getAllExpenseSubHead}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const getAllExpenseLogic = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getAllExpense}?from=${payload.from}&to=${payload.to}&status=ACTIVE`
  );
};
export const addExpenseLogic = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addExpense}`,
    payload
  );
};
export const deleteExpenseLogic = (payload) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.ledgerAccount.deleteExpense}/${payload}`
  );
};

export const changePasswordWithoutOtp = (payload) => {
  return authTokenAxios.put(
    `${API_ENDPOINTS.auth.changePasswordWithoutOtp}`,
    payload
  );
};

export const getLedgerReport = (queries) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getLedgerReport}`,
    {
      params: {
        ...queries,
      },
    }
  );
};

export const getInternalTransfer = (query) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getInternalTransfer}`,
    {
      params: {
        ...query,
      },
    }
  );
};

export const addInternalTransfer = (data) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addInternalTransfer}`,
    data
  );
};

export const updateStatusInternalTransfer = (data) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.ledgerAccount.updateStatusInternalTransfer}`,
    data
  );
};

export const deleteInternalTransfer = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.ledgerAccount.deleteInternalTransfer}/${id}`
  );
};
export const deleteCollectionHead = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.ledgerAccount.deleteCollectionHead}/${id}`
  );
};
export const deleteCollectionSubHead = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.ledgerAccount.deleteCollectionSubHead}/${id}`
  );
};
export const deleteExpenseHead = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.ledgerAccount.deleteExpenseHead}/${id}`
  );
};
export const deleteExpenseSubHead = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.ledgerAccount.deleteExpenseSubHead}/${id}`
  );
};
export const deleteUtilityInvoice = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.ledgerAccount.deleteUtilityInvoice}/${id}`
  );
};

export const getBookSellerNames = () => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getBookSellerNames}`
  );
};

export const addNewBookSeller = (data) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addNewBookSeller}`,
    data
  );
};
export const updateBookQuantity = (data) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.ledgerAccount.updateBookQuantity}`,
    data
  );
};

export const getBooksForSale = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getBooksForSale}`,
    {
      params: {
        ...params,
      },
    }
  );
};
export const getSingleBooksForSale = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getBooksForSale}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const getSoldBook = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.ledgerAccount.getSoldBook}`, {
    params: {
      ...params,
    },
  });
};

export const addBookExpenseDetail = (data) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addBookExpenseDetail}`,
    data
  );
};
export const updateBookRate = (data) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.ledgerAccount.updateBookRate}`,
    data
  );
};

export const getBookGroup = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.ledgerAccount.getBookGroup}`, {
    params: {
      ...params,
    },
  });
};

export const getTransactionDetail = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getTransactionByFirstInvoiceNo}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const payInstallment = (data) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.payInstallment}`,
    data
  );
};

export const addBookGroup = (data) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addBookGroup}`,
    data
  );
};

export const getUtilityInvoice = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getUtilityInvoice}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const saleBook = (data) => {
  return schoolTokenAxios.post(`${API_ENDPOINTS.ledgerAccount.saleBook}`, data);
};

export const getAllCollection = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.ledgerAccount.getAllCollection}`,
    {
      params: {
        ...params,
      },
    }
  );
};
export const deleteCollection = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.ledgerAccount.deleteCollection}/${id}`
  );
};

export const addCollectionDetail = (data) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addCollectionDetail}`,
    data
  );
};

export const getAllExpense = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.ledgerAccount.getAllExpense}`, {
    params: {
      ...params,
    },
  });
};
export const getAllExpenseByDocId = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.ledgerAccount.getAllExpense}`, {
    params,
  });
};

export const requestExpense = (data) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.requestExpense}`,
    data
  );
};

export const rejectExpense = (id) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.ledgerAccount.rejectExpense}/${id}`
  );
};

export const approveExpense = (data) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.ledgerAccount.approveExpense}`,
    data
  );
};

export const payExpense = (data) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.ledgerAccount.payExpense}`,
    data
  );
};

export const addCompletedExpenseDetail = (data) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addCompletedExpenseDetail}`,
    data
  );
};

export const deleteExpense = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.ledgerAccount.delExpense}/${id}`
  );
};

export const verifyPaidExpense = (id) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.ledgerAccount.verifyPaidExpense}/${id}`
  );
};

export const addCollectionSubHead = (data) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addCollectionSubHead}`,
    data
  );
};

export const addExpenseSubHead = (data) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.ledgerAccount.addExpenseSubHead}`,
    data
  );
};
