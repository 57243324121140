import appRoutes from "app/config/routes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setValidatingSession } from "../../operations";
import { refreshAccessToken } from "../../thunk";
import { authAccessTokenSelector } from "../../selectors";
import { schoolAccessTokenSelector } from "../../../schools/selectors";

import { authTokenAxios, schoolTokenAxios } from "app/services/axios";

const ValidateSession = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authAccessToken = useSelector(authAccessTokenSelector);
  const schoolAccessToken = useSelector(schoolAccessTokenSelector);

  useEffect(() => {
    const authRefreshToken = localStorage.getItem("authRefreshToken");

    // set Axios header with previous access tokens
    if (authAccessToken) {
      authTokenAxios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${authAccessToken}`;
    }
    if (schoolAccessToken) {
      schoolTokenAxios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${schoolAccessToken}`;
    }

    // If auth refresh token exists
    if (authRefreshToken && authRefreshToken.length > 0) {
      dispatch(setValidatingSession(true));
      dispatch(
        refreshAccessToken({
          refreshToken: authRefreshToken,
        })
      )
        .then(({ payload }) => {
          navigate({ pathname: `/${appRoutes.schools}` });
        })
        .catch((err) => {
          console.error(err);
          // Navigate to Login Page
          navigate("/login", { replace: true });
        })
        .finally(() => {
          dispatch(setValidatingSession(false));
        });
    } else {
      // Navigate to Login Page
      navigate("/login", { replace: true });
    }
  }, [dispatch]);

  return <div className="d-none">Restoring Session...</div>;
};

export default ValidateSession;
