import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { X } from "react-bootstrap-icons";
import styled from "styled-components";
import { useQuery } from "react-query";
import "react-notifications/lib/notifications.css";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { rejectAdmissionRequest } from "app/services/student-management";
import PersonalInfo from "./FormComponents/PersonalInfo";
import ParentInfo from "./FormComponents/ParentInfo";
import AddressInfo from "./FormComponents/AddressInfo";
import PrevEdDetail from "./FormComponents/PrevEdDetail";
import { useNavigate } from "react-router-dom";
import { setrequestDocId } from "../../operations";
import { ArrowForwardIos } from "@mui/icons-material";
import { toast } from "react-toastify";
import DialogHeader from "app/components/common/DialogHeader";
export default function AdmissionApprovalModal({
  open,
  setOpen,
  data,
  requestedOn,
  requestedFor,
  requestDocId,
  status,
  loading,
  refetch,
}) {
  const [expanded, setExpanded] = useState("personal_info");
  const navigate = useNavigate();
  useEffect(() => {
    setrequestDocId(requestDocId);
  }, [requestDocId]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleApproveAction = (data) => {
    navigate(
      `/student-management/admission-approval/edit-details/${requestDocId}`
    );
  };

  const { isLoading: rejecting, refetch: rejectRefetch } = useQuery({
    queryKey: ["rejectJoiningRequest"],
    queryFn: () => rejectAdmissionRequest(requestDocId),
    enabled: false,
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      refetch();
      setOpen(false);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    retry: false,
  });

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { minWidth: { xs: "98%", md: "50vw" } },
      }}
    >
      <DialogTitle sx={{ p: "0 !important" }}>
        <DialogHeader title="Student Information" handleClose={handleClose} />
      </DialogTitle>

      {loading && (
        <Stack direction={"row"} justifyContent={"center"} py={1}>
          <CircularProgress />
        </Stack>
      )}

      <DialogContent sx={{ mt: 1 }}>
        <Stack direction="row" gap={2} alignItems="center">
          <div>
            <img
              alt="student"
              src={
                data?.profilePic ? data?.profilePic : "/image-placeholder.jpeg"
              }
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "10px",
              }}
            />
          </div>

          <RequestedDiv style={{ height: isMobile && "9rem" }}>
            <Typography sx={{ fontSize: isMobile && "1.2rem" }}>
              Requested On
            </Typography>
            <h5>{moment(new Date(requestedOn)).format("DD-MMM-yyyy")}</h5>
          </RequestedDiv>

          <RequestedDiv style={{ height: isMobile && "9rem" }}>
            <Typography sx={{ fontSize: isMobile && "1.2rem" }}>
              Requested For
            </Typography>
            <h5>{requestedFor}</h5>
          </RequestedDiv>
        </Stack>

        <Wrapper>
          <Accordion
            expanded={expanded === "personal_info"}
            onChange={handleChange("personal_info")}
            className="customaccordian_style"
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIos
                  sx={{
                    transform:
                      expanded === "personal_info"
                        ? "rotate(270deg) !important"
                        : "rotate(0deg) !important",
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className="accordian_title">
                Personal Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordiantDetail_border">
              <PersonalInfo data={data} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "parent_info"}
            onChange={handleChange("parent_info")}
            className="customaccordian_style"
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIos
                  sx={{
                    transform:
                      expanded === "parent_info"
                        ? "rotate(270deg) !important"
                        : "rotate(0deg) !important",
                  }}
                />
              }
              id="panel1bh-header"
            >
              <Typography className="accordian_title">
                Parent`s Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordiantDetail_border">
              <ParentInfo data={data} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "address_info"}
            onChange={handleChange("address_info")}
            className="customaccordian_style"
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIos
                  sx={{
                    transform:
                      expanded === "address_info"
                        ? "rotate(270deg) !important"
                        : "rotate(0deg) !important",
                  }}
                />
              }
              id="panel1bh-header"
            >
              <Typography className="accordian_title">
                Address Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordiantDetail_border">
              <AddressInfo data={data} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "ed_info"}
            onChange={handleChange("ed_info")}
            className="customaccordian_style"
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIos
                  sx={{
                    transform:
                      expanded === "ed_info"
                        ? "rotate(270deg) !important"
                        : "rotate(0deg) !important",
                  }}
                />
              }
              id="panel1bh-header"
            >
              <Typography className="accordian_title">
                Previous Education Detail
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordiantDetail_border">
              <PrevEdDetail data={data} />
            </AccordionDetails>
          </Accordion>
        </Wrapper>
      </DialogContent>
      <DialogActions>
        {(status === "REQUESTED" || status === "IN_PROGRESS") && (
          <LoadingButton
            size="large"
            color="secondary"
            variant="outlined"
            loading={rejecting}
            onClick={() => rejectRefetch()}
          >
            Reject
          </LoadingButton>
        )}
        {status === "REQUESTED" && (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              handleApproveAction(data);
            }}
          >
            Admit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const RequestedDiv = styled.div`
  width: 25%;
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #d0d0e214;
  border: 1px solid #d0d0e2;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 12px;
`;

const Wrapper = styled.div`
  position: relative;
  border-radius: round;
  overflow: hidden !important;
  margin-top: 10px;
  flex-wrap: wrap;

  .container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;
    justify-content: space-between;
  }
  .container-sm {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;
    justify-content: space-around;
  }
  .container-element {
    display: flex;
    flex-direction: column;
  }
  .container-element p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .container-element-extra {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .container-element-extra p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element-extra h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .element-wrapper {
    padding: 25px;
    background: rgba(208, 208, 226, 0.08);
    border: 1px solid #d0d0e2;
    border-radius: 15px;
  }

  .preview-button {
    margin: 5px 20px 20px 20px;
    width: 136.03px;
    height: 50px;
    color: rgba(39, 137, 253, 1);
    border: 1px solid #2789fd;
    border-radius: 10px;
    right: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  .preview-btn-div {
    display: flex;
    justify-content: right;
  }
  .border {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 12px;
  }
  .accordian-wrapper {
    margin-bottom: 40px;
  }
`;
