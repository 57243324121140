import React, { useState } from "react";
import FilterButton from "../FilterButton";
import CommonFilterDialog from "./CommonFilterDialog";
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Grid,
  Box,
} from "@mui/material";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import TextFieldLabel from "../TextFieldLabel";
import { DatePicker } from "@mui/x-date-pickers";

const FinalCommonFilterDialog = ({
  mutate = () => {},
  extraData = () => {},
  showJoiningApprovalStatus = false,
  showJoiningApplicationDate = false,
}) => {
  const [state, setState] = useState({
    dialog: false,
    joiningStatus: "Paid",
    JoiningApprovalFrom: new Date(),
    JoiningApprovalTo: new Date(),
  });
  const [checkboxes, setCheckboxes] = useState({
    joiningStatus: false,
    joiningDate: false,
  });
  const handleCloseFilterDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleView = () => {
    const body = {
      ...(checkboxes.joiningStatus &&
        showJoiningApprovalStatus && {
          status: state.joiningStatus,
        }),
      ...(checkboxes.joiningDate &&
        showJoiningApplicationDate && {
          from: new Date(state.JoiningApprovalFrom),
          to: new Date(state.JoiningApprovalTo),
        }),
    };
    mutate(body);
    setState((prev) => ({ ...prev, dialog: false }));
  };

  return (
    <>
      <FilterButton
        hanldeClick={() => setState((prev) => ({ ...prev, dialog: true }))}
        count={Object.values(checkboxes).filter((item) => item === true).length}
      />
      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseFilterDialog}
        handleSaveAndView={handleView}
        minWidth="500px"
      >
        <DialogContent>
          {/* by JoiningApprovalStatus status */}
          {showJoiningApprovalStatus && (
            <Box>
              <FormControlLabel
                control={<Checkbox color="secondary" />}
                checked={checkboxes.joiningStatus}
                onChange={() =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    joiningStatus: !prev.joiningStatus,
                  }))
                }
                label="Status"
                componentsProps={{
                  typography: {
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                }}
              />
              {checkboxes.joiningStatus && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={5}>
                    <CustomSelectBox
                      fullWidth
                      displayEmpty
                      value={state.joiningStatus}
                      size="small"
                      MenuProps={MenuProps}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          joiningStatus: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value={"REQUESTED"}>REQUESTED</MenuItem>
                      <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
                      <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                      <MenuItem value={"WITHDRAWN"}>WITHDRAWN</MenuItem>
                    </CustomSelectBox>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}

          {/* by JoiningApplicationDate */}

          {showJoiningApplicationDate && (
            <Box>
              <FormControlLabel
                control={<Checkbox color="secondary" />}
                checked={checkboxes.joiningDate}
                onChange={() =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    joiningDate: !prev.joiningDate,
                  }))
                }
                label="Joining Date"
                componentsProps={{
                  typography: {
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                }}
              />
              {checkboxes.joiningDate && (
                <Grid container spacing={1.5}>
                  <Grid xs={6} item>
                    <TextFieldLabel title="From" />
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      value={state.JoiningApprovalFrom}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          JoiningApprovalFrom: e,
                        }))
                      }
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder="Select"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <TextFieldLabel title="To" />
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      value={state.JoiningApprovalTo}
                      onChange={(e) =>
                        setState((prev) => ({ ...prev, JoiningApprovalTo: e }))
                      }
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          placeholder="Select"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </DialogContent>
      </CommonFilterDialog>
    </>
  );
};

export default FinalCommonFilterDialog;
