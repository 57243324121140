import { FilterAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CustomLoader from "app/components/common/CustomLoader";
import CommonFilterDialog from "app/components/common/Dialogs/CommonFilterDialog";
import StudentCard from "app/components/common/Dialogs/ledger/books/components/StudentCard";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectSession,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { getStudentLogic } from "app/services/academics";
import { getClassDropdownLogic } from "app/services/reception";
import { FormatFullName, MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const FirmInvoiceFilter = ({ loading, mutate, extraData = () => {} }) => {
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const sessionlist = useSelector(selectSession);

  const [state, setState] = useState({
    dialog: false,
    createdFrom: new Date(),
    createdTo: new Date(),
    collectionFrom: new Date(),
    collectionTo: new Date(),
    className: "",
    classDocId: "",
    section: "",
    session: [],
    studentDocId: "",
    studentName: "",
    sectionList: [],
    status: "ACTIVE",
    selectedStudent: "",
    radio: "createdOn",
    classNameList: [],
  });
  const [checkboxes, setCheckboxes] = useState({
    range: false,
    class_and_studetName: false,
    status: true,
    session: false,
  });
  const handleSaveAndView = () => {
    mutate({
      ...(checkboxes.status && {
        status: state.status,
      }),
      ...(checkboxes.range &&
        state.radio === "createdOn" && {
          createdFrom: state.createdFrom,
          createdTo: state.createdTo,
        }),
      ...(checkboxes.range &&
        state.radio === "collectionOn" && {
          collectionFrom: state.collectionFrom,
          collectionTo: state.collectionTo,
        }),
      ...(checkboxes.class_and_studetName &&
        state.classDocId && { classDocId: state.classDocId }),
      ...(checkboxes.class_and_studetName &&
        state.section && { section: state.section }),
      ...(checkboxes.class_and_studetName &&
        state.studentDocId && { studentDocId: state.studentDocId }),
      ...(checkboxes.session &&
        state.session && { reqSessionList: JSON.stringify(state.session) }),
    });
    setState((prev) => ({ ...prev, dialog: false }));
    const body2 = {
      ...(checkboxes.class_and_studetName &&
        state.classDocId && {
          className: state.className,
        }),
      ...(checkboxes.class_and_studetName &&
        state.studentDocId && {
          studentName: state.studentName,
        }),
    };
    extraData(body2);
  };

  //fetch class data
  const { data: getClassDropdownLogicData } = useQuery({
    queryKey: ["getClassDropdownLogic", selectedSession, selectedMedium],
    queryFn: () =>
      getClassDropdownLogic({ schoolDocId, selectedSession, selectedMedium }),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    data: studentData,
    isLoading: studentLoading,
    isFetching: studentFetching,
  } = useQuery({
    queryKey: ["getStudentInfo", state.section],
    queryFn: () =>
      getStudentLogic({
        streamValue: state.classDocId,
        section: state.section,
        session: selectedSession,
      }),
    enabled: state.classDocId !== "" && state.section !== "",
  });
  useEffect(() => {
    handleSaveAndView();
  }, []);

  return (
    <>
      <CustomLoader show={studentLoading || studentFetching} />
      {/* filter dialog */}
      <CommonFilterDialog
        open={state.dialog}
        onClose={() => setState((prev) => ({ ...prev, dialog: false }))}
        title="Filter"
        minWidth="550px"
        handleSaveAndView={handleSaveAndView}
        loading={loading}
      >
        <DialogContent>
          {/* by session */}
          <Box>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.session}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  session: !prev.session,
                }))
              }
              label="session"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
            {checkboxes.session && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <CustomSelectBox
                    fullWidth
                    displayEmpty
                    multiple
                    value={state.session || []}
                    size="small"
                    MenuProps={MenuProps}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        session: e.target.value,
                      }))
                    }
                    renderValue={(selected) => selected.join(", ")}
                  >
                    <MenuItem disabled value="">
                      <em>Select</em>
                    </MenuItem>
                    {sessionlist &&
                      sessionlist.map((session) => (
                        <MenuItem key={session} value={session}>
                          <Checkbox
                            checked={state.session.indexOf(session) > -1}
                          />
                          {session}
                        </MenuItem>
                      ))}
                  </CustomSelectBox>
                </Grid>
              </Grid>
            )}
          </Box>
          {/* by created date */}
          <Box>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.range}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  range: !prev.range,
                }))
              }
              label="By Date"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
            {checkboxes.range && (
              <Grid container spacing={1.5}>
                <Grid xs={6} item>
                  <FormControlLabel
                    value={state.radio}
                    control={
                      <Radio
                        color="secondary"
                        onChange={() => {
                          setState((prev) => ({
                            ...prev,
                            deviceId: "",
                            location: "",
                          }));
                          setState((prev) => ({
                            ...prev,
                            radio: "createdOn",
                          }));
                        }}
                        value={state.radio}
                        componentsProps={{
                          typography: {
                            fontSize: "14px",
                            fontWeight: 500,
                          },
                        }}
                        checked={state.radio === "createdOn" ? true : false}
                      />
                    }
                    label={"Created On"}
                  />
                </Grid>
                <Grid xs={6} item>
                  <FormControlLabel
                    value={state.radio}
                    control={
                      <Radio
                        color="secondary"
                        onChange={() => {
                          setState((prev) => ({
                            ...prev,
                            deviceId: "",
                            location: "",
                          }));
                          setState((prev) => ({
                            ...prev,
                            radio: "collectionOn",
                          }));
                        }}
                        value={state.radio}
                        componentsProps={{
                          typography: {
                            fontSize: "14px",
                            fontWeight: 500,
                          },
                        }}
                        checked={state.radio === "collectionOn" ? true : false}
                      />
                    }
                    label={"Collection On"}
                  />
                </Grid>
              </Grid>
            )}
            {checkboxes.range && state.radio === "createdOn" && (
              <Grid container spacing={1.5}>
                <Grid xs={6} item>
                  <TextFieldLabel title="From" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.createdFrom}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, createdFrom: e }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextFieldLabel title="To" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.createdTo}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, createdTo: e }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {checkboxes.range && state.radio === "collectionOn" && (
              <Grid container spacing={1.5}>
                <Grid xs={6} item>
                  <TextFieldLabel title="From" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.collectionFrom}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, collectionFrom: e }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextFieldLabel title="To" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.collectionTo}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, collectionTo: e }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          {/* by class-student name */}
          <Box>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.class_and_studetName}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  class_and_studetName: !prev.class_and_studetName,
                }))
              }
              label="Class - Student Name"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
            {checkboxes.class_and_studetName && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4.5}>
                  <TextFieldLabel title="Class" />
                  <CustomSelectBox
                    fullWidth
                    displayEmpty
                    value={state.classDocId}
                    size="small"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {getClassDropdownLogicData?.data?.list.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item.classDocId}
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            classDocId: item.classDocId,
                            sectionList: item.sections,
                            section: "",
                            className: item?.className,
                          }))
                        }
                      >
                        {item?.className_stream}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title="Section" />
                  <CustomSelectBox
                    fullWidth
                    displayEmpty
                    size="small"
                    value={state.section}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        section: e.target.value,
                        studentDocId: "",
                      }))
                    }
                    MenuProps={MenuProps}
                    disabled={state.classDocId ? false : true}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {state.sectionList?.map((item, idx) => (
                      <MenuItem key={idx} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Grid>
                <Grid item xs={12} md={4.5}>
                  <TextFieldLabel title="Student" />
                  <CustomSelectBox
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state.studentDocId}
                    MenuProps={MenuProps}
                    renderValue={
                      state.studentDocId === ""
                        ? () => "Select"
                        : () => state.selectedStudent?.firstName
                    }
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        studentDocId: e.target.value?._id,
                        selectedStudent: e.target?.value,
                      }))
                    }
                  >
                    <MenuItem value="">Select</MenuItem>
                    {studentData &&
                      studentData?.data?.list?.map((student) => (
                        <MenuItem
                          key={student._id}
                          value={student}
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              studentName:
                                FormatFullName(
                                  student?.firstName,
                                  student?.middleName,
                                  student?.lastName
                                ) ?? student?.name,
                            }));
                          }}
                        >
                          <StudentCard item={student} />
                        </MenuItem>
                      ))}
                  </CustomSelectBox>
                </Grid>
              </Grid>
            )}
          </Box>
          {/* by status */}
          <Box>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.status}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  status: !prev.status,
                }))
              }
              label="Status"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
            {checkboxes.status && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <CustomSelectBox
                    fullWidth
                    displayEmpty
                    value={state.status}
                    size="small"
                    MenuProps={MenuProps}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        status: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value={"ACTIVE"}>Active</MenuItem>
                    <MenuItem value={"Paid"}>Paid</MenuItem>
                    <MenuItem value={"DELETED"}>Deleted</MenuItem>
                  </CustomSelectBox>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
      </CommonFilterDialog>
      <Box>
        <Button
          variant="contained"
          onClick={() => setState((prev) => ({ ...prev, dialog: true }))}
          color="secondary"
          sx={{
            width: "20rem",
            height: "4rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            borderRadius: "10px",
            background: "#0099ff",
            fontSize: "1.2rem",
          }}
        >
          Filter
          <FilterAlt />
          <Typography
            sx={{
              padding: "0 5px",
              background: "#fff",
              color: "black",
              borderRadius: "50%",
            }}
          >
            {Object.values(checkboxes).filter((item) => item === true).length}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default FirmInvoiceFilter;
