import { Box2, Eye } from "react-bootstrap-icons";
import { useMutation, useQuery } from "react-query";
import { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";

import {
  chnageFeeTypeNameInFeeCollection,
  rejectFeeStructureRequest,
  runFeeByFeeStructure,
  updateFeeStructureRelaxationCategory,
} from "app/services/management";
import {
  Box,
  MenuItem,
  Stack,
  Typography,
  Button,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  Container,
  useMediaQuery,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

//imports of our files
import ViewActionModal from "./components/ViewActionModal";
import {
  getAllClassDropdowns,
  getFeeStructureByClassDocID,
  filterFeeStructureByClassDocId,
  getFeeStructureByFeeDocId,
} from "app/services/management";
import { Helmet } from "react-helmet";
import AddNewFeeModal from "./components/AddNewFeeModal";
import {
  selectedMediumSelector,
  selectActiveSession,
  selectSelectedSchoolId,
  userPrimaryRoleSelector,
} from "app/modules/schools/selectors";
import { LoadingButton } from "@mui/lab";
import { Delete, Edit } from "@mui/icons-material";
import EditFeeModal from "./components/EditFeeModal";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import CommonTable1 from "app/components/Tables/CommonTable1";
import { toast } from "react-toastify";
import DialogHeader from "app/components/common/DialogHeader";
import TextFieldLabel from "app/components/common/TextFieldLabel";

import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import CustomLoader from "app/components/common/CustomLoader";
import { MenuProps } from "app/utils/helper";
import FeeLinkingDialog from "app/modules/fee-management/fee-structure/FeeLinkingDialog";
import {
  approveFeeStructure,
  deleteFeeStructure,
  getFeeRelaxationCategory,
  rejectFeeStructure,
} from "app/services/schoolService/newFee.service";
import { getFeeTypeForMultipleClass } from "../../../services/schoolService/newFee.service";
import dayjs from "dayjs";
import SpecificStudentDialog from "app/components/common/Dialogs/fee/SpecificStudentDialog";
import IndivisualFeeSturctureFilter from "app/components/common/Dialogs/fee/IndivisualFeeSturctureFilter";
import CommonTableHorizontalScrollable from "app/components/Tables/CommonTableHorizontalScrollable";
const DeleteGridType = ({ title, data }) => {
  return (
    <>
      <Grid container spacing={2} sx={{ pt: 0.6 }}>
        <Grid item xs={4}>
          <TextFieldLabel title={title} />
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
            {data}
          </Typography>
        </Grid>
      </Grid>
      <Divider
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          opacity: 0.3,
        }}
      />
    </>
  );
};
export default function FeesPage() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const session = useSelector(selectActiveSession);
  const [isLoading, setIsLoading] = useState(false);
  //getting the schooldocid using selector
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const primaryRole = useSelector(userPrimaryRoleSelector);
  const medium = useSelector(selectedMediumSelector);

  const [mainData, setMainData] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [classStreamObj, setClassStreamObj] = useState([]);
  const [actionModalContent, setActionModalContent] = useState({});
  const [show, setShow] = useState(false);
  const [viewActionModal, setViewActionModal] = useState(false);
  const [streamValue, setStreamValue] = useState("");
  const [disableBtn, setDisablebtn] = useState(true);
  const [editFeeDetails, setEditFeeDetails] = useState({});
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [runFeeDialog, setRunFeeDialog] = useState(false);
  const [runFeeId, setRunFeeId] = useState("");

  const [deleteData, setDeleteData] = useState(null);
  const [state, setState] = useState({
    month: new Date(),
    year: new Date(),
    linkDialog: false,
    linkDialogData: {},
    feeStructureDocId: "",
    feeStructureGroupDocId: "",
    updateFeeTypeDialog: false,
    updateFeeTypeClass: false,
    updateFeeType: "",
    updatefeeTypeStrucureDocId: "",
    optional: false,
    classDocId: "",
    section: "",
    specificStudentDialog: false,
    fetch: false,
    filterData: false,
  });
  const heightRef = useRef(null);
  const [falseState, setFalseState] = useState(false);
  const [allCheck, setAllCheck] = useState(false);
  const open = Boolean(anchorEl);
  const handleClickMenu = (event, cell) => {
    setAnchorEl(event.currentTarget);
    setRunFeeId(cell?.row?.original?._id);
    setState((prev) => ({
      ...prev,
      optional: cell?.row?.original?.optional,
      classDocId: cell?.row?.original?.classDocId,
      section: cell?.row?.original?.section,
    }));
  };
  const handleClickSpecific = () => {
    setState((prev) => ({ ...prev, specificStudentDialog: true }));
  };
  const handleClose = () => {
    setAnchorEl(null);
    setState((prev) => ({
      ...prev,
      classDocId: "",
      section: "",
      optional: false,
    }));
  };
  const handleCloseUpdateFeeTypeDialog = () => {
    setState((prev) => ({
      ...prev,
      updateFeeType: "",
      updateFeeTypeDialog: false,
      updatefeeTypeStrucureDocId: "",
    }));
  };
  const {
    isLoading: runFeeByFeeStructureLoading,
    mutate: runFeeByFeeStructureMutate,
  } = useMutation(runFeeByFeeStructure, {
    onSuccess: ({ data }) => {
      handleCloseRunFeeDialog();
      setState((prev) => ({ ...prev }));
      toast.success(data?.message);
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const handleClose1 = () => {
    setOpenModal(false);
  };

  const handleSubmitRunFee = () => {
    // const body = {
    //   feeStructureDocId: runFeeId,
    //   month: dayjs(state.month).format("MMMM"),
    //   year: dayjs(state.year).format("YYYY"),
    // };
    runFeeByFeeStructureMutate(runFeeId);
  };
  const {
    isLoading: getAllFeeFetchingLoading,
    isFetching: getAllFeeFetching,
    refetch: fetchFeeStructure,
  } = useQuery({
    queryKey: ["filterAllFeeStructure", session, medium, state.fetch],
    queryFn: () =>
      filterFeeStructureByClassDocId({
        ...(state.filterData && state.filterData),
        session: session,
        medium: medium,
      }),
    onSuccess: (data) => {
      const tempData = data?.data?.list?.map((item) => ({
        ...item,
        checked: false,
      }));
      setMainData(tempData);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const handleFilter = (payload) => {
    setState((prev) => ({ ...prev, fetch: !prev.fetch, filterData: payload }));
  };
  const handleOnClickWholeStudent = () => {
    setAnchorEl(null);
    setRunFeeDialog(true);
  };

  //function for getting the fee structure on click of action button
  const fetchFeeStructureByclassDocID = async (feeCode) => {
    setIsLoading(true);
    const res = await getFeeStructureByClassDocID(feeCode, session, medium);
    const temp = res?.data;
    setActionModalContent(temp?.obj);
    setViewActionModal(true);
    setIsLoading(false);
  };

  const fetchFeeStructureByFeeStructureId = async (feeDocId) => {
    const res = await getFeeStructureByFeeDocId(feeDocId);
    const temp = res.data;

    setEditFeeDetails(temp?.obj);
    setIsEditModalVisible(true);
  };

  const [showPlus, setShowPlus] = useState(true);
  const [doMutate, setDoMutate] = useState("");

  const handleButtonClick = (cell) => {
    setDoMutate(cell?.row?.original?._id);
    setInputValue(cell?.row?.original?.feeRelaxationCategory);
    setOpenModal(true);
    setShowPlus(false);
  };

  const { mutate, isLoading: updateFeeStructureRelaxationCategoryLoading } =
    useMutation(updateFeeStructureRelaxationCategory, {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        setOpenModal(false);
        fetchFeeStructure();
      },

      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });
  const {
    mutate: chnageFeeTypeNameInFeeCollectionMutate,
    isLoading: chnageFeeTypeNameInFeeCollectionLoading,
  } = useMutation(chnageFeeTypeNameInFeeCollection, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      handleCloseUpdateFeeTypeDialog();
      fetchFeeStructure();
    },

    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const { mutate: deleteFee, isLoading: deleteFeeLoading } = useMutation(
    deleteFeeStructure,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        fetchFeeStructure();
        setDeleteData(null);
      },

      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  //this is the updateFeeStructureRelaxationCategory used by mui table
  const handleDelete = () => {
    deleteFee(deleteData?._id);
  };

  const [approvalCalling, setApprovalCalling] = useState({
    dialog: false,
    total: "",
    current: "",
    percentage: "",
  });
  const handleApprove = async () => {
    const totalData = mainData
      ?.filter((item) => item?.checked)
      .map((item) => item?._id);

    setApprovalCalling((prev) => ({
      ...prev,
      dialog: true,
      total: totalData?.length,
      current: 0,
    }));
    await Promise.allSettled(
      totalData.map((item) => {
        return new Promise(async (resolve, reject) => {
          try {
            const success = await approveFeeStructure(item);
            setApprovalCalling((prev) => ({
              ...prev,
              current: prev.current + 1,
            }));
            toast.success(success?.data?.message);
            resolve("");
          } catch (error) {
            resolve("");
            toast.error(error?.response?.data?.message);
          }
        });
      })
    );
    setApprovalCalling((prev) => ({
      ...prev,
      dialog: false,
      total: 0,
      current: 0,
    }));
    fetchFeeStructure();
  };
  const handleReject = async () => {
    const totalData = mainData
      ?.filter((item) => item?.checked)
      .map((item) => item?._id);

    setApprovalCalling((prev) => ({
      ...prev,
      dialog: true,
      total: totalData?.length,
      current: 0,
    }));
    await Promise.allSettled(
      totalData.map((item) => {
        return new Promise(async (resolve, reject) => {
          try {
            const success = await rejectFeeStructure(item);
            setApprovalCalling((prev) => ({
              ...prev,
              current: prev.current + 1,
            }));
            toast.success(success?.data?.message);
            resolve("");
          } catch (error) {
            toast.error(error?.response?.data?.message);
            resolve("");
          }
        });
      })
    );
    setApprovalCalling((prev) => ({
      ...prev,
      dialog: false,
      total: 0,
      current: 0,
    }));
    fetchFeeStructure();
  };
  const showApproveButton = () => {
    let isShow = false;
    mainData.forEach((item) => {
      if (item?.checked) {
        isShow = true;
        return;
      }
    });
    return isShow;
  };

  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        accessorKey: "Sr.No",
        Cell: ({ cell }) => (
          <p
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {parseInt(cell.row.id) + 1}
          </p>
        ),
        size: 10,
      },
      {
        header: "Class-Str-Sec",
        accessorKey: "class_stream_sec",
        // maxSize: 50,
      },
      {
        header: "Fee Code",
        accessorKey: "feeCode",
        // maxSize: 30,
      },
      {
        header: "Fee Type",
        accessorKey: "feeType",
        // maxSize: 60,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  updateFeeType: original?.feeType,
                  updateFeeTypeDialog: true,
                  updatefeeTypeStrucureDocId: original?._id,
                  updateFeeTypeClass: original?.className,
                }));
              }}
              sx={{
                backgroundColor: "#EBF0F4",
                borderRadius: "8px",
                px: 1,
                py: 0.5,
                maxWidth: "100px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
                display: "inline",
              }}
            >
              {original?.feeType === undefined ? "+" : original?.feeType}
            </Typography>
          );
        },
      },
      {
        header: "Fee Amt",
        accessorKey: "feeAmount",
        Cell: ({ cell }) => (
          <Typography>{`₹${cell?.row?.original?.feeAmount}`}</Typography>
        ),
        // maxSize: 30,
      },
      {
        header: "Status",
        accessorKey: "status",
        Header: () => {
          return (
            <Stack direction="row" alignItems="center" gap={0.8}>
              <Typography>Status</Typography>
              <input
                type="checkbox"
                checked={allCheck}
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
                onClick={() => setAllCheck((prev) => !prev)}
              />
            </Stack>
          );
        },
        Cell: ({ cell, row }) => {
          return (
            <div
              style={{
                color:
                  row?.original?.status === "APPROVED"
                    ? "green"
                    : row?.original?.status === "REJECTED"
                    ? "red"
                    : "#FFBA23",
                fontWeight: "bold",
                width: "70px",
                height: "40px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {row?.original?.status}
              {row?.original?.status === "REQUESTED" && (
                <input
                  type="checkbox"
                  id="checkdiv"
                  checked={row?.original?.checked}
                  style={{ cursor: "pointer", width: "30px", height: "30px" }}
                  onChange={(e) => {
                    mainData[Number(row.id)].checked = !row?.original?.checked;
                    setFalseState(!falseState);
                  }}
                />
              )}
            </div>
          );
        },
        // maxSize: 40,
      },
      {
        header: "Fee Relaxation",
        accessorKey: "feeRelaxationCategory",
        Cell: ({ cell }) => {
          return (
            <Typography
              onClick={() => {
                handleButtonClick(cell);
              }}
              sx={{
                backgroundColor: "#EBF0F4",
                borderRadius: "8px",
                px: 1,
                py: 0.5,
                maxWidth: "100px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
                display: "inline",
              }}
            >
              {cell?.row?.original?.feeRelaxationCategory === undefined
                ? "+"
                : cell?.row?.original?.feeRelaxationCategory}
            </Typography>
          );
        },
        // maxSize: 60,
      },
      {
        header: "Grouped",
        accessorKey: "feeStructureGroupDocId",
        Cell: ({
          cell: {
            row: { original },
          },
        }) =>
          original?.feeStructureGroupDocId ? (
            <Typography>Yes</Typography>
          ) : (
            <Typography
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  linkDialog: true,
                  linkDialogData: original,
                  feeStructureDocId: original?._id,
                }))
              }
              sx={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              No
            </Typography>
          ),

        // size: 10,
      },
      {
        header: "Optional",
        accessorKey: "optional",
        Cell: ({
          cell: {
            row: { original },
          },
        }) =>
          original?.optional ? (
            <Typography
              sx={{
                color: "green",
              }}
            >
              Yes
            </Typography>
          ) : (
            <Typography
              sx={{
                color: "blue",
              }}
            >
              No
            </Typography>
          ),

        // size: 10,
      },
      // {
      //   header: "Created On",
      //   accessorKey: "createdOn",
      //   id: "createdOn",
      //   accessorfn: (row) => row.createdOn,
      //   Cell: ({ cell }) => {
      //     return format(new Date(cell.getValue()), "dd/MM/yyyy");
      //   },
      //   size: 60,
      // },
      // {
      //   header: "Created By",
      //   accessorKey: "createdBy.personName",
      //   Cell: ({ cell }) => {
      //     return (
      //       <Typography
      //         sx={{
      //           width: "80px",
      //           whiteSpace: "nowrap",
      //           overflow: "hidden",
      //           textOverflow: "ellipsis",
      //         }}
      //       >
      //         {cell?.row?.original?.createdBy?.personName}
      //       </Typography>
      //     );
      //   },
      //   maxSize: 40,
      // },
      {
        header: "Actions",
        accessorKey: "action",
        Cell: ({ cell }) => {
          return (
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              justifyContent="flex-start"
            >
              {cell?.row?.original?.status === "APPROVED" && (
                // (cell?.row?.original?.feeType === "Other Fee" ||
                //   cell?.row?.original?.feeType === "Exam Fee") &&
                <Button
                  variant="contained"
                  size="small"
                  onClick={(e) => handleClickMenu(e, cell)}
                >
                  Apply
                </Button>
              )}
              <button
                onClick={() => {
                  handleViewAction(cell);
                }}
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "8px",
                  border: "none",
                  outline: "none",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              >
                <Eye />
              </button>
              {cell?.row?.original?.status !== "APPROVED" &&
                [
                  "ROLE_ADMIN",
                  "ROLE_MANAGING_DIRECTOR",
                  "ROLE_ACCOUNTANT",
                ].includes(primaryRole) && (
                  <button
                    onClick={() => {
                      handleEditFeeStructure(cell);
                    }}
                    style={{
                      backgroundColor: "#EBF0F4",
                      borderRadius: "8px",
                      border: "none",
                      outline: "none",
                      width: "2.5rem",
                      height: "2.5rem",
                    }}
                  >
                    <Edit />
                  </button>
                )}
              <button
                onClick={() => {
                  setDeleteData(cell?.row?.original);
                }}
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "8px",
                  border: "none",
                  outline: "none",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              >
                <Delete color="error" />
              </button>
            </Stack>
          );
        },
        // size: 70,
      },
    ];
  }, [falseState, mainData]);

  //handle function for onClick of the action button
  const handleViewAction = (cell) => {
    fetchFeeStructureByclassDocID(cell?.row?.original?.feeCode);
  };

  const handleEditFeeStructure = (cell) => {
    fetchFeeStructureByFeeStructureId(cell?.row?.original?._id);
  };
  const handleCloseRunFeeDialog = () => {
    setRunFeeDialog(false);
    setRunFeeId("");
  };
  //function for getting the combined class stream and section

  const getClassStreamObj = (classOptions) => {
    let tempArray = [];
    classOptions.map((obj) => {
      obj.sections?.map((section) => {
        tempArray.push({
          classStreamSection: `${obj?.className_stream}-${
            section ? section : ""
          }`,
          classDocId: obj?.classDocId,
          section: section ? section : "",
        });
      });
    });

    setClassStreamObj(tempArray);
  };

  //calling that function every time when the data changes from the api
  useEffect(() => {
    getClassStreamObj(classOptions);
  }, [classOptions]);

  //handler function for the class stream section filter
  const handleCloseLinkingDialog = () => {
    setState((prev) => ({
      ...prev,
      linkDialog: false,
      linkDialogData: {},
      feeStructureDocId: "",
      feeStructureGroupDocId: "",
    }));
  };
  const {
    isLoading: getFeeRelaxationCategoryLoading,
    isFetching: getFeeRelaxationCategoryFetching,
    data: getFeeRelaxationCategoryData,
  } = useQuery({
    queryKey: ["getFeeRelaxationCategory", openModal],
    queryFn: () => getFeeRelaxationCategory(),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: openModal ? true : false,
  });
  const {
    isLoading: getFeeTypeLoading,
    isFetching: getFeeTypeFetching,
    data: getFeeTypeData,
  } = useQuery({
    queryKey: ["getFeeType", session, state.updateFeeTypeDialog],
    queryFn: () =>
      getFeeTypeForMultipleClass({
        classList: JSON.stringify([state.updateFeeTypeClass]),
        session: session,
      }),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: state.updateFeeTypeDialog ? true : false,
  });

  let header = [
    "#",
    "Session",
    "Class_Stream_Sec",
    "Fee_Type",
    "Fee_Code",
    "Fee_Relaxation_Category",
    "General_Fee_Amount",
    "Category_Discount_Amount",
    "Tax_Rate",
    "Fee_Amount",
    "Status",
    "Created_On",
    "Created_By",
  ];
  const [selectedOptions, setSelectedOptions] = useState(header);
  const [openCheckBox, setOpenCheckBox] = useState(false);

  let exportArray = mainData?.map((item, index) => {
    return {
      "#": index < 9 ? `0${index + 1}` : index + 1,
      Session: item?.session,
      Class_Stream_Sec: item?.class_stream_sec,
      Fee_Type: item?.feeType ?? "--",
      Fee_Code: item?.feeCode ?? "--",
      Fee_Relaxation_Category: item?.feeRelaxationCategory ?? "--",
      General_Fee_Amount: item?.generalFeeAmount ?? "--",
      Category_Discount_Amount: item?.categoryDiscountAmount ?? "--",
      Tax_Rate: item?.taxRate ?? "--",
      Fee_Amount: item?.feeAmount ?? "--",
      Status: item?.status,
      Created_On: dayjs(item?.createdOn).format("DD-MM-YYYY"),
      Created_By: item?.createdBy?.personName,
    };
  });

  const handleExport = () => {
    const filteredExportArray = exportArray?.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Individual Fee Structure"
    );
    XLSX.writeFile(workbook, "IndividualFeeStructure.xlsx");
  };
  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };
  //getting the all class dropdown
  useEffect(() => {
    const fetchClassData = async () => {
      const res = await getAllClassDropdowns(schoolDocId, session, medium);
      const data = res.data;
      setClassOptions(data?.list);
    };

    fetchClassData();
  }, [session, medium]);

  //logic for the disable section dropdown
  useEffect(() => {
    const sectionArray = classOptions.filter((item) => {
      return item.classDocId === streamValue;
    });

    setSectionOptions(sectionArray[0]?.sections);
    if (streamValue?.length > 0) setDisablebtn(false);
  }, [streamValue, classOptions]);
  useEffect(() => {
    if (!allCheck) {
      mainData?.forEach((item, i) => {
        mainData[i]["checked"] = false;
      });
      setFalseState(!falseState);
    } else {
      mainData?.forEach((item, i) => {
        if (item?.status === "REQUESTED") {
          mainData[i]["checked"] = true;
        }
      });
      setFalseState(!falseState);
    }
  }, [allCheck]);
  return (
    <>
      <CustomLoader
        show={getAllFeeFetchingLoading || getAllFeeFetching || isLoading}
      />
      {/* linking dialog */}
      <FeeLinkingDialog
        feeStructureDocId={state.feeStructureDocId}
        feeStructureGroupDocId={state.feeStructureGroupDocId}
        handleClose={handleCloseLinkingDialog}
        linkDialogData={state.linkDialogData}
        open={state.linkDialog}
        refetch={() => fetchFeeStructure()}
      />
      <Dialog
        open={openCheckBox}
        onClose={() => setOpenCheckBox(false)}
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to show in the excel file"
          handleClose={() => setOpenCheckBox(false)}
        />
        <DialogContent>
          <Box>
            {header.map((option) => (
              <Stack direction="row">
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChange(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptions(header);
              handleExport();
              setOpenCheckBox(false);
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{ sx: { maxWidth: "400px" } }}
        open={approvalCalling.dialog}
      >
        <DialogContent>
          <Typography sx={{ fontWeight: 600, mb: 1.5 }}>
            Processing the Request....
          </Typography>
          <Stack direction="row" gap={1.5} alignItems="center">
            <Box
              sx={{
                justifyContent: "center",
                width: "250px",
                height: "14px",
                border: "1px solid rgba(0,0,0,0.2)",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  width: `${Number(
                    (Number(approvalCalling.current) * 100) /
                      approvalCalling.total
                  ).toFixed()}%`,
                  height: "14px",
                  mt: "-1px",
                  backgroundColor: "green",
                  borderRadius: "10px",
                }}
              />
            </Box>
            <Typography>
              {approvalCalling.current}/{approvalCalling.total}
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteData}
        onClose={() => setDeleteData(null)}
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogContent>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              textAlign: "center",
              mb: 1,
            }}
          >
            Are You Sure Want To Delete.
          </Typography>
          <DeleteGridType title="Fee Type" data={deleteData?.feeType} />
          <DeleteGridType title="Fee Code" data={deleteData?.feeCode} />
          <DeleteGridType title="Fee Amount" data={deleteData?.feeAmount} />
          <DeleteGridType
            title="Class-Str-Sec"
            data={deleteData?.class_stream_sec}
          />
          <DeleteGridType title="Status" data={deleteData?.status} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => setDeleteData(null)}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            size="large"
            onClick={handleDelete}
            loading={deleteFeeLoading}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={runFeeDialog}
        onClose={handleCloseRunFeeDialog}
        PaperProps={{
          sx: { width: "400px" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Apply Fee Month Year"
            handleClose={handleCloseRunFeeDialog}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <Typography>Are You Sure Want To Apply</Typography>
            {/* <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box>
                <TextFieldLabel title="Select Month" />
                <DesktopDatePicker
                  views={["month"]}
                  inputFormat="MMMM"
                  onChange={(e) => setState((prev) => ({ ...prev, month: e }))}
                  value={state.month}
                  renderInput={(params) => (
                    <CustomTextField {...params} size="small" fullWidth />
                  )}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextFieldLabel title="Select Year" />
                <DesktopDatePicker
                  views={["year"]}
                  inputFormat="YYYY"
                  onChange={(e) => setState((prev) => ({ ...prev, year: e }))}
                  value={state.year}
                  renderInput={(params) => (
                    <CustomTextField {...params} size="small" fullWidth />
                  )}
                />
              </Box>
            </LocalizationProvider> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={runFeeByFeeStructureLoading}
            sx={{ textTransform: "capitalize", fontSize: "14px" }}
            variant="contained"
            color="secondary"
            onClick={handleSubmitRunFee}
          >
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* update fee relaxation dialog */}
      <Dialog
        open={openModal}
        onClose={handleClose1}
        PaperProps={{ sx: { minWidth: "400px" } }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Update Fee Relaxation"
            handleClose={handleClose1}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }} />
          {(getFeeRelaxationCategoryLoading ||
            getFeeRelaxationCategoryFetching) && (
            <Stack direction={"row"} justifyContent={"center"}>
              <CircularProgress />
            </Stack>
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              mutate({
                feeStructureDocId: doMutate,
                feeRelaxationCategory: inputValue,
              });
            }}
          >
            <TextFieldLabel title={"Fee Relaxation"} />
            <CustomSelectBox
              fullWidth
              size="small"
              displayEmpty
              required
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              MenuProps={MenuProps}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {getFeeRelaxationCategoryData?.data?.list?.map((item, i) => (
                <MenuItem value={item?.feeRelaxationCategory} key={i}>
                  {item?.feeRelaxationCategory}
                </MenuItem>
              ))}
            </CustomSelectBox>
            <LoadingButton
              loading={updateFeeStructureRelaxationCategoryLoading}
              size="large"
              variant="contained"
              color="secondary"
              type="submit"
              fullWidth
              sx={{ mt: 2 }}
            >
              Update
            </LoadingButton>
          </form>
        </DialogContent>
      </Dialog>
      {/* update fee type dialog */}
      <Dialog
        open={state.updateFeeTypeDialog}
        onClose={handleCloseUpdateFeeTypeDialog}
        PaperProps={{ sx: { minWidth: "400px" } }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Update Fee Type"
            handleClose={handleCloseUpdateFeeTypeDialog}
          />
        </DialogTitle>
        <DialogContent>
          {(getFeeTypeLoading || getFeeTypeFetching) && (
            <Stack direction={"row"} justifyContent={"center"}>
              <CircularProgress />
            </Stack>
          )}
          <Box sx={{ mt: 1 }} />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              chnageFeeTypeNameInFeeCollectionMutate({
                feeStructureDocId: state.updatefeeTypeStrucureDocId,
                newFeeTypeName: state.updateFeeType,
              });
            }}
          >
            <TextFieldLabel title={"Fee Type"} />
            <CustomSelectBox
              fullWidth
              size="small"
              displayEmpty
              required
              value={state.updateFeeType}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  updateFeeType: e.target.value,
                }));
              }}
              MenuProps={MenuProps}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {getFeeTypeData?.data?.list?.map((item, i) => (
                <MenuItem value={item?.feeType} key={i}>
                  {item?.feeType}
                </MenuItem>
              ))}
            </CustomSelectBox>
            <LoadingButton
              loading={chnageFeeTypeNameInFeeCollectionLoading}
              size="large"
              variant="contained"
              color="secondary"
              type="submit"
              fullWidth
              sx={{ mt: 2 }}
            >
              Update
            </LoadingButton>
          </form>
        </DialogContent>
      </Dialog>
      {/* spacific student dialog */}
      <SpecificStudentDialog
        open={state.specificStudentDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, specificStudentDialog: false }))
        }
        classDocId={state.classDocId}
        section={state.section}
        feeStructureDocId={runFeeId}
        session={session}
        refetch={fetchFeeStructure}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleOnClickWholeStudent}>Whole Student</MenuItem>
        <MenuItem onClick={handleClickSpecific} disabled={!state.optional}>
          Specific Student
        </MenuItem>
      </Menu>
      <Helmet>
        <title>Fee Structure</title>
      </Helmet>
      {/* the add new fee structure modal */}

      <AddNewFeeModal
        show={show}
        setShow={setShow}
        classOptions={classStreamObj?.map((obj) => obj?.classStreamSection)}
        classStreamObj={classStreamObj}
        isEditing={false}
        fetchFeeStructure={fetchFeeStructure}
      />

      <EditFeeModal
        show={isEditModalVisible}
        setShow={setIsEditModalVisible}
        classOptions={classStreamObj?.map((obj) => obj?.classStreamSection)}
        classStreamObj={classStreamObj}
        editingValues={editFeeDetails}
        feeDocId={editFeeDetails?._id}
      />

      <ViewActionModal
        open={viewActionModal}
        setOpen={setViewActionModal}
        feeStructureData={actionModalContent}
      />
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%", pb: 1 }}
          alignItems="center"
        >
          <IndivisualFeeSturctureFilter
            mutate={handleFilter}
            classList={classOptions}
          />
          <ExportButtonGroup
            handleClickExcel={() => setOpenCheckBox(true)}
            showPdf={false}
          />
        </Stack>

        <Box sx={{ height: "100%" }} ref={heightRef}>
          <CommonTableHorizontalScrollable
            columns={columns}
            data={mainData}
            setViewActionModal={setViewActionModal}
            maxHeight={`${heightRef?.current?.clientHeight - 50}px`}
            initialState={{
              columnPinning: {
                left: ["Sr.No"],
                right: ["action"],
              },
            }}
          />
        </Box>

        {showApproveButton() && (
          <Stack
            sx={{ justifyContent: "flex-end", mt: 2, pb: 6 }}
            direction="row"
            gap={2}
          >
            <Button
              variant="contained"
              color="success"
              size="large"
              sx={{ width: "12vw", p: "0.8rem", mr: 2 }}
              onClick={handleApprove}
            >
              APPROVE
            </Button>
            <Button
              variant="contained"
              color="error"
              size="large"
              sx={{ width: "12vw", p: "0.8rem", mr: 2 }}
              onClick={handleReject}
            >
              REJECT
            </Button>
          </Stack>
        )}
      </Box>
    </>
  );
}
