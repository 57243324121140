import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import appRoutes from "app/config/routes";
import { useDispatch, useSelector } from "react-redux";
import { refreshAccessToken } from "../../thunk";
import {
  changePassword,
  forgetPassword,
  verifyOTP,
} from "app/services/authService/auth.service";
import { setValidatingSession } from "../../operations";
import { selectIsValidatingSession, errorMsgSelector } from "../../selectors";
import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { AuthTextField } from "assets/styles/globalStyledComponent";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import OTPInput from "react-otp-input";
import {
  KeyboardBackspace,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Helmet } from "react-helmet";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [requestID, setRequestID] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isValidatingSession = useSelector(selectIsValidatingSession);
  const errorMsg = useSelector(errorMsgSelector);

  const [email, setEmail] = useState("");
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      password: yup.string().required("Password is required"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Password should be same")
        .required("Cofirm password is required"),
    }),
    onSubmit: (values) => {
      const obj = {
        username: email,
        password: values.password,
        passwordConfirmation: values.confirmPassword,
        request_id: requestID,
      };
      passwordChangeMutation(obj);
    },
  });
  // Check if refresh token exists
  useEffect(() => {
    const authRefreshToken = localStorage.getItem("authRefreshToken");

    // Check if refresh token exists
    if (authRefreshToken && authRefreshToken.length > 0) {
      dispatch(setValidatingSession(true));
      dispatch(
        refreshAccessToken({
          refreshToken: authRefreshToken,
        })
      )
        .then(({ payload }) => {
          navigate({ pathname: `/${appRoutes.schools}` });
        })
        .finally(() => {
          dispatch(setValidatingSession(false));
        });
    }
  }, []);

  const { mutate: forgotPasswordMutate, isLoading: forgotPasswordLoading } =
    useMutation(forgetPassword, {
      onSuccess: (res) => {
        toast.success(res.data?.message);
        setFirst(true);
      },
      onError: (err) => toast.error(err.response?.data?.message),
    });

  const {
    mutate: emailOtpVerifyMutation,
    isSuccess: emailOtpVerified,
    isLoading: emailOtpVerifyLoading,
  } = useMutation(verifyOTP, {
    onSuccess: (res) => {
      toast.success(res.data?.message);
      setRequestID(res.data?.request_id);
      setSecond(true);
    },
    onError: (err) => toast.error(err.response?.data?.message),
  });

  const { mutate: passwordChangeMutation, isLoading: passwordChangeLoading } =
    useMutation(changePassword, {
      onSuccess: (res) => {
        toast.success(res.data?.message);
        navigate("/login");
      },
      onError: (err) => toast.error(err.response?.data?.message),
    });

  const handleSubmit = (e) => {
    e.preventDefault();

    forgotPasswordMutate(email);
  };

  const handleMailVerify = (e) => {
    e.preventDefault();
    emailOtpVerifyMutation({ medium: email, otp });
  };

  useEffect(() => {
    if (errorMsg && errorMsg.length > 0) toast.error(errorMsg);
  }, [errorMsg]);

  return isValidatingSession ? null : (
    <Wrapper>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{
          width: { xs: "95vw", md: "80vw" },
          height: "90vh",
          backgroundColor: " #FBFBFF",
          borderRadius: "10px",
          boxShadow: 3,
        }}
      >
        {/* image section */}
        <Box className="left_div" sx={{ display: { xs: "none", md: "block" } }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ width: "100%" }}
          >
            <img
              src="/auth-logo.png"
              className="logo_image"
              alt="ekalsutra-logo"
            />
          </Stack>

          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: "90%" }}
          >
            {emailOtpVerified ? (
              <div className="img-container">
                <img
                  src="/circle.png"
                  className="bg-circle"
                  alt="ekalsutra-forgot-password"
                />
                <img
                  src="/computer.png"
                  className="computer"
                  alt="ekalsutra-forgot-password"
                />
              </div>
            ) : (
              <img
                src="/pad-lock.png"
                className="pad-lock"
                alt="ekalsutra-forgot-password"
              />
            )}
          </Stack>
        </Box>
        <Box sx={{ display: { md: "none" } }}>
          <Box sx={{ p: 2 }}>
            <KeyboardBackspace
              sx={{ fontSize: "30px" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Stack direction="row" justifyContent="center" sx={{ py: 3.5 }}>
            <img
              alt="ekalsutra logo"
              src="/logo-blue.png"
              style={{ height: "70px", objectFit: "contain" }}
            />
          </Stack>
        </Box>
        {/* form section */}
        {!first && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: { xs: "100%", md: "50%" } }}
          >
            <Typography
              className="login_text"
              sx={{ fontSize: { xs: "18px", md: "30px" } }}
            >
              Forgot Password ?
            </Typography>

            <form onSubmit={handleSubmit} style={{ width: "80%" }}>
              <Box sx={{ mt: "30px", width: "100%" }}>
                <Typography className="input_label">Email</Typography>
                <AuthTextField
                  required
                  type="email"
                  size="medium"
                  placeholder="example@gmail.com"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <MailOutlineIcon sx={{ color: "#000" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <LoadingButton
                loading={forgotPasswordLoading}
                variant="contained"
                className="login_button"
                color="secondary"
                type="submit"
              >
                Reset
              </LoadingButton>
            </form>
          </Stack>
        )}

        {first && !second && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: { xs: "100%", md: "50%" } }}
          >
            <Typography className="login_text" sx={{ marginBottom: "20px" }}>
              Enter OTP
            </Typography>

            <form onSubmit={handleMailVerify} style={{ width: "80%" }}>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ marginBottom: "10px" }}
              >
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => (
                    <input class="otp-input" {...props} />
                  )}
                />

                <LoadingButton
                  loading={emailOtpVerifyLoading}
                  variant="contained"
                  className="login_button"
                  color="secondary"
                  type="submit"
                  style={{ width: "70%" }}
                  // onClick={() => handleMailVerify()}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ marginTop: "20px" }}
            >
              Did not recieve OTP ?{" "}
              <Link onClick={() => forgotPasswordMutate(email)}>Resend</Link>
            </Stack>
          </Stack>
        )}

        {second && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: { xs: "100%", md: "50%" } }}
          >
            <Typography
              className="create_passowrd_text"
              sx={{ fontSize: { xs: "18px", md: "25px" } }}
            >
              Create New Password
            </Typography>

            <form onSubmit={formik.handleSubmit} style={{ width: "80%" }}>
              <Box sx={{ mt: "30px", width: "100%" }}>
                <Typography className="input_label">Password</Typography>
                <OutlinedInput
                  type={showPassword ? "text" : "password"}
                  className="password"
                  placeholder="xxxxxxxxx"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton>
                        <LockIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <Visibility sx={{ color: "#000" }} />
                        ) : (
                          <VisibilityOff sx={{ color: "#000" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formik.touched.password && Boolean(formik.errors.password) && (
                  <Typography color="error">
                    {formik.errors.password}
                  </Typography>
                )}
              </Box>
              <Box sx={{ mt: "30px", width: "100%" }}>
                <Typography className="input_label">
                  Confirm New Password
                </Typography>
                <OutlinedInput
                  type={showConfirmPassword ? "text" : "password"}
                  className="password"
                  placeholder="xxxxxxxxx"
                  name="confirmPassword"
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton>
                        <LockIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <Visibility sx={{ color: "#000" }} />
                        ) : (
                          <VisibilityOff sx={{ color: "#000" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword) && (
                    <Typography color="error">
                      {formik.errors.confirmPassword}
                    </Typography>
                  )}
              </Box>

              <LoadingButton
                loading={passwordChangeLoading}
                variant="contained"
                className="login_button"
                color="secondary"
                type="submit"
              >
                Save
              </LoadingButton>
            </form>
          </Stack>
        )}
      </Stack>
    </Wrapper>
  );
};

export default ForgotPassword;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .left_div {
    background: url("/auth-bg.png");
    background-color: #084c84;
    width: 50%;
    height: 90vh;
    background-position: center;
    background-size: cover;
    padding: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .password {
    width: 100%;
    background: #ffffff;
    border: 1px solid #b1b1b1;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
    display: flex;
    align-content: center;
    font-size: 15px;
  }

  .logo_image {
    height: 50px;
    object-fit: contain;
  }
  .welcom_text {
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .otp-input {
    width: 50px !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 50px !important;
    box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
    margin: 0 10px;
    border-radius: 4px;
  }
  @media only screen and (max-width: 768px) {
    .otp-input {
      width: 30px !important;
      height: 30px !important;
      margin: 0 6px;
      border-radius: 4px;
    }
  }
  .welcom_subtext {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
  }
  .pad-lock {
    width: 70%;
    object-fit: contain;
    margin-top: 30px;
  }
  .login_text {
    font-weight: 700;
    font-size: 26px;
    line-height: 41px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #0c2f49;
  }
  .create_passowrd_text {
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #0c2f49;
  }
  .login_subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #0c2f49;
    opacity: 0.75;
    margin-top: 10px;
  }
  .input_label {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #0c2f49;
    opacity: 0.75;
  }
  .text_input {
    height: 50px;
    background: #ffffff;
    border: 1px solid rgba(61, 57, 137, 0.1);
    box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
    border-radius: 10px;
    /* padding: 0 15px; */
    font-size: 16px;
  }
  .forgot_password_text {
    font-weight: 400;
    font-size: 14px;
    width: 80%;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.05em;
    color: #0c2f49;
    opacity: 0.5;
    margin-top: 10px;
    text-decoration: none;
  }
  .login_button {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    margin-top: 30px;
    font-size: 12px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
  .dont_accountText {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    color: rgba(12, 47, 73, 0.5);
  }
  .dont_hereText {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.05em;
    color: "rgba(39, 137, 253, 1)";
  }
  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .bg-circle {
    width: 80%;
    object-fit: contain;
  }
  .computer {
    width: 80%;
    object-fit: contain;
    position: absolute;
  }
`;
