import { schoolTokenAxios } from "../axios";

const REACT_APP_SCHOOL_REPORTING_BASE =
  process.env.REACT_APP_SCHOOL_REPORTING_BASE;
let website_builder = `${REACT_APP_SCHOOL_REPORTING_BASE}/websiteBuilder`;
let fee_url = `${REACT_APP_SCHOOL_REPORTING_BASE}/fee`;

{
  /* ################### News Calendar Services ################### */
}

export const addNewsCalendar = (body) => {
  return schoolTokenAxios.post(`${website_builder}/addNewsCalendar`, body);
};
export const deleteNewsCalendar = (popupDocId) => {
  return schoolTokenAxios.delete(
    `${website_builder}/deleteNewsCalendar/${popupDocId}`
  );
};
export const getNewsCalendar = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getNewsCalendar`, { params });
};

export const updateNewsCalendar = (body) => {
  return schoolTokenAxios.put(`${website_builder}/updateNewsCalendar`, body);
};

{
  /* ################### Youtube Services ################### */
}
export const addYouTubeVideo = (body) => {
  return schoolTokenAxios.post(`${website_builder}/addYouTubeVideo`, body);
};
export const deleteYouTubeVideo = (popupDocId) => {
  return schoolTokenAxios.delete(
    `${website_builder}/deleteYouTubeVideo/${popupDocId}`
  );
};
export const getYouTubeVideo = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getYouTubeVideo`, { params });
};

{
  /* ################### Popup Services ################### */
}
export const addSchoolPopup = (body) => {
  return schoolTokenAxios.post(`${website_builder}/addSchoolPopup`, body);
};
export const getSchoolPopup = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getSchoolPopup`, { params });
};
export const updateSchoolPopup = (body) => {
  return schoolTokenAxios.put(`${website_builder}/updateSchoolPopup`, body);
};
export const deleteSchoolPopup = (popupDocId) => {
  return schoolTokenAxios.delete(
    `${website_builder}/deleteSchoolPopup/${popupDocId}`
  );
};

{
  /* ################### Slider Services ################### */
}
export const addWebsiteSlider = (body) => {
  return schoolTokenAxios.post(`${website_builder}/addWebsiteSlider`, body);
};
export const getWebsiteSlider = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getWebsiteSlider`, {
    params: params,
  });
};
export const updateWebsiteSlider = (body) => {
  return schoolTokenAxios.put(`${website_builder}/updateWebsiteSlider`, body);
};
export const deleteWebsiteSlider = (sliderDocId) => {
  return schoolTokenAxios.delete(
    `${website_builder}/deleteWebsiteSlider/${sliderDocId}`
  );
};

{
  /* ################### About School Services ################### */
}
export const addUpdateAboutSchoolWebsite = (body) => {
  return schoolTokenAxios.put(`${website_builder}/addUpdateAboutSchool`, body);
};
export const getAboutSchoolWebsite = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getAboutSchool`, {
    params: params,
  });
};
export const getAboutSchoolWithImages = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getAboutSchoolWithImages`, {
    params: params,
  });
};

{
  /* ################### Founder Services ################### */
}
export const addFounder = (body) => {
  return schoolTokenAxios.post(`${website_builder}/addFounder`, body);
};
export const getFounderList = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getFounderList`, { params });
};
export const updateFounder = (body) => {
  return schoolTokenAxios.put(`${website_builder}/updateFounder`, body);
};
export const deleteFounder = (founderId) => {
  return schoolTokenAxios.delete(
    `${website_builder}/deleteFounder/${founderId}`
  );
};
{
  /* ################### Achievements Services ################### */
}
export const getAchievementList = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getAchievementList`, {
    params: params,
  });
};
export const addAchievement = (body) => {
  return schoolTokenAxios.post(`${website_builder}/addAchievement`, body);
};
export const updateAchievement = (body) => {
  return schoolTokenAxios.put(`${website_builder}/updateAchievement`, body);
};
export const deleteAchievement = (achievementDocId) => {
  return schoolTokenAxios.delete(
    `${website_builder}/deleteAchievement/${achievementDocId}`
  );
};

{
  /* ################### School Topper's Services ################### */
}
export const addSchoolTopper = (body) => {
  return schoolTokenAxios.post(`${website_builder}/addSchoolTopper`, body);
};
export const getSchoolTopper = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getSchoolTopper`, {
    params: params,
  });
};
export const updateSchoolTopper = (body) => {
  return schoolTokenAxios.put(`${website_builder}/updateSchoolTopper`, body);
};
export const deleteSchoolTopper = (schoolTopperDocId) => {
  return schoolTokenAxios.delete(
    `${website_builder}/deleteSchoolTopper/${schoolTopperDocId}`
  );
};

{
  /* ################### School Team Services ################### */
}

export const getOurTeam = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getOurTeam`, {
    params: params,
  });
};
export const updateStaffProfileAsPublic = (body) => {
  return schoolTokenAxios.put(
    `${website_builder}/updateStaffProfileAsPublic`,
    body
  );
};

{
  /* ################### Class Photo's Services ################### */
}
export const addClassPhotos = (body) => {
  return schoolTokenAxios.post(`${website_builder}/addClassPhotos`, body);
};
export const getClassPhotos = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getClassPhotos`, {
    params: params,
  });
};
export const updateClassPhotos = (body) => {
  return schoolTokenAxios.put(`${website_builder}/updateClassPhotos`, body);
};
export const deleteClassPhotos = (classPhotosDocId) => {
  return schoolTokenAxios.delete(
    `${website_builder}/deleteClassPhotos/${classPhotosDocId}`
  );
};

{
  /* ################### School Gallery Services ################### */
}
export const AddFolder = (body) => {
  return schoolTokenAxios.post(`${website_builder}/AddFolder`, body);
};

export const AddImage = (body) => {
  return schoolTokenAxios.post(`${website_builder}/AddImage`, body);
};

export const getFoldersDetail = (params) => {
  return schoolTokenAxios.get(`${website_builder}/getFoldersDetail`, {
    params: params,
  });
};

export const updateFolderName = (body) => {
  return schoolTokenAxios.put(`${website_builder}/updateFolderName`, body);
};

export const editImageDetail = (body) => {
  return schoolTokenAxios.put(`${website_builder}/editImageDetail`, body);
};

export const approveImageStatus = (body) => {
  return schoolTokenAxios.put(`${website_builder}/approveImageStatus`, body);
};

export const updateImageAsPublic = (imageDocId) => {
  return schoolTokenAxios.put(
    `${website_builder}/updateImageAsPublic/${imageDocId}`
  );
};

export const deleteFolder = (folderDocId) => {
  return schoolTokenAxios.delete(
    `${website_builder}/deleteFolder/${folderDocId}`
  );
};

export const deleteImageDetail = (imageDocId) => {
  return schoolTokenAxios.put(
    `${website_builder}/deleteImageDetail/${imageDocId}`
  );
};

//fees
export const getClasswiseCollectionsByFeeType = (params) => {
  return schoolTokenAxios.get(`${fee_url}/getClasswiseCollectionsByFeeType`, {
    params: params,
  });
};
