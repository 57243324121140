import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

import DialogHeader from "app/components/common/DialogHeader";

import TextFieldLabel from "app/components/common/TextFieldLabel";
import { addFeeRelaxationCategory } from "app/services/management";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import React from "react";

import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";

const AddFeeRelaxationDialog = ({ refetch, onDialogClose, showDialog }) => {
  const {
    values,

    errors,
    touched,
    handleChange,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      addNewDialog: false,
      otherCategory: "",
    },
    validationSchema: yup.object({
      otherCategory: yup.string().required("Category name required"),
    }),
    onSubmit: (res) => {
      addFeeRelaxationCategoryMutate({
        feeRelaxationCategory: res.otherCategory,
      });
    },
  });

  const handleColoseCreateDialog = () => {
    resetForm();
    onDialogClose();
  };
  const {
    isLoading: addFeeRelaxationCategoryLoading,
    mutate: addFeeRelaxationCategoryMutate,
  } = useMutation(addFeeRelaxationCategory, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      refetch();
      handleColoseCreateDialog();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  return (
    <Dialog
      onClose={handleColoseCreateDialog}
      open={showDialog}
      PaperProps={{
        sx: { minWidth: { xs: "98%", md: "450px" } },
      }}
    >
      <DialogTitle sx={{ p: "0 !important" }}>
        <DialogHeader
          handleClose={handleColoseCreateDialog}
          title="Fee Relaxation Category Form"
        />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mt: 1 }}>
            <Stack direction={"row"} gap={2}>
              <Box sx={{ flex: 1 }}>
                <TextFieldLabel title={"Category Name"} />
                <CustomTextField
                  fullWidth
                  size="small"
                  placeholder="Type category"
                  value={values.otherCategory}
                  name="otherCategory"
                  onChange={handleChange}
                  error={touched.otherCategory && Boolean(errors.otherCategory)}
                />
                {touched.otherCategory && Boolean(errors.otherCategory) && (
                  <Typography color="error">{errors.otherCategory}</Typography>
                )}
              </Box>
            </Stack>
            <Stack direction={"row"} justifyContent={"center"}>
              <LoadingButton
                loading={addFeeRelaxationCategoryLoading}
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                color="secondary"
                sx={{ mt: 2, width: "90%" }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddFeeRelaxationDialog;
