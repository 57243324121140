import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import CustomLoader from "app/components/common/CustomLoader";
import DialogHeader from "app/components/common/DialogHeader";
import PdfSummaryPreviewDialog from "app/components/common/Dialogs/PdfSummaryPreviewDialog";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import TableProfilePic from "app/components/common/TableProfilePic";
import appRoutes from "app/config/routes";
import {
  selectActiveSession,
  selectSelectedSchool,
  userNameSelector,
  userPrimaryRoleSelector,
} from "app/modules/schools/selectors";
import {
  deleteUtilityInvoice,
  getUtilityInvoice,
} from "app/services/ledgerAccount";
import {
  FormatFullName,
  IndianCurrency,
  getPaymentDetailsString,
} from "app/utils/helper";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as XLSX from "xlsx";
import FirmInvoiceFilter from "./components/FirmInvoiceFilter";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import reportHeader from "app/components/common/pdfReport/reportHeader";
import moment from "moment";
import Footer from "app/components/common/pdfReport/ReportFooter";

const FirmInvoiceTable = () => {
  const [state, setState] = useState({
    from: new Date(),
    to: new Date(),
    dialog: false,
    deleteData: null,
    firmInvoiceList: [],
    checkExportOptions: false,
    checkExportOptionsPdf: false,
    pdfSummaryDialog: false,
    totalDiscount: 0,
    totalNetpaid: 0,
    t_CardMode: 0,
    t_CashMode: 0,
    t_ChequeMode: 0,
    t_NEFTMode: 0,
    t_UpiMode: 0,
    t_other: 0,
    aaproveRejectDialog: false,
    aprroveRejectData: {},
    filterPayload: false,
    deleteInvoiceDialog: false,
    deleteId: "",
    filterExtraData: false,
  });

  let header = [
    "Session",
    "Invoice Number",
    "Class Stream Sec",
    "Collection Head Name",
    "Collection Sub Head Name",
    "Registration Number",
    "Admission Number",
    "Student Name",
    "Gender",
    "Father's Name",
    "Previous Dues",
    "Paid Amount",
    "Total Discount",
    "Discount Remark",
    "Balance Amount",
    "Balance Remarks",
    "Collection Date",
    "Created On",
    "Created By",
    "Payment Mode",
    "UPI Mode Amount",
    "Cash Mode Amount",
    "Payment Detail",
    "Status",
  ];

  let headerPdf = [
    "Session",
    "Invoice Number",
    "Class",
    // "Collection Head Name",
    // "Collection Sub Head Name",
    // "Registration Number",
    "Adm No.",
    "Student Name",
    "Gender",
    "Father's Name",
    // "Previous Dues",
    // "Total Paid Amount",
    "Total Amount",
    "Total Discount",
    "Net Paid",
    // "Balance Amount",
    // "Balance Remarks",
    // "Collection Code",
    "Collection Date",
    "Created On",
    "Created By",
    // "Depositor Name",
    "Payment Mode",
    "Payment Detail",
    "Status",
  ];
  const [selectedOptions, setSelectedOptions] = useState(header);
  const [selectedOptionsForPdf, setSelectedOptionsForPdf] = useState(headerPdf);

  let exportArray = state.firmInvoiceList?.map((item) => {
    return {
      Session: item?.session,
      "Invoice Number": item?.invoiceNumber,
      "Class Stream Sec": `${item?.studentDetail?.className ?? "-"} - ${
        item?.studentDetail?.stream ?? "-"
      } - ${item?.studentDetail?.section ?? "-"}`,
      "Collection Head Name": item?.collectionHeadName,
      "Collection Sub Head Name": item?.collectionSubHeadName,
      "Registration Number": item?.studentDetail?.registrationNumber,
      "Admission Number": item?.studentDetail?.admissionNumber,
      "Student Name": FormatFullName(item?.studentDetail?.studentName),
      Gender: item?.studentDetail?.gender,
      "Father's Name": FormatFullName(item?.studentDetail?.fatherName),
      "Previous Dues": item?.previousDuesAmount,
      "Paid Amount": item?.paidAmount,
      "Total Discount": Number(item?.totalDiscountAmount),
      "Discount Remark": item?.discountRemark,
      "Balance Amount": item?.balanceAmount,
      "Balance Remarks": item?.balanceRemarks,
      "Collection Date": dayjs(item?.collectionDate).format("DD-MM-YYYY"),

      "Created On": `${dayjs(item?.createdOn).format("DD-MM-YYYY")}`,
      "Created By": item?.createdBy?.personName,
      "Depositor Name": item?.depositorName,
      "Payment Mode": Object.keys(item?.paymentDetails)[0],
      "UPI Mode Amount": item?.paymentDetails?.UpiMode?.amount ?? "",
      "Cash Mode Amount": item?.paymentDetails?.CashMode?.amount ?? "",
      "Payment Detail": getPaymentDetailsString(item?.paymentDetails),
      Status: item?.status,
    };
  });

  let exportArrayForPdf = state.firmInvoiceList?.map((item) => {
    return {
      Session: item?.session,
      "Invoice Number": item?.invoiceNumber,
      Class: `${item?.studentDetail?.className ?? "-"} - ${
        item?.studentDetail?.stream ?? "-"
      } - ${item?.studentDetail?.section ?? "-"}`,
      // "Collection Head Name": item?.collectionHeadName,
      // "Collection Sub Head Name": item?.collectionSubHeadName,
      // "Registration Number": item?.studentDetail?.registrationNumber,
      "Adm No.": item?.studentDetail?.admissionNumber,
      "Student Name": FormatFullName(item?.studentDetail?.studentName),
      Gender: item?.studentDetail?.gender,
      "Father's Name": FormatFullName(item?.studentDetail?.fatherName),
      // "Previous Dues": item?.previousDuesAmount,
      // "Paid Amount": item?.paidAmount,
      "Total Amount": item?.totalAmount,
      "Total Discount": Number(item?.totalDiscountAmount),
      "Net Paid":
        (Number(item?.paidAmount) || 0) + (Number(item?.totalFineAmount) || 0),
      // "Balance Amount": item?.balanceAmount,
      // "Balance Remarks": item?.balanceRemarks,
      "Collection Date": dayjs(item?.collectionDate).format("DD-MM-YYYY"),
      "Created On": `${dayjs(item?.createdOn).format("DD-MM-YYYY")}`,
      "Created By": item?.createdBy?.personName,
      // "Depositor Name": item?.depositorName,
      "Payment Mode": Object.keys(item?.paymentDetails)[0],

      "Payment Detail": getPaymentDetailsString(item?.paymentDetails),
      Status: item?.status,
    };
  });
  const selectedSchool = useSelector(selectSelectedSchool);
  const session = useSelector(selectActiveSession);

  const userName = useSelector(userNameSelector);

  const handleExportPdf = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const rows = [];
    rows.push(selectedOptionsForPdf);
    exportArrayForPdf.forEach((item) => {
      const row = selectedOptionsForPdf.map((option) => item[option]);
      rows.push(row);
    });
    const tableColumn = selectedOptionsForPdf.map((col) => ({
      title: col,
      dataKey: col,
    }));
    const tableStyles = {
      headStyles: {
        fillColor: [182, 200, 214],
        textColor: 0,
        fontSize: 9,
        cellPadding: { top: 1.5, right: 1.5, bottom: 1.5, left: 1.5 },
        cellWidth: "auto", // Enable text wrapping
      },
      bodyStyles: {
        fontSize: 8,
        cellPadding: { top: 1.5, right: 1.5, bottom: 1.5, left: 1.5 },
        cellWidth: "auto",
      },
      columnStyles: {
        // 1: { cellWidth: 20, overflow: "linebreak" },
        // 2: { cellWidth: 20, overflow: "linebreak" },
        // 3: { cellWidth: 15, overflow: "linebreak" },
        // 4: { cellWidth: 20, overflow: "linebreak" },
        // 5: { cellWidth: 10, overflow: "linebreak" },
        // 6: { cellWidth: 20, overflow: "linebreak" },
        // 7: { cellWidth: 15, overflow: "linebreak" },
        // 8: { cellWidth: 15, overflow: "linebreak" },
        // 9: { cellWidth: 15, overflow: "linebreak" },
        // 10: { cellWidth: 20, overflow: "linebreak" },
        // 11: { cellWidth: 20, overflow: "linebreak" },
        // 12: { cellWidth: 20, overflow: "linebreak" },
        // 13: { cellWidth: 20, overflow: "linebreak" },
        14: { cellWidth: 25, overflow: "linebreak" },
      },
      theme: "grid",
    };

    const totalPagesExp = "{total_pages_count_string}";

    let appliedFiltery_axis = 34;

    // report header
    reportHeader({
      doc: doc,
      selectedSchool: selectedSchool,
    });

    doc.text(`Applied Filter:`, 15, appliedFiltery_axis);
    doc.text(
      "_______________________________________",
      15,
      appliedFiltery_axis + 2
    );
    let filterTexts = [];
    Object.keys(state.filterPayload).forEach((key) => {
      // Skip if the key is 'classDocId', 'section', or 'studentDocId'
      let value = state.filterPayload[key];
      if (key === "classDocId") {
        key = "Class";
        value = state.filterExtraData?.className;
      }
      if (key === "studentDocId") {
        key = "Student Name";
        value = state.filterExtraData?.studentName;
      } else {
        let date = new Date(value);
        if (!isNaN(date.getTime())) {
          value = moment(date).format("DD-MM-YYYY");
        }
      }
      filterTexts.push(`${key}: ${value}`);
    });
    let xaxis = 15;
    let yaxis = appliedFiltery_axis + 6;
    let difference = 0;
    let lineHeight = 4; // Line height
    let maxLineWidth = 180;

    filterTexts.forEach((filterText, index) => {
      let textWidth = doc.getStringUnitWidth(filterText) * doc.getFontSize();
      if (xaxis + textWidth > maxLineWidth) {
        xaxis = 15;
        yaxis += lineHeight;
        difference += lineHeight;
      }
      doc.text(filterText, xaxis, yaxis);
      xaxis += textWidth;
      if (index < filterTexts.length - 1) {
        doc.text(",", xaxis, yaxis);
        xaxis += doc.getStringUnitWidth(",") * doc.getFontSize();
        doc.text(" ", xaxis, yaxis);
        xaxis += doc.getStringUnitWidth(" ") * doc.getFontSize();
      }
    });

    let textheight = appliedFiltery_axis + 12 + difference;
    doc.text(
      "______________________________________________________________________________________",
      15,
      textheight - 4
    );

    doc.text("Fee Dues Summary", 15, textheight + 2);
    doc.text("_______________________________________", 15, textheight + 4);
    doc.text(`Total Cash Mode: ${state.t_CashMode}`, 15, textheight + 12);
    doc.text(`Total Netpaid: ${state.totalNetpaid}`, 100, textheight + 12);
    doc.text(`Total Discount: ${state.totalDiscount}`, 15, textheight + 18);
    doc.text(`Total Upi Mode: ${state.t_UpiMode}`, 100, textheight + 18);
    doc.text(`Total Cheque Mode: ${state.t_ChequeMode}`, 200, textheight + 18);
    doc.text(`Total NEFT Mode: ${state.t_NEFTMode}`, 15, textheight + 24);
    doc.text(`Total Card Mode: ${state.t_CardMode}`, 100, textheight + 24);
    doc.text(`Total Other Mode: ${state.t_other}`, 200, textheight + 24);

    // Define the columns for which you want to calculate totals
    const totalColumns = ["Total Amount", "Total Discount", "Net Paid"];

    // Get the indices of the total columns in the selectedOptionsForPdf array
    const totalIndices = totalColumns.map((column) =>
      selectedOptionsForPdf.indexOf(column)
    );

    // Calculate totals
    const totals = rows.reduce((acc, row) => {
      totalIndices.forEach((index, i) => {
        const value = parseFloat(row[index]);
        acc[i] += isNaN(value) ? 0 : value;
      });
      return acc;
    }, new Array(totalIndices.length).fill(0));

    // Create the summary row
    const summaryRow = new Array(selectedOptionsForPdf.length).fill("");
    totalIndices.forEach((index, i) => {
      summaryRow[index] = totals[i];
    });

    // Append the summary row to the existing rows
    rows.push(summaryRow);

    // Draw the table with the updated rows
    autoTable(doc, {
      head: [tableColumn],
      body: rows,
      startY: textheight + 30,
      didDrawPage: (data) => {
        Footer({
          doc: doc,
          data: data,
          userName: userName,
          totalPagesExp: totalPagesExp,
        });
      },
      ...tableStyles,
    });

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save("FirmInvoice.pdf");
  };

  const handlegetFeeInvoiceMutate = (payload) => {
    getUtilityInvoiceMutate(payload);
    setState((prev) => ({ ...prev, filterPayload: payload }));
    // refetch(payload);
  };
  const primaryRole = useSelector(userPrimaryRoleSelector);

  const {
    isLoading: getUtilityInvoiceMutateLoading,
    mutate: getUtilityInvoiceMutate,
  } = useMutation("getUtilityInvoiceMutate", getUtilityInvoice, {
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        firmInvoiceList: success?.data?.list,
        totalDiscount: success?.data?.totalDiscount,
        totalNetpaid: success?.data?.totalNetpaid,
        t_CashMode: success?.data?.t_CashMode,
        t_NEFTMode: success?.data?.t_NEFTMode,
        t_ChequeMode: success?.data?.t_ChequeMode,
        t_CardMode: success?.data?.t_CardMode,
        t_UpiMode: success?.data?.t_UpiMode,
        t_other: success?.data?.t_other,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const {
    isLoading: getUtilityInvoiceLoading,
    refetch,
    isFetching: getUtilityInvoiceFetching,
  } = useQuery({
    queryKey: ["getFeeInvoice"],
    queryFn: () => {
      if (state.filterPayload) {
        return getUtilityInvoice(state.filterPayload);
      } else {
        return getUtilityInvoice();
      }
    },
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        firmInvoiceList: success?.data?.list,
        totalDiscount: success?.data?.totalDiscount,
        totalNetpaid: success?.data?.totalNetpaid,
        t_CashMode: success?.data?.t_CashMode,
        t_NEFTMode: success?.data?.t_NEFTMode,
        t_ChequeMode: success?.data?.t_ChequeMode,
        t_CardMode: success?.data?.t_CardMode,
        t_UpiMode: success?.data?.t_UpiMode,
        t_other: success?.data?.t_other,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: false,
  });

  const {
    mutate: deleteUtilityInvoiceMutate,
    isLoading: deleteUtilityInvoiceLoading,
  } = useMutation(deleteUtilityInvoice, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      handleCloseDeleteInvoiceDialog();
      refetch();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleDelete = (cell) => {
    const {
      row: { original },
    } = cell;
    setState((prev) => ({ ...prev, deleteData: original }));
  };
  const columns = [
    {
      header: "Sr.No",
      Cell: ({ cell }) => (
        <Typography>
          {parseInt(cell?.row?.id) + 1 < 10
            ? `0${parseInt(cell?.row?.id) + 1}`
            : parseInt(cell?.row?.id) + 1}
        </Typography>
      ),
      size: 20,
    },

    {
      header: "Invoice Number",
      accessorKey: "invoiceNumber",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Link
          to={`/ledger-n-account/books/pay-slip/${String(
            original?.invoiceNumber
          ).replace(/\//g, " ")}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "bold" }}
        >
          {original?.invoiceNumber}
        </Link>
      ),
      size: 40,
    },

    {
      header: "Class",
      accessorKey: "className",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Typography>
          {original?.studentDetail?.className}-{original?.studentDetail?.stream}
          -{original?.studentDetail?.section}
        </Typography>
      ),
      size: 60,
    },
    {
      header: "Student Name",
      accessorKey: "studentDetail",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Stack direction="row" alignItems="center" gap={0.6}>
            <Link
              to={`/${appRoutes.student_profile}/${original?.studentDetail?.studentDocId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TableProfilePic image={original?.studentDetail?.profilePic} />
            </Link>
            <Stack>
              <Link
                to={`/${appRoutes.student_profile}/${original?.studentDetail?.studentDocId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography>{original?.studentDetail?.studentName}</Typography>
              </Link>
              <Typography sx={{ color: "green" }}>
                {original?.studentDetail?.fatherName}
              </Typography>
            </Stack>
          </Stack>
        );
      },
      size: 60,
    },

    {
      header: "Total Amount",
      accessorKey: "totalAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>{IndianCurrency(original?.totalAmount)}</Typography>,
      size: 20,
    },
    {
      header: "Discount",
      accessorKey: "totalDiscountAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Typography>{IndianCurrency(original?.totalDiscountAmount)}</Typography>
      ),
      size: 20,
    },
    {
      header: "Net Paid",
      accessorKey: "totalPaidFeeAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Typography>{IndianCurrency(Number(original?.paidAmount))}</Typography>
      ),
      size: 20,
    },
    // {
    //   header: "Balance",
    //   accessorKey: "totalBalanceFeeAmount",
    //   Cell: ({
    //     cell: {
    //       row: { original },
    //     },
    //   }) => (
    //     <Typography>
    //       {IndianCurrency(original?.totalBalanceFeeAmount)}
    //     </Typography>
    //   ),
    //   size: 20,
    // },

    {
      header: "Payment Mode",
      accessorKey: "paymentDetails",

      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <Typography>
          {original?.paymentDetails
            ? Object.keys(original?.paymentDetails).map((item) => item)
            : ""}
        </Typography>
      ),
      size: 20,
    },
    {
      header: "Created By",
      accessorKey: "createdBy.personName",

      Cell: ({
        cell: {
          row: { original },
        },
      }) => <Typography>{original?.createdBy?.personName}</Typography>,
      size: 40,
    },
    {
      header: "Status",
      accessorKey: "status",

      Cell: ({
        cell: {
          row: { original },
        },
      }) => (
        <>
          {original?.status === "ACTIVE" && (
            <Typography sx={{ color: colors.green[600] }}>
              {original?.status}
            </Typography>
          )}
          {original?.status === "DELETED" && (
            <Typography sx={{ color: colors.red[600] }}>
              {original?.status}
            </Typography>
          )}
          {/* {original?.status === "REQUESTED" && (
            <Typography
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  aaproveRejectDialog: true,
                  aprroveRejectData: original,
                }));
              }}
              sx={{
                color: colors.orange[600],
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {original?.status}
            </Typography>
          )} */}
        </>
      ),

      size: 20,
    },
    {
      header: "Actions",
      Cell: ({ cell }) => {
        return cell?.row?.original?.status !== "DELETED" ? (
          <Stack direction="row">
            <Delete
              color="error"
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  deleteId: cell?.row?.original?._id,
                  deleteInvoiceDialog: true,
                }))
              }
            />
          </Stack>
        ) : (
          "--"
        );
      },
      size: 20,
    },
  ];

  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };
  const handleCheckboxChangeForPdf = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptionsForPdf((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptionsForPdf((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };

  const handleExport = () => {
    const filteredExportArray = exportArray?.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    let columnTotals = {};
    filteredExportArray.forEach((item) => {
      const keys = [
        "Previous Dues",
        "Paid Amount",
        "Total Discount",
        "Balance Amount",
        "UPI Mode Amount",
        "Cash Mode Amount",
      ];

      Object.keys(item).forEach((key) => {
        if (!columnTotals[key]) {
          columnTotals[key] = 0;
        }
        if (keys.includes(key) && !isNaN(item[key])) {
          columnTotals[key] += Number(item[key]);
        } else {
          columnTotals[key] = "";
        }
      });
    });
    const worksheetData = [
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],

      Object.keys(filteredExportArray[0] || {}).map((key) => key),
      ...filteredExportArray.map((item) => Object.values(item)),
      Object.values(columnTotals),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Fee Report");

    const addDataToSheet = (worksheet, data, origin) => {
      XLSX.utils.sheet_add_aoa(worksheet, [[data]], { origin });
    };

    const dataPoints = [
      { data: `School Name: ${selectedSchool?.schoolName}`, origin: "E1" },
      { data: `Session: ${session}`, origin: "D2" },
      {
        data: `Downloaded On: ${dayjs(new Date()).format("DD-MM-YYYY")}`,
        origin: "H2",
      },
      //   {
      //     data: `Fee Invoice From: ${dayjs(
      //       new Date(state?.filterPayload?.from)
      //     ).format("DD-MM-YYYY")}`,
      //     origin: "D3",
      //   },
      //   {
      //     data: `Fee Invoice To: ${dayjs(
      //       new Date(state?.filterPayload?.to)
      //     ).format("DD-MM-YYYY")}`,
      //     origin: "H3",
      //   },
      { data: `Firm Collection Summary`, origin: "E5" },

      { data: `CASH Mode`, origin: "D6" },
      { data: `NEFT`, origin: "E6" },
      { data: `UPI`, origin: "F6" },
      { data: `Cheque`, origin: "G6" },
      { data: `Card`, origin: "H6" },

      { data: `Total Paid`, origin: "D9" },
      { data: `Total Discount`, origin: "E9" },

      { data: `${IndianCurrency(state.t_CashMode)}`, origin: "D7" },
      { data: `${IndianCurrency(state.t_NEFTMode)}`, origin: "E7" },
      { data: `${IndianCurrency(state.t_UpiMode)}`, origin: "F7" },
      { data: `${IndianCurrency(state.t_ChequeMode)}`, origin: "G7" },
      { data: `${IndianCurrency(state.t_CardMode)}`, origin: "H7" },

      { data: `${IndianCurrency(state.totalNetpaid)}`, origin: "D10" },
      { data: `${IndianCurrency(state.totalDiscount)}`, origin: "E10" },
    ];
    dataPoints.forEach(({ data, origin }) =>
      addDataToSheet(worksheet, data, origin)
    );

    XLSX.writeFile(workbook, "firmInvoice.xlsx");
  };

  const handleCloseDeleteInvoiceDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteInvoiceDialog: false,
    }));
  };

  const handledeleteInvoiceMutate = () => {
    deleteUtilityInvoiceMutate(state?.deleteId);
  };

  const handleExtraData = (payload) => {
    setState((prev) => ({
      ...prev,
      filterExtraData: payload,
    }));
  };

  return (
    <>
      <div>
        {/* loader */}
        <CustomLoader
          show={
            getUtilityInvoiceLoading ||
            getUtilityInvoiceFetching ||
            getUtilityInvoiceMutateLoading
          }
        />

        {/* Delete Dialog Confirmation of Collection*/}
        <DeleteDialog
          open={state.deleteInvoiceDialog}
          handleClose={handleCloseDeleteInvoiceDialog}
          handleDelete={handledeleteInvoiceMutate}
          loading={deleteUtilityInvoiceLoading}
        />

        {/* Pdf Summary Dialog 
        <PdfSummaryPreviewDialog
          open={state.pdfSummaryDialog}
          handleClose={() =>
            setState((prev) => ({ ...prev, pdfSummaryDialog: false }))
          }
          data={state.firmInvoiceList}
        /> */}

        {/* Dialog for selecting options for export*/}
        <Dialog
          open={state.checkExportOptions}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              checkExportOptions: false,
            }))
          }
          PaperProps={{
            sx: { minWidth: "400px" },
          }}
        >
          <DialogHeader
            title="Select Information to show in the excel file"
            handleClose={() =>
              setState((prev) => ({
                ...prev,
                checkExportOptions: false,
              }))
            }
          />
          <DialogContent>
            <Box>
              {header.map((option) => (
                <Stack direction="row">
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={selectedOptions.includes(option)}
                        onChange={(e) =>
                          handleCheckboxChange(option, e.target.checked)
                        }
                      />
                    }
                    label={option}
                  />
                </Stack>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ mb: 1 }}
              onClick={() => {
                setSelectedOptions(header);
                handleExport();
                setState((prev) => ({
                  ...prev,
                  checkExportOptions: false,
                }));
              }}
            >
              DownLoad
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for selecting options for exporting pdf*/}
        <Dialog
          open={state.checkExportOptionsPdf}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              checkExportOptionsPdf: false,
            }))
          }
          PaperProps={{
            sx: { minWidth: "400px" },
          }}
        >
          <DialogHeader
            title="Select Information to show in the pdf file"
            handleClose={() =>
              setState((prev) => ({
                ...prev,
                checkExportOptionsPdf: false,
              }))
            }
          />
          <DialogContent>
            <Box>
              {headerPdf.map((option) => (
                <Stack direction="row">
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={selectedOptionsForPdf.includes(option)}
                        onChange={(e) =>
                          handleCheckboxChangeForPdf(option, e.target.checked)
                        }
                      />
                    }
                    label={option}
                  />
                </Stack>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ mb: 1 }}
              onClick={() => {
                setSelectedOptionsForPdf(headerPdf);
                handleExportPdf();
                setState((prev) => ({
                  ...prev,
                  checkExportOptionsPdf: false,
                }));
              }}
            >
              DownLoad
            </Button>
          </DialogActions>
        </Dialog>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
        >
          <FirmInvoiceFilter
            mutate={handlegetFeeInvoiceMutate}
            loading={getUtilityInvoiceLoading}
            extraData={handleExtraData}
          />
          <Stack direction="row" gap={1} alignItems="center">
            <ExportButtonGroup
              showPdfSummary={false}
              showPdf={true}
              handleClickExcel={() =>
                setState((prev) => ({
                  ...prev,
                  checkExportOptions: true,
                }))
              }
              handleClickPdf={() =>
                setState((prev) => ({
                  ...prev,
                  checkExportOptionsPdf: true,
                }))
              }
              handleClickPdfSummary={() => {
                setState((prev) => ({ ...prev, pdfSummaryDialog: true }));
              }}
            />
          </Stack>
        </Stack>
        <FeeBox>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                CASH Mode
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(state.t_CashMode)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                NEFT
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(state.t_NEFTMode)}
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                UPI
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(state.t_UpiMode)}
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Cheque
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(state.t_ChequeMode)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Card
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(state.t_CardMode)}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ backgroundColor: "#0C2F49" }} />
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Total Paid
              </Typography>
              <Typography
                style={{ fontWeight: 600, fontSize: "15px", color: "#29DB5B" }}
              >
                {IndianCurrency(state.totalNetpaid)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Total Discount
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                  color: colors.blue[600],
                }}
              >
                {IndianCurrency(state.totalDiscount)}
              </Typography>
            </Grid>
          </Grid>
        </FeeBox>
        <Box sx={{ mt: 2 }}>
          <CommonTable1
            columns={columns}
            data={state.firmInvoiceList}
            maxHeight="60vh"
          />
        </Box>
      </div>
    </>
  );
};

export default FirmInvoiceTable;

const FeeBox = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(19, 115, 229, 0.2);
  background: rgba(39, 137, 253, 0.02);
  padding: 10px;
  //   display: inline-block;
  margin-top: 10px;
`;
