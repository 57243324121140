import { schoolTokenAxios } from "../axios";

const ORG_BASE = process.env.REACT_APP_ORG_BASE;

let school_url = `${ORG_BASE}/schoolSelf`;
let payment_url = `${ORG_BASE}/payment`;

export const getAllSessionOrderBySchool = (params) => {
  return schoolTokenAxios.get(`${school_url}/getAllSessionOrderBySchool`, {
    params,
  });
};
export const getTransactionBySessionOrder = (params) => {
  return schoolTokenAxios.get(`${school_url}/getTransactionBySessionOrder`, {
    params,
  });
};
export const getInvoice = (params) => {
  return schoolTokenAxios.get(`${school_url}/getInvoice`, {
    params,
  });
};
export const purchaseSessionOrder = (payload) => {
  return schoolTokenAxios.post(`${payment_url}/purchaseSessionOrder`, payload);
};

export const saveSuccessPaymentDetail = (payload) => {
  return schoolTokenAxios.post(
    `${payment_url}/saveSuccessPaymentDetail`,
    payload
  );
};

export const saveFailedPaymentDetail = (payload) => {
  return schoolTokenAxios.post(
    `${payment_url}/saveFailedPaymentDetail`,
    payload
  );
};
