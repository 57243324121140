import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import CommonDialog from "../CommonDialog";
import { CustomTextField2 } from "assets/styles/globalStyledComponent";
import ErrorText from "app/modules/student/pages/CreateStudent/components/ErrorText";
import { CloudUpload } from "@mui/icons-material";
import uploadFile from "app/utils/uploadFile";
import { selectSelectedSchoolId } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";

const AddNewsCalendarDialog = ({
  open = false,
  onClose = () => {},
  refetch,
  edit = false,
  editData = {},
  addNewsCalendarMutate,
  updateNewsCalendarMutate,
}) => {
  const [loading, setLoading] = useState(false);
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const newsCalendarFormik = useFormik({
    initialValues: {
      title: editData?.title || "",
      date: editData?.date || "",
      description: editData?.description || "",
      url: editData?.url || "",
      isLink: editData?.isLink || false,
      show: editData?.show || true,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
      date: yup.string().required("Date is required"),
      description: yup.string().required("Description is required"),
      url: yup.string().required(null, ""),
      isLink: yup.boolean().required(null, ""),
      show: yup.boolean().required(null, ""),
    }),
    onSubmit: (values) => {
      const obj = {
        // schoolDocId: "6410053616d5253b29ce5ed9",
        schoolDocId: schoolDocId,
        title: values.title,
        date: values.date,
        description: values.description,
        url: values.url,
        isLink: values.isLink,
        show: values.show,
      };
      if (edit) {
        updateNewsCalendarMutate({ ...obj, newsCalendarDocId: editData?._id });
        newsCalendarFormik.resetForm();
      } else {
        addNewsCalendarMutate(obj);
        newsCalendarFormik.resetForm();
      }
    },
  });

  const handleClose = async () => {
    newsCalendarFormik.resetForm();
    onClose();
  };
  const handleAddImage = async (e) => {
    setLoading(true);
    const image = URL.createObjectURL(e.target.files[0]);
    const imageFile = e.target.files[0];

    if (imageFile) {
      const s3FilePath = `news-calendar/${Date.now()}/${imageFile.name}`;
      const url = await uploadFile(imageFile, s3FilePath);
      newsCalendarFormik.setFieldValue("url", url?.fileURL);
    }
    setLoading(false);
  };

  return (
    <CommonDialog
      title={edit ? "Edit News Calendar" : "Add News Calendar"}
      open={open}
      onClose={handleClose}
      minWidth="650px"
      fullWidth
    >
      <DialogContent>
        {/* upload image */}
        {!newsCalendarFormik?.values?.isLink && (
          <>
            <Box>
              <img
                src={
                  newsCalendarFormik?.values?.url
                    ? newsCalendarFormik?.values?.url
                    : "/image-placeholder.jpeg"
                }
                style={{
                  height: "250px",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                }}
              />
              {newsCalendarFormik.errors?.url && (
                <ErrorText text={newsCalendarFormik.errors?.url} />
              )}
            </Box>
            <Stack direction={"row"} justifyContent={"center"}>
              <Button
                fullWidth
                role={undefined}
                component="label"
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<CloudUpload />}
                sx={{ my: 1 }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleAddImage}
                  hidden
                />
                {loading
                  ? "Uploading..."
                  : newsCalendarFormik.values.url
                  ? "Change"
                  : "Browse"}
              </Button>
            </Stack>
          </>
        )}
        {/* title date islink show */}
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={4}>
            <CustomTextField2
              label="Title"
              name="title"
              fullWidth
              value={newsCalendarFormik.values.title}
              onChange={newsCalendarFormik.handleChange}
              error={
                newsCalendarFormik.touched.title &&
                Boolean(newsCalendarFormik.errors.title)
              }
              helperText={
                newsCalendarFormik.touched.title &&
                newsCalendarFormik.errors.title
              }
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField2
              label="Date"
              name="date"
              type="date"
              fullWidth
              value={newsCalendarFormik.values.date}
              onChange={newsCalendarFormik.handleChange}
              error={
                newsCalendarFormik.touched.date &&
                Boolean(newsCalendarFormik.errors.date)
              }
              helperText={
                newsCalendarFormik.touched.date &&
                newsCalendarFormik.errors.date
              }
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              required
              control={
                <Switch
                  checked={newsCalendarFormik.values.isLink}
                  onChange={newsCalendarFormik.handleChange}
                  name="isLink"
                  color="secondary"
                />
              }
              label="isLink"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              required
              control={
                <Switch
                  checked={newsCalendarFormik.values.show}
                  onChange={newsCalendarFormik.handleChange}
                  name="show"
                  color="secondary"
                />
              }
              label="show"
            />
          </Grid>
        </Grid>
        {/* description , url */}
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={newsCalendarFormik?.values?.isLink ? 6 : 12}>
            <CustomTextField2
              name="description"
              label="Description"
              fullWidth
              multiline
              value={newsCalendarFormik.values.description}
              onChange={newsCalendarFormik.handleChange}
              error={
                newsCalendarFormik.touched.description &&
                Boolean(newsCalendarFormik.errors.description)
              }
              helperText={
                newsCalendarFormik.touched.description &&
                newsCalendarFormik.errors.description
              }
            />
          </Grid>
          {newsCalendarFormik.values.isLink && (
            <Grid item xs={6}>
              <CustomTextField2
                label="URL"
                name="url"
                value={newsCalendarFormik.values.url}
                onChange={newsCalendarFormik.handleChange}
                error={
                  newsCalendarFormik.touched.url &&
                  Boolean(newsCalendarFormik.errors.url)
                }
                helperText={
                  newsCalendarFormik.touched.url &&
                  newsCalendarFormik.errors.url
                }
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error" variant="outlined">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={newsCalendarFormik.handleSubmit}
        >
          {edit ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </CommonDialog>
  );
};

export default AddNewsCalendarDialog;
