import Fallback from "app/components/Fallback";
import React from "react";
import SchoolProfile from "./SchoolProfile";

const Schools = React.lazy(() => import("app/modules/schools/pages/Schools"));
const AboutSchool = React.lazy(() =>
  import("app/modules/schools/pages/AboutSchool")
);

export const SchoolsPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Schools {...props} />
  </React.Suspense>
);

export const AboutSchoolPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <AboutSchool {...props} />
  </React.Suspense>
);
export const SchoolProfilePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SchoolProfile {...props} />
  </React.Suspense>
);
