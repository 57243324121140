import {
  Box,
  Button,
  colors,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { IndianCurrency } from "app/utils/helper";
import LabelTextComponent from "./LabelTextComponent";
import TextFieldLabel from "./TextFieldLabel";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";

const PaymentModeComponent2 = ({
  data = {},
  onClickSubmit = () => {},
  loading = false,
}) => {
  const dt = new Date();
  const paymentMode = [
    {
      title: "Cash Mode",
      name: "cashMode",
    },
    {
      title: "Cheque Mode",
      name: "chequeMode",
    },
    {
      title: "NEFT Mode",
      name: "neftMode",
    },
    {
      title: "Card Mode",
      name: "cardMode",
    },
    {
      title: "UPI Mode",
      name: "upiMode",
    },
  ];
  const [state, setState] = useState({
    cashMode: {
      amount: "",
      reference_no: "",
    },
    chequeMode: {
      amount: "",
      reference_no: "",
    },
    neftMode: {
      amount: "",
      reference_no: "",
    },
    cardMode: {
      amount: "",
      reference_no: "",
    },
    upiMode: {
      amount: "",
      reference_no: "",
    },
    transactionDate: dt,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [mode, field] = name.split(".");

    setState((prev) => ({
      ...prev,
      [mode]: {
        ...prev[mode],
        [field]: value,
      },
    }));
  };
  const totalPaying =
    Number(state.cardMode.amount ?? 0) +
    Number(state.cashMode.amount ?? 0) +
    Number(state.chequeMode.amount ?? 0) +
    Number(state.neftMode.amount ?? 0) +
    Number(state.upiMode.amount ?? 0);
  const toBePaid = Number(data?.netPay ?? 0) - Number(data?.paidAmount ?? 0);
  const totalBalance = toBePaid - totalPaying;
  const errorReturn = () => {
    if (totalPaying > toBePaid) {
      return `-> Paying Amount ${totalPaying} Greater than To be Paid ${toBePaid}`;
    }

    return "";
  };
  const handlePay = () => {
    const isError = Boolean(errorReturn());
    if (!isError) {
      const totalPayload = {
        balanceAmount: totalBalance || 0,
        transactionDate: state.transactionDate,
        transactionList: [
          {
            transactionAmount: Number(state.cashMode.amount ?? 0),
            paymentMode: "CashMode",
            referenceNumber: state.cashMode.reference_no,
          },
          {
            transactionAmount: Number(state.cardMode.amount ?? 0),
            paymentMode: "CardMode",
            referenceNumber: state.cardMode.reference_no,
          },
          {
            transactionAmount: Number(state.chequeMode.amount ?? 0),
            paymentMode: "ChequeMode",
            referenceNumber: state.chequeMode.reference_no,
          },
          {
            transactionAmount: Number(state.neftMode.amount ?? 0),
            paymentMode: "NEFTMode",
            referenceNumber: state.neftMode.reference_no,
          },
          {
            transactionAmount: Number(state.upiMode.amount ?? 0),
            paymentMode: "UpiMode",
            referenceNumber: state.upiMode.reference_no,
          },
        ],
      };
      onClickSubmit(totalPayload);
    }
  };
  return (
    <Wrapper>
      <Stack direction={"row"} gap={2}>
        <LabelTextComponent
          label={"Total Earnings"}
          text={IndianCurrency(data?.totalEarnings)}
        />
        <LabelTextComponent
          label={"Total Deductions"}
          text={IndianCurrency(data?.totalDeductions)}
        />
        <LabelTextComponent
          label={"Net Payable"}
          text={IndianCurrency(data?.netPay)}
        />
        <LabelTextComponent
          label={"Previously Paid"}
          text={IndianCurrency(data?.paidAmountx)}
        />
        <LabelTextComponent
          label={"To Be Paid"}
          text={IndianCurrency(toBePaid)}
        />
      </Stack>

      <Grid container spacing={1} display={"flex"} alignItems={"center"} mt={1}>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <Typography className="text">Amount</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography className="text">Reference Number</Typography>
        </Grid>
      </Grid>
      {paymentMode?.map((item, i) => (
        <>
          <Grid
            container
            spacing={1}
            display={"flex"}
            alignItems={"center"}
            key={i}
            mt={0.5}
          >
            <Grid item xs={2}>
              <Typography className="text">{item.title}</Typography>
            </Grid>
            <Grid item xs={5}>
              <CustomTextField
                fullWidth
                name={`${item.name}.amount`}
                placeholder="type amount"
                type="number"
                value={state[item.name].amount}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={5}>
              <CustomTextField
                fullWidth
                name={`${item.name}.reference_no`}
                placeholder="Type reference number"
                type="text"
                value={state[item.name].reference_no}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </>
      ))}
      <Grid container spacing={1} display={"flex"} alignItems={"center"} mt={1}>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <LabelTextComponent
            label={"Total Paying"}
            text={IndianCurrency(totalPaying)}
          />
        </Grid>
        <Grid item xs={5}>
          <LabelTextComponent
            label={"Net Balance"}
            text={IndianCurrency(totalBalance)}
          />
        </Grid>
      </Grid>
      <Typography sx={{ fontSize: "14px", color: colors.red[500] }}>
        {errorReturn()}
      </Typography>
      <Box mt={1}>
        <TextFieldLabel title={"Transaction Date"} required />
        <DesktopDatePicker
          inputFormat="DD-MM-YYYY"
          onChange={(e) =>
            setState((prev) => ({ ...prev, transactionDate: e }))
          }
          value={state.transactionDate}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              size="small"
              error={!state.transactionDate}
              helperText={!state.transactionDate && "Transaction date required"}
            />
          )}
        />
      </Box>
      <Stack direction={"row"} justifyContent={"flex-end"} mt={2}>
        <LoadingButton
          loading={loading}
          variant="contained"
          size="large"
          onClick={handlePay}
          color="secondary"
          sx={{ width: "200px" }}
        >
          Pay
        </LoadingButton>
      </Stack>
    </Wrapper>
  );
};

export default PaymentModeComponent2;
const Wrapper = styled(Box)`
  .text {
    color: var(--primary);
    font-weight: 500;
    font-size: 14px;
  }
`;
