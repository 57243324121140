import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let admissionEnquiry_url = `${SCHOOL_BASE}/reception`;

export const addAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.post(
    `${admissionEnquiry_url}/addAdmissionEnquiry`,
    payload
  );
};

export const getAdmissionEnquiry = (params) => {
  return schoolTokenAxios.get(`${admissionEnquiry_url}/getAdmissionEnquiry`, {
    params: params,
  });
};

export const updateAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/updateAdmissionEnquiry`,
    payload
  );
};
export const changeStatusOfAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.put(
    `${admissionEnquiry_url}/changeStatusOfAdmissionEnquiry`,
    payload
  );
};

export const deleteAdmissionEnquiry = (payload) => {
  return schoolTokenAxios.delete(
    `${admissionEnquiry_url}/deleteAdmissionEnquiry/${payload}`
  );
};
