import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

const SchoolPrintUtilityHeader = ({ data }) => {
  return (
    <>
      <Stack direction={"row"} gap={2}>
        <Box
          sx={{
            ml: 1,
            height: "65px",
            width: "65px",
          }}
        >
          <img
            src={data?.profilePic}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            alt="school-logo"
          />
        </Box>
        <Box
          sx={{
            mt: 0.5,
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "17px",
              textDecoration: "underline",
            }}
          >
            {data?.firmName}
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            {data?.schoolFirmAddress?.address} {/* <br /> */}
            {data?.schoolFirmAddress?.dist}, {data?.schoolFirmAddress?.state},{" "}
            {data?.schoolFirmAddress?.pinCode}
            {/* <br /> */}
          </Typography>
        </Box>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 2 }}
        gap={1}
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "10px",
            flex: 1,
          }}
        >
          {/* Website : {selectedSchool?.affiliatedTo} */}
          Website : <span style={{ fontWeight: 600 }}>{data?.website}</span>
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "10px",
            flex: 1,
            textAlign: "center",
          }}
        >
          Mobile No :{" "}
          <span style={{ fontWeight: 600 }}>{data?.mobileNumber}</span>
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "10px",
            flex: 1,
            textAlign: "center",
          }}
        >
          Email : <span style={{ fontWeight: 600 }}>{data?.email}</span>
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "10px",
            flex: 1,
            textAlign: "end",
          }}
        >
          GST Number :{" "}
          <span style={{ fontWeight: 600 }}>{data?.gstNumber ?? "--"}</span>
        </Typography>
      </Stack>
    </>
  );
};

export default SchoolPrintUtilityHeader;
