import React from "react";
import styled from "styled-components";
import { Grid, Typography, Box, Stack, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import dayjs from "dayjs";
import { IndianCurrency, NumberInWord } from "app/utils/helper";

const calendarOrder = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const InvoiceTemplate2 = ({ invoiceText, data2, invoiceItems2 }) => {
  const selectedSchool = useSelector(selectSelectedSchool);

  const totalAmount = data2?.totalPaidFeeAmount + data2?.totalFineAmount;

  return (
    <LeftSideDiv>
      <InvoiceHeader>
        <div
          style={{
            width: "100%",
          }}
        >
          <Grid container>
            <Grid item xs={2}>
              <img
                src={selectedSchool?.schoolLogo}
                style={{
                  height: "60px",
                  width: "60px",
                  objectFit: "contain",
                  marginLeft: "5px",
                }}
                alt="school-logo"
              />
            </Grid>
            <Grid item xs={10}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {selectedSchool?.schoolName}
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {`${selectedSchool?.schoolAddress?.address}, ${selectedSchool?.schoolAddress?.dist}, ${selectedSchool?.schoolAddress?.state}, ${selectedSchool?.schoolAddress?.pinCode}`}
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {selectedSchool?.schoolContactNumber}, {selectedSchool?.email}
              </Typography>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
              textTransform: "capitalize",
              textAlign: "center",
              color: "#FFFFFF",
              backgroundColor: "#0C4B7E",
            }}
          >
            {invoiceText} - {`${data2?.session}`}
          </div>
        </div>
      </InvoiceHeader>
      <InvoiceBody>
        <Grid container sx={{ px: 1, pt: 0.5 }}>
          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
              Invoice No.
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
              {data2?.invoiceNumber}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
              Collection Date
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
              {dayjs(data2?.collectionDate).format("DD MMM, YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
              Depositor Name
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
              {data2?.depositorName}
            </Typography>
          </Grid>
          {/* student detail */}
          <StudentDetailBox>
            <Grid container spacing={1}>
              {/* <Grid item xs={3}>
                <img
                  src={
                    data2?.studentDetail?.profilePic ?? "/image-placeholder.jpg"
                  }
                  style={{
                    height: "60px",
                    width: "60px",
                    objectFit: "contain",
                    marginLeft: "5px",
                  }}
                  alt="student-pic"
                />
              </Grid> */}
              {/* <Grid item xs={9}> */}
              {/* <Grid container spacing={1}> */}
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                  Name :
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                  {data2?.studentDetail?.studentName}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                  Father's Name :
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                  {data2?.studentDetail?.fatherName}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                  Class-Str-Sec :
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                  {data2?.studentDetail?.className_stream_sec}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                  Gender :
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                  {data2?.studentDetail?.gender}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                  Transport Info :
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "10px",
                    wordWrap: "breakWord",
                    overflowWrap: "breakWord",
                    whiteSpace: "normal",
                  }}
                >
                  {data2?.transportInfo?.vehicleNumber
                    ? `${data2?.transportInfo?.vehicleNumber}: ${data2?.transportInfo?.to}`
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                  Reg No :
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                  {data2?.studentDetail?.registrationNumber}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                  SR Number :
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                  {data2?.studentDetail?.srNumber}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                  Adm No :
                </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                  {data2?.studentDetail?.admissionNumber}
                </Typography>
              </Grid>
              {/* </Grid> */}
              {/* </Grid> */}
            </Grid>
          </StudentDetailBox>
        </Grid>

        <Box sx={{ background: "#ffaf0d", py: 1, px: 1 }}>
          <Grid container columns={25} spacing={2}>
            <Grid item xs={5}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                  wordWrap: "breakWord",
                  overflowWrap: "breakWord",
                }}
              >
                Fee Type
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                  wordWrap: "breakWord",
                  overflowWrap: "breakWord",
                }}
              >
                Fee Amount
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                  wordWrap: "breakWord",
                  overflowWrap: "breakWord",
                }}
              >
                Discount
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                  wordWrap: "breakWord",
                  overflowWrap: "breakWord",
                }}
              >
                Prev. Paid
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                  wordWrap: "breakWord",
                  overflowWrap: "breakWord",
                }}
              >
                Paying Fee
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                  wordWrap: "breakWord",
                  overflowWrap: "breakWord",
                }}
              >
                Balance
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {invoiceItems2?.map((invoice, i) => {
          const formatMonthList = (monthList) => {
            const sortedMonthList = monthList.sort((a, b) => {
              const [monthA, yearA] = a.split("-");
              const [monthB, yearB] = b.split("-");

              const monthIndexA = calendarOrder.indexOf(monthA);
              const monthIndexB = calendarOrder.indexOf(monthB);

              if (yearA === yearB) {
                return monthIndexA - monthIndexB;
              }

              return yearA - yearB || monthIndexA - monthIndexB;
            });

            return sortedMonthList
              .map(
                (item, i) =>
                  `${item}${i < sortedMonthList.length - 1 ? ", " : ""}`
              )
              .join(" ");
          };

          const monthList =
            invoice?.monthYearList && formatMonthList(invoice.monthYearList);
          return (
            <Box key={i}>
              <Grid container sx={{ px: 1 }} columns={25} spacing={1}>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                    {invoice?.feeType}
                    {/* ({invoice?.feeCode}) */}
                  </Typography>
                  <Stack direction={"row"} gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: 500,
                        wordWrap: "breakWord",
                        overflowWrap: "breakWord",
                      }}
                    >
                      ({monthList})
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: 500,
                      wordWrap: "breakWord",
                      overflowWrap: "breakWord",
                    }}
                  >
                    {IndianCurrency(invoice?.t_feeAmount)}
                    {/* +
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        {IndianCurrency(invoice?.t_fineAmount)}
                      </span> */}
                  </Typography>
                </Grid>

                {/* Discount */}
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: 500,
                      wordWrap: "breakWord",
                      overflowWrap: "breakWord",
                    }}
                  >
                    {IndianCurrency(
                      invoice?.t_recurringDiscountAmount +
                        invoice?.givenExtraDiscount
                    )}
                    +
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      {IndianCurrency(invoice?.givingExtraDiscount)}
                    </span>
                    {/* +
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        {IndianCurrency(invoice?.preWaivedFine)}
                      </span> */}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: 500,
                      wordWrap: "breakWord",
                      overflowWrap: "breakWord",
                    }}
                  >
                    {IndianCurrency(invoice?.prePaidFee)}
                    {/* +
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        {IndianCurrency(invoice?.prePaidFine)}
                      </span> */}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: 500,
                      wordWrap: "breakWord",
                      overflowWrap: "breakWord",
                    }}
                  >
                    {IndianCurrency(invoice?.payingFee)}
                    {/* +
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        {IndianCurrency(invoice?.payingFine)}
                      </span> */}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: 500,
                      wordWrap: "breakWord",
                      overflowWrap: "breakWord",
                    }}
                  >
                    {IndianCurrency(invoice?.balanceAmount ?? 0)}
                  </Typography>
                </Grid>
              </Grid>
              {invoiceItems2?.length !== i + 1 && (
                <Divider
                  sx={{ background: (theme) => theme.palette.primary.main }}
                />
              )}
            </Box>
          );
        })}
        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
        <Grid container sx={{ px: 1 }} spacing={1} columns={25}>
          {/* <Grid item xs={2}>
              <Typography sx={{ fontSize: "10px", fontWeight: 500 }}>
                Pre Dues
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                --
              </Typography>
            </Grid> */}
          <Grid item xs={5}>
            <Typography sx={{ fontSize: "10px", fontWeight: 700 }}></Typography>
          </Grid>

          {/* Total Fee Amount */}
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>
              {IndianCurrency(data2?.totalFeeAmount ?? 0)}
              {/* +{" "}
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {IndianCurrency(data2?.TotalFineAmount ?? 0)}
                </span> */}
            </Typography>
          </Grid>

          {/* Total Discount */}
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>
              {IndianCurrency(
                (data2?.totalRecurringDiscountAmount ?? 0) +
                  (data2?.totalGivenExtraDiscount ?? 0)
              )}{" "}
              +{" "}
              <span
                style={{
                  color: "green",
                }}
              >
                {IndianCurrency(data2?.totalGivingExtraDiscount ?? 0)}
              </span>
              {/* +{" "}
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {IndianCurrency(data2?.PreWaivedFine ?? 0)}
                </span> */}
            </Typography>
          </Grid>

          {/* Previous Paid */}
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>
              {IndianCurrency(data2?.totalPrePaidFee ?? 0)}
              {/* +{" "}
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {IndianCurrency(data2?.totalPrePaidFine ?? 0)}
                </span> */}
            </Typography>
          </Grid>

          {/*Total Paying Fee */}
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>
              {IndianCurrency(data2?.totalPayingFee ?? 0)}
              {/*                 
                +{" "}
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {IndianCurrency(data2?.totalPayingFine ?? 0)}
                </span> */}
            </Typography>
          </Grid>

          {/* Total Balance */}
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>
              {IndianCurrency(data2?.totalBalanceAmount ?? 0)}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
        <Grid container sx={{ px: 1 }}>
          <Grid item xs={12}>
            {/* <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                Current Discount Amount:{data2?.totalGivingExtraDiscount ?? 0}
              </Typography> */}
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: 600,
              }}
            >
              Discount Remarks:{data2?.givingDiscountRemarksList ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: 600,
              }}
            ></Typography>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: 600,
              }}
            ></Typography>
          </Grid>
        </Grid>
        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
        <Grid container sx={{ px: 1 }}>
          <Grid item xs={12}>
            {/* <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                Current Discount Amount:{data2?.totalGivingExtraDiscount ?? 0}
              </Typography> */}
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: 600,
              }}
            >
              Total Fine Amount:{data2?.totalPayingFine ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: 600,
              }}
            ></Typography>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: 600,
              }}
            ></Typography>
          </Grid>
        </Grid>
        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
        {/* <Grid container sx={{ px: 1 }}>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                Grand Total
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                {IndianCurrency(calculateGrandTotal())}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                Paying Fee + Fine
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                {data?.totalPaidFeeAmount} +{" "}
                <span style={{ color: "red" }}>{data?.totalFineAmount}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                Balance
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                {data?.totalBalanceFeeAmount}
              </Typography>
            </Grid>
          </Grid> */}
        {/* <Divider sx={{ background: (theme) => theme.palette.primary.main }} /> */}
        <Grid container sx={{ px: 1 }}>
          <Grid item xs={9}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              Paid Amount :{" "}
              {NumberInWord(
                totalAmount
                // + (data2?.totalPayingFine ?? 0)
              )}{" "}
              {/* {NumberInWord(calculateGrandTotal() + calculateTotalFine())}{" "} */}
              Rupees Only
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>
              {IndianCurrency(
                totalAmount ?? 0
                // + (data2?.totalPayingFine ?? 0)
              )}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />

        <Grid container sx={{ px: 1 }}>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
              Payment Mode :{" "}
              {data2?.paymentDetails
                ? Object.keys(data2?.paymentDetails)[0]
                : "--"}
            </Typography>
          </Grid>
          {data2?.paymentDetails?.UpiMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  UPI Number: {data2?.paymentDetails?.UpiMode?.upiNumber}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Transaction ID:{" "}
                  {data2?.paymentDetails?.UpiMode?.transactionId}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Amount: {data2?.paymentDetails?.UpiMode?.amount}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data2?.paymentDetails?.CardMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Card Holder Name:{" "}
                  {data2?.paymentDetails?.CardMode?.cardHolderName}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Card Number: {data2?.paymentDetails?.CardMode?.cardNumber}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Amount: {data2?.paymentDetails?.CardMode?.amount}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data2?.paymentDetails?.ChequeMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Cheque No.: {data2?.paymentDetails?.ChequeMode?.chequeNumber}
                </Typography>

                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Amount: {data2?.paymentDetails?.ChequeMode?.amount}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Deposition Date:{" "}
                  {dayjs(
                    data2?.paymentDetails?.ChequeMode?.depositionDate
                  ).format("DD MMM YYYY")}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data2?.paymentDetails?.NEFTMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Reference No.:{" "}
                  {data2?.paymentDetails?.NEFTMode?.referenceNumber}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Amount: {data2?.paymentDetails?.NEFTMode?.amount}
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Transaction No.:{" "}
                  {data2?.paymentDetails?.NEFTMode?.transactionNumber}
                </Typography>
              </Stack>
            </Grid>
          )}
          {data2?.paymentDetails?.CashMode && (
            <Grid item xs={8}>
              <Stack direction={"row"} justifyContent={"space-around"}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  Amount: {data2?.paymentDetails?.CashMode?.amount}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>

        <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
        <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
          <Box>
            <Typography sx={{ fontSize: "10px" }}>
              Created By : {data2?.createdBy?.personName}
            </Typography>
            <Typography sx={{ fontSize: "10px" }}>
              Created On : {dayjs(data2?.createdOn).format("DD MMM YYYY")}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "10px" }}>For,</Typography>
            <Typography sx={{ fontSize: "10px" }}>
              {data2?.schoolName}
            </Typography>
            <Typography sx={{ fontSize: "10px", pt: 2, pb: 1 }}>
              Authorised Sign.
            </Typography>
          </Box>
        </Stack>
      </InvoiceBody>
    </LeftSideDiv>
  );
};

export default InvoiceTemplate2;

const LeftSideDiv = styled.div`
  margin: 5px;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: #fff;
`;

const InvoiceHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const InvoiceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const StudentDetailBox = styled.div`
  border-radius: 8px;
  border: 1px solid #d0d0e2;
  background: rgba(208, 208, 226, 0.08);
  padding: 10px;
  width: 100%;
  margin: 10px 0;
`;
