import React from "react";
import { Helmet } from "react-helmet";
import DebtToStaff from "./DebtToStaff";

const AdvancePage = () => {
  return (
    <>
      <Helmet>
        <title>Payroll | Advance Salary</title>
      </Helmet>
      <DebtToStaff />
    </>
  );
};

export default AdvancePage;
