import {
  Button,
  Card,
  CardActions,
  CardContent,
  DialogContent,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { MaterialReactTable } from "material-react-table";
import { useMemo, useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useQuery } from "react-query";
import { queryClient } from "App";

//import for our files
import {
  approveFeeStructureRequest,
  rejectFeeStructureRequest,
} from "app/services/management";
import { mobile } from "app/modules/reception/components/responsive";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { userPrimaryRoleSelector } from "app/modules/schools/selectors";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import { THEME } from "app/config/constants";
import dayjs from "dayjs";
import CommonTable1 from "app/components/Tables/CommonTable1";

export default function ViewActionModal({ open, setOpen, feeStructureData }) {
  const [successMessage, setSuccessMessage] = useState(null);
  const [isFetching, setFetching] = useState(false);
  const primaryRole = useSelector(userPrimaryRoleSelector);
  // React Query to approve Fee Structure
  const {
    isLoading: approvalLoading,
    data: approvedData,
    isError: approvalError,
    error,
    refetch: approvalRefetch,
  } = useQuery({
    queryKey: ["approveFeeStructureRequest", feeStructureData?._id],
    queryFn: () => approveFeeStructureRequest(feeStructureData?._id),
    enabled: false,
    onSuccess: (data) => {
      NotificationManager.success(data?.data?.message, "", 3000);
      queryClient.invalidateQueries("filterAllFeeStructure");
      setOpen(false);
    },

    onError: (err) => {
      NotificationManager.error(err?.response?.data?.message, "", 3000);
    },
  });

  // React Query to Reject Fee Structure
  const {
    isLoading: rejectLoading,
    data: rejectedData,
    refetch: rejectRefetch,
  } = useQuery({
    queryKey: ["rejectFeeStructureRequest", feeStructureData?._id],
    queryFn: () => rejectFeeStructureRequest(feeStructureData?._id),
    enabled: false,
    onSuccess: (data) => {
      NotificationManager.success(data?.data?.message, "", 3000);
      queryClient.invalidateQueries("filterAllFeeStructure");
      setOpen(false);
    },

    onError: (err) => {
      NotificationManager.error(err.response?.data?.message, "", 3000);
    },
  });
  // const columns = useMemo(() => {
  //   return [
  //     {
  //       header: "Sr.No",
  //       Cell: ({ cell }) => (
  //         <p
  //           style={{
  //             width: "100%",
  //             height: "100%",
  //           }}
  //         >
  //           {parseInt(cell?.row?.id) + 1}
  //         </p>
  //       ),
  //       size: 10,
  //     },
  //     {
  //       header: "Fee Name",
  //       accessorKey: "feeCategory",
  //       Cell: ({ cell }) => (
  //         <p
  //           style={{
  //             width: "12vw",
  //             height: "30px",
  //             border: "1px solid #B6C8D6",
  //             borderRadius: "8px",
  //             display: "flex",
  //             alignItems: "center",
  //             paddingLeft: "1rem",
  //           }}
  //         >
  //           {cell?.getValue()}
  //         </p>
  //       ),
  //       size: 60,
  //     },
  //     {
  //       header: "Description",
  //       accessorKey: "description",
  //       Cell: ({ cell }) => (
  //         <p
  //           style={{
  //             width: "20vw",
  //             height: "30px",
  //             border: "1px solid #B6C8D6",
  //             borderRadius: "8px",
  //             display: "flex",
  //             alignItems: "center",
  //             paddingLeft: "1rem",
  //           }}
  //         >
  //           {cell?.getValue()}
  //         </p>
  //       ),
  //       size: 200,
  //     },
  //     {
  //       header: "Amount",
  //       accessorKey: "amount",
  //       Cell: ({ cell }) => (
  //         <p
  //           style={{
  //             width: "7vw",
  //             height: "30px",
  //             border: "1px solid #B6C8D6",
  //             borderRadius: "8px",
  //             display: "flex",
  //             alignItems: "center",
  //             paddingLeft: "1rem",
  //           }}
  //         >
  //           {cell?.getValue()}
  //         </p>
  //       ),
  //       size: 60,
  //     },
  //   ];
  // }, []);
  return (
    <>
      <CommonDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Fee Structure View"
        minWidth="60%"
      >
        <DialogContent>
          <DialogStyledWrapper>
            <Stack className="feeDetail">
              <Grid container spacing={2}>
                {/* Session  */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Session
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.session ?? "--"}
                  </Typography>
                </Grid>
                {/* ClassName */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    ClassName
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.className}-{feeStructureData?.stream}-
                    {feeStructureData?.section}
                  </Typography>
                </Grid>
                {/* feeCode  */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Fee Code
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.feeCode ?? "--"}
                  </Typography>
                </Grid>
                {/* feeType  */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Fee Type
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.feeType ?? "--"}
                  </Typography>
                </Grid>
                {/* feeRelaxationCategory  */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Fee Relaxation Category
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.feeRelaxationCategory ?? "--"}
                  </Typography>
                </Grid>
                {/* generalFeeAmount  */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    General Fee Amount
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.generalFeeAmount ?? "--"}
                  </Typography>
                </Grid>
                {/* categoryDiscountAmount */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Category Discount Amount
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.categoryDiscountAmount ?? "--"}
                  </Typography>
                </Grid>
                {/* taxRate */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Tax Rate
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.taxRate ?? "--"}
                  </Typography>
                </Grid>
                {/* netFeeAmount */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Net Fee Amount
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.feeAmount ?? "--"}
                  </Typography>
                </Grid>
                {/* status */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Status
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.status ?? "--"}
                  </Typography>
                </Grid>
                {/* optional */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Optional
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.optional ? "Yes" : "No"}
                  </Typography>
                </Grid>
                {/* createdOn */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Created On
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.createdOn
                      ? dayjs(new Date(feeStructureData?.createdOn)).format(
                          "DD-MM-YYYY"
                        )
                      : "--"}
                  </Typography>
                </Grid>
                {/* createdBy */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Created By
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.createdBy?.personName ?? "--"}
                  </Typography>
                </Grid>
                {/* approvedBy */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Approved By
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.approvedBy?.personName ?? "--"}
                  </Typography>
                </Grid>
                {/* approvedOn */}
                <Grid item xs={3}>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Approved On
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.approvedOn
                      ? dayjs(new Date(feeStructureData?.approvedOn)).format(
                          "DD-MM-YYYY"
                        )
                      : "--"}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
            {/* <Stack
              sx={{
                mt: 2,
              }}
            >
              <TableWrapper>
                <MaterialReactTable
                  enableBottomToolbar={false}
                  enableTopToolbar={false}
                  columns={columns}
                  data={feeStructureData?.feeDetails}
                  muiTableProps={{
                    sx: {
                      borderRadius: "20px",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontSize: "12px",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      fontWeight: "normal",
                      fontSize: "13px",
                      backgroundColor: "#F6F8FC",
                      textAlign: "center",
                    },
                  }}
                />
              </TableWrapper>
            </Stack> */}
          </DialogStyledWrapper>
        </DialogContent>
      </CommonDialog>
      {/* <Modal
        open={open}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
        }}
      >
        <Card>
          <NotificationContainer />
          <Wrapper>
            <CardContent
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3>Fee Structure View</h3>
              <span>
                <Button
                  onClick={() => setOpen(false)}
                  variant="outlined"
                  color="error"
                  style={{
                    width: "10px",
                  }}
                >
                  <Close />
                </Button>
              </span>
            </CardContent>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "1.5rem",
                height: "100%",
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              <SecondTopHeader>
                <span>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Fee Type
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.feeType}
                  </Typography>
                </span>
                <span>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Caste Category
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.category}
                  </Typography>
                </span>
                <span>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Relaxation Category
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.feeRelaxationCategory}
                  </Typography>
                </span>
                <span>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Class Stream Section
                  </Typography>
                  <Typography fontWeight="bold" fontSize={14}>
                    {feeStructureData?.className}-{feeStructureData?.stream}-
                    {feeStructureData?.section}
                  </Typography>
                </span>
              </SecondTopHeader>

              <ContentWrapper>
                <span>
                  <Typography fontWeight="semi-bold" fontSize={12}>
                    Due Day
                  </Typography>
                  <TextField value={feeStructureData?.dueDay} disabled />
                </span>

                {feeStructureData?.fineRate ? (
                  <>
                    <span>
                      <Typography>Fine Rate</Typography>
                      <RadioGroup
                        defaultValue={
                          feeStructureData?.fineIncreamentUnit === "fixedAmount"
                            ? "Fixed Amount"
                            : "Per Day"
                        }
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          value="Fixed Amount"
                          control={<Radio />}
                          label="Fixed Amount"
                          disabled
                        />
                        <FormControlLabel
                          value="Per Day"
                          control={<Radio />}
                          label="Per Day"
                          disabled
                        />
                      </RadioGroup>
                    </span>
                    <span>
                      <TextField value={feeStructureData?.fineRate} disabled />
                    </span>

                    {feeStructureData?.fineIncreamentUnit ===
                    "perDayPercent" ? (
                      <>
                        <TextField
                          value={feeStructureData?.fineIncreamentUnit}
                          disabled
                        />
                      </>
                    ) : feeStructureData?.fineIncreamentUnit ===
                      "perDayFixedRate" ? (
                      <TextField
                        value={feeStructureData?.fineIncreamentUnit}
                        disabled
                      />
                    ) : null}
                  </>
                ) : (
                  <>
                    <span>
                      <Typography>Fine Rate</Typography>
                      <RadioGroup
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          value="Fixed Amount"
                          control={<Radio />}
                          label="Fixed Amount"
                          disabled
                        />
                        <FormControlLabel
                          value="Per Day"
                          control={<Radio />}
                          label="Per Day"
                          disabled
                        />
                      </RadioGroup>
                    </span>
                    <span>
                      <TextField value={"Fine Rate Not Found"} disabled />
                    </span>
                  </>
                )}
              </ContentWrapper>

              <TableWrapper>
                <MaterialReactTable
                  enableBottomToolbar={false}
                  enableTopToolbar={false}
                  columns={columns}
                  data={feeStructureData?.feeDetails}
                  muiTableProps={{
                    sx: {
                      borderRadius: "20px",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      fontSize: "12px",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      fontWeight: "normal",
                      fontSize: "13px",
                      backgroundColor: "#F6F8FC",
                      textAlign: "center",
                    },
                  }}
                />
              </TableWrapper>
            </CardContent>
            {["ROLE_ADMIN", "ROLE_MANAGING_DIRECTOR"].includes(primaryRole) && (
              <CardActions
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "end",
                  paddingRight: "1.5rem",
                }}
              >
                <LoadingButton
                  variant="outlined"
                  loading={rejectLoading}
                  style={{
                    height: "3rem",
                    width: "10rem",
                  }}
                  disabled={
                    feeStructureData?.status === "APPROVED" ||
                    feeStructureData?.status === "REJECTED"
                  }
                  onClick={() => {
                    rejectRefetch(feeStructureData);
                  }}
                >
                  {feeStructureData?.status === "REJECTED"
                    ? "REJECTED"
                    : "REJECT"}
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  loading={approvalLoading}
                  style={{
                    height: "3rem",
                    width: "10rem",
                  }}
                  disabled={
                    feeStructureData?.status === "APPROVED" ||
                    feeStructureData?.status === "REJECTED"
                  }
                  onClick={() => {
                    approvalRefetch(feeStructureData);
                  }}
                >
                  {feeStructureData?.status === "APPROVED"
                    ? "APPROVED"
                    : "APPROVE"}
                </LoadingButton>
              </CardActions>
            )}
          </Wrapper>
        </Card>
      </Modal> */}
    </>
  );
}

const Wrapper = styled.div`
  height: 80vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  overflow-y: scroll;
  ${mobile({ width: "100%", height: "100%" })}
`;

const SecondTopHeader = styled.div`
  width: 100%;
  height: 80px;
  background: #f6f8fc;
  border-bottom: 1px solid #b6c8d6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  padding-left: 1rem;
  ${mobile({ flexWrap: "wrap" })}
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  border: 1px solid #b6c8d6;
  border-radius: 15px;
  margin: auto;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  padding-left: 1rem;
`;
const DialogStyledWrapper = styled("div")`
  .feeDetail {
    border-radius: 15px;
    border: 1px solid #d0d0e2;
    background: rgba(208, 208, 226, 0.08);
    padding: 10px;
    margin-top: 10px;
  }
  .demo_image {
    border-radius: 11px;
    background: #d9d9d9;
    height: 100px;
    width: 100px;
  }
  .student_admitCard__label {
    color: ${THEME.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    opacity: 0.75;
  }
  .student_admitCard__text {
    color: ${THEME.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
