import { createAsyncThunk } from "@reduxjs/toolkit";
import API_ENDPOINTS from "app/config/endpoints";
import { authTokenAxios, schoolTokenAxios } from "app/services/axios";
import { getUserProfile } from "app/services/schoolService/user.service";

export const getSchoolList = createAsyncThunk("schools/schoolList", () => {
  return new Promise((res, rej) => {
    authTokenAxios
      .get(API_ENDPOINTS.auth.schoolList)
      .then((resp) => res(resp.data))
      .catch(rej);
  });
});

export const getSchoolToken = createAsyncThunk(
  "schools/getSchoolToken",
  (payload, { dispatch }) => {
    return new Promise((res, rej) => {
      // school Details
      let school_detail = payload;

      authTokenAxios
        .post(API_ENDPOINTS.auth.schoolAccessToken, {
          schoolDocId: school_detail?._id,
        })
        .then((resp) => {
          schoolTokenAxios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${resp.data.obj.accessToken}`;

          // console.info("school token updated", resp.data.obj.accessToken);
          localStorage.setItem("schoolToken", resp.data.obj.accessToken);
          localStorage.setItem(
            "schoolRefreshToken",
            resp.data.obj.refreshToken
          );

          // Set the school id in the local storage
          localStorage.setItem("selectedSchoolId", school_detail?._id);

          res({
            school_detail: school_detail,
            accessToken: resp?.data?.obj?.accessToken,
            refreshToken: resp?.data?.obj?.refreshToken,
            menuOptionsToShow: resp?.data?.obj?.menuOptionsToShow,
          });
        })
        .catch(rej);
    });
  }
);

export const getSchoolSessionList = createAsyncThunk(
  "schoolManagement/getAllSessions",
  (payload, { dispatch }) => {
    return new Promise((resolve, reject) => {
      schoolTokenAxios
        .get(API_ENDPOINTS.school.getAllSessions)
        .then((resp) => {
          resolve(resp.data.list);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
);

export const getLoggedInUserDetail = createAsyncThunk(
  "/user/getUserProfile",
  () => {
    return new Promise(async (resolve, reject) => {
      try {
        let staffDetail = await getUserProfile();

        resolve(staffDetail?.data?.obj);
      } catch (err) {
        reject(err);
      }
    });
  }
);
