import React from "react";
import Fallback from "app/components/Fallback";
import { INSTITUTE_TYPES } from "app/config/institutes";
import appRoutes from "app/config/routes";
import { selectedInstituteTypeSelector } from "app/modules/auth/selectors";
import { selectSelectedSchoolId } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const CoachingDashboard = React.lazy(() =>
  import("app/modules/dashboard/pages/CoachingDashboard")
);

const CollegeDashboard = React.lazy(() =>
  import("app/modules/dashboard/pages/CollegeDashboard")
);

const SchoolDashboard = React.lazy(() =>
  import("app/modules/dashboard/pages/SchoolDashboard")
);

const GroupOfInstitutesDashboard = React.lazy(() =>
  import("app/modules/dashboard/pages/GroupOfInstitutesDashboard")
);

export const CoachingDashboardPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <CoachingDashboard {...props} />
  </React.Suspense>
);

export const CollegeDashboardPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <CollegeDashboard {...props} />
  </React.Suspense>
);

export const SchoolDashboardPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SchoolDashboard {...props} />
  </React.Suspense>
);

export const GroupOfInstitutesDashboardPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <GroupOfInstitutesDashboard {...props} />
  </React.Suspense>
);

const DashboardIndex = () => {
  const userLoginProfile = useSelector(selectedInstituteTypeSelector);
  const schoolId = useSelector(selectSelectedSchoolId);

  return userLoginProfile === INSTITUTE_TYPES.COACHING ? (
    <Navigate to={{ pathname: `/${appRoutes.coachingDashboard}/acs` }} />
  ) : userLoginProfile === INSTITUTE_TYPES.COLLEGE ? (
    <Navigate to={{ pathname: `/${appRoutes.collegeDashboard}` }} />
  ) : userLoginProfile === INSTITUTE_TYPES.SCHOOL ? (
    <Navigate to={{ pathname: `/${appRoutes.schoolDashboard}/${schoolId}` }} />
  ) : (
    <Navigate to={{ pathname: `/` }} />
  );
};

export default DashboardIndex;
