import { TextField, MenuItem } from "@mui/material";
import { useField, useFormikContext } from "formik";

export default function EditFeeSelectComponent({
  name,
  options,
  ...otherProps
}) {
  const { setFieldValue } = useFormikContext();
  const [field, metaData] = useField(name);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };
  const selectConfig = {
    ...field,
    ...otherProps,
    select: true,
    variant: "outlined",
    fullWidth: true,
    default: field.value,
    onChange: handleChange,
  };

  if (metaData && metaData.touched && metaData.error) {
    selectConfig.error = true;
    selectConfig.helperText = metaData.error;
  }
  return (
    <TextField {...selectConfig}>
      {/* {options &&
        options.map((option, index) => {
          return (
            <MenuItem value={option} key={index}>
              {option}
            </MenuItem>
          );
        })} */}
      <MenuItem value="perDayFixedRate">Fixed Rate/Day</MenuItem>
      <MenuItem value="perDayPercent">Percentage/Day</MenuItem>
    </TextField>
  );
}
