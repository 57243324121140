import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Select,
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useState } from "react";
import CommonTable1 from "app/components/Tables/CommonTable1";
import DialogHeader from "app/components/common/DialogHeader";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { useMutation, useQuery } from "react-query";
import {
  getBooksForSale,
  updateBookQuantity,
} from "app/services/ledgerAccount";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import {
  IndianCurrency,
  MenuProps,
  calculateTotalsAndAppendSummaryRowPdf,
} from "app/utils/helper";
import CustomLoader from "app/components/common/CustomLoader";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import ExportDialog from "app/components/common/exportDialog";
import CommonFilterDialog from "app/components/common/Dialogs/CommonFilterDialog";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import FilterButton from "app/components/common/FilterButton";
import AddBookDialog from "app/components/common/Dialogs/ledger/books/AddBookDialog";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchool,
  selectSelectedSchoolId,
  selectedMediumSelector,
  userNameSelector,
} from "app/modules/schools/selectors";
import { getClassDropdown } from "app/services/student-management";
import UpdateBookRateDialog from "./UpdateBookRateDialog";
import jsPDF from "jspdf";
import reportHeader from "app/components/common/pdfReport/reportHeader";
import dayjs from "dayjs";
import autoTable from "jspdf-autotable";
import Footer from "app/components/common/pdfReport/ReportFooter";
import PdfSummaryPreviewDialog from "app/components/common/Dialogs/PdfSummaryPreviewDialog";
const IncreaseDecreaseDialog = ({ open, handleClose, refetch, bookDocId }) => {
  const [state, setState] = useState({
    bookQuantity: "",
  });
  const onClose = () => {
    handleClose();
    setState((prev) => ({
      ...prev,
      bookQuantity: "",
    }));
  };
  const handleSubmitIncreaseDecrease = (e) => {
    e.preventDefault();
    updateBookQuantityMutate({
      quantity: state.bookQuantity,
      bookDocId,
    });
  };
  const {
    mutate: updateBookQuantityMutate,
    isLoading: updateBookQuantityLoading,
  } = useMutation(updateBookQuantity, {
    onSuccess: (res) => {
      refetch();
      onClose();
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  return (
    <CommonDialog
      open={open}
      title="Increase/Decrease Books"
      onClose={onClose}
      minWidth="450px"
    >
      <DialogContent>
        <form
          id="increase-decrease-book-form"
          onSubmit={handleSubmitIncreaseDecrease}
        >
          <TextFieldLabel title={"Increase Decrease Quantity"} />
          <CustomTextField
            placeholder="Type quantity"
            required
            value={state.bookQuantity}
            size="small"
            sx={{ width: "100%" }}
            type="number"
            onChange={(e) =>
              setState((prev) => ({ ...prev, bookQuantity: e.target.value }))
            }
          />
          <Typography sx={{ opacity: 0.7, my: 0.5, fontSize: "14px" }}>
            For Decrease use minus sign before quantity (ex. -10)
          </Typography>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={updateBookQuantityLoading}
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          form="increase-decrease-book-form"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};
const BookSummary = () => {
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const selectedMedium = useSelector(selectedMediumSelector);
  const userName = useSelector(userNameSelector);
  const selectedSchool = useSelector(selectSelectedSchool);
  const selectedActiveSession = useSelector(selectActiveSession);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [openCheckBox, setOpenCheckBox] = useState(false);
  const [state, setState] = useState({
    addBookDialog: false,
    tableData: [],
    selectedClass: "",
    selectedSeller: "",
    customize: {
      class: "",
      section: "",
      gName: "",
      bookList: [],
    },
    image: {
      link: "",
      file: null,
    },
    section: "",
    bookQuantity: 0,
    bookQuantityDialog: false,
    bookDocId: "",
    filterExtraData: false,
    checkExportOptionsPdf: false,
    filterData: {},
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogs, setDialogs] = useState({
    add: false,
    book: false,
    view: false,
    editRate: false,
    editRateData: {},
  });

  const [checkboxes, setCheckboxes] = useState({
    one: false,
  });

  const columns = [
    {
      header: "Sr.No",
      Cell: ({
        cell: {
          row: { id },
        },
      }) => (
        <Typography>
          {parseInt(id) + 1 < 10 ? `0${parseInt(id) + 1}` : parseInt(id) + 1}
        </Typography>
      ),
      maxSize: isMobile ? 40 : 30,
    },
    {
      header: "Class-Stream",
      accessorKey: "stream",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.stream}</Typography>;
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Book Title",
      accessorKey: "title",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.title}</Typography>;
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Bought Qty",
      accessorKey: "qty",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography
            onClick={() =>
              setState((prev) => ({
                ...prev,
                bookQuantityDialog: true,
                bookDocId: original?.id,
              }))
            }
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#2789FD",
            }}
          >
            {original?.bqty}
          </Typography>
        );
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Expense Amount",
      accessorKey: "eamount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.expenseAmount}</Typography>;
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Sold Qty",
      accessorKey: "sqty",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.soldQuantity}</Typography>;
      },
      maxSize: isMobile ? 60 : 40,
    },

    {
      header: "Balance Qty",
      accessorKey: "bqty",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.balanceQuantity}</Typography>;
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Created By",
      accessorKey: "created",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.created}</Typography>;
      },
      maxSize: isMobile ? 60 : 40,
    },
    {
      header: "Actions",
      Cell: ({ cell }) => {
        return (
          <Stack direction="row" gap={2}>
            <button
              style={{
                backgroundColor: "#EBF0F4",
                borderRadius: "8px",
                border: "none",
                outline: "none",
                width: "2.5rem",
                height: "2.5rem",
                color: "#6197ed",
              }}
              onClick={() => {
                setDialogs((prev) => ({ ...prev, view: true }));
                setState((prev) => ({
                  ...prev,
                  active: cell?.row?.original?.data,
                }));
              }}
            >
              <VisibilityIcon />
            </button>
          </Stack>
        );
      },
      size: isMobile ? 40 : 20,
    },
  ];

  const tableData = state?.tableData?.map((item) => {
    return {
      data: item,
      stream:
        item?.className + "-" + item?.stream || "--" + item?.section || "--",
      title: item?.bookTitle || "--",
      bqty: item?.quantity || "--",
      expenseAmount: item?.expenseAmount,
      soldQuantity: item?.soldQuantity || 0,
      balanceQuantity: item?.balanceQuantity,
      created: item?.createdBy?.personName || "--",
      id: item?._id,
      amount: item?.amount ?? "--",
      status: item?.status ?? "--",
      paid_By: item?.paidBy?.personName ?? "--",
    };
  });

  const {
    refetch: getBooksForSaleRefetch,
    isLoading: getBooksForSaleLoading,
    isFetching: getBooksForSaleFetching,
  } = useQuery({
    queryKey: ["getBooksForSale"],
    queryFn: () =>
      getBooksForSale({
        ...(state.selectedClass && {
          classDocId: state?.selectedClass,
          section: state?.section,
        }),
      }),
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, tableData: res?.data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleCloseFilterDialog = () => {
    setModalOpen(false);
  };
  let header = [
    "ClassName",
    "Book_Title",
    "Expense_Amount",
    "Balance_Quantity",
    "Bought_Quantity",
    "Sold_Quantity",
    "Created_By",
  ];

  let exportArray = tableData?.map((item) => {
    return {
      ClassName: item?.stream,
      Book_Title: item?.title,
      Expense_Amount: item?.expenseAmount ?? "--",
      Balance_Quantity: item?.balanceQuantity ?? "--",
      Bought_Quantity: item?.bqty ?? "--",
      Sold_Quantity: item?.soldQuantity ?? "--",
      Created_By: item?.created ?? "--",
    };
  });

  // pdf building start here************************
  //*************************************** */
  let headerPdf = [
    "ClassName",
    "Book_Title",
    "Expense_Amount",
    "Balance_Quantity",
    "Bought_Quantity",
    "Sold_Quantity",
    "Created_By",
  ];
  const [selectedOptionsForPdf, setSelectedOptionsForPdf] = useState(headerPdf);

  let exportArrayPdf = tableData?.map((item) => {
    return {
      ClassName: item?.stream ?? "--",
      Book_Title: item?.title ?? "--",
      Expense_Amount: item?.expenseAmount ?? "--",
      Balance_Quantity: item?.balanceQuantity ?? "--",
      Bought_Quantity: item?.bqty ?? "--",
      Sold_Quantity: item?.soldQuantity ?? "--",
      Created_By: item?.created ?? "--",
    };
  });

  const classes = state?.classData?.map((item) => ({
    name: item?.className_stream,
    classDocId: item?.classDocId,
  }));

  const getClassName = (classDocId) => {
    return state?.classData?.filter((item) => item.classDocId === classDocId)[0]
      ?.className_stream;
  };

  const handleExportPdf = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const rows = [];
    rows.push(selectedOptionsForPdf);
    exportArrayPdf.forEach((item) => {
      const row = selectedOptionsForPdf.map((option) => item[option]);
      rows.push(row);
    });
    const tableColumn = selectedOptionsForPdf.map((col) => ({
      title: col,
      dataKey: col,
    }));
    const tableStyles = {
      headStyles: {
        fillColor: [182, 200, 214],
        textColor: 0,
        fontSize: 9,
        cellPadding: { top: 1.5, right: 1.5, bottom: 1.5, left: 1.5 },
        cellWidth: "auto", // 'auto'|'auto'|'fixed'
      },
      bodyStyles: {
        fontSize: 8,
        cellPadding: { top: 1.5, right: 1.5, bottom: 1.5, left: 1.5 },
        cellWidth: "auto",
      },
      theme: "grid",
    };

    const totalPagesExp = "{total_pages_count_string}";

    let appliedFiltery_axis = 34;

    // report header
    reportHeader({
      doc: doc,
      selectedSchool: selectedSchool,
      state: state,
      extraData: state?.filterExtraData,
    });

    doc.text(`Applied Filter:`, 15, appliedFiltery_axis);
    doc.text(
      "_______________________________________",
      15,
      appliedFiltery_axis + 2
    );
    let filterTexts = [];

    if (state?.selectedClass) {
      filterTexts.push(`Class: ${getClassName(state?.selectedClass)}`);
    }
    if (state?.section) {
      filterTexts.push(`Section: ${state?.section}`);
    }

    filterTexts.push(`session: ${selectedActiveSession}`);

    let xaxis = 15;
    let yaxis = appliedFiltery_axis + 6;
    let difference = 0;
    let lineHeight = 4; // Line height
    let maxLineWidth = 180;

    filterTexts.forEach((filterText, index) => {
      let textWidth = doc.getStringUnitWidth(filterText) * doc.getFontSize();
      if (xaxis + textWidth > maxLineWidth) {
        xaxis = 15;
        yaxis += lineHeight;
        difference += lineHeight;
      }
      doc.text(filterText, xaxis, yaxis);
      xaxis += textWidth;
      if (index < filterTexts.length - 1) {
        doc.text(",", xaxis, yaxis);
        xaxis += doc.getStringUnitWidth(",") * doc.getFontSize();
        doc.text(" ", xaxis, yaxis);
        xaxis += doc.getStringUnitWidth(" ") * doc.getFontSize();
      }
    });

    let textheight = appliedFiltery_axis + 12 + difference;
    doc.text(
      "______________________________________________________________________________________",
      15,
      textheight - 4
    );

    const totalColumns = [
      "Expense_Amount",
      "Balance_Quantity",
      "Bought_Quantity",
      "Sold_Quantity",
    ];

    rows.push(
      calculateTotalsAndAppendSummaryRowPdf(
        rows,
        selectedOptionsForPdf,
        totalColumns
      )
    );

    // Draw the table with the updated rows
    autoTable(doc, {
      head: [tableColumn],
      body: rows,
      startY: 100,
      didDrawPage: (data) => {
        Footer({
          doc: doc,
          data: data,
          userName: userName,
          totalPagesExp: totalPagesExp,
        });
      },
      ...tableStyles,
    });

    if (typeof doc.putTotalPages === "function") {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save("BookSummary.pdf");
  };

  const { data: getClassDropdownData, isLoading: getClassDropdownDataLoading } =
    useQuery({
      queryKey: "getClassess",
      queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
      onSuccess: (res) => {
        setState((prev) => ({ ...prev, classData: res?.data?.list }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });

  const sections = (data) => {
    return state?.classData?.filter((item) => item.classDocId === data)[0]
      ?.sections;
  };

  const handleEditRateDialog = (data) => {
    setDialogs((prev) => ({ ...prev, editRate: true, editRateData: data }));
  };
  return (
    <>
      <CustomLoader
        show={
          getBooksForSaleLoading ||
          getBooksForSaleFetching ||
          getClassDropdownDataLoading
        }
      />

      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Book Summary"
      />
      {/* Pdf Summary Dialog  */}
      <PdfSummaryPreviewDialog
        open={state.checkExportOptionsPdf}
        handleClose={() =>
          setState((prev) => ({ ...prev, checkExportOptionsPdf: false }))
        }
        data={headerPdf}
        setSelectedOptionsForPdf={setSelectedOptionsForPdf}
        selectedOptionsForPdf={selectedOptionsForPdf}
        handleExportPdf={handleExportPdf}
      />

      <UpdateBookRateDialog
        open={dialogs?.editRate}
        handleClose={() =>
          setDialogs((prev) => ({ ...prev, editRate: false, view: false }))
        }
        refetch={getBooksForSaleRefetch}
        data={dialogs?.editRateData}
      />

      {/* filter modal */}
      <CommonFilterDialog
        handleSaveAndView={() => {
          getBooksForSaleRefetch();
          handleCloseFilterDialog();
        }}
        open={modalOpen}
        onClose={handleCloseFilterDialog}
        title="Filter"
        minWidth="450px"
      >
        <DialogContent>
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.one}
                  onClick={() => {
                    setCheckboxes((prev) => ({ ...prev, one: !prev.one }));

                    checkboxes.one === true &&
                      setState((prev) => ({
                        ...prev,
                        selectedClass: "",
                        section: "",
                      }));
                  }}
                />
              }
              label="Class info"
            />
            {checkboxes.one && (
              <Stack direction="row" alignItems="center" spacing={2}>
                <CustomSelectBox
                  size="small"
                  fullWidth
                  MenuProps={MenuProps}
                  displayEmpty
                  value={state?.selectedClass}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      selectedClass: e.target.value,
                      sections: sections(e.target.value),
                      section: "",
                    }));
                  }}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {classes?.map((item) => (
                    <MenuItem value={item.classDocId}>{item.name}</MenuItem>
                  ))}
                </CustomSelectBox>

                <CustomSelectBox
                  size="small"
                  fullWidth
                  MenuProps={MenuProps}
                  displayEmpty
                  value={state?.section}
                  disabled={!state.selectedClass}
                  onChange={(e) =>
                    setState((prev) => ({ ...prev, section: e.target.value }))
                  }
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {state?.sections?.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </CustomSelectBox>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </CommonFilterDialog>

      {/* increase / decrease book dialog */}
      <IncreaseDecreaseDialog
        open={state?.bookQuantityDialog}
        handleClose={() => {
          setState((prev) => ({ ...prev, bookQuantityDialog: false }));
        }}
        refetch={getBooksForSaleRefetch}
        bookDocId={state.bookDocId}
      />

      {/* book account detail dialog */}

      <AddBookDialog
        open={state.addBookDialog}
        onClose={() => setState((prev) => ({ ...prev, addBookDialog: false }))}
        refetch={() => getBooksForSaleRefetch()}
        classData={getClassDropdownData?.data?.list}
      />
      {/* book detail dialog */}
      <Dialog
        open={dialogs?.view}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, view: false }));
        }}
        PaperProps={{
          sx: { minWidth: { xs: "90%", md: 500, lg: 600 } },
        }}
      >
        <DialogHeader
          title="Book Detail"
          handleClose={() => {
            setDialogs((prev) => ({ ...prev, view: false }));
          }}
        />
        <DialogContent>
          <TextFieldLabel title="Book Detail" />
          <GrayBox>
            <Grid container rowGap={2}>
              <Grid item xs={isMobile ? 5 : 4}>
                <TextFieldLabel
                  title="Book title"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <Typography
                  sx={{ fontWeight: 700, fontSize: isMobile && "12px" }}
                >
                  {state?.active?.bookTitle}
                </Typography>
              </Grid>
              <Grid item xs={isMobile ? 5 : 4}>
                <TextFieldLabel
                  title="Class-Stream-Sec"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <Typography
                  sx={{ fontWeight: 700, fontSize: isMobile && "12px" }}
                >
                  {state?.active?.className}-{state?.active?.stream}-
                  {state?.active?.section}
                </Typography>
              </Grid>
              <Grid item xs={isMobile ? 5 : 4}>
                <TextFieldLabel
                  title="Reciept Detail"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                {state?.active?.attachementUrl ? (
                  <a
                    href={state?.active?.attachementUrl}
                    style={{ color: "#1d8ba7" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      sx={{ textTransform: "lowercase" }}
                    >
                      View
                    </Button>
                  </a>
                ) : (
                  <Typography>--</Typography>
                )}
              </Grid>
              <Grid item xs={isMobile ? 4 : 3}>
                <TextFieldLabel
                  title="Bought Qty"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <Typography
                  sx={{ fontWeight: 700, fontSize: isMobile && "12px" }}
                >
                  {state?.active?.quantity}
                </Typography>
              </Grid>
              <Grid item xs={isMobile ? 5 : 3}>
                <TextFieldLabel
                  title="Buying Rate"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <Stack direction={"row"} gap={1}>
                  <Typography
                    sx={{ fontWeight: 700, fontSize: isMobile && "12px" }}
                  >
                    {IndianCurrency(state?.active?.buyingRate)}
                  </Typography>
                  <Box
                    onClick={() => handleEditRateDialog(state?.active)}
                    sx={{ cursor: "pointer" }}
                  >
                    <img
                      alt="edit"
                      src="/edit-icon.png"
                      style={{
                        height: "20px",
                        width: "20px",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={isMobile ? 5 : 3}>
                <TextFieldLabel
                  title="Sold Quantity"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <Typography
                  sx={{ fontWeight: 700, fontSize: isMobile && "12px" }}
                >
                  {state?.active?.soldQuantity
                    ? state?.active?.soldQuantity
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={isMobile ? 5 : 3}>
                <TextFieldLabel
                  title="Selling Rate"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <Stack direction={"row"} gap={1}>
                  <Typography
                    sx={{ fontWeight: 700, fontSize: isMobile && "12px" }}
                  >
                    {IndianCurrency(state?.active?.sellingRate)}
                  </Typography>
                  <Box
                    onClick={() => handleEditRateDialog(state?.active)}
                    sx={{ cursor: "pointer" }}
                  >
                    <img
                      alt="edit"
                      src="/edit-icon.png"
                      style={{
                        height: "20px",
                        width: "20px",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </GrayBox>
          <TextFieldLabel mt={2} title="Seller Detail" />
          <GrayBox>
            <Grid container rowGap={2}>
              <Grid item xs={isMobile ? 5 : 3}>
                <TextFieldLabel
                  title="Seller Name"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <Typography
                  sx={{ fontWeight: 700, fontSize: isMobile && "12px" }}
                >
                  {" "}
                  {state?.active?.bookSellerDetail?.firmName
                    ? state?.active?.bookSellerDetail?.firmName
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={isMobile ? 5 : 4}>
                <TextFieldLabel
                  title="Mobile Number"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <Typography
                  sx={{ fontWeight: 700, fontSize: isMobile && "12px" }}
                >
                  {state?.active?.bookSellerDetail?.mobileNumber
                    ? state?.active?.bookSellerDetail?.mobileNumber
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextFieldLabel
                  title="Alternate Mobile Number"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <Typography
                  sx={{ fontWeight: 700, fontSize: isMobile && "12px" }}
                >
                  {" "}
                  {state?.active?.bookSellerDetail?.alternateMobileNumber
                    ? state?.active?.bookSellerDetail?.alternateMobileNumber
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextFieldLabel
                  title="Address"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <Typography
                  sx={{ fontWeight: 700, fontSize: isMobile && "12px" }}
                >
                  {state?.active?.bookSellerDetail?.permanentAddress?.address},{" "}
                  {state?.active?.bookSellerDetail?.permanentAddress?.dist},{" "}
                  {state?.active?.bookSellerDetail?.permanentAddress?.state},
                  {state?.active?.bookSellerDetail?.permanentAddress?.state}`
                </Typography>
              </Grid>
            </Grid>
          </GrayBox>
        </DialogContent>
      </Dialog>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FilterButton
          count={
            Object.values(checkboxes).filter((item) => item === true).length
          }
          hanldeClick={() => setModalOpen(true)}
        />

        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Button
            onClick={() => {
              setState((prev) => ({ ...prev, addBookDialog: true }));
            }}
            variant="outlined"
            color="secondary"
            size="large"
            sx={{ fontSize: { xs: "0.9rem", md: "1.2rem" } }}
          >
            + Add Books
          </Button>
          <Box sx={{ mt: "-10px" }}>
            <ExportButtonGroup
              handleClickExcel={() => setOpenCheckBox(true)}
              showPdf={true}
              handleClickPdf={() =>
                setState((prev) => ({
                  ...prev,
                  checkExportOptionsPdf: true,
                }))
              }
            />
          </Box>
        </Stack>
      </Stack>
      {/* <Stack
        direction="row"
        alignItems="center"
        spacing={4}
        marginTop={2}
        marginBottom={1}
      >
        <CardWrapper
          sx={{
            background: "rgba(89, 172, 24, 0.05)",
            border: "1px solid rgba(89, 172, 24, 0.2)",
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <ImgWrapper>
              <img
                src="/ledger_report_img2.png"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                alt="ekalsutra-ledger-report"
              />
            </ImgWrapper>
            <CardTitle>&#8377;</CardTitle>
          </Stack>
          <CardSubTitle>Total Sold</CardSubTitle>
        </CardWrapper>
        <CardWrapper
          sx={{
            background: "rgba(255, 236, 236, 0.7)",
            border: " 1px solid #F4C2C2",
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <ImgWrapper>
              <img
                src="/ledger_report_img3.png"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                alt="ekalsutra-ledger-report"
              />
            </ImgWrapper>
            <CardTitle>&#8377;</CardTitle>
          </Stack>
          <CardSubTitle>Total Bought</CardSubTitle>
        </CardWrapper>
        <CardWrapper
          sx={{
            background: "rgba(244, 242, 255, 0.7)",
            border: "1px solid #D3CCF9",
          }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <ImgWrapper>
              <img
                src="/ledger_report_img1.png"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                alt="ekalsutra-ledger-report"
              />
            </ImgWrapper>
            <CardTitle>&#8377;</CardTitle>
          </Stack>
          <CardSubTitle>Total Profit</CardSubTitle>
        </CardWrapper>
      </Stack> */}
      <Stack mt={3}>
        {/* <BooksTable columns={columns} data={tableData || []} /> */}
        <CommonTable1 columns={columns} data={tableData} maxHeight="67vh" />
      </Stack>
    </>
  );
};

export default BookSummary;
const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 600,
  color: "#0C2F49",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  lineHeight: "1.4rem",
  fontWeight: 600,
  color: "#0C2F49",
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  width: "200px",
  backgroundColor: "#fff",
  color: "#333",
  borderRadius: "7px",
}));

const GrayBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#f8fbff",
  border: "1px solid rgba(61, 57, 137, 0.1)",
  boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.05)",
  borderRadius: "20px",
  padding: "10px",
}));

const BlueBox = styled(Box)(({ theme }) => ({
  background: "rgba(39, 137, 253, 0.05)",
  border: "1px solid #2789FD",
  borderRadius: "50px",
  padding: "10px",
}));
