import React, { useEffect, useState } from "react";
import CommonDialog from "../CommonDialog";
import { DialogActions, DialogContent } from "@mui/material";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { LoadingButton } from "@mui/lab";

const AddYoutubeVideoDialog = ({
  open = false,
  onClose = () => {},
  handleUpdate = () => {},
  loading = false,
  edit = "",
}) => {
  const [text, setText] = useState("");
  useEffect(() => {
    setText(edit);
  }, [edit]);
  return (
    <CommonDialog
      minWidth="500px"
      open={open}
      onClose={onClose}
      title="Youtube Video"
    >
      <DialogContent>
        <CustomTextField
          label="Youtube Video URL"
          value={text}
          onChange={(e) => setText(e.target.value)}
          fullWidth
          style={{ marginTop: "20px", placeContent: "center" }}
          inputMode="url"
          helperText="Provide Src URL of Youtube from Embed"
          placeholder="https://www.youtube.com/embed/..."
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          onClick={() => {
            handleUpdate(text);
            setText("");
          }}
          variant="contained"
          color="secondary"
          size="large"
          disabled={!String(text).trim()}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

export default AddYoutubeVideoDialog;
