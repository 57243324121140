import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let dashboard_url = `${SCHOOL_BASE}/dashboard`;
export const getStaffByName = (params) => {
  return schoolTokenAxios.get(`${dashboard_url}/getStaffByName`, {
    params: params,
  });
};

export const pushBirthDayMessage = (payload) => {
  return schoolTokenAxios.put(`${dashboard_url}/pushBirthDayMessage`, payload);
};
