import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import CustomLoader from "app/components/common/CustomLoader";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";

import { addFeeType, deleteFeeType, getFeeType } from "app/services/management";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";

import { toast } from "react-toastify";
import AddFeeTypeDialog from "./AddFeeTypeDialog";
import { Close } from "@mui/icons-material";
import { getClassDropdownWithOnlyClass } from "app/services/reception";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import DialogHeader from "app/components/common/DialogHeader";
import { LoadingButton } from "@mui/lab";
import ExportDialog from "app/components/common/exportDialog";

const FeeType = () => {
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const [openCheckBox, setOpenCheckBox] = useState(false);

  const heightRef = useRef(null);
  const [state, setState] = useState({
    dialog: false,
    selectedClassList: null,
    showClassList: [],
    anchor: null,
    body: {},
    deleteBody: {},
    deleteDialog: false,
  });
  const menuOpen = Boolean(state.anchor);
  const {
    isLoading: getFeeTypeLoading,
    isFetching: getFeeTypeFetching,
    data: getFeeTypeData,
    refetch: getFeeTypeRefetch,
  } = useQuery({
    queryKey: ["getFeeType", selectedSession],
    queryFn: () =>
      getFeeType({ isGroupedByClass: "Yes", session: selectedSession }),

    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const {
    isLoading: getClassDropdownWithOnlyClassLoading,
    data: getClassDropdownWithOnlyClassData,
  } = useQuery({
    queryKey: ["getClassInfo", selectedMedium],
    queryFn: () =>
      getClassDropdownWithOnlyClass({
        schoolDocId,
        medium: selectedMedium,
        onlyClassName: "Yes",
      }),
  });
  const { isLoading: deleteFeeTypeLoading, mutate: deleteFeeTypeMutate } =
    useMutation(deleteFeeType, {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        getFeeTypeRefetch();
        handleCloseDeleteDialog();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });
  const { isLoading: addFeeTypeLoading, mutate: addFeeTypeMutate } =
    useMutation(addFeeType, {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        getFeeTypeRefetch();
        handleCloseMenu();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });

  const columns = useMemo(
    () => [
      {
        Cell: ({ cell }) =>
          Number(cell.row.id) + 1 < 10
            ? `0${Number(cell.row.id) + 1}`
            : Number(cell.row.id) + 1,
        header: "Sr.No",
        maxSize: 20,
      },
      {
        accessorKey: "feeType",
        accessorFn: (row) => row?.feeType,
        header: "Fee Type Name",
        maxSize: 30,
      },
      {
        accessorKey: "classList",
        accessorFn: (row) => row?.classList?.map((item) => item).join(", "),
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <>
            <Stack direction={"row"} gap={0.5} flexWrap={"wrap"}>
              {original?.classList?.map((item, i) => (
                <Typography
                  sx={{
                    border: "1px solid rgba(27, 50, 60, 0.05)",
                    backgroundColor: "rgba(43, 81, 177, 0.05)",
                    borderRadius: "5px",
                    px: 0.6,
                    py: 0.3,
                    color: "#000",
                    fontSize: { xs: "10px", md: "13px" },
                    fontWeight: 500,
                  }}
                  key={i}
                >
                  {item}{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        deleteBody: {
                          feeType: original?.feeType,
                          session: selectedSession,
                          classList: [item],
                        },
                        deleteDialog: true,
                      }))
                    }
                  >
                    <Close color="error" />
                  </span>
                </Typography>
              ))}
            </Stack>
            {!original?.classList?.includes("allClass") && (
              <Typography
                onClick={(e) =>
                  setState((prev) => ({
                    ...prev,
                    anchor: e.currentTarget,
                    selectedClassList: original?.classList,
                    body: {
                      feeType: original.feeType,
                      session: selectedSession,
                      studentAdmissionType: original.studentAdmissionType,
                      feeOccurrence: original.feeOccurrence,
                    },
                  }))
                }
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  cursor: "pointer",
                  textDecoration: "underline",
                  mt: 0.5,
                }}
              >
                + Add Class
              </Typography>
            )}
          </>
        ),
        header: "Class List",
        maxSize: 80,
      },
      {
        accessorKey: "studentAdmissionType",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.studentAdmissionType === "newStudent"
              ? "New Student"
              : original?.studentAdmissionType === "allStudent"
              ? "All Student"
              : "Old Student"}
          </Typography>
        ),
        header: "Admission Type",
        maxSize: 20,
      },
      {
        accessorKey: "feeOccurrence",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.feeOccurrence === "oneTime" ? "One Time" : "Recurring"}
          </Typography>
        ),
        header: "Occurrence Type",
        maxSize: 20,
      },
      {
        accessorKey: "createdBy.personName",
        accessorFn: (row) => row?.createdBy?.personName,
        header: "Created By",
        maxSize: 30,
      },
    ],
    []
  );
  const handleCloseMenu = () => {
    setState((prev) => ({
      ...prev,
      selectedClassList: null,
      anchor: null,
      body: {},
      showClassList: [],
    }));
  };
  const handleClickMenu = (value) => {
    addFeeTypeMutate({ ...state.body, className: value });
  };
  const handleCloseDeleteDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteDialog: false,
      deleteBody: {},
    }));
  };
  useEffect(() => {
    if (state.selectedClassList) {
      const finalList = [];
      getClassDropdownWithOnlyClassData?.data?.list?.forEach((item) => {
        if (!state.selectedClassList.includes(item)) {
          finalList.push(item);
        }
      });
      setState((prev) => ({ ...prev, showClassList: finalList }));
    }
  }, [state.selectedClassList]);
  let header = [
    "#",
    "Fee_Type",
    "Fee_Occurrence",
    "ClassList",
    "Created_By",
    "Student_Admission_Type",
  ];

  let exportArray = getFeeTypeData?.data?.list.map((item, index) => {
    return {
      "#": index < 9 ? `0${index + 1}` : index + 1,
      Fee_Type: item?.feeType ?? "--",
      Fee_Occurrence: item?.feeOccurrence ?? "--",
      ClassList: item?.classList?.map((list) => list).join(",") ?? "--",
      Created_By: item?.createdBy?.personName ?? "--",
      Student_Admission_Type: item?.studentAdmissionType ?? "--",
    };
  });

  return (
    <>
      <Helmet>
        <title>Fee Type</title>
      </Helmet>
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Fee_Type"
      />
      <Menu
        open={menuOpen}
        anchorEl={state.anchor}
        onClose={handleCloseMenu}
        slotProps={{
          paper: { sx: { maxHeight: "300px" } },
        }}
      >
        {state.showClassList?.map((item, i) => (
          <>
            <MenuItem
              onClick={() => handleClickMenu(item)}
              sx={{
                width: "150px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              key={i}
              divider
            >
              <Typography>{item}</Typography>
              <Typography
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  cursor: "pointer",
                }}
              >
                + Add
              </Typography>
            </MenuItem>
          </>
        ))}
      </Menu>
      <CustomLoader
        show={
          getFeeTypeLoading ||
          getFeeTypeFetching ||
          addFeeTypeLoading ||
          getClassDropdownWithOnlyClassLoading
        }
      />
      {/* delete fee type dialog */}
      <Dialog
        open={state.deleteDialog}
        onClose={handleCloseDeleteDialog}
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "400px" } },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Delete Fee Type"
            handleClose={handleCloseDeleteDialog}
          />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }} />
          <Typography
            sx={{ fontWeight: 600, fontSize: "16px", textAlign: "center" }}
          >
            Are You Sure Want to Delete.
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            <span style={{ fontStyle: "italic" }}>
              {`"${state.deleteBody?.feeType}" `}
            </span>
            from {state.deleteBody?.classList && state.deleteBody?.classList[0]}{" "}
            Class
          </Typography>
          <Stack direction={"row"} gap={2} py={2}>
            <Button
              variant="outlined"
              size="large"
              sx={{ width: "100%" }}
              onClick={handleCloseDeleteDialog}
            >
              No
            </Button>
            <LoadingButton
              loading={deleteFeeTypeLoading}
              variant="contained"
              color="secondary"
              size="large"
              sx={{ width: "100%" }}
              onClick={() => deleteFeeTypeMutate(state.deleteBody)}
            >
              Yes
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
      {/* add new fee type */}
      <AddFeeTypeDialog
        open={state.dialog}
        handleClose={() => setState((prev) => ({ ...prev, dialog: false }))}
        refetch={() => getFeeTypeRefetch()}
        getFeeTypeData={getFeeTypeData}
      />
      {/* first render content */}
      <StyledWrapper>
        {/* button or export section */}
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          gap={2}
          alignItems={"center"}
        >
          <Button
            onClick={() => setState((prev) => ({ ...prev, dialog: true }))}
            size="large"
            variant="outlined"
            color="secondary"
            sx={{
              background: "#fff",
              borderRadius: "10px",
              textTransform: "capitalize",
              py: 1.3,
              px: 4,
            }}
          >
            + Add New
          </Button>
          <ExportButtonGroup
            handleClickExcel={() => setOpenCheckBox(true)}
            showPdf={false}
          />
        </Stack>
        <Box sx={{ height: "95%", mt: 1 }} ref={heightRef}>
          <CommonTable1
            columns={columns}
            data={getFeeTypeData?.data?.list}
            maxHeight={`${heightRef?.current?.clientHeight - 50}px`}
          />
        </Box>
      </StyledWrapper>
    </>
  );
};

export default FeeType;
const StyledWrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
