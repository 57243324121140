import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import PersonalInfo from "./components/PersonalInfo";
import AddressInfo from "./components/AddressInfo";
import PrevEdInfo from "./components/PrevEdInfo";
import { getDetailRequest, rejectJoiningRequest } from "app/services/hr";
import { useQuery } from "react-query";
import { LoadingButton } from "@mui/lab";
import EmploymentModal from "./components/EmploymentModal";
import { getAllDepartments } from "../../../../services/management";
import { toast } from "react-toastify";
import { queryClient } from "App";
import DialogHeader from "app/components/common/DialogHeader";
import dayjs from "dayjs";
import { ArrowForwardIos } from "@mui/icons-material";
import ParentInfo from "./components/ParentInfo";
import moment from "moment/moment";
import { getDuration } from "app/utils/helper";
const NameComponent = ({ label, text, flex = 1 }) => {
  return (
    <Box sx={{ flex }}>
      <Typography fontSize={14} color="primary" sx={{ opacity: 0.75 }}>
        {label}
      </Typography>
      <Typography fontSize={16} fontWeight={600} color="primary">
        {text && text !== "undefined" && text !== undefined ? text : "--"}
      </Typography>
    </Box>
  );
};
export default function JoiningDetailModal({
  open,
  setOpen,
  requestedOn,
  requestedFor,
  requestDocId,
  status,
}) {
  const [expanded, setExpanded] = useState("personal_info");

  const [isEmploymentModalVisible, setIsEmploymentModalVisible] =
    useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { isLoading: rejecting, refetch: rejectRefetch } = useQuery({
    queryKey: ["rejectJoiningRequest"],
    queryFn: () => rejectJoiningRequest(requestDocId),
    enabled: false,
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      queryClient.invalidateQueries({ queryKey: ["getAllJoiningRequest"] });
      setOpen(false);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    isLoading: departmentListLoading,
    data: departmentList,
    refetch: fetchDepartment,
  } = useQuery({
    queryKey: ["getAllDepartments"],
    queryFn: () => getAllDepartments(),
    enabled: false,
    onSuccess: (data) => {},
    onError: (err) => {
      toast.error(err?.response?.data?.message, "", 3000);
    },
  });
  const {
    isLoading: getDetailRequestLoading,
    isFetching: getDetailRequestFetching,
    data: getDetailRequestData,
  } = useQuery({
    queryKey: ["getDetailRequest"],
    queryFn: () => getDetailRequest(requestDocId),
    onSuccess: ({ data }) => {
      toast.success(data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: open && requestDocId ? true : false,
  });

  return (
    <>
      <EmploymentModal
        open={isEmploymentModalVisible}
        setOpen={setIsEmploymentModalVisible}
        requestedOn={requestedOn}
        requestedFor={requestedFor}
        requestDocId={requestDocId}
        departmentList={departmentList}
        setParentOpen={setOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { minWidth: "880px" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader title="Staff Information" handleClose={handleClose} />
        </DialogTitle>
        {getDetailRequestLoading || getDetailRequestFetching ? (
          <Stack direction="row" justifyContent="center" sx={{ py: 2 }}>
            <CircularProgress size={35} />
          </Stack>
        ) : (
          <DialogContent>
            <Stack direction="row" alignItems="center" gap={2} sx={{ py: 2 }}>
              <Box>
                <img
                  alt="ekalsutra-staff"
                  src={getDetailRequestData?.data?.obj?.profilePic}
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "10px",
                  }}
                />
              </Box>

              <NameComponent
                label={"Requested On"}
                text={dayjs(new Date(requestedOn)).format("DD MMM, YYYY")}
                flex={0.3}
              />
              <NameComponent
                label={"Requested For"}
                text={requestedFor}
                flex={0.5}
              />
            </Stack>
            {/* personal info */}
            <Accordion
              expanded={expanded === "personal_info"}
              onChange={handleChange("personal_info")}
              className="customaccordian_style"
            >
              <AccordionSummary
                expandIcon={
                  <ArrowForwardIos
                    sx={{
                      transform:
                        expanded === "personal_info"
                          ? "rotate(270deg) !important"
                          : "rotate(0deg) !important",
                    }}
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className="accordian_title">
                  Personal Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordiantDetail_border">
                <PersonalInfo data={getDetailRequestData?.data?.obj} />
              </AccordionDetails>
            </Accordion>
            {/* parent info */}
            {/* <Accordion
              expanded={expanded === "parents_info"}
              onChange={handleChange("parents_info")}
              className="customaccordian_style"
            >
              <AccordionSummary
                expandIcon={
                  <ArrowForwardIos
                    sx={{
                      transform:
                        expanded === "parents_info"
                          ? "rotate(270deg) !important"
                          : "rotate(0deg) !important",
                    }}
                  />
                }
                id="panel1bh-header"
              >
                <Typography className="accordian_title">
                  Parent`s Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordiantDetail_border">
                <ParentInfo data={getDetailRequestData?.data?.obj} />
              </AccordionDetails>
            </Accordion> */}
            {/* address info */}
            <Accordion
              expanded={expanded === "address_info"}
              onChange={handleChange("address_info")}
              className="customaccordian_style"
            >
              <AccordionSummary
                expandIcon={
                  <ArrowForwardIos
                    sx={{
                      transform:
                        expanded === "address_info"
                          ? "rotate(270deg) !important"
                          : "rotate(0deg) !important",
                    }}
                  />
                }
                id="panel1bh-header"
              >
                <Typography className="accordian_title">
                  Address Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordiantDetail_border">
                <AddressInfo
                  data={getDetailRequestData?.data?.obj?.currentAddress}
                />
              </AccordionDetails>
            </Accordion>
            {/* education info */}
            <Accordion
              expanded={expanded === "previous_education_info"}
              onChange={handleChange("previous_education_info")}
              className="customaccordian_style"
            >
              <AccordionSummary
                expandIcon={
                  <ArrowForwardIos
                    sx={{
                      transform:
                        expanded === "previous_education_info"
                          ? "rotate(270deg) !important"
                          : "rotate(0deg) !important",
                    }}
                  />
                }
                id="panel1bh-header"
              >
                <Typography className="accordian_title">
                  Previous Education Detail
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordiantDetail_border">
                <PrevEdInfo
                  data={getDetailRequestData?.data?.obj?.educationInfo}
                />
              </AccordionDetails>
            </Accordion>
            {/* experiences info */}
            <Accordion
              expanded={expanded === "previous_experiences_info"}
              onChange={handleChange("previous_experiences_info")}
              className="customaccordian_style"
            >
              <AccordionSummary
                expandIcon={
                  <ArrowForwardIos
                    sx={{
                      transform:
                        expanded === "previous_experiences_info"
                          ? "rotate(270deg) !important"
                          : "rotate(0deg) !important",
                    }}
                  />
                }
                id="panel1bh-header"
              >
                <Typography className="accordian_title">
                  Previous Experiences Detail
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordiantDetail_border">
                <Grid container spacing={1}>
                  {[
                    "Employer's Name",
                    "Designation",
                    "From",
                    "To",
                    "Duration",
                    "Attachment",
                  ].map((item, i) => (
                    <Grid item xs={2} key={i}>
                      <Typography
                        fontSize={14}
                        color="primary"
                        sx={{ opacity: 0.75 }}
                      >
                        {item}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid container item columnSpacing={1} rowSpacing={1.5}>
                  {getDetailRequestData?.data?.obj?.experiences?.map(
                    (exp, i) => (
                      <>
                        <Grid item xs={2} key={i}>
                          <Typography
                            fontSize={16}
                            fontWeight={600}
                            color="primary"
                          >
                            {exp.employerName ?? "--"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} key={i}>
                          <Typography
                            fontSize={16}
                            fontWeight={600}
                            color="primary"
                          >
                            {exp.designation ?? "--"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} key={i}>
                          <Typography
                            fontSize={16}
                            fontWeight={600}
                            color="primary"
                          >
                            {exp.joiningDate
                              ? moment(new Date(exp.joiningDate)).format(
                                  "DD MMM, YYYY"
                                )
                              : "--"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} key={i}>
                          <Typography
                            fontSize={16}
                            fontWeight={600}
                            color="primary"
                          >
                            {exp.separationDate
                              ? moment(new Date(exp.separationDate)).format(
                                  "DD MMM, YYYY"
                                )
                              : "--"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} key={i}>
                          <Typography
                            fontSize={16}
                            fontWeight={600}
                            color="primary"
                          >
                            {getDuration(exp.separationDate, exp.joiningDate)}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} key={i}>
                          {exp?.experienceCertificate && (
                            <a
                              href={exp?.experienceCertificate}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              view
                            </a>
                          )}
                        </Grid>
                      </>
                    )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
        )}
        {status === "REQUESTED" && (
          <DialogActions>
            <LoadingButton
              variant="outlined"
              color="secondary"
              loading={rejecting}
              size="large"
              onClick={() => rejectRefetch()}
            >
              Reject
            </LoadingButton>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                fetchDepartment();
                setIsEmploymentModalVisible(true);
              }}
            >
              Admit
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
