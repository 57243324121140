import { createSlice, current } from "@reduxjs/toolkit";
import { getExtraFeeStructureDropdown } from "./thunk";

const initialState = {
  totalSum: 0,
  totalFine: 0,
  list: [],
  extraFeeDropDown: [],
  isLoading: false,
  successMessage: "",
  error: "",
  isError: false,
};

export const feeCollectionSlice = createSlice({
  name: "feeCollection",
  initialState: initialState,
  reducers: {
    addToPay(state, action) {
      let feeList = state.list;
      feeList = [...feeList, action.payload];
      state.list = feeList;
    },

    removeFromPay(state, action) {
      let newList = current(state.list);
      let final_fee_lsit = newList.filter(
        (item) => item.monthName !== action.payload
      );
      state.list = final_fee_lsit;
    },

    updateTotalSumAndFine(state, action) {
      state.totalSum += action.payload;
      // state.totalFine = action.payload.totalFine;
    },

    resetFeeCollectionModalState(state) {
      state.list = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getExtraFeeStructureDropdown.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExtraFeeStructureDropdown.fulfilled, (state, action) => {
        state.extraFeeDropDown = action.payload;
        state.isLoading = false;
      })
      .addCase(getExtraFeeStructureDropdown.rejected, (state, action) => {
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export default feeCollectionSlice.reducer;
