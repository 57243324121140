import { Box, Divider, Stack, Typography } from "@mui/material";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import React from "react";
import { useSelector } from "react-redux";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";

const SchoolPrintHeader2 = () => {
  const selectedSchool = useSelector(selectSelectedSchool);

  return (
    <Stack
      direction={"row"}
      sx={{
        p: 1,
        flex: 1,
      }}
    >
      <Stack
        direction={"row"}
        gap={2}
        sx={{
          flex: 0.8,
        }}
      >
        <Box
          sx={{
            ml: 1,
            height: "65px",
            width: "65px",
          }}
        >
          <img
            src={selectedSchool?.schoolLogo}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            alt="school-logo"
          />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {selectedSchool?.schoolName}
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "11px",
            }}
          >
            {selectedSchool?.schoolAddress?.address}
            <br />
            {selectedSchool?.schoolAddress?.dist},{" "}
            {selectedSchool?.schoolAddress?.state},{" "}
            {selectedSchool?.schoolAddress?.pinCode}
            <br />
            UDISE Code: {selectedSchool?.udiseCode}
          </Typography>
        </Box>
      </Stack>
      <Divider
        orientation="vertical"
        sx={{ backgroundColor: "#000000" }}
        flexItem
      />
      <Divider
        orientation="vertical"
        sx={{ backgroundColor: "#000000" }}
        flexItem
      />
      <Stack direction={"column"} sx={{ flex: 0.2 }}>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "8px",
          }}
        >
          Affiliation No.
        </Typography>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "10px",
          }}
        >
          {selectedSchool?.affiliationNumber}
        </Typography>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <CallIcon
            sx={{
              fontSize: "10px",
            }}
          />
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "10px",
            }}
          >
            {selectedSchool?.schoolContactNumber}
          </Typography>
        </Stack>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <EmailIcon
            sx={{
              fontSize: "10px",
            }}
          />
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "10px",
            }}
          >
            {selectedSchool?.email}
          </Typography>
        </Stack>
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <LanguageIcon
            sx={{
              fontSize: "10px",
            }}
          />
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "10px",
            }}
          >
            {selectedSchool?.website}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SchoolPrintHeader2;
