import React, { useState } from "react";
import CommonDialog from "../CommonDialog";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  DialogActions,
  DialogContent,
  MenuItem,
  Stack,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  CustomSelectBox,
  CustomTextField,
  TabsWrapper,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { useMutation, useQuery } from "react-query";

import { toast } from "react-toastify";
import {
  addExpenseHeadLogic,
  addExpenseSubHead,
  getAllExpenseHead,
} from "app/services/ledgerAccount";
import CustomLoader from "../../CustomLoader";

const AddExpenseHeadDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
}) => {
  const [value, setValue] = useState("1");
  const [state, setState] = useState({
    eHeads: null,
  });
  const addEHeadformik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Head name is required"),
    }),
    onSubmit: (values) => {
      createExpenseHead({ expenseHeadName: values.name });
    },
  });

  const addESubHeadformik = useFormik({
    initialValues: {
      name: "",
      sName: "",
      desc: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Head name is required"),
      sName: yup.string().required("Sub Head name is required"),
      desc: yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      const obj = {
        expenseHeadDocId: values.name,
        expenseSubHeadName: values.sName,
        description: values.desc,
      };
      addExpenseSubHeadMutate(obj);
    },
  });

  const handleClose = () => {
    onClose();
    addESubHeadformik.resetForm();
    addEHeadformik.resetForm();
  };
  const { mutate: createExpenseHead, isLoading: creatingExpenseHead } =
    useMutation(addExpenseHeadLogic, {
      onSuccess: (res) => {
        refetch();
        toast.success(res?.data?.message);
        handleClose();
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  const {
    mutate: addExpenseSubHeadMutate,
    isLoading: addExpenseSubHeadLoading,
  } = useMutation(addExpenseSubHead, {
    onSuccess: (res) => {
      refetch();
      toast.success(res?.data?.message);
      handleClose();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const { isLoading: getAllExpenseHeadLoading } = useQuery({
    queryKey: ["getAllExpenseHead"],
    queryFn: () => getAllExpenseHead(),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        eHeads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.expenseHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: open ? true : false,
  });
  return (
    <>
      <CustomLoader show={getAllExpenseHeadLoading} />
      <CommonDialog
        open={open}
        onClose={handleClose}
        title="Add Expense Head"
        PaperProps={{
          sx: { minWidth: { xs: "95%", md: 200, lg: 300 } },
        }}
        minWidth="400px"
      >
        <DialogContent>
          <TabContext value={value}>
            <TabsWrapper>
              <TabList
                onChange={(e, newValue) => setValue(newValue)}
                aria-label="basic tabs example"
              >
                <Tab label="Expense Sub Head" value="1" />
                <Tab label="Expense Head" value="2" />
              </TabList>
            </TabsWrapper>
            <TabPanel value="1" sx={{ p: "0 !important" }}>
              <form id="add-e-sub" onSubmit={addESubHeadformik.handleSubmit}>
                <Stack mt={2}>
                  <Label>Expense Head Name</Label>
                  <CustomSelectBox
                    name="name"
                    onBlur={addESubHeadformik.handleBlur}
                    MenuProps={MenuProps}
                    displayEmpty
                    value={addESubHeadformik.values.name}
                    onChange={addESubHeadformik.handleChange}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {state?.eHeads?.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </CustomSelectBox>
                  {addESubHeadformik.touched.name &&
                    addESubHeadformik.errors.name && (
                      <Typography sx={{ color: "red" }}>
                        {addESubHeadformik.errors.name}
                      </Typography>
                    )}
                </Stack>
                <Stack mt={2}>
                  <Label>Sub Head Name</Label>
                  <CustomTextField
                    name="sName"
                    placeholder="Enter Sub Head Name"
                    onBlur={addESubHeadformik.handleBlur}
                    value={addESubHeadformik.values.sName}
                    onChange={addESubHeadformik.handleChange}
                  />
                  {addESubHeadformik.touched.sName &&
                    addESubHeadformik.errors.sName && (
                      <Typography sx={{ color: "red" }}>
                        {addESubHeadformik.errors.sName}
                      </Typography>
                    )}
                </Stack>
                <Stack mt={2}>
                  <Label>Description</Label>
                  <CustomTextField
                    name="desc"
                    placeholder="Enter Description"
                    onBlur={addESubHeadformik.handleBlur}
                    value={addESubHeadformik.values.desc}
                    onChange={addESubHeadformik.handleChange}
                  />
                  {addESubHeadformik.touched.desc &&
                    addESubHeadformik.errors.desc && (
                      <Typography sx={{ color: "red" }}>
                        {addESubHeadformik.errors.desc}
                      </Typography>
                    )}
                </Stack>
              </form>
            </TabPanel>
            <TabPanel value="2" sx={{ p: "0 !important" }}>
              <form id="add-eHead" onSubmit={addEHeadformik.handleSubmit}>
                <Stack mt={2}>
                  <Label>Expense Head Name</Label>
                  <CustomTextField
                    name="name"
                    placeholder="Enter Expense Head Name"
                    onBlur={addEHeadformik.handleBlur}
                    value={addEHeadformik.values.name}
                    onChange={addEHeadformik.handleChange}
                  />
                  {addEHeadformik.touched.name &&
                    addEHeadformik.errors.name && (
                      <Typography sx={{ color: "red" }}>
                        {addEHeadformik.errors.name}
                      </Typography>
                    )}
                </Stack>
              </form>
            </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={
              value === "2" ? creatingExpenseHead : addExpenseSubHeadLoading
            }
            type="submit"
            form={value === "2" ? "add-eHead" : "add-e-sub"}
            variant="contained"
            color="secondary"
            size="large"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddExpenseHeadDialog;
const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 600,
  color: "#0C2F49",
}));
