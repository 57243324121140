import { Button, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import CommonFilterDialog from "../../CommonFilterDialog";
import { FilterAlt } from "@mui/icons-material";

const SalaryTransactionFilterDilog = ({ handleSave = () => {} }) => {
  const [state, setState] = useState({
    dialog: false,
  });
  const [checkboxes, setCheckboxes] = useState({});
  const handleCloseDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleSaveAndView = () => {
    handleSave({});
    handleCloseDialog();
  };
  return (
    <div>
      <Button
        variant="contained"
        onClick={() => {
          setState((prev) => ({ ...prev, dialog: true }));
        }}
        color="secondary"
        sx={{
          width: "20rem",
          height: "4rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          borderRadius: "10px",
          background: "#0099ff",
          fontSize: "1.2rem",
        }}
      >
        Filter
        <FilterAlt />
        <Typography
          sx={{
            padding: "0 5px",
            background: "#fff",
            color: "black",
            borderRadius: "50%",
          }}
        >
          {Object.values(checkboxes).filter((item) => item === true).length}
        </Typography>
      </Button>
      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseDialog}
        minWidth="400px"
        title="Filter Salary Transaction "
        handleSaveAndView={handleSaveAndView}
      >
        <DialogContent></DialogContent>
      </CommonFilterDialog>
    </div>
  );
};

export default SalaryTransactionFilterDilog;
