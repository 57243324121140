import { Grid, Typography } from "@mui/material";

const NameComponent = ({ label, text }) => {
  return (
    <Grid item xs={4}>
      <Typography fontSize={12} color="primary" sx={{ opacity: 0.75 }}>
        {label}
      </Typography>
      <Typography fontSize={14} fontWeight={600} color="primary">
        {text && text !== "undefined" && text !== undefined
          ? String(text).replace(/undefined/g, "")
          : "--"}
      </Typography>
    </Grid>
  );
};
export default function ParentInfo({ data }) {
  return (
    <>
      <Grid container spacing={1}>
        <NameComponent
          label="Mother`s Name"
          text={`${data?.motherInfo?.firstName ?? ""} ${
            data?.motherInfo?.middleName ?? ""
          } ${data?.motherInfo?.lastName ?? ""}`}
        />

        <NameComponent
          label="Mobile No"
          text={data?.motherInfo?.mobileNumber}
        />

        <NameComponent label="Occupation" text={data?.motherInfo?.occupation} />
        <NameComponent label="Email" text={data?.motherInfo?.Email} />
      </Grid>
      <hr
        style={{
          width: "100%",
          backgroundColor: "#B6C8D6",
        }}
      />
      <Grid container spacing={1}>
        <NameComponent
          label="Father`s Name"
          text={`${data?.fatherInfo?.firstName ?? ""} ${
            data?.fatherInfo?.middleName ?? ""
          } ${data?.fatherInfo?.lastName ?? ""}`}
        />
        <NameComponent
          label="Mobile No"
          text={data?.fatherInfo?.mobileNumber}
        />
        <NameComponent label="Occupation" text={data?.fatherInfo?.occupation} />
        <NameComponent label="Email" text={data?.fatherInfo?.email} />
      </Grid>
      <hr
        style={{
          width: "100%",
          backgroundColor: "#B6C8D6",
        }}
      />
      <Grid container spacing={1}>
        <NameComponent
          label="Guardian`s Name"
          text={data?.guardianInfo?.firstName}
        />
        <NameComponent
          label="Mobile No"
          text={data?.guardianInfo?.mobileNumber}
        />
        <NameComponent
          label="Occupation"
          text={data?.guardianInfo?.occupation}
        />
        <NameComponent label="Email" text={data?.guardianInfo?.email} />
      </Grid>
    </>
  );
}
