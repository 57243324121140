import { Close } from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import CustomLoader from "app/components/common/CustomLoader";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import TableProfilePic from "app/components/common/TableProfilePic";
import ExportDialog from "app/components/common/exportDialog";
import { THEME } from "app/config/constants";
import appRoutes from "app/config/routes";
import { selectActiveSession } from "app/modules/schools/selectors";
import { getStudents1 } from "app/services/management";
import { FormatFullName } from "app/utils/helper";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

const PayrollDrawer = ({ open, handleClose, data, staffData }) => {
  const [openCheckBox, setOpenCheckBox] = useState(false);

  const columns = [
    {
      header: "Sr.No",
      Cell: ({ cell }) => (
        <Typography>
          {parseInt(cell?.row?.id) < 9
            ? `0${parseInt(cell?.row?.id) + 1}`
            : parseInt(cell?.row?.id) + 1}
        </Typography>
      ),
      size: 20,
    },

    {
      accessorKey: "stringDate",
      header: "Date",
      Cell: ({ cell }) => {
        const { original } = cell?.row;
        return <Typography>{original?.stringDate ?? "--"}</Typography>;
      },
      size: 20,
    },
    {
      accessorKey: "inTime",
      header: "First Punch",
      Cell: ({ cell }) => {
        const { original } = cell?.row;
        return <Typography>{original?.inTime ?? "--"}</Typography>;
      },
      size: 20,
    },
    {
      header: "Last Punch",
      accessorKey: "outTime",
      Cell: ({ cell }) => {
        const { original } = cell?.row;
        return <Typography>{original?.outTime ?? "--"}</Typography>;
      },
      size: 50,
    },
    {
      header: "Medium",
      Cell: ({ cell }) => {
        const { original } = cell?.row;
        return <Typography>{original?.medium ?? "--"}</Typography>;
      },
      size: 50,
    },
    {
      header: "Attendance",
      Cell: ({ row }) => (
        <Typography>
          {row?.original?.isHoliday ? (
            <Typography className="text_typo">Holiday</Typography>
          ) : row?.original?.onLeave === true ? (
            <Typography className="text_typo">On Leave</Typography>
          ) : row?.original?.onHalfDay ? (
            <Typography className="text_typo">Half Day</Typography>
          ) : row?.original?.attendance === "" ||
            row?.original?.attendance === "Absent" ? (
            <Typography className="text_typo">Absent</Typography>
          ) : row?.original?.attendance === "Present" ? (
            <Typography className="text_typo">Present</Typography>
          ) : (
            "--"
          )}
        </Typography>
      ),
      size: 20,
    },
  ];
  return (
    <>
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            maxWidth: "85%",
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            mt: 1,
            ml: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Attendance List
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <Close
              sx={{
                fontWeight: "bold",
                border: "1px solid",
                width: "20px",
                height: "20px",
              }}
            />
          </IconButton>
        </Stack>
        <Divider sx={{ backgroundColor: "#0C2F49" }} />
        <Stack
          sx={{
            m: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            Staff Information
          </Typography>
        </Stack>

        <DialogStyledWrapper>
          <Stack className="student_detail__boxWrap">
            <Grid container columns={24}>
              <Grid item xs={2}>
                {staffData?.staffDetail?.profilePic ? (
                  <img
                    alt=""
                    src={staffData?.staffDetail?.profilePic}
                    className="demo_image"
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <Box className="demo_image" />
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Staff Name
                </Typography>
                <Typography
                  sx={{
                    color: "0C2F49",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {FormatFullName(
                    staffData?.staffDetail?.firstName,
                    staffData?.staffDetail?.middleName,
                    staffData?.staffDetail?.lastName
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Employee iD
                </Typography>
                <Typography
                  sx={{
                    color: "0C2F49",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {staffData?.employeeId}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Mobile number
                </Typography>
                <Typography
                  sx={{
                    color: "0C2F49",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {staffData?.staffDetail?.mobileNumber ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Email ID
                </Typography>
                <Typography
                  sx={{
                    color: "0C2F49",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {staffData?.staffDetail?.email ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Pan number
                </Typography>
                <Typography
                  sx={{
                    color: "0C2F49",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {staffData?.staffDetail?.PAN ?? "--"}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </DialogStyledWrapper>
        <Stack
          direction={"row"}
          gap={2}
          sx={{
            m: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            Total Days: {data?.totalDays}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            Present: {data?.presentDays}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            Half Days:
            {data?.halfDays}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            Leave Days: {data?.leaveDays}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            Holidays: {data?.holidays}
          </Typography>

          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            LOP Days:
            {data?.lopDay}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            Absents:
            {data?.absentDays}
          </Typography>
        </Stack>
        <Wrapper>
          <Box sx={{ mt: 1 }}>
            <CommonTable1
              columns={columns}
              data={data?.attendanceList}
              maxHeight="60vh"
            />
          </Box>
        </Wrapper>
      </Drawer>
    </>
  );
};

export default PayrollDrawer;
const Wrapper = styled.div`
  padding: 10px;
`;
const DialogStyledWrapper = styled("div")`
  .student_detail__boxWrap {
    border-radius: 15px;
    border: 1px solid #d0d0e2;
    background: rgba(208, 208, 226, 0.08);
    padding: 10px;
    margin: 10px;
  }
  .demo_image {
    border-radius: 11px;
    background: #d9d9d9;
    height: 60px;
    width: 60px;
  }
`;
