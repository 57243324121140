import styled from "styled-components";
import { Eye } from "react-bootstrap-icons";
import { useMutation, useQuery } from "react-query";
import { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "react-notifications/lib/notifications.css";
import {
  selectActiveSession,
  selectedMediumSelector,
  selectSelectedSchoolId,
} from "app/modules/schools/selectors";
import { Helmet } from "react-helmet";
import { getAllClassDropdowns } from "app/services/management";
import { LoadingButton } from "@mui/lab";
import { Edit } from "@mui/icons-material";
import { getAlumni } from "app/services/student-management";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import CommonTable1 from "app/components/Tables/CommonTable1";
import { toast } from "react-toastify";
import { FormatFullName, MenuProps } from "app/utils/helper";
import appRoutes from "app/config/routes";
import { Link, useNavigate } from "react-router-dom";
import Preview from "./Preview";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import TableProfilePic from "app/components/common/TableProfilePic";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import ExportDialog from "app/components/common/exportDialog";
import TableActionButtons from "app/components/common/TableActionButtons";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import { deleteTransferCertificatePartially } from "app/services/schoolService/alumni.service";
import { queryClient } from "App";
export default function TransferCertificate() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [mainData, setMainData] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const session = useSelector(selectActiveSession);
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const [state, setState] = useState({
    previewDialog: false,
    id: "",
    classDocId: "",
    transferCertificateDocId: "",
    sectionList: [],
    section: "",
    deleteDialog: false,
  });
  //getting the schooldocid using selector
  const medium = useSelector(selectedMediumSelector);
  const navigate = useNavigate();
  const heightRef = useRef(null);
  const {
    isLoading: alumniLoading,
    isFetching: almunitFetching,
    refetch: fetchAlumni,
  } = useQuery({
    queryKey: ["getAlumni"],
    queryFn: () =>
      getAlumni({
        session,
        classDocId: state.classDocId,
        section: state.section,
      }),

    onSuccess: (data) => {
      setMainData(data?.data?.list);
      toast.success(data?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    mutate: deleteTransferCertificatePartiallyMutate,
    isLoading: deleteTransferCertificatePartiallyLoading,
  } = useMutation(deleteTransferCertificatePartially, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      handleCloseDelete();
      fetchAlumni();
      setState((prev) => ({
        ...prev,
        transferCertificateDocId: "",
        deleteDialog: false,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const handleViewAction = (cell) => {
    const {
      row: { original },
    } = cell;
    setState((prev) => ({ ...prev, previewDialog: true, id: original?._id }));
  };
  const handleEditAction = (cell) => {
    const {
      row: { original },
    } = cell;
    navigate(`/${appRoutes.editMigrate}/${original?._id}`);
  };
  //getting the all class dropdown
  useEffect(() => {
    const fetchClassData = async () => {
      const res = await getAllClassDropdowns(schoolDocId, session, medium);
      const data = res.data;
      setClassOptions(data?.list);
    };

    fetchClassData();
  }, [session, medium]);
  //logic for the disable section dropdown

  const [openCheckBox, setOpenCheckBox] = useState(false);
  let header = [
    "Reg_No",
    "Student_Name",
    "Gender",
    "Category",
    "Passout_Session",
    "TC_Number",
  ];
  let exportArray = mainData?.map((item) => {
    return {
      Reg_No: item?.studentProfile?.registrationNumber ?? "--",
      Student_Name:
        FormatFullName(
          item?.studentProfile?.firstName,
          item?.studentProfile?.middleName,
          item?.studentProfile?.lastName
        ) ?? "--",
      Gender: item?.studentProfile?.gender ?? "--",
      Category: item?.studentProfile?.category ?? "--",
      Passout_Session: item?.lastClassDetail?.session ?? "--",
      TC_Number: item?.tcNumber ?? "--",
    };
  });

  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <p
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {parseInt(cell.row.id) + 1 < 10
              ? `0${parseInt(cell.row.id) + 1}`
              : parseInt(cell.row.id) + 1}
          </p>
        ),
        size: 30,
      },
      {
        header: "Reg No",
        accessorKey: "studentProfile.registrationNumber",
        size: 60,
      },
      {
        header: "Student Name",
        accessorKey: "firstName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Link
              to={`/${appRoutes.student_profile}/${original?.studentDocId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Stack direction="row" alignItems="center" gap={0.6}>
                <TableProfilePic image={original?.studentProfile?.profilePic} />
                <Typography>
                  {FormatFullName(
                    original?.studentProfile?.firstName,
                    original?.studentProfile?.middleName,
                    original?.studentProfile?.lastName
                  )}
                </Typography>
              </Stack>
            </Link>
          );
        },
        size: 80,
      },
      {
        header: "Gender",
        accessorKey: "studentProfile.gender",
        size: 60,
      },
      {
        header: "Category",
        accessorKey: "studentProfile.category",
        size: 60,
      },
      {
        header: "Passout Session",
        accessorKey: "lastClassDetail.session",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <Typography>{original?.lastClassDetail?.session}</Typography>;
        },
        size: 60,
      },
      {
        header: "TC Number",
        accessorKey: "tcNumber",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <Typography>{original?.tcNumber}</Typography>;
        },
        size: 80,
      },
      {
        header: "Status",
        accessorKey: "status",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <Typography>{original?.status}</Typography>;
        },
        size: 80,
      },
      {
        header: "Actions",
        Cell: ({ cell }) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                gap: "5px",
              }}
            >
              <button
                onClick={() => {
                  handleViewAction(cell);
                }}
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "8px",
                  border: "none",
                  outline: "none",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              >
                <Eye />
              </button>

              <button
                onClick={() => {
                  handleEditAction(cell);
                }}
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "8px",
                  border: "none",
                  outline: "none",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              >
                <Edit />
              </button>

              <TableActionButtons
                showDelete={true}
                handleDelete={() =>
                  setState((prev) => ({
                    ...prev,
                    deleteDialog: true,
                    transferCertificateDocId: cell?.row?.original?._id,
                  }))
                }
              />
            </div>
          );
        },
        size: 60,
      },
    ];
  }, []);
  const handleCloseDelete = () => {
    setState((prev) => ({ ...prev, deleteDialog: false }));
  };

  const handledeleteArrear = () => {
    deleteTransferCertificatePartiallyMutate(state.transferCertificateDocId);
  };

  return (
    <>
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Alumni"
      />

      {/* Delete Dialog Confirmation */}
      <DeleteDialog
        open={state.deleteDialog}
        handleClose={handleCloseDelete}
        handleDelete={handledeleteArrear}
        loading={deleteTransferCertificatePartiallyLoading}
      />
      <Dialog
        open={state.previewDialog}
        PaperProps={{
          sx: { minWidth: isMobile ? "80vw" : "900px" },
        }}
        onClose={() => setState((prev) => ({ ...prev, previewDialog: false }))}
      >
        <Preview id={state.id} />
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            size="large"
            sx={{ textTransform: "capitalize" }}
            onClick={() =>
              setState((prev) => ({ ...prev, previewDialog: false }))
            }
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Helmet>
        <title>Alumni</title>
      </Helmet>
      <Container
        maxWidth="xl"
        disableGutters={isMobile ? true : false}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Stack
          direction="row"
          alignItems="flex-end"
          gap={1.5}
          sx={{ mb: 2 }}
          justifyContent={"space-between"}
        >
          <Stack direction="row" gap={1}>
            <Box sx={{ width: "15vw" }} flex={isMobile && 1}>
              <TextFieldLabel title="Class-Stream" />
              <CustomSelectBox
                displayEmpty
                fullWidth
                size="small"
                value={state.classDocId}
                MenuProps={MenuProps}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {classOptions &&
                  classOptions?.map((opt, i) => (
                    <MenuItem
                      key={opt?.classDocId}
                      value={opt?.classDocId}
                      onClick={(e) => {
                        setState((prev) => ({
                          ...prev,
                          classDocId: opt?.classDocId,
                          sectionList: opt?.sections,
                          section: "",
                        }));
                      }}
                    >
                      {opt?.className_stream}
                    </MenuItem>
                  ))}
              </CustomSelectBox>
            </Box>
            <Box sx={{ width: "10vw" }} flex={isMobile && 1}>
              <TextFieldLabel title="Section" />
              <CustomSelectBox
                displayEmpty
                fullWidth
                size="small"
                value={state.section}
                MenuProps={MenuProps}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, section: e.target.value }))
                }
                disabled={state.classDocId ? false : true}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {state.sectionList?.map((opt, i) => {
                  return (
                    <MenuItem key={i} value={opt}>
                      {opt}
                    </MenuItem>
                  );
                })}
              </CustomSelectBox>
            </Box>

            <LoadingButton
              flex={isMobile && 1}
              disabled={state.classDocId && state.section ? false : true}
              loading={alumniLoading || almunitFetching}
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => {
                fetchAlumni();
              }}
              sx={{ mb: 0.5, mt: 3 }}
            >
              View
            </LoadingButton>
          </Stack>
          <Stack
            direction="row"
            alignItems="flex-end
    "
            gap={2}
            sx={{ pt: 1 }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                mb: 0.5,
              }}
            >
              Total Student :{" "}
              <span style={{ fontSize: "18px" }}>{mainData?.length || 0}</span>
            </Typography>
            <Box>
              <ExportButtonGroup
                handleClickExcel={() => setOpenCheckBox(true)}
                showPdf={false}
              />
            </Box>
          </Stack>
        </Stack>

        <Box sx={{ height: "90%" }} ref={heightRef}>
          <CommonTable1
            columns={columns}
            data={mainData}
            maxHeight={isMobile ? "40vh" : "70vh"}
          />
        </Box>
      </Container>
    </>
  );
}
