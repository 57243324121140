import React, { useState } from "react";
import Box from "@mui/material/Box";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PreviewCertificate from "./PreviewCertificate";
import { Helmet } from "react-helmet";
import StudentDrawer from "./StudentDrawer";
import FeeCertificatePNG from "../../../../../assets/media/images/certificate-Images/FeeCertificate.png";
import TeacherCertificatePNG from "../../../../../assets/media/images/certificate-Images/Teacher Experience Certificate.png";
import CharacterCertificatePNG from "../../../../../assets/media/images/certificate-Images/Character Certificate.png";
import ParticipationCertificatePNG from "../../../../../assets/media/images/certificate-Images/Participation Certificate.png";
import SchoolLeavingCertificatePNG from "../../../../../assets/media/images/certificate-Images/School Leaving Certificate.png";
import DOBCertificatePNG from "../../../../../assets/media/images/certificate-Images/Date of Birth Certificate.png";
import StaffDrawer from "./StaffDrawer";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import Transition from "./components/utils/Transition";

const certificateNameList = [
  {
    _id: 1,
    imageURL: FeeCertificatePNG,
    name: "Fee Certificate",
  },
  {
    _id: 2,
    imageURL: CharacterCertificatePNG,
    name: "Character Certificate",
  },
  {
    _id: 3,
    imageURL: SchoolLeavingCertificatePNG,
    name: "School Leaving Certificate",
  },
  {
    _id: 4,
    imageURL: TeacherCertificatePNG,
    name: "Teacher Experience Certificate",
  },
  {
    _id: 5,
    imageURL: DOBCertificatePNG,
    name: "Birth Certificate",
  },
  {
    _id: 6,
    imageURL: ParticipationCertificatePNG,
    name: "Participation Certificate",
  },
  {
    _id: 7,
    imageURL: ParticipationCertificatePNG,
    name: "Bonafide Certificate",
  },
];

const Certificate = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [show, setShow] = useState(false);
  const [showStaff, setShowStaff] = useState(false);
  const [showParticipation, setShowParticipation] = useState(false);
  const [participationData, setParticipationData] = useState({
    eventName: "",
    eventDate: "",
    eventDetails: "",
  });
  const [state, setState] = useState({
    certificateNameList: certificateNameList,
    viewData: {},
    viewDialog: false,
    previewDialog: false,
    certificateName: "",
  });

  const handleProceed = () => {
    setState((prev) => ({
      ...prev,
      previewDialog: false,
    }));

    if (state.certificateName === "Teacher Experience Certificate") {
      setShowStaff(true);
    } else {
      if (state.certificateName === "Participation Certificate") {
        setShowParticipation(true);
      } else {
        setShow(true);
      }
    }
  };

  return (
    <>
      <StudentDrawer
        setShow={setShow}
        show={show}
        certificateName={state.certificateName}
        participationData={participationData}
      />
      {/* event dialog for proceding */}
      <Dialog
        open={showParticipation}
        TransitionComponent={Transition}
        onClose={() => setShowParticipation(false)}
        PaperProps={{
          sx: { minWidth: isMobile ? "90vw" : "50%" },
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextFieldLabel title="Event Name" />
              <CustomTextField
                name="eventName"
                fullWidth
                value={participationData.eventName}
                onChange={(e) =>
                  setParticipationData((prev) => ({
                    ...prev,
                    eventName: e.target.value,
                  }))
                }
                placeholder="Event Name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Event WorkShop Name" />
              <CustomTextField
                name="WorkShopName"
                fullWidth
                value={participationData?.eventDetails}
                onChange={(e) =>
                  setParticipationData((prev) => ({
                    ...prev,
                    eventDetails: e.target.value,
                  }))
                }
                placeholder="Event WorkShop Name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Event Date" />
              <CustomTextField
                name="eventDate"
                fullWidth
                type="date"
                value={participationData?.eventDate}
                onChange={(e) =>
                  setParticipationData((prev) => ({
                    ...prev,
                    eventDate: e.target.value,
                  }))
                }
                placeholder="Event Date"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            size="large"
            disabled={
              !participationData.eventName ||
              !participationData.eventDetails ||
              !participationData.eventDate
            }
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              setShowParticipation(false);
              setShow(true);
            }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <StaffDrawer
        setShowStaff={setShowStaff}
        showStaff={showStaff}
        certificateName={state.certificateName}
      />

      {/* view dialog */}
      <Dialog
        open={state.previewDialog}
        TransitionComponent={Transition}
        PaperProps={{
          sx: { minWidth: isMobile ? "90vw" : "90%" },
        }}
        onClose={() => setState((prev) => ({ ...prev, previewDialog: false }))}
      >
        <PreviewCertificate
          state={state}
          setState={setState}
          handleProceed={handleProceed}
          certificateName={state.certificateName}
        />
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            size="large"
            sx={{ textTransform: "capitalize" }}
            onClick={() =>
              setState((prev) => ({ ...prev, previewDialog: false }))
            }
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Helmet>
        <title>Certificate</title>
      </Helmet>
      {/* mapping the certificate name */}
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        sx={{ mt: 2, mb: 2, px: 2 }}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        {state.certificateNameList?.map((value, index) => (
          <ShowImage key={value?._id}>
            <img src={value?.imageURL} alt="ekalsutra-folder" />
            <Stack
              className="folder_image__box"
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <ViewButtonWrap
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    previewDialog: true,
                    certificateName: value?.name,
                  }))
                }
              >
                <RemoveRedEyeIcon sx={{ color: "#0B4977", fontSize: "25px" }} />
              </ViewButtonWrap>
            </Stack>
            <Typography variant="h6" sx={{ mt: 1, textAlign: "center" }}>
              {value?.name}
            </Typography>
          </ShowImage>
        ))}
      </Stack>
    </>
  );
};

export default Certificate;

const ShowImage = styled(Box)`
  width: 200px;
  height: 182px;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 0 10px 20px 8px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  .folder_image__box {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(8, 76, 132, 0.75);
    border-radius: 10px;
    display: none;
    transition: all 0.3s;
    z-index: 3;
  }
  :hover .folder_image__box {
    display: flex;
    transition: all 0.3s;
  }
`;

const ViewButtonWrap = styled(Box)`
  height: 36px;
  width: 36px;
  background: #ebf0f4;
  border-radius: 10.2857px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
