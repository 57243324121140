import Fallback from "app/components/Fallback";
import React from "react";
import NoticeBoard from "./pages/NoticeBoard";

const Notice = React.lazy(() =>
  import("app/modules/communication/pages/NoticeBoard")
);

const TextSMS = React.lazy(() =>
  import("app/modules/communication/pages/TextSMS")
);


export const NoticeBoardPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Notice {...props} />
  </React.Suspense>
);


export const TextSMSPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <TextSMS {...props} />
  </React.Suspense>
);