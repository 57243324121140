import {
  Button,
  Stack,
  Typography,
  colors,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { Eye } from "react-bootstrap-icons";
import "react-notifications/lib/notifications.css";
import { getAllJoiningRequest, getDetailRequest } from "app/services/hr";
import { useQuery } from "react-query";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";
import JoiningDetailModal from "./JoiningDetailModal";
import CommonTable1 from "app/components/Tables/CommonTable1";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";

import { toast } from "react-toastify";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import ExportDialog from "app/components/common/exportDialog";
import YoutubeVideoDialog from "app/components/common/Dialogs/YoutubeVideoDialog";
import { YouTube } from "@mui/icons-material";
import FinalCommonFilterDialog from "app/components/common/Dialogs/FinalCommonFilterDialog";

export default function JoinApproval() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [openYoutubeDialog, setOpenYoutubeDialog] = useState(false);

  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [requestedOn, setrequestedOn] = useState("");
  const [requestedFor, setrequestedFor] = useState("");
  const [requestDocId, setRequestDocId] = useState("");
  const [status, setStatus] = useState("");
  const isMounted = useRef(false);
  const [state, setState] = useState({
    dialog: false,
    filterData: false,
    fetch: false,
    filterExtraData: false,
    checkExportOptionsPdf: false,
  });

  const {
    isLoading: getAllJoiningRequestLoading,
    isFetching: getAllJoiningRequestFetching,
    data: joiningData,
    refetch: getAllJoiningRequestFetch,
  } = useQuery({
    queryKey: ["getAllJoiningRequest", state.fetch],
    queryFn: () => {
      if (state.filterData) {
        return getAllJoiningRequest({ ...state.filterData });
      } else {
        return getAllJoiningRequest();
      }
    },
    onSuccess: ({ data }) => {
      toast.success(data?.message);
    },
    onError: ({ response: { data } }) => {
      toast.success(data?.message);
    },
  });

  const handleViewAction = (cell) => {
    setStatus(cell?.row?.original?.status);
    setRequestDocId(cell?.row?.original?._id);
    setIsViewModalVisible(true);
    setrequestedFor(cell?.row?.original?.requestedFor);
    setrequestedOn(cell?.row?.original?.requestedOn);
  };
  useEffect(() => {
    isMounted.current = true;
  }, []);
  const [openCheckBox, setOpenCheckBox] = useState(false);
  let header = ["Enquiry_Person", "Requested_For", "Requested_On", "Status"];
  let exportArray = joiningData?.data?.list?.map((item) => {
    return {
      Enquiry_Person: item?.personName ?? "--",
      Requested_For: item?.requestedFor ?? "--",
      Requested_On: item?.requestedOn ?? "--",
      Status: item?.status ?? "--",
    };
  });

  const handleMutate = (payload) => {
    setState((prev) => ({ ...prev, filterData: payload, fetch: !prev.fetch }));
  };
  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <p
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {parseInt(cell.row.id) + 1}
          </p>
        ),

        size: 30,
      },
      {
        header: "Enquiry Person",
        accessorKey: "personName",
        size: 60,
      },
      {
        header: "Requested For",
        accessorKey: "requestedFor",
        size: 60,
      },
      {
        header: "Requested On",
        accessorKey: "requestedOn",
        id: "requestedOn",
        accessorfn: (row) => row?.requestedOn,
        Cell: ({ cell }) => {
          return format(new Date(cell.getValue()), "dd/MM/yyyy");
        },
        size: 60,
      },
      {
        header: "Status",
        accessorKey: "status",
        Cell: ({ cell, row }) => {
          return (
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: isMobile ? "10px" : "14px",
                color:
                  row?.original?.status === "APPROVED"
                    ? colors.green[600]
                    : row?.original?.status === "REQUESTED"
                    ? colors.orange[600]
                    : colors.red[600],
              }}
            >
              {row?.original?.status}
            </Typography>
          );
        },
        size: isMobile ? 80 : 60,
      },
      {
        header: "Actions",
        Cell: ({ cell }) => {
          return (
            <Button
              onClick={() => {
                handleViewAction(cell);
              }}
              style={{
                backgroundColor: "#EBF0F4",
                borderRadius: "8px",
                border: "none",
                outline: "none",
                width: "2.5rem",
                height: "2.5rem",
              }}
            >
              <Eye />
            </Button>
          );
        },
        size: isMobile ? 100 : 20,
      },
    ];
  }, []);
  const videoLink = [
    {
      link: "https://www.youtube.com/embed/fFpSqd0yi9g?si=qlA44eYvowr7T12J",
      title: "How to add Staff by Approving their requests",
    },
  ];
  return (
    <>
      <JoiningDetailModal
        open={isViewModalVisible}
        setOpen={setIsViewModalVisible}
        requestedFor={requestedFor}
        requestedOn={requestedOn}
        requestDocId={requestDocId}
        status={status}
      />

      <YoutubeVideoDialog
        open={openYoutubeDialog}
        handleCloseDialog={() => setOpenYoutubeDialog(false)}
        videoLink={videoLink}
      />
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Joining_Approval"
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "flex-end"}
          gap={1}
          sx={{ mb: 1 }}
          justifyContent={"space-between"}
        >
          <FinalCommonFilterDialog
            mutate={handleMutate}
            // extraData={handleExtraData}
            showJoiningApplicationDate={true}
            showJoiningApprovalStatus={true}
          />
          {/* <Stack direction="row" alignItems="flex-end" gap={1}>
            <Box sx={{ width: isMobile ? "100%" : "15vw" }}>
              <TextFieldLabel title="Application From" />
              <DesktopDatePicker
                inputFormat="DD MMM, YYYY"
                value={fromDate}
                onChange={(newValue) => {
                  setFromDate(newValue);
                }}
                renderInput={(params) => (
                  <CustomTextField {...params} fullWidth size="small" />
                )}
              />
            </Box>
            <Box sx={{ width: isMobile ? "100%" : "15vw" }}>
              <TextFieldLabel title="Application To" />
              <DesktopDatePicker
                inputFormat="DD MMM, YYYY"
                value={toDate}
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
                renderInput={(params) => (
                  <CustomTextField {...params} fullWidth size="small" />
                )}
              />
            </Box>
            <LoadingButton
              variant="contained"
              loading={
                getAllJoiningRequestLoading || getAllJoiningRequestFetching
              }
              color="secondary"
              size="large"
              sx={{ mb: 0.5, width: isMobile ? "100%" : "auto" }}
              onClick={() => getAllJoiningRequestFetch()}
            >
              View
            </LoadingButton>
          </Stack> */}

          <Stack direction="row" alignItems="flex-end" gap={2} sx={{ pt: 1 }}>
            <Box>
              <ExportButtonGroup
                handleClickExcel={() => setOpenCheckBox(true)}
                showPdf={false}
              />
            </Box>
            <YouTube
              sx={{
                color: "#FF0000",
                fontSize: "2.5rem",
                cursor: "pointer",
                mt: 2,
              }}
              onClick={() => setOpenYoutubeDialog(true)}
            />
          </Stack>
        </Stack>
      </LocalizationProvider>

      <CommonTable1
        columns={columns}
        data={joiningData?.data?.list}
        maxHeight={isMobile && "55vh"}
      />
    </>
  );
}
