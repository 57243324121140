import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { selectSelectedSchoolId } from "../schools/selectors";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  addNewsCalendar,
  deleteNewsCalendar,
  getNewsCalendar,
  updateNewsCalendar,
} from "app/services/schoolService/websiteBuilder.service";
import CustomLoader from "app/components/common/CustomLoader";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import TableActionButtons from "app/components/common/TableActionButtons";
import moment from "moment";
import DeleteConfirmationDialog from "app/components/common/Dialogs/DeleteConfirmationDialog";
import AddNewsCalendarDialog from "app/components/common/Dialogs/websiteBuilder/AddNewsCalendarDialog";

const NewsCalendar = () => {
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const [state, setState] = useState({
    addDialog: false,
    edit: false,
    editData: {},
    deleteDialog: false,
    deleteDocId: "",
  });
  const handleViewAction = (data) => {
    setState((prev) => ({
      ...prev,
      addDialog: true,
      edit: true,
      editData: data,
    }));
  };
  const handleDelete = (docId) => {
    setState((prev) => ({ ...prev, deleteDialog: true, deleteDocId: docId }));
  };
  const handleDeleteConfirmation = () => {
    deleteNewsCalendarMutate(state.deleteDocId);
  };
  const handleCloseDelete = () => {
    setState((prev) => ({ ...prev, deleteDialog: false, deleteDocId: "" }));
  };

  const {
    mutate: deleteNewsCalendarMutate,
    isLoading: deleteNewsCalendarLoading,
  } = useMutation({
    mutationKey: ["deleteNewsCalendar"],
    mutationFn: deleteNewsCalendar,
    onSuccess: (success) => {
      handleCloseDelete();
      toast.success(success?.data.message);
      getNewsCalendarRefetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const { mutate: addNewsCalendarMutate, isLoading: addNewsCalendarLoading } =
    useMutation({
      mutationKey: ["addNewsCalendar"],
      mutationFn: addNewsCalendar,
      onSuccess: (success) => {
        setState((prev) => ({
          ...prev,
          addDialog: false,
          editData: {},
          edit: false,
        }));
        toast.success(success?.data.message);
        getNewsCalendarRefetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });
  const {
    mutate: updateNewsCalendarMutate,
    isLoading: updateNewsCalendarLoading,
  } = useMutation({
    mutationKey: ["updateNewsCalendar"],
    mutationFn: updateNewsCalendar,
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        addDialog: false,
        editData: {},
        edit: false,
      }));
      toast.success(success?.data.message);
      getNewsCalendarRefetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    data: getNewsCalendarData,
    isLoading: getNewsCalendarLoading,
    isFetching: getNewsCalendarFetching,
    refetch: getNewsCalendarRefetch,
  } = useQuery({
    queryKey: ["getNewsCalendar"],
    queryFn: () =>
      getNewsCalendar({
        // schoolDocId: "6410053616d5253b29ce5ed9",
        schoolDocId: schoolDocId,
      }),
    onSuccess: (success) => {
      toast.success(success?.data.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const columns = [
    {
      header: "Sr.No",
      Cell: ({ cell }) => <Typography>{parseInt(cell.row.id) + 1}</Typography>,
    },
    {
      header: "Title",
      accessorKey: "title",
      Cell: ({ cell }) => {
        return (
          <>
            <Typography>{cell?.row?.original?.title}</Typography>
          </>
        );
      },
    },
    {
      header: "Description",
      accessorKey: "description",
      Cell: ({ cell }) => {
        return (
          <>
            <Typography>{cell?.row?.original?.description}</Typography>
          </>
        );
      },
    },
    {
      header: "isLink",
      accessorKey: "isLink",
      Cell: ({ cell }) => {
        return (
          <>
            <Typography>
              {(cell?.row?.original?.isLink ?? "--").toString()}
            </Typography>
          </>
        );
      },
    },
    {
      header: "Date",
      accessorKey: "date",
      Cell: ({ cell }) => {
        return (
          <>
            <Typography>
              {moment(cell?.row?.original?.date).format("DD-MM-YYYY")}
            </Typography>
          </>
        );
      },
    },
    {
      header: "Show",
      accessorKey: "show",
      Cell: ({ cell }) => {
        return (
          <>
            <Typography>{(cell?.row?.original?.show).toString()}</Typography>
          </>
        );
      },
    },
    {
      header: "Image",
      accessorKey: "image",
      Cell: ({ cell }) => {
        return (
          <>
            <img
              src={cell?.row?.original?.url}
              style={{ height: "100px", objectFit: "contain" }}
            />
          </>
        );
      },
    },
    {
      header: "Actions",
      Cell: ({ cell }) => {
        return (
          <TableActionButtons
            showEdit
            showDelete
            handleDelete={() =>
              setState((prev) => ({
                ...prev,
                deleteDialog: true,
                deleteDocId: cell?.row?.original?._id,
              }))
            }
            handleEdit={() => handleViewAction(cell?.row?.original)}
          />
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>News Calendar</title>
      </Helmet>
      <CustomLoader
        show={
          getNewsCalendarLoading ||
          getNewsCalendarFetching ||
          deleteNewsCalendarLoading ||
          addNewsCalendarLoading ||
          updateNewsCalendarLoading
        }
      />
      <DeleteConfirmationDialog
        open={state.deleteDialog}
        onClose={handleCloseDelete}
        handleDelete={handleDeleteConfirmation}
      />
      <AddNewsCalendarDialog
        open={state.addDialog}
        loading={addNewsCalendarLoading}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            addDialog: false,
            editData: {},
            edit: false,
          }))
        }
        refetch={() => getNewsCalendarRefetch()}
        edit={state.edit}
        editData={state.editData}
        addNewsCalendarMutate={addNewsCalendarMutate}
        updateNewsCalendarMutate={updateNewsCalendarMutate}
      />

      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Add />}
              size={"large"}
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                backgroundColor: "#fff",
                borderRadius: "10px",
              }}
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  addDialog: true,
                  edit: false,
                  editData: {},
                }))
              }
            >
              Add New
            </Button>
          </Grid>
        </Grid>
        <Box mt={2}>
          <CommonTable1
            columns={columns}
            data={getNewsCalendarData?.data?.list}
            maxHeight="69vh"
          />
        </Box>
      </Container>
    </>
  );
};

export default NewsCalendar;
