import styled from "styled-components";

import { Grid, Typography } from "@mui/material";
import TextFieldLabel from "app/components/common/TextFieldLabel";
export default function PrevEdInfo({ data }) {
  return (
    <MainWrapper>
      <Grid container columnSpacing={1}>
        <Grid item xs={4}>
          <TextFieldLabel title="Description" />
        </Grid>
        <Grid item xs={3}>
          <TextFieldLabel title="Board/University" />
        </Grid>
        <Grid item xs={2}>
          <TextFieldLabel title="Percentage" />
        </Grid>
        <Grid item xs={1}>
          <TextFieldLabel title="Grades" />
        </Grid>
        <Grid item xs={2}>
          <TextFieldLabel title="Passing Year" />
        </Grid>
      </Grid>
      {data?.map((item, i) => (
        <Grid container columnSpacing={1} key={i}>
          <Grid item xs={4}>
            <Typography
              sx={{
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
                fontSize: "14px",
              }}
            >
              {item?.description}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
                fontSize: "14px",
              }}
            >
              {item?.instituteName}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
                fontSize: "14px",
              }}
            >
              {item?.percentage}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
                fontSize: "14px",
              }}
            >
              {item?.grades}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
                fontSize: "14px",
              }}
            >
              {item?.passingYear}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;
