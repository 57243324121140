import { Box, FormControlLabel, Radio, Stack, styled } from "@mui/material";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import GroupedView from "./GroupedView";
import Fees from "app/modules/fee-management/old-component";
import SummarizedView from "./SummarizedView";

const FeeStructureComponent = () => {
  const [state, setState] = useState({
    radio: "Summarized",
    dialog: false,
  });

  return (
    <>
      <Helmet>
        <title>Fee Structure</title>
      </Helmet>
      <StyledWrapper>
        <Box>
          {/* radio button */}
          <Stack direction="row">
            <FormControlLabel
              control={
                <Radio
                  color="secondary"
                  checked={state.radio === "Summarized" ? true : false}
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      radio: "Summarized",
                    }))
                  }
                />
              }
              label="Summarized"
            />
            <FormControlLabel
              control={
                <Radio
                  color="secondary"
                  checked={state.radio === "groupView" ? true : false}
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      radio: "groupView",
                    }))
                  }
                />
              }
              label="Grouped View"
            />

            <FormControlLabel
              control={
                <Radio
                  color="secondary"
                  checked={state.radio === "individual" ? true : false}
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      radio: "individual",
                    }))
                  }
                />
              }
              label="Individual View"
            />
          </Stack>
        </Box>
        <Box sx={{ height: "100%" }}>
          {state.radio === "Summarized" && <SummarizedView />}
          {state.radio === "groupView" && <GroupedView />}
          {state.radio === "individual" && <Fees />}
        </Box>
      </StyledWrapper>
    </>
  );
};

export default FeeStructureComponent;
const StyledWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
