/* eslint-disable react-hooks/exhaustive-deps */
import { Clear } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";

//out file imports
import { addToPay, removeFromPay } from "./operations";
import {
  extraFeeDropDownLoadingFlag,
  extraFeeDropDownSelector,
} from "./selectors";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";

export default function CheckBoxComponent({
  feeCollObject,
  totalAmount,
  monthName,
  handleInvoiceDetails,
}) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const [isAddedtoReduxPaymentArray, setIsAddedToReduxPaymentArray] =
    useState(false);

  const data = useSelector(extraFeeDropDownSelector);
  const dataLoading = useSelector(extraFeeDropDownLoadingFlag);

  // this state is for the extra fee dropdown
  const [feeTypes, setFeeTypes] = useState([]);

  // this state is for the selected extra fee Type dropdown
  const [selectedFeeType, setSelectedFeeType] = useState(null);

  // This state is for the extra fee list
  const [extraFeeList, setExtraFeeList] = useState([]);

  const extraData = dataLoading
    ? []
    : data?.list?.map((item) => {
        if (!feeTypes.includes(item?.feeType)) {
          setFeeTypes([...feeTypes, item?.feeType]);
        }

        return {
          feeType: item?.feeType,
          feeCode: item?.feeCode,
          feeAmount: item?.feeAmount,
          fine: item?.fineAmount ? item.fineAmount : 0,
        };
      });

  //this is the state for the mandatory payments
  const [feeTypeSelectedValues, setFeeTypeSelectedValues] = useState([]);

  //this is the state for the select component
  const [selectedAdvanceFeeCode, setAdvanceFeeCode] = useState("");

  //this state hold the extra added payments
  const [extraAddList, setExtraAddList] = useState([]);
  const [amount, setAmount] = useState(0);

  //to store the fee code of extra list that will be send to the backend
  const [advancedPaymentsArray, setAdvancedPaymentsArray] = useState([]);

  // set extraFeeList based on selectedFeeType
  useEffect(() => {
    if (selectedFeeType) {
      let extraFeeList = extraData.filter((item) => {
        if (item.feeType === selectedFeeType) {
          return item;
        }
      });
      setExtraFeeList(extraFeeList);
    }
  }, [selectedFeeType]);

  // add Mandatory payments to fee doc list
  useEffect(() => {
    if (feeCollObject.feeCollectionArray.length > 0) {
      let feeDocIds = [];
      feeCollObject.feeCollectionArray?.map((item) => {
        // if fee is not paid
        if (item["status"] !== "Paid") {
          feeDocIds.push(item._id);
        }
      });
      setFeeTypeSelectedValues(feeDocIds);
    }
  }, [feeCollObject.feeCollectionArray]);

  // function to add the extra fee
  const addAdanceFeeInList = () => {
    let advanceFeeStructure = extraData.find((feeStructure) => {
      if (feeStructure.feeCode === selectedAdvanceFeeCode) {
        return feeStructure;
      }
    });

    if (advanceFeeStructure) {
      // check if same feeCode is already added
      let checkin_extraAddList = extraAddList.find((item) => {
        if (item.feeCode === selectedAdvanceFeeCode) {
          return item;
        }
      });
      if (!checkin_extraAddList) {
        setExtraAddList([...extraAddList, advanceFeeStructure]);

        setAdvanceFeeCode("");
      } else {
        NotificationManager.warning(
          `FeeCode ${selectedAdvanceFeeCode} is already addedd.`,
          "",
          3000
        );
      }
    }
  };

  //function to calculate the total amount
  const getTotalFeeAmount = (feeStructureIds, extraAddList) => {
    let sum = 0;
    feeStructureIds?.map((item) => {
      feeCollObject.feeCollectionArray.map((obj) => {
        if (obj._id === item) {
          sum += obj?.feeAmount;
        }
      });
    });

    extraAddList?.map((item) => {
      sum += item.feeAmount;
    });

    setAmount(sum);
  };

  useEffect(() => {
    getTotalFeeAmount(feeTypeSelectedValues, extraAddList);
  }, [feeTypeSelectedValues, extraAddList]);

  //main onChange for the mandatory payments
  const addFeesToPay = (fee_doc_id) => {
    let updatedList = [...feeTypeSelectedValues];

    if (updatedList.includes(fee_doc_id)) {
      // remove fee doc id from list
      updatedList.splice(updatedList.indexOf(fee_doc_id), 1);
    } else {
      // add fee doc id to list
      updatedList.push(fee_doc_id);
    }
    setFeeTypeSelectedValues(updatedList);
  };

  //onChange for the extra advanced payments
  const handleExtrasChange = (e) => {
    let updatedList = [...advancedPaymentsArray];
    if (e.target.checked) {
      updatedList = [...advancedPaymentsArray, e.target.value];
    } else {
      updatedList.splice(advancedPaymentsArray.indexOf(e.target.value), 1);
    }

    setAdvancedPaymentsArray(updatedList);
  };
  return (
    <>
      <Wrapper style={{ flexDirection: isMobile && "column" }}>
        {/* checkbox component */}
        <TableCellStyle style={{ width: "15%" }}>
          {feeCollObject.feeCollectionArray?.map((innerObj) => {
            // add the mandatory payments to fee payment list
            return (
              <InnerDiv style={{ width: "100%" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) => addFeesToPay(event.target.id)}
                        value={innerObj?._id}
                        id={innerObj?._id}
                        disabled={innerObj?.status === "Paid"}
                        defaultChecked
                      />
                    }
                    label={innerObj?.feeType}
                  />
                </FormGroup>
              </InnerDiv>
            );
          })}

          {/* checkbox component extra list */}
          {extraAddList &&
            extraAddList?.map((extras, i) => (
              <>
                <AddMoreContainer>
                  <InnerDiv>
                    <FormGroup key={i}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={extras?.feeCode}
                            onChange={(e) => handleExtrasChange(e)}
                          />
                        }
                        label={extras?.feeType}
                      />
                    </FormGroup>
                  </InnerDiv>
                  <Button
                    size="small"
                    onClick={() => {
                      let newList = extraAddList.filter(
                        (item) => item?.feeCode !== extras?.feeCode
                      );
                      setExtraAddList(newList);

                      let newAdvancedPaymentList = advancedPaymentsArray.filter(
                        (item) => item !== extras?.feeCode
                      );
                      setAdvancedPaymentsArray(newAdvancedPaymentList);
                    }}
                  >
                    <Clear />
                  </Button>
                </AddMoreContainer>
              </>
            ))}

          {/* Extra Fee Dropdown */}
          <FormControl fullWidth>
            <Select
              style={{
                fontSize: "8px",
                width: "80px",
              }}
              // value={selectedAdvanceFeeCode.feeType}
              onChange={(e) => {
                setSelectedFeeType(e.target.value);
              }}
              size="small"
            >
              {feeTypes?.map((feeType) => (
                <MenuItem value={feeType}>{feeType}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCellStyle>

        {/* feecode  */}
        <TableCellStyle>
          {feeCollObject.feeCollectionArray?.map((innerObj) => {
            return <InnerDiv>{innerObj?.feeCode}</InnerDiv>;
          })}
          {extraAddList &&
            extraAddList?.map((extras) => {
              return (
                <AddMoreContainer key={extras?.feeCode}>
                  {extras?.feeCode}
                </AddMoreContainer>
              );
            })}

          {/* Dropdown to select extra fee code */}

          {selectedFeeType ? (
            <FormControl fullWidth>
              <Select
                style={{
                  fontSize: "8px",
                  width: "80px",
                }}
                // value={selectedAdvanceFeeCode.feeType}
                onChange={(e) => {
                  setAdvanceFeeCode(e.target.value);
                }}
                size="small"
              >
                {extraFeeList?.map((feeItem) => (
                  <MenuItem value={feeItem.feeCode}>
                    {feeItem.feeCode + "-" + feeItem.feeAmount}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </TableCellStyle>

        {/* feeAmount column */}
        <TableCellStyle>
          {feeCollObject.feeCollectionArray?.map((innerObj) => {
            return <InnerDiv>{innerObj?.feeAmount}</InnerDiv>;
          })}
          {extraAddList &&
            extraAddList?.map((extras, i) => {
              return (
                <AddMoreContainer key={i}>{extras?.feeAmount}</AddMoreContainer>
              );
            })}

          {/* Button to add extra Fee */}
          {selectedFeeType && selectedAdvanceFeeCode ? (
            <Button
              color="primary"
              size="small"
              onClick={() => addAdanceFeeInList()}
              style={{
                width: "28px",
                height: "28px",
                border: "1px solid #B6C8D6",
              }}
            >
              <CheckIcon sx={{ color: "green" }} />
            </Button>
          ) : null}
        </TableCellStyle>

        {/* fine  column*/}
        <TableCellStyle>
          {feeCollObject?.total_Fine_Amount}
          {/* {feeCollObject.feeCollectionArray.map((innerObj) => {
            return <InnerDiv>{innerObj?.fine}</InnerDiv>;
          })}
          {extraAddList &&
            extraAddList.map((extras) => {
              return (
                <AddMoreContainer key={extras?.feeCode}>
                  {extras?.fine}
                </AddMoreContainer>
              );
            })} */}
        </TableCellStyle>

        <TableCellStyle>{amount}</TableCellStyle>
        {/* <TableCellStyle>Invoice detail</TableCellStyle> */}
        {/* Invoice detail */}

        {/* Invoice detail column */}
        <TableCellStyle>
          {feeCollObject.feeCollectionArray?.map((innerObj) => {
            return (
              <Link
                to={`/${appRoutes.invoice}/${String(
                  innerObj?.invoiceNumber
                ).replace(/\//g, " ")}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: "bold" }}
                onClick={() => handleInvoiceDetails(innerObj?.invoiceNumber)}
              >
                <InnerDiv>{innerObj?.invoiceNumber}</InnerDiv>;
              </Link>
            );
          })}
        </TableCellStyle>

        {/* Column to show Add to Pay button or status */}
        <TableCellStyle>
          {feeCollObject.feeCollectionArray?.map((innerObj) => {
            return (
              <InnerDiv>
                <div
                  style={{
                    color: innerObj?.status === "Paid" ? "green" : "#FF0606",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                  }}
                >
                  {innerObj?.status}
                </div>
              </InnerDiv>
            );
          })}

          {isAddedtoReduxPaymentArray ? (
            <Button
              variant="outlined"
              color="error"
              size="small"
              sx={{
                fontSize: "9px",
              }}
              onClick={() => {
                dispatch(removeFromPay(monthName));
                setIsAddedToReduxPaymentArray(!isAddedtoReduxPaymentArray);
              }}
            >
              Remove From Pay
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              sx={{
                fontSize: "9px",
              }}
              disabled={
                feeTypeSelectedValues.length === 0 && extraAddList.length === 0
              }
              onClick={() => {
                if (feeTypeSelectedValues.length > 0) {
                  dispatch(
                    addToPay({
                      paymentList: feeTypeSelectedValues,
                      advancedPaymentList: extraAddList,
                      totalSum: amount,
                      monthName: monthName,
                    })
                  );
                  setIsAddedToReduxPaymentArray(!isAddedtoReduxPaymentArray);
                }
              }}
            >
              Add To Pay
            </Button>
          )}
        </TableCellStyle>
      </Wrapper>
    </>
  );
}

const InnerDiv = styled.div`
  width: 100%;
  height: 40px;
  gap: 10px;
`;

const AddMoreContainer = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TableCellStyle = styled.td`
  width: 10%;
  min-height: 40px;
  /* border: 1px solid black; */
  /* overflow-x: hidden; */
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  width: 75%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
