import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { useField } from "formik";

export default function DatePickerComponent({ name, ...otherProps }) {
  const [field, metaData] = useField(name);
  const dateConfig = {
    ...field,
    ...otherProps,

    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  };

  if (metaData && metaData.touched && metaData.error) {
    dateConfig.error = true;
    dateConfig.helperText = metaData.error;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        inputFormat="DD MMM, YYYY"
        {...dateConfig}
        renderInput={(params) => (
          <CustomTextField {...params} size="small" fullWidth />
        )}
      />
    </LocalizationProvider>
  );
}
