import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import DialogHeader from "../DialogHeader";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  button: {
    alignItems: "center",
    backgroundColor: "#FCFCFD",
    borderRadius: 1,
    border: 0,
    boxShadow:
      "rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset",
    boxSizing: "border-box",
    color: "#36395A",
    cursor: "pointer",
    fontFamily: "JetBrains Mono, monospace",
    justifyContent: "center",
    lineHeight: "1",
    padding: "16px",
    textAlign: "left",
    textDecoration: "none",
    transition: "box-shadow .15s,transform .15s",
    userSelect: "none",
    fontSize: "18px",
    "&:hover": {
      boxShadow:
        "rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset",
      transform: "translateY(-2px)",
    },
    "&:active": {
      boxShadow: "#D6D6E7 0 3px 7px inset",
      transform: "translateY(2px)",
    },
    "&:focus": {
      boxShadow:
        "#D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset",
    },
  },
  buttonSelected: {
    alignItems: "center",
    backgroundImage:
      "radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%)",
    border: 0,
    borderRadius: 1,
    boxShadow:
      "rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset",
    boxSizing: "border-box",
    color: "white",
    cursor: "pointer",
    fontFamily: "JetBrains Mono, monospace",
    justifyContent: "center",
    lineHeight: "1",
    padding: "16px",
    textAlign: "left",
    textDecoration: "none",
    transition: "box-shadow .15s,transform .15s",
    userSelect: "none",
    fontSize: "18px",
    mb: 1,
    "&:hover": {
      boxShadow:
        "rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset",
      transform: "translateY(-2px)",
    },
    "&:active": {
      boxShadow: "#3c4fe0 0 3px 7px inset",
      transform: "translateY(2px)",
    },
    "&:focus": {
      boxShadow:
        "#3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset",
    },
  },
});
const YoutubeVideoDialog = ({ open, handleCloseDialog, videoLink }) => {
  const [defaultVideoLink, setDefaultVideoLink] = useState(videoLink[0]?.link);
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: { minWidth: { xs: "60%" } },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            handleClose={() => handleCloseDialog()}
            title="YouTube video"
          />
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <iframe
                width="100%"
                height="415"
                src={defaultVideoLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              ></iframe>
            </Grid>
            <Grid item xs={3}>
              <Stack
                sx={{
                  p: 1,
                  m: 1,
                }}
              >
                {videoLink?.map((item, index) => (
                  <Stack
                    sx={{
                      m: 1,
                    }}
                  >
                    <Typography
                      key={index}
                      className={
                        defaultVideoLink === item.link
                          ? classes.buttonSelected
                          : classes.button
                      }
                      onClick={() => {
                        setDefaultVideoLink(item?.link);
                      }}
                    >
                      {item?.title}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default YoutubeVideoDialog;
