import { FilterAlt } from "@mui/icons-material";
import {
  Container,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  CircularProgress,
  Divider,
  DialogContent,
  FormControlLabel,
  Checkbox,
  styled,
  Box,
  MenuItem,
  Button,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CommonTable1 from "app/components/Tables/CommonTable1";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import { DATE_FORMAT } from "app/config/constants";
import {
  selectActiveSession,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import {
  getFeeCollectionsReportByUser,
  getStaffByDepartmentDocId,
} from "app/services/management";
import { getAllDepartmentLogic } from "app/services/reception";
import { FormatFullName, IndianCurrency, MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomLoader from "app/components/common/CustomLoader";
import { StaffCardDropDown } from "app/components/common/StaffCardDropDown";
const FilterDialogHeader = ({
  title = "Modal",
  handleSaveAndView = () => {},
  loading = false,
}) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 1, px: 2 }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }} color="primary">
          {title}
        </Typography>
        {loading ? (
          <CircularProgress size={25} />
        ) : (
          <Typography
            onClick={handleSaveAndView}
            sx={{
              fontSize: "16px",
              color: "#28789c",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
          >
            Save & View
          </Typography>
        )}
      </Stack>
      <Divider sx={{ backgroundColor: "#0C2F49" }} />
    </>
  );
};
const ByAccountantReport = () => {
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const [state, setState] = useState({
    filterDialog: false,
    collectionFrom: new Date(),
    collectionTo: new Date(),
    departmentDocId: "",
    staffDocId: "",
    staffList: [],
    departmentList: [],
    feeCollectionData: [],
  });
  const [checkboxes, setCheckboxes] = useState({
    byStaff: false,
    byCollectionCreatedOn: false,
  });
  const handleOnCloseFilterDialog = () => {
    setState((prev) => ({ ...prev, filterDialog: false }));
  };
  const handleOnClickSaveAndView = () => {
    getFeeCollectionsReportByUserRefetch();
  };
  const handleOnClickExportExcel = () => {
    if (state.feeCollectionData?.length === 0) {
      return;
    }
    const filteredExportArray = state.feeCollectionData?.map((item, i) => ({
      "Sr No": i + 1,
      "Created By": item?.createdBy?.personName,
      Amount: IndianCurrency(item?.totalAmount),
      "Created On": dayjs(item?.date).format(DATE_FORMAT.indianDate),
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll");
    XLSX.writeFile(workbook, "ByAccountantFeeReport.xlsx");
  };
  const {
    isLoading: getAllDepartmentsLoading,
    isFetching: getAllDepartmentsFetching,
  } = useQuery({
    queryKey: ["getAllDepartments", state.departmentDocId],
    queryFn: () => getAllDepartmentLogic({ selectedSession, selectedMedium }),
    onSuccess: ({ data }) => {
      setState((prev) => ({ ...prev, departmentList: data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: checkboxes.byStaff ? true : false,
  });
  const {
    isLoading: getStaffByDepartmentDocIdLoading,
    isFetching: getStaffByDepartmentDocIdFetching,
  } = useQuery({
    queryKey: ["getStaffByDepartmentDocId", state.staffDocId],
    queryFn: () => getStaffByDepartmentDocId(state.departmentDocId),
    onSuccess: ({ data }) => {
      setState((prev) => ({ ...prev, staffList: data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: state.departmentDocId ? true : false,
  });
  const {
    isLoading: getFeeCollectionsReportByUserLoading,
    isFetching: getFeeCollectionsReportByUserFetching,
    refetch: getFeeCollectionsReportByUserRefetch,
  } = useQuery({
    queryKey: ["getFeeCollectionsReportByUser"],
    queryFn: () =>
      getFeeCollectionsReportByUser({
        session: selectedSession,
        ...(checkboxes.byCollectionCreatedOn && {
          from: state.collectionFrom,
          to: state.collectionTo,
        }),
        ...(checkboxes.byStaff && { staffDocId: state.staffDocId }),
      }),
    onSuccess: ({ data }) => {
      setState((prev) => ({
        ...prev,
        feeCollectionData: data?.list,
        filterDialog: false,
      }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const columns = [
    {
      header: "Sr.No",
      Cell: ({
        cell: {
          row: { id },
        },
      }) => (
        <Typography>
          {parseInt(id) + 1 < 10 ? `0${parseInt(id) + 1}` : parseInt(id) + 1}
        </Typography>
      ),
      maxSize: 10,
    },
    {
      header: "Created By",
      accessorKey: "createdBy.personName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>{original?.createdBy?.personName ?? "--"}</Typography>
        );
      },
      maxSize: 30,
    },
    {
      header: "Amount",
      accessorKey: "totalAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>
            {original?.totalAmount
              ? IndianCurrency(original?.totalAmount)
              : "--"}
          </Typography>
        );
      },
      maxSize: 30,
    },
    {
      header: "Created On",
      accessorKey: "date",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>
            {original?.date
              ? dayjs(original?.date).format(DATE_FORMAT.withMonthName)
              : "--"}
          </Typography>
        );
      },
      maxSize: 30,
    },
  ];
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {/* Filter Dialog */}
      <Dialog
        onClose={handleOnCloseFilterDialog}
        open={state.filterDialog}
        PaperProps={{
          sx: { minWidth: "450px" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <FilterDialogHeader
            handleSaveAndView={handleOnClickSaveAndView}
            title="Filter"
            loading={
              getFeeCollectionsReportByUserLoading ||
              getFeeCollectionsReportByUserFetching
                ? true
                : false
            }
          />
        </DialogTitle>
        <DialogContent>
          <Stack padding="10px">
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.byCollectionCreatedOn}
                  onClick={() =>
                    setCheckboxes((prev) => ({
                      ...prev,
                      byCollectionCreatedOn: !prev.byCollectionCreatedOn,
                    }))
                  }
                />
              }
              label="Collection Created On"
            />
            {checkboxes.byCollectionCreatedOn && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                marginRight={2}
              >
                <Stack>
                  <Label>From</Label>
                  <DesktopDatePicker
                    inputFormat="DD-MM-YYYY"
                    name="collectionDate"
                    value={state.collectionFrom}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        collectionFrom: e,
                      }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <Label>To</Label>
                  <DesktopDatePicker
                    inputFormat="DD-MM-YYYY"
                    name="collectionDate"
                    value={state.collectionTo}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        collectionTo: e,
                      }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.byStaff}
                  onClick={() =>
                    setCheckboxes((prev) => ({
                      ...prev,
                      byStaff: !prev.byStaff,
                    }))
                  }
                />
              }
              label="Staff Name"
            />
            {checkboxes.byStaff && (
              <Stack direction="row" alignItems="center" gap={2}>
                <Box sx={{ flex: 1 }}>
                  <Label>Department Name</Label>
                  <CustomSelectBox
                    MenuProps={MenuProps}
                    size="small"
                    fullWidth
                    displayEmpty
                    value={state.departmentDocId}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        departmentDocId: e.target.value,
                        staffDocId: "",
                      }))
                    }
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {state.departmentList?.map((item) => (
                      <MenuItem value={item?._id} key={item?._id}>
                        {item?.departmentName}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Label>Staff Name</Label>
                  <CustomSelectBox
                    MenuProps={MenuProps}
                    size="small"
                    fullWidth
                    displayEmpty
                    disabled={!state.departmentDocId}
                    value={state.staffDocId}
                    renderValue={
                      state.staffDocId
                        ? () => {
                            const staff = state.staffList?.find(
                              (item) => item?.staffDocId === state.staffDocId
                            );
                            return (
                              FormatFullName(
                                staff?.firstName,
                                staff?.middleName,
                                staff?.lastName
                              ) ?? "--"
                            );
                          }
                        : () => "Select"
                    }
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        staffDocId: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {state.staffList?.map((item) => (
                      <MenuItem value={item?.staffDocId} key={item?.staffDocId}>
                        <StaffCardDropDown item={item} />
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Box>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
      {/* loader  */}
      <CustomLoader
        show={
          getAllDepartmentsLoading ||
          getAllDepartmentsFetching ||
          getStaffByDepartmentDocIdLoading ||
          getStaffByDepartmentDocIdFetching
        }
      />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            onClick={() => {
              setState((prev) => ({ ...prev, filterDialog: true }));
            }}
            color="secondary"
            sx={{
              width: "20rem",
              height: "4rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              borderRadius: "10px",
              background: "#0099ff",
              fontSize: "1.2rem",
            }}
          >
            Filter
            <FilterAlt />
            <Typography
              sx={{
                padding: "0 5px",
                background: "#fff",
                color: "black",
                borderRadius: "50%",
              }}
            >
              {Object.values(checkboxes).filter((item) => item === true).length}
            </Typography>
          </Button>
          <ExportButtonGroup
            showExcel
            showPdf={false}
            handleClickExcel={handleOnClickExportExcel}
          />
        </Stack>
        <Box sx={{ mt: 1.5 }}>
          <CommonTable1
            columns={columns}
            data={state.feeCollectionData}
            maxHeight="53vh"
          />
        </Box>
      </Container>
    </LocalizationProvider>
  );
};

export default ByAccountantReport;
const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 500,
  color: "#0C2F49",
}));
