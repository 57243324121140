import React from "react";
import CommonDialog from "../CommonDialog";
import PaymentModeComponent from "../../PaymentModeComponent";
import {
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { addInternalTransfer } from "app/services/ledgerAccount";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { getActiveStaffBySchoolDocId } from "app/services/hr";
import CustomLoader from "../../CustomLoader";
import { StaffCardDropDown } from "../../StaffCardDropDown";
const AddTransferReportDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
}) => {
  const selectedSession = useSelector(selectActiveSession);

  const addFormik = useFormik({
    initialValues: {
      amt: "",
      name: "",
      date: new Date(),
      remarks: "",
    },
    validationSchema: yup.object({
      amt: yup.number().required("Amount is required"),
      name: yup.mixed().required("Selection is required"),
      date: yup.string().required("Date is required"),
      remarks: yup.string().required("Remarks is required"),
    }),
    onSubmit: (values) => {
      addInternalTransferMutate({
        transferToDocId: values.name,
        session: selectedSession,
        amount: values.amt,
        remarks: values.remarks,
        transferDate: values.date,
      });
    },
  });

  const handleClose = () => {
    onClose();
    addFormik.resetForm();
  };
  const {
    mutate: addInternalTransferMutate,
    isLoading: addInternalTransferMutateLoading,
  } = useMutation(addInternalTransfer, {
    onSuccess: (success) => {
      refetch();
      handleClose();
      toast.success(success?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const { isLoading: getActiveStaffLoading, data: getActiveStaffData } =
    useQuery({
      queryKey: ["getActiveStaff"],
      queryFn: () => getActiveStaffBySchoolDocId(),
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: open ? true : false,
    });
  return (
    <>
      <CustomLoader show={getActiveStaffLoading} />
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="600px"
        title="Transfer Amount"
      >
        <DialogContent>
          <form onSubmit={addFormik.handleSubmit} id="addForm">
            <Grid container rowSpacing={1.5} columnSpacing={1}>
              <Grid item xs={6} md={4}>
                <Label>Amount</Label>
                <CustomTextField
                  size="small"
                  fullWidth
                  type="number"
                  placeholder="Enter Amount"
                  name="amt"
                  value={addFormik.values.amt}
                  onChange={addFormik.handleChange}
                />
                {addFormik.touched.amt && addFormik.errors.amt && (
                  <Typography sx={{ color: "red" }}>
                    {addFormik.errors.amt}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} md={4}>
                <Label>Tranfer To</Label>
                <CustomSelectBox
                  size="small"
                  displayEmpty
                  MenuProps={MenuProps}
                  fullWidth
                  name="name"
                  value={addFormik.values.name}
                  renderValue={
                    addFormik?.values?.name
                      ? () => {
                          const item = getActiveStaffData?.data?.list?.find(
                            (item) =>
                              item.staffDocId === addFormik?.values?.name
                          );
                          return (
                            item?.firstName +
                            " " +
                            item?.middleName +
                            " " +
                            item?.lastName
                          );
                        }
                      : null
                  }
                  onChange={(e) => addFormik.handleChange(e)}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {getActiveStaffData?.data?.list?.map((item) => (
                    <MenuItem value={item.staffDocId}>
                      <StaffCardDropDown item={item} />
                    </MenuItem>
                  ))}
                </CustomSelectBox>
                {addFormik.touched.name && addFormik.errors.name && (
                  <Typography sx={{ color: "red" }}>
                    {addFormik.errors.name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} md={4}>
                <Label>Transfer On</Label>
                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  value={addFormik.values.date}
                  name="date"
                  onChange={(e) => {
                    addFormik.setValues((prev) => ({
                      ...prev,
                      date: e,
                    }));
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select"
                      size="small"
                      fullWidth
                    />
                  )}
                />
                {addFormik.touched.date && addFormik.errors.date && (
                  <Typography sx={{ color: "red" }}>
                    {addFormik.errors.date}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Label>Remarks</Label>
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="Enter Remarks"
                  name="remarks"
                  value={addFormik.values.remarks}
                  onChange={addFormik.handleChange}
                />
                {addFormik.touched.remarks && addFormik.errors.remarks && (
                  <Typography sx={{ color: "red" }}>
                    {addFormik.errors.remarks}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
          <Stack mt={2}>
            <PaymentModeComponent
              formId="add-form-pay"
              amount={String(addFormik.values.amt)}
              onClickSubmit={(data) => {
                addFormik.setValues((prev) => ({
                  ...prev,
                  paymentDetails: data,
                }));
                addFormik.handleSubmit();
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={addInternalTransferMutateLoading}
            form="add-form-pay"
            type="submit"
            size="large"
            variant="outlined"
            color="warning"
          >
            Send For Approval
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddTransferReportDialog;
const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 500,
  color: "#0C2F49",
}));
