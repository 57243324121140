import React, { useRef } from "react";
import {
  Stack,
  Container,
  Typography,
  Button,
  Box,
  Dialog,
  DialogContent,
} from "@mui/material";
import styled from "styled-components";
import ReactToPrint from "react-to-print";
import SalarySlip from "./SalarySlip";
import { useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import DialogHeader from "app/components/common/DialogHeader";
import { LoadingButton } from "@mui/lab";
import {
  approveMonthlyPayroll,
  getPayrollPageData,
  rejectMonthlyPayroll,
} from "app/services/hr";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import CustomLoader from "app/components/common/CustomLoader";
import appRoutes from "app/config/routes";
import PaymentModeComponent2 from "app/components/common/PaymentModeComponent2";
import { paySalaryInPartPayment } from "app/services/schoolService/hr.service";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";

const PrintSlip = (props) => {
  const componentRef = useRef();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [amount, setAmount] = useState(0);
  const [state, setState] = useState({
    data: null,
    earnings: null,
    deductions: null,
  });
  const [dialogs, setDialogs] = useState({
    reject: false,
    pay: false,
  });

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      remarks: "",
    },
    validationSchema: yup.object({
      remarks: yup.string().required("Please input the reason"),
    }),
    onSubmit: (values) => {
      rejectMonthlyPayrollMutate({
        payrollDocId: id,
        ...values,
      });
      navigate(`/${appRoutes.payroll}?month=${searchParams.get("month")}`);
    },
  });
  const {
    isLoading: getSinglePayrollLoading,
    isFetching: getSinglePayrollFetching,
  } = useQuery({
    queryKey: "getSinglePayrollData",
    queryFn: () =>
      getPayrollPageData({
        payrollDocId: id,
      }),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        data: res?.data?.obj,
        earnings: res?.data?.obj?.earnings,
        deductions: res?.data?.obj?.deductions,
      }));
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const {
    mutate: rejectMonthlyPayrollMutate,
    isLoading: rejectMonthlyPayrollLoading,
  } = useMutation(rejectMonthlyPayroll, {
    onSuccess: (res) => {
      setDialogs((prev) => ({ ...prev, reject: false }));
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    mutate: approveMonthlyPayrollMutate,
    isLoading: approveMonthlyPayrollLoading,
  } = useMutation(approveMonthlyPayroll, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      navigate(`/${appRoutes.payroll}?month=${searchParams.get("month")}`);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    mutate: paySalaryInPartPaymentMutate,
    isLoading: paySalaryInPartPaymentLoading,
  } = useMutation(paySalaryInPartPayment, {
    onSuccess: (res) => {
      setDialogs((prev) => ({ ...prev, pay: false }));
      toast.success(res?.data?.message);
      navigate(`/${appRoutes.payroll}?month=${searchParams.get("month")}`);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handlePayFormSubmit = (data) => {
    const newData = {
      payrollDocId: id,
      balanceAmount: data?.balanceAmount,
      transactionDate: data?.transactionDate,
      transactionList: data?.transactionList,
    };
    paySalaryInPartPaymentMutate(newData);
  };

  return (
    <>
      <CustomLoader
        show={getSinglePayrollLoading || getSinglePayrollFetching}
      />
      <Dialog
        open={dialogs.reject}
        onClose={() => setDialogs((prev) => ({ ...prev, reject: false }))}
        maxWidth={700}
      >
        <DialogHeader
          title="Reject Payroll"
          handleClose={() => {
            setDialogs((prev) => ({ ...prev, reject: false }));
          }}
        />
        <DialogContent
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img src="/deactivate_account.png" alt="" />
          <Typography fontSize="2rem">Are you sure want to reject</Typography>
          <Typography>Please provide a reason</Typography>
          <form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
            onSubmit={formik.handleSubmit}
          >
            <textarea
              placeholder="Type reason..."
              style={{
                width: "100%",
                borderRadius: "20px",
                resize: "none",
                padding: "10px",
              }}
              name="remarks"
              onChange={formik.handleChange}
              value={formik.remarks}
              onBlur={formik.handleBlur}
              cols="30"
              rows="3"
            ></textarea>
            <Stack flex direction="row" gap={2}>
              <Button
                size="large"
                variant="outlined"
                color="error"
                onClick={() =>
                  setDialogs((prev) => ({ ...prev, reject: false }))
                }
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                loading={rejectMonthlyPayrollLoading}
                size="large"
                variant="outlined"
                color="secondary"
              >
                Yes
              </LoadingButton>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
      {/* pay dialog */}
      <CommonDialog
        title="Pay Payroll"
        open={dialogs.pay}
        onClose={() => setDialogs((prev) => ({ ...prev, pay: false }))}
        minWidth="700px"
      >
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <PaymentModeComponent2
              onClickSubmit={handlePayFormSubmit}
              data={state.data}
              loading={paySalaryInPartPaymentLoading}
            />
          </Box>
        </DialogContent>
      </CommonDialog>
      <Container maxWidth="lg">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          padding="20px"
          border="1px solid #b6c8d6"
          sx={{ backgroundColor: "#fff" }}
        >
          <Stack direction="row" alignItems="center">
            <Label>Download As:</Label>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Box
                sx={{
                  marginLeft: "10px",
                  padding: "10px",
                  background: "rgba(255, 186, 35, 0.1)",
                }}
              >
                pdf
              </Box>
              <ReactToPrint
                trigger={() => (
                  <Button variant="outlined" color="secondary">
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Label>Current Status:</Label>{" "}
            <Label
              style={{
                opacity: "0.75",
              }}
            >
              {state?.data?.status || "--"}
            </Label>
          </Stack>
        </Stack>
        <SalarySlip
          ref={componentRef}
          setAmount={setAmount}
          data={state.data}
          earnings={state.earnings}
          deductions={state.deductions}
        />
        <Buttons>
          {/* buttons */}
          {(state?.data?.status?.toLowerCase() === "requested" ||
            state?.data?.status?.toLowerCase() === "created") && (
            <>
              <LoadingButton
                variant="outlined"
                size="large"
                color="error"
                onClick={() =>
                  setDialogs((prev) => ({ ...prev, reject: true }))
                }
              >
                Reject
              </LoadingButton>
              <LoadingButton
                loading={approveMonthlyPayrollLoading}
                onClick={() => approveMonthlyPayrollMutate(id)}
                variant="outlined"
                size="large"
                color="secondary"
              >
                Approve
              </LoadingButton>
            </>
          )}
          {["approved", "partially paid"].includes(
            state?.data?.status?.toLowerCase()
          ) && (
            <>
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={() => setDialogs((prev) => ({ ...prev, pay: true }))}
              >
                Pay
              </Button>
            </>
          )}
        </Buttons>
      </Container>
    </>
  );
};

export default PrintSlip;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #0c2f49;
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  /* align-self: flex-end; */
`;
