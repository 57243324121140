import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import {
  CreditCard,
  CreditScore,
  CurrencyRupee,
  Receipt,
} from "@mui/icons-material";
import {
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import {
  DatePicker,
  DateTimePicker,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import TextFieldLabel from "./TextFieldLabel";
import { useFormik } from "formik";
import * as yup from "yup";
import { IndianCurrency } from "app/utils/helper";
const validationSchema = yup.object({
  chequeNumber: yup.string().when("paymentMode", {
    is: "ChequeMode",
    then: yup.string().required("Cheque number required"),
  }),
  referenceNumber: yup.string().when("paymentMode", {
    is: "NEFTMode",
    then: yup.string().required("Refrence number required"),
  }),
  transactionNumber: yup.string().when("paymentMode", {
    is: "NEFTMode",
    then: yup.string().required("Transaction number required"),
  }),
  cardHolderName: yup.string().when("paymentMode", {
    is: "CardMode",
    then: yup.string().required("Card holder name required"),
  }),
  approvalCode: yup.string().when("paymentMode", {
    is: "CardMode",
    then: yup.string().required("Approval code required"),
  }),
  cardNumber: yup.string().when("paymentMode", {
    is: "CardMode",
    then: yup.string().required("Card number required"),
  }),
  UPI_platform: yup.string().when("paymentMode", {
    is: "UpiMode",
    then: yup.string().required("Platform required"),
  }),
  upiNumber: yup.string().when("paymentMode", {
    is: "UpiMode",
    then: yup.string().required("UPI Number required"),
  }),
  transactionId: yup.string().when("paymentMode", {
    is: "UpiMode",
    then: yup.string().required("Transaction id required"),
  }),
  timeStamp: yup.string().when("paymentMode", {
    is: "UpiMode",
    then: yup
      .string("Date time required")
      .required("Date time required")
      .nullable(),
  }),
});
const PaymentModeComponent = ({
  amount = 0,
  onClickSubmit = () => {},
  formId,
}) => {
  const dt = new Date();
  const {
    values,
    setValues,
    handleChange,
    touched,
    errors,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      paymentMode: "CashMode",
      amount,
      referenceNumber: "",
      transactionNumber: "",
      cardNumber: "",
      approvalCode: "",
      cardHolderName: "",
      chequeNumber: "",
      depositDate: dt,
      // upi payload
      UPI_platform: "",
      upiNumber: "",
      transactionId: "",
      timeStamp: dt,
    },
    onSubmit: (values) => {
      const CashModeObj = {
        amount,
      };
      const ChequeModeObj = {
        chequeNumber: values.chequeNumber,
        depositionDate: values.depositDate,
        amount,
      };
      const NEFTModeObj = {
        referenceNumber: values.referenceNumber,
        transactionNumber: values.transactionNumber,
        amount,
      };
      const CardModeObj = {
        cardNumber: values.cardNumber,
        approvalCode: values.approvalCode,
        cardHolderName: values.cardHolderName,
        amount,
      };
      const UPIModeObj = {
        UPI_platform: values.UPI_platform,
        upiNumber: values.upiNumber,
        transactionId: values.transactionId,
        timeStamp: values.timeStamp,
        amount,
      };

      const mainObj = {
        [values.paymentMode]:
          values.paymentMode === "CashMode"
            ? CashModeObj
            : values.paymentMode === "ChequeMode"
            ? ChequeModeObj
            : values.paymentMode === "NEFTMode"
            ? NEFTModeObj
            : values.paymentMode === "UpiMode"
            ? UPIModeObj
            : CardModeObj,
      };
      onClickSubmit(mainObj);

      return mainObj;
    },
    validationSchema,
  });
  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        gap={{ xs: 0.7, md: 1.5 }}
        sx={{ mb: 1.5 }}
        justifyContent="center"
      >
        <PaymentDiv
          style={
            values.paymentMode === "ChequeMode"
              ? {
                  border: "2px solid lightblue",
                }
              : null
          }
          onClick={() =>
            setValues((prev) => ({ ...prev, paymentMode: "ChequeMode" }))
          }
        >
          <CreditScore className="payment_icon" />
          <PaymentTitleText>Cheque Mode</PaymentTitleText>
        </PaymentDiv>
        <PaymentDiv
          onClick={() =>
            setValues((prev) => ({ ...prev, paymentMode: "NEFTMode" }))
          }
          style={
            values.paymentMode === "NEFTMode"
              ? {
                  border: "2px solid lightblue",
                }
              : null
          }
        >
          <Receipt className="payment_icon" />
          <PaymentTitleText>NEFT Mode</PaymentTitleText>
        </PaymentDiv>
        <PaymentDiv
          onClick={() =>
            setValues((prev) => ({ ...prev, paymentMode: "CardMode" }))
          }
          style={
            values.paymentMode === "CardMode"
              ? {
                  border: "2px solid lightblue",
                }
              : null
          }
        >
          <CreditCard className="payment_icon" />
          <PaymentTitleText>Card Mode</PaymentTitleText>
        </PaymentDiv>
        <PaymentDiv
          onClick={() =>
            setValues((prev) => ({ ...prev, paymentMode: "UpiMode" }))
          }
          style={
            values.paymentMode === "UpiMode"
              ? {
                  border: "2px solid lightblue",
                }
              : null
          }
        >
          <Typography className="upi_payment_icon">UPI</Typography>
          <PaymentTitleText>UPI Mode</PaymentTitleText>
        </PaymentDiv>
        <PaymentDiv
          onClick={() =>
            setValues((prev) => ({ ...prev, paymentMode: "CashMode" }))
          }
          style={
            values.paymentMode === "CashMode"
              ? {
                  border: "2px solid lightblue",
                }
              : null
          }
        >
          <CurrencyRupee className="payment_icon" />
          <PaymentTitleText>Cash Mode</PaymentTitleText>
        </PaymentDiv>
      </Stack>

      {/* payment input Field */}
      <form onSubmit={handleSubmit} id={formId}>
        <Grid container columnSpacing={2} rowSpacing={1}>
          {values.paymentMode === "CashMode" && (
            <Grid item xs={12} md={6}>
              <TextFieldLabel title="Cash Amount" />
              <CustomTextFieldDisabled
                size="small"
                value={IndianCurrency(amount)}
                name="cashAmount"
              />
            </Grid>
          )}
          {values.paymentMode === "NEFTMode" && (
            <>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title="Reference Number" />
                <CustomTextField
                  size="small"
                  fullWidth
                  name="referenceNumber"
                  value={values.referenceNumber}
                  onChange={handleChange}
                  placeholder="type reference Number"
                  error={
                    Boolean(errors.referenceNumber) && touched.referenceNumber
                  }
                  helperText={touched.referenceNumber && errors.referenceNumber}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title="Transaction Number" />

                <CustomTextField
                  size="small"
                  fullWidth
                  value={values.transactionNumber}
                  onChange={handleChange}
                  name="transactionNumber"
                  placeholder="type transaction number"
                  error={
                    Boolean(errors.transactionNumber) &&
                    touched.transactionNumber
                  }
                  helperText={
                    touched.transactionNumber && errors.transactionNumber
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title="NEFT Amount" />
                <CustomTextFieldDisabled
                  size="small"
                  value={IndianCurrency(amount)}
                  name="NEFTAmount"
                />
              </Grid>
            </>
          )}
          {values.paymentMode === "CardMode" && (
            <>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title="Card Number(Last four digits)" />
                <CustomTextField
                  name="cardNumber"
                  placeholder="type last four digit"
                  type="number"
                  size="small"
                  fullWidth
                  value={values.cardNumber}
                  error={Boolean(errors.cardNumber) && touched.cardNumber}
                  helperText={touched.cardNumber && errors.cardNumber}
                  onChange={(e) => {
                    if (e.target.value.length < 5) {
                      setValues((prev) => ({
                        ...prev,
                        cardNumber: e.target.value,
                      }));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title="Approval Code" />
                <CustomTextField
                  name="approvalCode"
                  placeholder="type approval code"
                  size="small"
                  fullWidth
                  value={values.approvalCode}
                  onChange={handleChange}
                  error={Boolean(errors.approvalCode) && touched.approvalCode}
                  helperText={touched.approvalCode && errors.approvalCode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title="Card Holder Name" />
                <CustomTextField
                  name="cardHolderName"
                  placeholder="type cardholder name"
                  size="small"
                  fullWidth
                  value={values.cardHolderName}
                  onChange={handleChange}
                  error={
                    Boolean(errors.cardHolderName) && touched.cardHolderName
                  }
                  helperText={touched.cardHolderName && errors.cardHolderName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title="Card Amount" />
                <CustomTextFieldDisabled
                  size="small"
                  fullWidth
                  value={IndianCurrency(amount)}
                  name="cardAmount"
                />
              </Grid>
            </>
          )}
          {values.paymentMode === "ChequeMode" && (
            <>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title="Cheque Number" />
                <CustomTextField
                  size="small"
                  fullWidth
                  value={values.chequeNumber}
                  onChange={handleChange}
                  name="chequeNumber"
                  placeholder="type checque Number"
                  error={Boolean(errors.chequeNumber) && touched.chequeNumber}
                  helperText={touched.chequeNumber && errors.chequeNumber}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title="Deposit Date" />

                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, depositDate: e }))
                  }
                  value={values.depositDate}
                  renderInput={(params) => (
                    <CustomTextField {...params} size="small" fullWidth />
                  )}
                  views={["year", "month", "day"]}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title="Cheque Amount" />
                <CustomTextFieldDisabled
                  size="small"
                  value={IndianCurrency(amount)}
                  name="chequeAmount"
                />
              </Grid>
            </>
          )}
          {values.paymentMode === "UpiMode" && (
            <>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title="UPI Platform" />
                <CustomTextField
                  name="UPI_platform"
                  placeholder="Plateform name"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.UPI_platform}
                  error={Boolean(errors.UPI_platform) && touched.UPI_platform}
                  helperText={touched.UPI_platform && errors.UPI_platform}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title="UPI Number" />
                <CustomTextField
                  name="upiNumber"
                  placeholder="Upi number"
                  size="small"
                  fullWidth
                  value={values.upiNumber}
                  onChange={handleChange}
                  error={Boolean(errors.upiNumber) && touched.upiNumber}
                  helperText={touched.upiNumber && errors.upiNumber}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title="Transaction Id" />
                <CustomTextField
                  name="transactionId"
                  placeholder="Transaction Id"
                  size="small"
                  fullWidth
                  value={values.transactionId}
                  onChange={handleChange}
                  error={Boolean(errors.transactionId) && touched.transactionId}
                  helperText={touched.transactionId && errors.transactionId}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title="Date Time" />
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, timeStamp: e }))
                  }
                  value={values.timeStamp}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      fullWidth
                      error={Boolean(errors.timeStamp) && touched.timeStamp}
                      helperText={touched.timeStamp && errors.timeStamp}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title="UPI Amount" />
                <CustomTextFieldDisabled
                  size="small"
                  fullWidth
                  value={IndianCurrency(amount)}
                  name="cardAmount"
                />
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Box>
  );
};

export default PaymentModeComponent;
const PaymentDiv = styled(Box)`
  width: 100px;
  height: 70px;
  border: 1px solid rgba(21, 62, 111, 0.12);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .payment_icon {
    background-color: #febc1233;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    padding: 5px;
  }
  .upi_payment_icon {
    background-color: #febc1233;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-style: italic;
  }
`;
const PaymentTitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "9px",
  marginTop: "5px",
  fontWeight: 500,
}));
