import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import styled from "styled-components";
import { useRef, useState } from "react";
import "react-notifications/lib/notifications.css";

//import for our files
import { mobile } from "app/modules/reception/components/responsive";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getInvoiceDetail } from "app/services/student-management";
import { toast } from "react-toastify";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { IndianCurrency, NumberInWord } from "app/utils/helper";
import dayjs from "dayjs";
import { THEME } from "app/config/constants";
import CustomLoader from "../common/CustomLoader";
const LabelText = ({ label, text }) => {
  return (
    <>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
          : {text}
        </Typography>
      </Grid>
    </>
  );
};

export default function InvoiceComponent() {
  const invoicePrintRef = useRef(null);
  const [data, setData] = useState({});
  const { invoiceId } = useParams();
  const [state, setState] = useState({
    school: true,
    student: true,
  });
  const selectedSchool = useSelector(selectSelectedSchool);
  const handlePrint = useReactToPrint({
    content: () => invoicePrintRef.current,
  });
  const {
    isLoading: getFeeInvoiceDetailLoading,
    isFetching: getFeeInvoiceDetailFetching,
  } = useQuery({
    queryKey: ["getFeeInvoiceDetail", invoiceId],
    queryFn: () => getInvoiceDetail(String(invoiceId).replace(/ /g, "/")),
    onSuccess: (value) => {
      setData(value?.data?.obj);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const InvoiceDetail = ({ text }) => {
    return (
      <LeftSideDiv>
        <InvoiceHeader>
          <div
            style={{
              width: "100%",
            }}
          >
            <Stack direction="row" alignItems="center" gap={1} sx={{ py: 0.5 }}>
              <img
                src={selectedSchool?.schoolLogo}
                style={{
                  height: "60px",
                  width: "60px",
                  objectFit: "contain",
                  marginLeft: "5px",
                }}
                alt="schoolLogo"
              />
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {data?.schoolName}
                </Typography>

                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {`${data?.schoolAddress?.address}, ${data?.schoolAddress?.dist}, ${data?.schoolAddress?.state}, ${data?.schoolAddress?.pinCode}`}
                </Typography>

                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  {selectedSchool?.schoolContactNumber}, {selectedSchool?.email}
                </Typography>
              </Box>
            </Stack>

            <div
              style={{
                width: "100%",
                fontWeight: "600",
                fontSize: "16px",

                textTransform: "capitalize",
                textAlign: "center",
                color: "#FFFFFF",
                backgroundColor: "#0C4B7E",
              }}
            >
              {text} - {`${data?.session}`}
            </div>
          </div>
        </InvoiceHeader>
        <InvoiceBody>
          <Grid container sx={{ px: 1, pt: 0.5 }}>
            <LabelText label="Invoice No." text={data?.invoiceNumber} />
            <LabelText
              label="Date"
              text={moment(new Date(data?.createdOn)).format("DD/MM/YYYY")}
            />
            <LabelText label="Depositor Name" text={data?.depositorName} />
            <LabelText
              label="Mode"
              text={
                data?.paymentDetails && Object.keys(data?.paymentDetails)[0]
              }
            />
          </Grid>
          <Box sx={{ backgroundColor: "#000", my: 0.3, height: "1px" }} />
          <Grid container sx={{ px: 1 }}>
            <LabelText
              label="Student Name"
              text={data?.studentDetail?.studentName}
            />
            <LabelText label="Gender" text={data?.studentDetail?.gender} />
            <LabelText
              label="Father's Name"
              text={data?.studentDetail?.fatherName}
            />
            <LabelText
              label="Mob Number"
              text={data?.studentDetail?.studentMobileNumber}
            />
            <LabelText
              label="Class-Stream-Sec"
              text={`${data?.studentDetail?.className}-
                    ${data?.studentDetail?.stream}-${data?.studentDetail?.section}`}
            />
            <LabelText
              label="Reg No. (Adm No.)"
              text={`${data?.studentDetail?.registrationNumber} (${
                data?.studentDetail?.admissionNumber ?? ""
              })`}
            />
            <LabelText
              text={data?.studentDetail?.transportInfo?.vehicleNumber}
              label="Transport Number"
            />
            <LabelText
              label="Transport Type"
              text={data?.studentDetail?.transportInfo?.type}
            />
          </Grid>

          <Grid container sx={{ background: "#ffaf0d", py: 1, px: 1, mx: 0.1 }}>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Particulars
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Discription
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                UOM
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Amount
              </Typography>
            </Grid>
          </Grid>
          {data?.invoiceItems?.map((item, ind) => (
            <Grid key={ind} container sx={{ py: 0.1, px: 1 }}>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  {item?.description}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {item?.newDescription?.map((res, i) => (
                  <Typography
                    key={i}
                    sx={{ fontSize: "10px", fontWeight: 500 }}
                  >
                    {res?.feeCategory}: {IndianCurrency(res?.amount)}
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  {item?.UOM}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                  {IndianCurrency(item?.itemAmount)}
                </Typography>
              </Grid>
            </Grid>
          ))}
          <Grid container sx={{ px: 1, pt: 0.3, borderTop: "1px solid #000" }}>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "10px" }}>
                Previous Balance
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "10px", color: colors.red[600] }}>
                {IndianCurrency(data?.studentDetail?.previousBalance)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "10px" }}>Total Amount</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "10px" }}>
                {IndianCurrency(data?.totalAmount)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "10px" }}>
                Discount {data?.discountRemark && `(${data?.discountRemark})`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "10px" }}>
                {IndianCurrency(data?.discountAmount)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "10px" }}>Grand Total</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "10px" }}>
                {IndianCurrency(data?.grandTotalAmount)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "10px" }}>
                Balance Amount{" "}
                {data?.balanceRemark && `(${data?.balanceRemark})`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: 600,
                  color: colors.red[600],
                }}
              >
                {IndianCurrency(data?.balanceAmount)}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ backgroundColor: "#000", my: 0.3, height: "1px" }} />
          <Grid container sx={{ px: 1 }}>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: "10px" }}>
                <span style={{ fontWeight: 600 }}>Paid Amount:</span>{" "}
                {NumberInWord(data?.paidAmount)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "10px", fontWeight: 600 }}>
                {IndianCurrency(data?.paidAmount)}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ backgroundColor: "#000", my: 0.3, height: "1px" }} />
          <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>
                Created By : {data?.createdBy?.personName}
              </Typography>
              <Typography sx={{ fontSize: "10px" }}>
                Created On : {dayjs(data?.createdOn).format("DD MMM YYYY")}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "10px" }}>For,</Typography>
              <Typography sx={{ fontSize: "10px" }}>
                {data?.schoolName}
              </Typography>
              <Typography sx={{ fontSize: "10px", pt: 2, pb: 1 }}>
                Authorised Sign.
              </Typography>
            </Box>
          </Stack>
        </InvoiceBody>
      </LeftSideDiv>
    );
  };

  return (
    <>
      <CustomLoader
        show={getFeeInvoiceDetailLoading || getFeeInvoiceDetailFetching}
      />
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            gap={1}
            sx={{
              background: "#fff",
              px: 2,
              py: 0.5,
              borderRadius: "10px",
              boxShadow: THEME.boxShadow1,
              mb: 1,
            }}
          >
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={state.school}
              onChange={() =>
                setState((prev) => ({ ...prev, school: !prev.school }))
              }
              label="School Invoice"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              }}
            />
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={state.student}
              onChange={() =>
                setState((prev) => ({ ...prev, student: !prev.student }))
              }
              label="Student Invoice"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              }}
            />
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              handlePrint();
            }}
          >
            Print
          </Button>
        </Stack>

        <Stack
          sx={{ width: "60%", m: "auto" }}
          direction={"row"}
          justifyContent={"center"}
        >
          <InvoiceDetail text={"Invoice"} />
        </Stack>

        <Box sx={{ display: "none" }}>
          <Stack direction="row" sx={{ p: 1 }} gap={0.5} ref={invoicePrintRef}>
            {state.school && <InvoiceDetail text={"School Invoice"} />}
            {state.student && <InvoiceDetail text={"Student Invoice"} />}
          </Stack>
        </Box>
      </Box>
    </>
  );
}

const LeftSideDiv = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: #fff;
`;

const InvoiceHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
