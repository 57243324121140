import { Box, Stack, styled, Typography } from "@mui/material";
import CustomLoader from "app/components/common/CustomLoader";
import SalaryTransactionFilterDilog from "app/components/common/Dialogs/hr/payroll/SalaryTransactionFilterDilog";
import TableActionButtons from "app/components/common/TableActionButtons";
import CommonTable1 from "app/components/Tables/CommonTable1";
import appRoutes from "app/config/routes";
import { getPartPaymentSalary } from "app/services/schoolService/hr.service";
import { IndianCurrency } from "app/utils/helper";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SalaryTransactions = () => {
  const [state, setState] = useState({
    filterPayload: null,
  });
  const handleSaveFilerPayload = (payload) => {
    setState((prev) => ({ ...prev, filterPayload: payload }));
  };
  const {
    isLoading: getPartPaymentSalaryLoading,
    isFetching: getPartPaymentSalaryFetching,
    data: getPartPaymentSalaryData,
  } = useQuery({
    queryKey: ["getPartPaymentSalary"],
    queryFn: () => {
      if (state.filterPayload) {
        return getPartPaymentSalary(state.filterPayload);
      }
      return getPartPaymentSalary();
    },
    onSuccess: (succes) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        accessorKey: "srNo",
        Cell: ({ cell }) => {
          return (
            <>
              <Typography>
                {parseInt(cell?.row?.id) < 9
                  ? `0${parseInt(cell?.row?.id) + 1}`
                  : parseInt(cell?.row?.id) + 1}
              </Typography>
            </>
          );
        },
        maxSize: 30,
      },

      {
        header: "Employee name",
        accessorKey: "name",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <LinkText
              to={`/${appRoutes.staffProfile}/${original?.staffDocId?._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {original?.staffDocId?.firstName ?? ""}{" "}
              {original?.staffDocId?.middleName ?? ""}{" "}
              {original?.staffDocId?.lastName ?? ""} &#8599;
            </LinkText>
          );
        },
        size: 40,
      },

      {
        header: "Amount",
        accessorKey: "paidAmount",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>{IndianCurrency(original?.paidAmount)}</Typography>
          );
        },
        size: 30,
      },
      {
        header: "Transaction Date",
        accessorKey: "transactionDate",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>
              {dayjs(original?.transactionDate).format("DD MMM, YYYY")}
            </Typography>
          );
        },
        size: 40,
      },
      {
        header: "Created On",
        accessorKey: "createdOn",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>
              {dayjs(original?.createdOn).format("DD MMM, YYYY")}
            </Typography>
          );
        },
        size: 40,
      },
      {
        header: "Created By",
        accessorKey: "createdBy.personname",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <Typography>{original?.createdBy?.personName}</Typography>;
        },
        size: 40,
      },

      {
        header: "Actions",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <TableActionButtons showDelete />;
        },
        size: 20,
      },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Payroll | Salary Transaction</title>
      </Helmet>
      <CustomLoader
        show={getPartPaymentSalaryLoading || getPartPaymentSalaryFetching}
      />
      <Stack direction={"row"} alignItems={"center"} gap={20}>
        <SalaryTransactionFilterDilog handleSave={handleSaveFilerPayload} />
        <FeeBox>
          <Stack direction="row" alignItems={"center"} gap={2}>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Cash
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.totalAmount)}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                NEFT
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.REQUESTED)}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Card
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.APPROVED)}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                UPI
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.PAID)}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Card
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.Not_Created)}
              </Typography>
            </Box>
          </Stack>
        </FeeBox>
      </Stack>
      <Stack py={1}>
        <CommonTable1
          columns={columns}
          data={getPartPaymentSalaryData?.data?.list || []}
          maxHeight="56vh"
        />
      </Stack>
    </>
  );
};

export default SalaryTransactions;
const FeeBox = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(19, 115, 229, 0.2);
  background: rgba(39, 137, 253, 0.02);
  padding: 5px;
  display: inline-block;
  margin-top: 10px;
`;
const LinkText = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: underline;
    color: blue;
  }
`;
