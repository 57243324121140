import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { login, refreshAccessToken, getUserProfile } from "./thunk";
import AsyncStorage from "@react-native-async-storage/async-storage";
const initialState = {
  status: "idle",
  isAuthenticated: false,
  authData: null,
  hasError: false,
  errorMsg: "",
  selectedInstituteType: "",
  loggedInUserProfile: null,
  login_isLoading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSelectedInstituteType: (state, { payload }) => {
      state.selectedInstituteType = payload;
    },
    setValidatingSession: (state, { payload }) => {
      state.status = payload ? "validatingSession" : "idle";
    },
    setRegisterData: (state, action) => {
      state.login_isLoading = false;
      state.status = "idle";
      state.hasError = false;
      state.errorMsg = null;
      state.authData = {
        accessToken: action.payload.token,
        ...action.payload,
      };
      state.isAuthenticated = true;

      // Set Local storage
      localStorage.setItem("authRefreshToken", action.payload?.refreshToken);
      localStorage.setItem("useDocId", action.payload?.userDocId);
    },
    resetAuthSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "isAuthenticating";
        state.hasError = false;
        state.errorMsg = null;
        state.isAuthenticated = false;
        state.authData = null;
        state.login_isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.login_isLoading = false;
        state.status = "idle";
        state.hasError = false;
        state.errorMsg = null;
        state.authData = {
          accessToken: action.payload.token,
          ...action.payload,
        };
        state.isAuthenticated = true;

        // Set Local storage
        localStorage.setItem("authRefreshToken", action.payload?.refreshToken);
        localStorage.setItem("useDocId", action.payload?.userDocId);
      })
      .addCase(login.rejected, (state, action) => {
        state.login_isLoading = false;
        state.status = "idle";
        state.hasError = true;
        state.errorMsg = action.payload;
        state.isAuthenticated = false;
        state.authData = null;
      });

    builder.addCase(refreshAccessToken.pending, (state) => {
      state.status = "isAuthenticating";
      state.isAuthenticated = false;
    });
    builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
      state.status = "idle";
      state.authData = {
        ...state.authData,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        userDocId: localStorage.getItem("useDocId"),
      };
      state.isAuthenticated = true;
    });
    builder.addCase(refreshAccessToken.rejected, (state, action) => {
      state.status = "idle";
      state.hasError = true;
      state.errorMsg = action.payload;
      state.isAuthenticated = false;
      state.authData = null;
    });

    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      state.loggedInUserProfile = payload;
    });
    builder.addCase(PURGE, () => {
      // When user logs out, clear the store
      AsyncStorage.removeItem("persist:root");
      return initialState;
    });
  },
});

export default authSlice.reducer;
