import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  styled,
  Divider,
  Checkbox,
  DialogTitle,
  FormControlLabel,
  DialogActions,
} from "@mui/material";
import { saveAs, FileSaverOptions } from "file-saver";
import {
  ArrowReturnLeft,
  Eye,
  PencilSquare,
  Send,
} from "react-bootstrap-icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useQuery } from "react-query";
import { Plus } from "react-bootstrap-icons";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  getNotice,
  publishNotice,
  revertNotice,
} from "app/services/communication";
import dayjs from "dayjs";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchool,
} from "app/modules/schools/selectors";
import CommonTable1 from "../../../components/Tables/CommonTable1";
import DialogHeader from "app/components/common/DialogHeader";
import { useReactToPrint } from "react-to-print";
import CustomLoader from "app/components/common/CustomLoader";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import * as XLSX from "xlsx";
import { DATE_FORMAT } from "app/config/constants";
import AddNewNoticeDrawer from "../components/AddNewNoticeDrawer";
import { YouTube } from "@mui/icons-material";
import YoutubeVideoDialog from "app/components/common/Dialogs/YoutubeVideoDialog";
import PreviewDownloadImage from "app/components/common/PreviewDownloadImage";
import ViewNoticeDialog from "../components/ViewNoticeDialog";

const NoticeBoard = () => {
  const [state, setState] = useState({
    from: dayjs(new Date()).subtract(1, "day"),
    to: new Date(),
    draft: false,
    classData: [],
    publishNoticeDocId: "",
    revertNoticeDocId: "",
    showaddEditNoticeDrawer: false,
    isEdit: false,
    editData: {},
  });
  const printNoticeRef = useRef();
  const selectedSession = useSelector(selectActiveSession);

  const [viewNotice, setViewNotice] = useState(null);
  const [noticeDialog, setViewNoticeDialog] = useState(false);
  const selectedSchool = useSelector(selectSelectedSchool);

  const isMounted = useRef(false);

  const handlePrint = useReactToPrint({
    content: () => printNoticeRef.current,
    documentTitle: "Notice",
  });

  // get All Notice
  const {
    isLoading: getAllNoticeLoading,
    data: getAllNoticeData,
    isFetching: getAllNoticeFetching,
    refetch: getAllNoticeFetch,
  } = useQuery({
    queryKey: ["getAllNotices", selectedSession],
    queryFn: () => {
      if (isMounted.current) {
        return getNotice({ from: state.from, to: state.to });
      } else {
        return getNotice();
      }
    },

    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  // publish notice get type
  const { isLoading: publishNoticeLoading, isFetching: publishNoticeFetching } =
    useQuery({
      queryKey: ["publishNotice"],
      queryFn: () => publishNotice(state.publishNoticeDocId),
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        getAllNoticeFetch();
        setState((prev) => ({ ...prev, publishNoticeDocId: "" }));
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
        setState((prev) => ({ ...prev, publishNoticeDocId: "" }));
      },
      enabled: state.publishNoticeDocId ? true : false,
    });
  // revert notice get type
  const { isLoading: revertNoticeLoading, isFetching: revertNoticeFetching } =
    useQuery({
      queryKey: ["revertNotice"],
      queryFn: () => revertNotice(state.revertNoticeDocId),
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        getAllNoticeFetch();
        setState((prev) => ({ ...prev, revertNoticeDocId: "" }));
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
        setState((prev) => ({ ...prev, revertNoticeDocId: "" }));
      },
      enabled: state.revertNoticeDocId ? true : false,
    });

  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>
            {parseInt(cell.row.id) + 1 < 10
              ? `0${parseInt(cell.row.id) + 1}`
              : parseInt(cell.row.id) + 1}
          </Typography>
        ),
        maxSize: 30,
      },

      {
        header: "Created On",
        accessorKey: "createdOn",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {dayjs(original?.createdOn).format(DATE_FORMAT.withMonthName)}
          </Typography>
        ),
        maxSize: 40,
      },
      {
        header: "Publish Date",
        accessorKey: "publishDate",
        Cell: ({
          cell: {
            row: { original },
          },
        }) =>
          original?.active ? (
            <Typography>
              {dayjs(original?.publishDate).format(DATE_FORMAT.withMonthName)}
            </Typography>
          ) : (
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography>Draft</Typography>
              <Send
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    publishNoticeDocId: original?._id,
                  }))
                }
                title="Publish Notice"
                size={15}
                style={{ cursor: "pointer", color: "rgba(0, 169, 7, 1)" }}
              />
            </Stack>
          ),
        maxSize: 40,
      },

      {
        header: "Category",
        accessorKey: "category",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>{original?.category}</Typography>,
        maxSize: 40,
      },

      {
        header: "Title",
        accessorKey: "subject",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>{original?.subject}</Typography>,
        maxSize: 40,
      },
      {
        header: "Notice For",
        accessorKey: "noticeFor",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.noticeFor === "wholeSchool"
              ? "Whole School"
              : original?.noticeFor === "classNotice"
              ? "Class"
              : "Group"}
          </Typography>
        ),
        maxSize: 40,
      },

      {
        header: "Expires",
        accessorKey: "expiryDate",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {dayjs(original?.expiryDate).format(DATE_FORMAT.withMonthName)}
          </Typography>
        ),
        maxSize: 40,
      },

      {
        header: "Actions  ",
        Cell: ({ cell }) => {
          return (
            <Stack direction="row" gap={2} alignItems="center">
              {cell?.row?.original?.active && (
                <ArrowReturnLeft
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      revertNoticeDocId: cell?.row?.original?._id,
                    }))
                  }
                  title="Revert Notice"
                  size={22}
                  style={{ transform: "rotateX(180deg)", cursor: "pointer" }}
                />
              )}
              <button
                onClick={() => {
                  setViewNotice(cell?.row?.original);
                  setViewNoticeDialog(true);
                }}
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "8px",
                  border: "none",
                  outline: "none",
                  width: "30px",
                  height: "30px",
                }}
              >
                <Eye />
              </button>
              <button
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    isEdit: true,
                    editData: cell?.row?.original,
                    showaddEditNoticeDrawer: true,
                  }))
                }
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "8px",
                  border: "none",
                  outline: "none",
                  width: "30px",
                  height: "30px",
                }}
              >
                <PencilSquare />
              </button>
            </Stack>
          );
        },
        size: 40,
      },
    ];
  }, []);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  let header = [
    "createdOn",
    "publishDate",
    "category",
    "title",
    "noticeFor",
    "noticeNumber",
    "message",
    "createdByersonDocId",
    "personDocId",
    "publishPersonRole",
    "session",
    "active",
  ];
  let exportArray = getAllNoticeData?.data?.list?.map((item) => {
    return {
      createdOn: item?.createdOn,
      publishDate: item?.publishDate,
      category: item?.category,
      title: item?.subject,
      noticeFor: item?.noticeFor,
      noticeNumber: item?.noticeNumber,
      message: item?.message,
      createdByersonDocId: item?.createdBy?.personDocId,
      personDocId: item?.createdBy?.personName,
      publishPersonRole: item?.publishPersonRole,
      session: item?.session,
      active: item?.active,
    };
  });
  const [openCheckBox, setOpenCheckBox] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(header);
  const [openYoutubeDialog, setOpenYoutubeDialog] = useState(false);

  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };

  //for handling export
  const handleExport = () => {
    const filteredExportArray = exportArray?.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "notice");
    XLSX.writeFile(workbook, "notice.xlsx");
  };
  const videoLink = [
    {
      link: "https://www.youtube.com/embed/zISE4HR916w?si=jhQe3qBgbNVFrI5-",
      title: "How to send Notice",
    },
  ];
  // const handleDownload = async () => {
  //   const attachments = viewNotice?.attachments;
  //   if (attachments && attachments.length > 0) {
  //     for (const attachmentUrl of attachments) {
  //       try {
  //         const response = await fetch(attachmentUrl);
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! status: ${response.status}`);
  //         }
  //         const blob = await response.blob();
  //         const url = window.URL.createObjectURL(blob);
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", "attachment");
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //       } catch (error) {
  //         console.error(
  //           "There was a problem with the fetch operation: ",
  //           error
  //         );
  //       }
  //     }
  //   } else {
  //   }
  // };
  const handleDownload = async () => {
    const attachments = viewNotice?.attachments;
    if (attachments && attachments.length > 0) {
      attachments.forEach(async (attachmentUrl, index) => {
        try {
          // const response = await fetch(attachmentUrl);
          // const blob = await response.blob();
          const docName = `attachment_${index + 1}`;
          saveAs(attachmentUrl, docName);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      });
    } else {
    }
  };
  return (
    <>
      <CustomLoader
        show={
          publishNoticeLoading ||
          publishNoticeFetching ||
          revertNoticeLoading ||
          revertNoticeFetching
        }
      />

      {/* add edit notice drawer */}
      <AddNewNoticeDrawer
        show={state.showaddEditNoticeDrawer}
        handleCloseDrawer={() =>
          setState((prev) => ({
            ...prev,
            showaddEditNoticeDrawer: false,
            isEdit: false,
            editData: {},
          }))
        }
        isEdit={state.isEdit}
        editData={state.editData}
        refetchAllNotice={() => getAllNoticeFetch()}
      />
      {/* View Notice */}
      <ViewNoticeDialog
        open={noticeDialog}
        handleClose={() => setViewNoticeDialog(false)}
        viewNotice={viewNotice}
      />
      {/* <Dialog
        open={viewNotice}
        onClose={() => setViewNotice(null)}
        PaperProps={{
          sx: {
            minWidth: "595px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0 !important" }}>
          <DialogHeader
            title="Notice"
            handleClose={() => setViewNotice(null)}
          />
        </DialogTitle>
        <DialogContent sx={{ p: "0 !important" }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ pt: 1, px: "20px" }}
          >
            <Button
              color="secondary"
              onClick={handlePrint}
              variant="contained"
              sx={{
                width: "20%",
                textTransform: "capitalize",
                fontSize: "14px",
                letterSpacing: "1px",
              }}
            >
              Print
            </Button>
          </Stack>
          <NoticeDialogWrapper ref={printNoticeRef}>
            <Box>
              <Stack direction="row" alignItems="center">
                <Box
                  sx={{
                    ml: 1,
                    height: "80px",
                    width: "80px",
                    py: 1,
                  }}
                >
                  <img
                    src={selectedSchool?.schoolLogo}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt="schoolLogo"
                  />
                </Box>
                <Stack
                  direction="row"
                  sx={{ width: "calc(100% - 120px)" }}
                  justifyContent="center"
                >
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "17px",
                        textAlign: "center",
                      }}
                    >
                      {selectedSchool?.schoolName}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {selectedSchool?.schoolAddress?.address}
                      <br />
                      {selectedSchool?.schoolAddress?.dist},{" "}
                      {selectedSchool?.schoolAddress?.state},{" "}
                      {selectedSchool?.schoolAddress?.pinCode}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
              <Divider fullWidth sx={{ backgroundColor: "#000000" }} />
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ p: 1 }}
              >
                <Typography sx={{ fontSize: "15px" }}>
                  Ref No:{" "}
                  <span style={{ fontWeight: 700 }}>
                    {viewNotice?.noticeNumber}
                  </span>
                </Typography>

                <Typography sx={{ fontSize: "15px" }}>
                  Date:{" "}
                  <span style={{ fontWeight: 700 }}>
                    {viewNotice?.publishDate
                      ? dayjs(viewNotice?.publishDate).format("DD MMM, YYYY")
                      : "Draft"}
                  </span>
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="center">
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "900",
                    fontSize: "16px",
                    color: "#000000",
                    mt: 2,
                  }}
                >
                  Notice
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{ px: 2, pt: 2 }}
                justifyContent="center"
              >
                <Typography
                  style={{
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "15px",
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  Subject:{" "}
                  <span style={{ fontWeight: 700 }}>{viewNotice?.subject}</span>
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ px: 2, pt: 2 }}>
                <Typography
                  style={{
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16px",
                    textAlign: "justify",
                    whiteSpace: "pre-wrap",
                    color: "#000000",
                  }}
                >
                  {viewNotice?.message}
                </Typography>
              </Stack>
              <Stack flexWrap={"wrap"} gap={1} py={1} px={1}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Attachments
                </Typography>
                {viewNotice?.attachments?.length === 0 ? (
                  <Typography>No Attachments to display</Typography>
                ) : (
                  viewNotice?.attachments?.map((item, i) => (
                    <Box sx={{ my: 0.5 }} key={i}>
                      <PreviewDownloadImage src={item} documentName="Notice" />
                    </Box>
                  ))
                )}
              </Stack>
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ px: 2, py: 1 }}
              alignItems="flex-end"
            >
              <Stack direction="column" gap={0.5}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Notice For:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "15px",
                    textTransform: "capitalize",
                  }}
                >
                  {viewNotice?.viewerType}
                </Typography>
              </Stack>
              <Stack direction="column" alignItems={"center"}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {viewNotice?.createdBy?.personName}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                >
                  {String(viewNotice?.publishPersonRole).toLowerCase()}
                </Typography>
              </Stack>
            </Stack>
          </NoticeDialogWrapper>
        </DialogContent>
      </Dialog> */}
      {/* Dialog for selecting options for export*/}
      <Dialog
        open={openCheckBox}
        onClose={() => setOpenCheckBox(false)}
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to show in the excel file"
          handleClose={() => setOpenCheckBox(false)}
        />
        <DialogContent>
          <Box>
            {header?.map((option) => (
              <Stack direction="row">
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChange(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptions(header);
              handleExport();
              setOpenCheckBox(false);
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>
      <YoutubeVideoDialog
        open={openYoutubeDialog}
        handleCloseDialog={() => setOpenYoutubeDialog(false)}
        videoLink={videoLink}
      />

      <Container>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <ViewText>Communication</ViewText>
            <div
              style={{
                display: "flex",
                float: "right",
                alignItems: "flex-end",
                textAlign: "right",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                size="large"
                startIcon={<Plus />}
                color="secondary"
                sx={{ backgroundColor: "#fff", borderRadius: "10px" }}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    showaddEditNoticeDrawer: true,
                    isEdit: false,
                    editData: {},
                  }))
                }
              >
                Add New
              </Button>
              <YouTube
                sx={{
                  color: "#FF0000",
                  fontSize: "2.5rem",
                  cursor: "pointer",
                  mt: 2,
                }}
                onClick={() => setOpenYoutubeDialog(true)}
              />
            </div>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ my: 1 }}
            alignItems="center"
          >
            <Stack direction="row" alignItems="flex-end" gap={2}>
              <Box>
                <TextFieldLabel title="From" />
                <DesktopDatePicker
                  inputFormat="DD MMM, YYYY"
                  value={state.from}
                  onChange={(e) => setState((prev) => ({ ...prev, from: e }))}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select"
                      size="small"
                    />
                  )}
                />
              </Box>
              <Box>
                <TextFieldLabel title="To" />
                <DesktopDatePicker
                  inputFormat="DD MMM, YYYY"
                  value={state.to}
                  onChange={(e) => setState((prev) => ({ ...prev, to: e }))}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select"
                      size="small"
                    />
                  )}
                />
              </Box>
              <Box>
                <LoadingButton
                  variant="contained"
                  loading={getAllNoticeLoading || getAllNoticeFetching}
                  color="secondary"
                  size="large"
                  sx={{ mb: 0.5 }}
                  onClick={() => getAllNoticeFetch()}
                >
                  View
                </LoadingButton>
              </Box>
            </Stack>
            <ExportButtonGroup
              handleClickExcel={() => setOpenCheckBox(true)}
              showPdf={false}
            />
          </Stack>
          <CommonTable1
            columns={columns}
            data={getAllNoticeData?.data?.list}
            maxHeight="67vh"
          />
        </LocalizationProvider>
      </Container>
    </>
  );
};

export default NoticeBoard;

const ViewText = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "20px",
  color: "#232360",
}));
const NoticeDialogWrapper = styled(Box)`
  border: 1px solid #000;
  margin: 10px 20px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
