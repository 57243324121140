import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import CustomMenu from "app/components/CustomMenu";
import { ChevronDown } from "react-bootstrap-icons";
import CustomToggle from "app/components/CustomDropdownToggle";

import styled from "styled-components";
import { useMediaQuery } from "@mui/material";

const Session = ({ handleSessionSelection, selectedSession, sessions }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <StyledWrapper className="d-flex text-color">
      <Dropdown
        className={isMobile ? "mobile-session-container" : "session-container"}
      >
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
          size="sm"
        >
          <div
            className={isMobile ? "fs-6 text-color" : "text-color"}
            style={{ marginBottom: "-10px", marginTop: "7px" }}
          >
            Session: {selectedSession} <ChevronDown />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} className="w-100 p-3 text-color">
          {sessions.map((session) => (
            <Dropdown.Item
              key={session}
              onClick={() => handleSessionSelection(session)}
              active={session === selectedSession}
              eventKey={session}
              className="p-3 w-100"
            >
              {session}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledWrapper>
  );
};

export const Medium = ({
  handleMediumSelection,
  mediumList,
  selectedMedium,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <StyledWrapper className="d-flex text-color">
      <Dropdown
        className={isMobile ? "mobile-session-container" : "session-container"}
      >
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
          size="sm"
        >
          <>
            <div
              className={isMobile ? " fs-6 text-color" : "text-color"}
              style={{ marginBottom: "-10px", marginTop: "7px" }}
            >
              Medium-{selectedMedium} <ChevronDown />
            </div>
          </>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} className="w-100 p-3 text-color">
          {mediumList.map((medium) => (
            <Dropdown.Item
              key={medium}
              onClick={() => handleMediumSelection(medium)}
              active={medium === selectedMedium}
              eventKey={medium}
              className="p-3 w-100"
            >
              {medium}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .text-color {
    color: #084c84;
    font-size: 1rem;
  }

  .mobile-session-container {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 8px;
    font-weight: 200;
    padding: 0 5px 0 5px;
    font-size: 5px;
    align-items: center;
  }

  .session-container {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 8px;
    font-weight: 200;
    padding: 0 10px;
    margin: 0 5px;
    font-size: 5px;
    align-items: center;
  }
`;

export default Session;
