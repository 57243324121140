import Fallback from "app/components/Fallback";
import React from "react";

const StaffManagement = React.lazy(() =>
    import("app/modules/hr/pages/StaffManagement")
);

const StaffProfile = React.lazy(() =>
    import("app/modules/hr/pages/StaffProfile")
);

const StaffLeave = React.lazy(() =>
    import("app/modules/hr/pages/StaffLeave")
);

export const StaffManagementPage = (props) => (
    <React.Suspense fallback={<Fallback />}>
        <StaffManagement  {...props} />
    </React.Suspense>
);

export const StaffProfilePage = (props) => (
    <React.Suspense fallback={<Fallback />}>
        <StaffProfile  {...props} />
    </React.Suspense>
);

export const StaffLeavePage = (props) => (
    <React.Suspense fallback={<Fallback />}>
        <StaffLeave  {...props} />
    </React.Suspense>
);

