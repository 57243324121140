import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { changeStatusOfAdmissionEnquiry } from "app/services/schoolService/admissionEnquiry.service";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { toast } from "react-toastify";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import DialogHeader from "app/components/common/DialogHeader";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { MenuProps } from "app/utils/helper";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  remarks: yup.string().required("remarks is required"),
  paymentStatus: yup.string().required("paymentStatus is required"),
  enquiryStatus: yup.string().required("enquiryStatus is required"),
});
const ChangeStatusDialog = ({
  open,
  handleClose,
  data,
  refetch = () => {},
}) => {
  const formik = useFormik({
    initialValues: {
      remarks: "",
      paymentStatus: "",
      enquiryStatus: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let body = {
        admissionEnquiryDocId: data._id,
        enquiryStatus: values.enquiryStatus,
        paymentStatus: values.paymentStatus,
        remarks: values.remarks,
      };
      changeStatusOfAdmissionEnquiryMutate(body);
    },
  });
  const {
    mutate: changeStatusOfAdmissionEnquiryMutate,
    isLoading: changeStatusOfAdmissionEnquiryLoading,
  } = useMutation(changeStatusOfAdmissionEnquiry, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      refetch();
      formik.resetForm();
      handleClose();
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  useEffect(() => {
    if (open) {
      formik.setValues({
        remarks: data?.remarks,
        paymentStatus: data?.paymentStatus,
        enquiryStatus: data?.enquiryStatus,
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { minWidth: "25vw" },
      }}
    >
      <DialogHeader title="Change Status" handleClose={handleClose} />
      <DialogContent>
        <form id="change_status_form" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextFieldLabel title="Enquiry Status" />
              <CustomSelectBox
                displayEmpty
                size="small"
                fullWidth
                name="enquiryStatus"
                onChange={formik.handleChange}
                value={formik.values.enquiryStatus}
                error={
                  formik.touched.enquiryStatus &&
                  Boolean(formik.errors?.enquiryStatus)
                }
                MenuProps={MenuProps}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Won">Won</MenuItem>
                <MenuItem value="Lost">Lost</MenuItem>
              </CustomSelectBox>
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Payment Status" />
              <CustomSelectBox
                displayEmpty
                size="small"
                fullWidth
                name="paymentStatus"
                onChange={formik.handleChange}
                value={formik.values.paymentStatus}
                error={
                  formik.touched.paymentStatus &&
                  Boolean(formik.errors?.paymentStatus)
                }
                MenuProps={MenuProps}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Paid">Paid</MenuItem>
                <MenuItem value="Not Paid">Not Paid</MenuItem>
              </CustomSelectBox>
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Remarks" />
              <CustomTextField
                size="small"
                id="remarks"
                name="remarks"
                placeholder="Type Remarks"
                fullWidth
                value={formik.values.remarks}
                onChange={formik.handleChange}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                helperText={formik.touched.remarks && formik.errors.remarks}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} justifyContent={"center"} gap={2}>
          <LoadingButton
            color="secondary"
            variant="outlined"
            size="medium"
            type="submit"
            onClick={() => handleClose()}
          >
            CANCEL
          </LoadingButton>
          <LoadingButton
            color="success"
            loading={changeStatusOfAdmissionEnquiryLoading}
            variant="contained"
            size="medium"
            type="submit"
            form="change_status_form"
          >
            CONFIRM
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default ChangeStatusDialog;
