import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
} from "@mui/material";
import * as XLSX from "xlsx";
import DialogHeader from "../DialogHeader";
const ExportDialog = ({
  open = false,
  handleClose = () => {},
  header = [],
  fileName = "Report",
  exportArray = [],
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCloseDialog = () => {
    handleClose();
  };
  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };
  const handleExport = () => {
    const filteredExportArray = exportArray.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  useEffect(() => {
    setSelectedOptions(header);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: { minWidth: "400px" },
      }}
    >
      <DialogHeader
        title="Select Information to show in the excel file"
        handleClose={handleCloseDialog}
      />
      <DialogContent>
        <Box>
          {header.map((option) => (
            <Stack direction="row">
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={selectedOptions.includes(option)}
                    onChange={(e) =>
                      handleCheckboxChange(option, e.target.checked)
                    }
                  />
                }
                label={option}
              />
            </Stack>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mb: 1 }}
          onClick={() => {
            setSelectedOptions(header);
            handleExport();
            handleCloseDialog();
          }}
        >
          DownLoad
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
