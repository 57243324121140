import Fallback from "app/components/Fallback";
import React from "react";
const FeeCollection2 = React.lazy(() =>
  import("app/modules/fee-management/fee-collection2/FeeIndex")
);
const FeeReport = React.lazy(() =>
  import("app/modules/fee-management/fee-report/FeeReport")
);
const Arrear = React.lazy(() =>
  import("app/modules/fee-management/arrear/index")
);
const CollectFee = React.lazy(() =>
  import("app/modules/fee-management/collect-fee/CollectFee")
);

export const FeeCollection2Page = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <FeeCollection2 {...props} />
  </React.Suspense>
);
export const CollectFeePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <CollectFee {...props} />
  </React.Suspense>
);

export const FeesReportPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <FeeReport {...props} />
  </React.Suspense>
);

export const ArrearPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Arrear {...props} />
  </React.Suspense>
);
