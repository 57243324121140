import styled from "styled-components";

export const FormioStyleOverrider = styled.div`
  .auth {
    div[ref="component"] {
      label[ref="label"] {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
      }

      div[ref="element"] {
        input.form-control {
          border: 1px solid var(--border-color);
          border-radius: 11px;
          height: 53px;

          &::placeholder {
            line-height: 18px;
            font-size: 15px;
            font-weight: 400;
            color: var(--placeholder-color);
          }
        }
      }

      &.formio-component-button {
        margin-top: 1rem;
        button {
          border-radius: 80px 0px 160px 80px !important;
          background-color: var(--primary-color);
          border: none;
          height: 54px;
        }
      }
    }
  }

  div.input-group-append {
    span.input-group-text {
      height: 100%;
      border-radius: 0px 0.375rem 0.375rem 0px !important;
    }
  }
`;
