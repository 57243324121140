import Fallback from "app/components/Fallback";
import React from "react";
// import Expenses from "./pages/Expennses";
import Expenses from "./pages/expenses/ExpensesPage";
import LedgerReport from "./pages/ledgerReport/LedgerReport";
import FirmInvoice from "./pages/firmInvoice/FirmInvoice";

const Collections = React.lazy(() =>
  import("app/modules/LedgerAccount/pages/collections/collections")
);

export const CollectionPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Collections {...props} />
  </React.Suspense>
);
export const ExpensesPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Expenses {...props} />
  </React.Suspense>
);
export const LedgerReportPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <LedgerReport {...props} />
  </React.Suspense>
);
export const FirmInvoicePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <FirmInvoice {...props} />
  </React.Suspense>
);
