import React, { useEffect, useState } from "react";
import CommonFilterDialog from "../CommonFilterDialog";
import FilterButton from "../../FilterButton";
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Stack,
} from "@mui/material";
import { MenuProps } from "app/utils/helper";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import TextFieldLabel from "../../TextFieldLabel";
import { useQuery } from "react-query";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { getClassDropdown } from "app/services/student-management";
import { getStudents1 } from "app/services/schoolService/studentProfile";
import CustomLoader from "../../CustomLoader";
import StudentCard from "../ledger/books/components/StudentCard";

const ConvertInvoiceFilter = ({ mutate }) => {
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const [checkboxes, setCheckboxes] = useState({
    converted: false,
    notConverted: true,
  });
  const [state, setState] = useState({
    filterDialog: false,
    classDocId: "",
    className: "",
    section: "",
    studentDocId: "",
    sections: [],
    selectedStudent: "",
  });
  const {
    isLoading: getClassDropdownLoading,
    isFetching: getClassDropdownFetching,
    data: getClassDropdownData,
  } = useQuery({
    queryKey: ["getClassDropdown", selectedSchoolId, selectedMedium],
    queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
  });
  const {
    data: getStudentsData,
    isLoading: getStudentsDataLoading,
    isFetching: getStudentsDataFetching,
  } = useQuery({
    queryKey: ["getStudents1", state.section],
    queryFn: () =>
      getStudents1({
        classDocId: state.classDocId,
        section: state.section,
        session: selectedSession,
      }),
    enabled: state.section ? true : false,
  });
  useEffect(() => {
    if (state.classDocId) {
      const filteredData = getClassDropdownData?.data?.list?.find(
        (item) => item?.classDocId === state.classDocId
      );
      setState((prev) => ({
        ...prev,
        sections: filteredData?.sections,
        section: "",
        className: filteredData?.className,
      }));
    }
  }, [state.classDocId]);
  const handleSaveAndView = () => {
    mutate({
      ...(state.className && { className: state.className }),
      ...(state.section && state.className && { section: state.section }),
      ...(state.studentDocId && { studentDocId: state.studentDocId }),
      ...(checkboxes.converted &&
        !checkboxes.notConverted && {
          convertedToNewInvoice: true,
        }),
      ...(checkboxes.notConverted &&
        !checkboxes.converted && {
          convertedToNewInvoice: false,
        }),
    });
    setState((prev) => ({ ...prev, filterDialog: false }));
  };
  return (
    <>
      <CustomLoader show={getStudentsDataLoading || getStudentsDataFetching} />
      <CommonFilterDialog
        onClose={() => setState((prev) => ({ ...prev, filterDialog: false }))}
        open={state.filterDialog}
        title="Filter"
        minWidth="450px"
        handleSaveAndView={handleSaveAndView}
      >
        <DialogContent>
          <Stack direction={"row"}>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.notConverted}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  notConverted: !prev.notConverted,
                }))
              }
              componentsProps={{
                typography: { fontWeight: 600, fontSize: "14px" },
              }}
              label="Not Converted"
            />
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.converted}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  converted: !prev.converted,
                }))
              }
              componentsProps={{
                typography: { fontWeight: 600, fontSize: "14px" },
              }}
              label="Converted"
            />
          </Stack>
          <Stack pt={1}>
            <Stack direction="row" gap={2}>
              <Stack flex={1}>
                <TextFieldLabel title="Class-Stream" />
                <CustomSelectBox
                  size="small"
                  select
                  MenuProps={MenuProps}
                  displayEmpty
                  value={state.classDocId}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      classDocId: e.target.value,
                      className: "",
                      section: "",
                    }))
                  }
                  fullWidth
                >
                  <MenuItem value="" component="option">
                    Select
                  </MenuItem>

                  {getClassDropdownData?.data?.list?.map((opt, i) => {
                    return (
                      <MenuItem
                        component="option"
                        key={opt?.classDocId}
                        value={opt?.classDocId}
                      >
                        {opt?.className_stream}
                      </MenuItem>
                    );
                  })}
                </CustomSelectBox>
              </Stack>

              <Stack flex={1}>
                <TextFieldLabel title="Section" />
                <CustomSelectBox
                  MenuProps={MenuProps}
                  displayEmpty
                  size="small"
                  fullWidth
                  value={state?.section}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      section: e.target.value,
                    }))
                  }
                  disabled={!state?.classDocId}
                >
                  <MenuItem value="" component="option">
                    Select
                  </MenuItem>

                  {state?.sections &&
                    state?.sections.map((opt, i) => {
                      return (
                        <MenuItem key={i} value={opt} component="option">
                          {opt}
                        </MenuItem>
                      );
                    })}
                </CustomSelectBox>
              </Stack>
            </Stack>
            <Stack pt={1}>
              <TextFieldLabel title="Student Name" />
              <CustomSelectBox
                size="small"
                select
                MenuProps={MenuProps}
                displayEmpty
                value={state.studentDocId}
                renderValue={() =>
                  state?.classDocId === ""
                    ? () => "Select"
                    : state?.selectedStudent?.firstName
                }
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    studentDocId: e.target.value?._id,
                    selectedStudent: e.target.value,
                  }))
                }
                fullWidth
              >
                <MenuItem value="" component="option">
                  Select
                </MenuItem>

                {getStudentsData?.data?.list?.map((student, idx) => (
                  <MenuItem key={student._id} value={student}>
                    <StudentCard item={student} />
                  </MenuItem>
                ))}
              </CustomSelectBox>
            </Stack>
          </Stack>
        </DialogContent>
      </CommonFilterDialog>
      <FilterButton
        hanldeClick={() =>
          setState((prev) => ({ ...prev, filterDialog: true }))
        }
        count={1}
      />
    </>
  );
};

export default ConvertInvoiceFilter;
