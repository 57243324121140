import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import certificateBG from "../../../../../../../assets/media/images/certificate-Images/CertificateBG.png";
import styled from "styled-components";
import SchoolPrintHeader3 from "app/components/common/SchoolPrintHeader3";
import CertificateContentLabel, {
  CertificateContentLabelA4,
} from "app/components/common/CertificateContentLabel";
import { FormatFullName } from "app/utils/helper";
import moment from "moment";
import A4SchoolCertificateHeader from "app/components/common/A4SchoolCertificateHeader";
import {
  CertificateBottomDetails,
  CertificateBottomDetailsA4,
  CertificateHeaderDetails,
  CertificateHeaderDetailsA4,
  ContentOfCertificate,
} from "../utils/CertificateHelper";

const A4CharacterCertificatePrint = ({ studentData, color, printRef }) => {
  return (
    <Wrapper ref={printRef}>
      <Box
        style={{
          width: "595px",
          height: "842px",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundBlendMode: "normal",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        sx={{
          alignSelf: "center",
          border: "1px solid",
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            backgroundColor: "red",
          }}
        >
          <A4SchoolCertificateHeader />
        </Stack>
        <Divider
          sx={{
            backgroundColor: "#000",
            height: "1px",
            width: "100%",
            margin: "0 auto",
            alignSelf: "center",
          }}
        />

        {/* title of the certificate */}
        <Stack direction="row" justifyContent="center" marginTop={1}>
          <Box>
            <Box className="heading">Birth Certificate</Box>
          </Box>
        </Stack>
        {/* certificate label */}
        <Stack direction="row" justifyContent="center" bgcolor={"blue"}>
          <CertificateContentLabelA4 contentTittle={"TO WHOM IT MAY CONCERN"} />
        </Stack>

        {/* content of the certificate */}
        <CertificateHeaderDetailsA4 value={studentData} />

        {/* content for certificate */}
        <Stack
          sx={{
            backgroundColor: "green",
            height: "500px",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <ContentOfCertificate
            certificateType={"character"}
            studentData={studentData}
          />
        </Stack>

        {/* bottom date */}
        <CertificateBottomDetailsA4 />
      </Box>
    </Wrapper>
  );
};

export default A4CharacterCertificatePrint;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .heading {
    font-weight: 700;
    font-size: 25px;
  }
  .sub-heading {
    font-weight: 700;
    font-size: 10px;
  }
  .content {
    font-size: 20px;
    alingself: center;
  }
  .content-bold {
    font-weight: 700;
    font-size: 16px;
  }
  .content-italic {
    font-style: italic;
    font-size: 10px;
  }
  .content-underline {
    text-decoration: underline;
    font-size: 10px;
  }
  .content-uppercase {
    text-transform: uppercase;
    font-size: 10px;
  }

  @media (max-width: 768px) {
    .heading {
      font-size: 20px;
    }
    .sub-heading {
      font-size: 8px;
    }
    .content {
      font-size: 10px;
    }
    .content-bold {
      font-size: 8px;
    }
    .content-italic {
      font-size: 8px;
    }
    .content-underline {
      font-size: 8px;
    }
    .content-uppercase {
      font-size: 8px;
    }
  }
`;
