import { LoadingButton } from "@mui/lab";
import {
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import dayjs from "dayjs";
import CommonDialog from "../CommonDialog";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
const CustomErrorText = ({ text }) => {
  return (
    <Typography color="error" fontSize={"11px"}>
      {text}
    </Typography>
  );
};
const dt = new Date();
const validationSchema = yup.object().shape({
  //schoolDocId: yup.string().required(),
  sessionName: yup.string().required(),
  startDate: yup.string().required(),
  endDate: yup.date().required(),
  summerTime: yup.object({
    fromTime: yup.string().required(),
    toTime: yup.string().required(),
  }),
  winterTime: yup.object({
    fromTime: yup.string().required(),
    toTime: yup.string().required(),
  }),
  subscriptionType: yup.string().required(),
  studentLimitCount: yup.string().required(),
  remarks: yup.string().required(),
});

const AddNewSessionDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  schoolDocId = "",
}) => {
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      sessionName: "",
      startDate: dt,
      endDate: dt,
      summerTime: {
        fromTime: dayjs().format("mm:ss"),
        toTime: dayjs().format("mm:ss"),
      },
      winterTime: {
        fromTime: dayjs().format("mm:ss"),
        toTime: dayjs().format("mm:ss"),
      },
      subscriptionType: "",
      studentLimitCount: "",
      remarks: "",
    },
    validationSchema,
    onSubmit: (formValues) => {
      // mutate({ schoolDocId, ...formValues });
    },
  });
  const hanldeCloseDialog = () => {
    onClose();
    resetForm();
  };
  // const { mutate, isLoading } = useMutation(addSession, {
  //   onSuccess: (success) => {
  //     toast.success(success?.data?.message);
  //     refetch();
  //     hanldeCloseDialog();
  //   },
  //   onError: (error) => {
  //     toast.error(error?.response?.data?.message);
  //   },
  // });

  return (
    <CommonDialog
      open={open}
      onClose={hanldeCloseDialog}
      minWidth="600px"
      title="Add New Session"
    >
      <DialogContent>
        <Wrapper>
          <form onSubmit={handleSubmit} id="addNewSession">
            {/* session, startdate, enddate */}
            <Grid container spacing={2} className="form-control">
              <Grid item xs={4}>
                <Typography className="heading">Select Session</Typography>
                <CustomSelectBox
                  displayEmpty
                  size="small"
                  fullWidth
                  name="sessionName"
                  type="text"
                  value={values?.sessionName}
                  onChange={handleChange}
                  error={touched.sessionName && Boolean(errors.sessionName)}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="2023-2024">2023-2024</MenuItem>
                  <MenuItem value="2024-2025">2024-2025</MenuItem>
                  <MenuItem value="2025-2026">2025-2026 </MenuItem>
                </CustomSelectBox>
                {touched.sessionName && Boolean(errors.sessionName) && (
                  <CustomErrorText text={errors.sessionName} />
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className="heading">Start Date</Typography>

                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  name="startDate"
                  value={values?.startDate}
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, startDate: e }))
                  }
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      fullWidth
                      error={touched.startDate && Boolean(errors.startDate)}
                    />
                  )}
                />
                {touched.startDate && Boolean(errors.startDate) && (
                  <CustomErrorText text={errors.startDate} />
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className="heading">End Date</Typography>
                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  name="endDate"
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, endDate: e }))
                  }
                  value={values?.endDate}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      fullWidth
                      error={touched.endDate && Boolean(errors.endDate)}
                    />
                  )}
                />
                {touched.endDate && Boolean(errors.endDate) && (
                  <CustomErrorText text={errors.endDate} />
                )}
              </Grid>
            </Grid>

            {/* Summer -> Intime, Outtime */}
            <Grid container spacing={2} className="form-control">
              <Grid item xs={4}>
                <Typography className="summer_heading">Summer Time</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="heading">In Time</Typography>
                <CustomTextField
                  size="small"
                  fullWidth
                  name="summerTime.fromTime"
                  type="time"
                  onChange={handleChange}
                  value={values?.summerTime?.fromTime}
                  error={
                    touched.summerTime?.fromTime &&
                    Boolean(errors.summerTime?.fromTime)
                  }
                />
                {touched.summerTime?.fromTime &&
                  Boolean(errors.summerTime?.fromTime) && (
                    <CustomErrorText text={errors.summerTime?.fromTime} />
                  )}
              </Grid>
              <Grid item xs={4}>
                <Typography className="heading">Out Time</Typography>
                <CustomTextField
                  size="small"
                  fullWidth
                  name="summerTime.toTime"
                  type="time"
                  onChange={handleChange}
                  value={values?.summerTime?.toTime}
                  error={
                    touched.summerTime?.toTime &&
                    Boolean(errors.summerTime?.toTime)
                  }
                />
                {touched.summerTime?.toTime &&
                  Boolean(errors.summerTime?.toTime) && (
                    <CustomErrorText text={errors.summerTime?.toTime} />
                  )}
              </Grid>
            </Grid>

            {/* Winter -> Intime, Outtime */}
            <Grid container spacing={2} className="form-control">
              <Grid item xs={4}>
                <Typography className="summer_heading">Winter Time</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="heading">In Time</Typography>
                <CustomTextField
                  size="small"
                  fullWidth
                  name="winterTime.fromTime"
                  type="time"
                  onChange={handleChange}
                  value={values?.winterTime?.fromTime}
                  error={
                    touched.winterTime?.fromTime &&
                    Boolean(errors.winterTime?.fromTime)
                  }
                />
                {touched.winterTime?.fromTime &&
                  Boolean(errors.winterTime?.fromTime) && (
                    <CustomErrorText text={errors.winterTime?.fromTime} />
                  )}
              </Grid>
              <Grid item xs={4}>
                <Typography className="heading">Out Time</Typography>
                <CustomTextField
                  size="small"
                  fullWidth
                  name="winterTime.toTime"
                  type="time"
                  onChange={handleChange}
                  value={values?.winterTime?.toTime}
                  error={
                    touched.winterTime?.toTime &&
                    Boolean(errors.winterTime?.toTime)
                  }
                />
                {touched.winterTime?.toTime &&
                  Boolean(errors.winterTime?.toTime) && (
                    <CustomErrorText text={errors.winterTime?.toTime} />
                  )}
              </Grid>
            </Grid>

            {/* Subscription Type, Student Limit, Registration Charge */}
            <Grid container spacing={2} className="form-control">
              <Grid item xs={4}>
                <Typography className="heading">Subscription Type</Typography>
                <CustomSelectBox
                  //MenuProps={MenuProps}
                  size="large"
                  fullWidth
                  displayEmpty
                  name="subscriptionType"
                  onChange={handleChange}
                  value={values?.subscriptionType}
                  defaultValue={values?.subscriptionType}
                  error={
                    touched.subscriptionType && Boolean(errors.subscriptionType)
                  }
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="Free">Free</MenuItem>
                  <MenuItem value="Basic">Basic</MenuItem>
                  <MenuItem value="Advance">Advance</MenuItem>
                  <MenuItem value="Pro">Pro</MenuItem>
                </CustomSelectBox>
                {touched.subscriptionType &&
                  Boolean(errors.subscriptionType) && (
                    <CustomErrorText text={errors.subscriptionType} />
                  )}
              </Grid>
              <Grid item xs={4}>
                <Typography className="heading">Student Limit</Typography>
                <CustomTextField
                  size="small"
                  fullWidth
                  name="studentLimitCount"
                  placeholder="Type student limit"
                  type="number"
                  onChange={handleChange}
                  value={values?.studentLimitCount}
                  error={
                    touched.studentLimitCount &&
                    Boolean(errors.studentLimitCount)
                  }
                />
                {touched.studentLimitCount &&
                  Boolean(errors.studentLimitCount) && (
                    <CustomErrorText text={errors.studentLimitCount} />
                  )}
              </Grid>
            </Grid>

            <Grid container spacing={2} className="form-control">
              <Grid item xs={12}>
                <Typography className="heading">Remark </Typography>
                <CustomTextField
                  size="small"
                  fullWidth
                  name="remarks"
                  value={values?.remarks}
                  onChange={handleChange}
                  placeholder="remarks"
                  error={touched.remarks && Boolean(errors.remarks)}
                />
                {touched.remarks && Boolean(errors.remarks) && (
                  <CustomErrorText text={errors.remarks} />
                )}
              </Grid>
            </Grid>
          </form>
        </Wrapper>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          form="addNewSession"
          type="submit"
          // loading={isLoading}
          variant="contained"
          color="secondary"
          sx={{ fontSize: "14px", textTransform: "capitalize", px: 3 }}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

export default AddNewSessionDialog;
const Wrapper = styled("div")`
  .heading {
    color: #0c2f49;
    font-size: 14px;
    font-weight: 500;
  }
  .summer_heading {
    margin-top: 20px;
    padding: 8px;
    //height: 46px;
    flex-shrink: 0;
    color: #0c2f49;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid rgba(182, 200, 214, 0.3);
    background: rgba(182, 200, 214, 0.2);
    color: #0c2f49;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .form-control {
    margin-bottom: 15px;
  }
  .AddMore {
    color: #2789fd;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
`;
