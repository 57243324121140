import { Box, Divider, Stack, Typography, styled } from "@mui/material";
import SchoolPrintHeader from "app/components/common/SchoolPrintHeader";
import { DOB_in_Word, FormatFullName } from "app/utils/helper";
import dayjs from "dayjs";
import React from "react";
import { ToWords } from "to-words";

const TcTemplate1 = ({ data, color, printRef }) => {
  const toWords = new ToWords();
  return (
    <>
      <Wrapper ref={printRef} style={{ backgroundColor: color }}>
        <SchoolPrintHeader />
        <Divider fullWidth sx={{ backgroundColor: "#000000" }} />
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "12px",
            textAlign: "center",
            lineHeight: "1.2",
            padding: "10px",
            textDecoration: "underline",
          }}
        >
          Transfer Certificate{" "}
        </Typography>
        <Box sx={{ px: "2em" }}>
          <Stack
            direction="row"
            className="upperContent"
            justifyContent="space-between"
          >
            <Typography className="upperTypo">
              SR Number : {data?.studentProfile?.srNumber ?? ".............."}
            </Typography>
            <Typography className="upperTypo">
              PEN Number :{" "}
              {data?.studentProfile?.penNumber_center ?? ".............."}
            </Typography>

            <Typography className="upperTypo">
              Admission Number :{" "}
              {data?.studentProfile?.admissionNumber ?? ".............."}
            </Typography>
          </Stack>
          <Stack direction="row" className="upperContent">
            <Typography className="upperTypo">
              Reg Number :{" "}
              {data?.studentProfile?.registrationNumber ?? ".............."}
            </Typography>
            <Typography className="upperTypo">
              TC Number : {data?.tcNumber}
            </Typography>
            <Typography className="upperTypo">
              Book Number :{" "}
              {data?.bookNumber ? data?.bookNumber : ".............."}
            </Typography>
          </Stack>
          <Stack sx={{ paddingTop: "2em" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              Registration No. of the candidate ( in case of Class -{" "}
              <span style={{ fontWeight: 600 }}>IX</span> to{" "}
              <span style={{ fontWeight: 600 }}>XII</span> ) : .............
            </Typography>
            <StackWrapper direction="row" gap={2}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  width: "180px",
                }}
              >
                1. Name of the Pupil :
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                ........
                {FormatFullName(
                  data?.studentProfile?.firstName,
                  data?.studentProfile?.middleName,
                  data?.studentProfile?.lastName
                )}
                ........
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row" gap={2}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  width: "180px",
                }}
              >
                2. Father’s/Gurardian's Name :
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                ........
                {FormatFullName(
                  data?.studentProfile?.fatherInfo?.firstName,
                  data?.studentProfile?.fatherInfo?.middleName,
                  data?.studentProfile?.fatherInfo?.lastName
                )}
                ........
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row" gap={2}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  width: "180px",
                }}
              >
                3. Mother’s Name :
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                ........
                {FormatFullName(
                  data?.studentProfile?.motherInfo?.firstName,
                  data?.studentProfile?.motherInfo?.middleName,
                  data?.studentProfile?.motherInfo?.lastName
                )}
                ........
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row" gap={2}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  width: "180px",
                }}
              >
                4. Nationality :
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                ........Indian ........
              </Typography>
            </StackWrapper>
            {/* <StackWrapper direction="row" gap={2}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      width: "180px",
                    }}
                  >
                    4. Community :
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "12px",
                    }}
                  >
                    ........{data?.community}
                    ........
                  </Typography>
                </StackWrapper> */}
            <StackWrapper direction="row" alignItems="center">
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",

                  width: "280px",
                }}
              >
                5. Class in which the pupil last studied:
              </Typography>
              <Stack direction="row" gap={0.5} sx={{ minWidth: "250px" }}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  Class:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  {/* {data?.lastClassDetail?.className}-
                      {data?.lastClassDetail?.stream}-
                      {data?.lastClassDetail?.section} */}
                  ...{data?.lastClassDetail?.className}...
                </Typography>
              </Stack>
              {/* <Stack direction="row" alignItems="center" gap={0.5}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      Date :{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {dayjs(data?.lastClassDetail?.admissionDate).format(
                        "DD-MM-YYYY"
                      )}
                    </Typography>
                  </Stack> */}
            </StackWrapper>
            <StackWrapper direction="row" gap={2}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                6. Whether the pupil belogns to SC/ST/OBC Category :
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "12px",
                }}
              >
                ...........{data?.studentProfile?.category}
                ...........
              </Typography>
            </StackWrapper>

            <StackWrapper direction="column" gap={0.5}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                7. Date of Birth according to the Admission Register:
              </Typography>
              <Box sx={{ ml: 3 }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  (In Figures) : .......
                  <span style={{ fontWeight: 600 }}>
                    {dayjs(data?.studentProfile?.DOB).isValid()
                      ? dayjs(data?.studentProfile?.DOB).format("DD-MM-YYYY")
                      : "--"}
                  </span>
                  .......
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                    fontSize: "12px",
                  }}
                >
                  (In Words) : .......
                  <span style={{ fontWeight: 600, textTransform: "uppercase" }}>
                    {DOB_in_Word(data?.studentProfile?.DOB)}
                  </span>
                  .......
                </Typography>
              </Box>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                8. Subject Offered :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600, ml: 2 }}>
                ...........{data?.lastClassDetail?.subjects?.join(", ")}
                ...........
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row" gap={1}>
              <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
                9. Date of first Admission in the School with Class :
              </Typography>

              <Typography
                sx={{
                  fontWeight: 600,
                  fontize: "12px",
                }}
              >
                ...........
                {dayjs(data?.firstAdmissionDetail?.admissionDate).format(
                  "DD-MM-YYYY"
                )}
                {/* .....
                    {data?.firstAdmissionDetail?.className}-
                    {data?.firstAdmissionDetail?.stream}-
                    {data?.firstAdmissionDetail?.section}........... */}
                .....{data?.firstAdmissionDetail?.className}...........
              </Typography>
            </StackWrapper>

            <StackWrapper direction="row">
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  width: "350px",
                }}
              >
                10. School/Board Annual Examination last taken with result :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                .......................{data?.lastClassDetail?.result}
                .......................
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  width: "350px",
                }}
              >
                11.Whether failed if so (once/twice) in same class :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                .......................
                {data?.lastClassDetail?.failCount === 0 ? "No" : "Yes"}
                .......................
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography
                sx={{ fontSize: "12px", fontWeight: 400, width: "350px" }}
              >
                12. Whether qualified for promotion to the next higher class :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                ..............{data?.ableToPromote ? "Yes" : "No"}{" "}
                ..............
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  width: "240px",
                  marginLeft: "4em",
                  marginRight: "1em",
                }}
              >
                If so, to which class :
              </Typography>
              <Typography className="rightTypo">
                ..............{data?.nextClass} ..............
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography
                sx={{ fontSize: "12px", fontWeight: 400, width: "350px" }}
              >
                13. Whether the pupil has paid all dues of the School?
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                ..............{data?.isDuesCleared ? "Yes" : "No"}
                ..............
              </Typography>
            </StackWrapper>

            <StackWrapper direction="row">
              <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                14. Whether the pupil was in receipt of any fee concession :
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row" sx={{ marginLeft: "4em" }}>
              <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                if so the nature of such concession :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600, ml: 8 }}>
                .......{data?.availedFeeConcession}{" "}
                {data?.availedFeeConcession === "Yes" &&
                  `(${data?.availedFeeConcessionRemarks})`}
                .......
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                15. Date on which pupil's name was struck off from the rolls of
                school :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600, ml: 2 }}>
                .......
                {dayjs(data?.lastClassDetail?.relievingDate).format(
                  "DD-MM-YYYY"
                )}
                .......
              </Typography>
            </StackWrapper>

            <StackWrapper direction="row">
              <Typography
                sx={{ fontSize: "12px", fontWeight: 400, width: "230px" }}
              >
                16. Date of issuing Certificate :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                ..............
                {dayjs(data?.certificateIssueDate).format("DD-MM-YYYY")}
                ..............
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                17. Whether participated in NCC Cadets / Boy Scout / Girl-Guide
                (Details may be given) :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600, ml: 2 }}>
                .........{data?.participated_in_NCC}.........
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography
                sx={{ fontSize: "12px", fontWeight: 400, width: "230px" }}
              >
                18. Reason for leaving the school :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                ..............
                {data?.lastClassDetail?.relievingReason}
                ..............
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography
                sx={{ fontSize: "12px", fontWeight: 400, width: "280px" }}
              >
                19. Number of attendance upto Last date :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                ..............{data?.totalWorkingDays}
                ..............
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography
                sx={{ fontSize: "12px", fontWeight: 400, width: "280px" }}
              >
                20. Number of school days the pupil attended :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                ..............{data?.totalPresent}
                ..............
              </Typography>
            </StackWrapper>

            <StackWrapper direction="row">
              <Typography
                sx={{ fontSize: "12px", fontWeight: 400, width: "230px" }}
              >
                21. General Conduct :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                ..............{data?.generalConduct}
                ..............
              </Typography>
            </StackWrapper>
            <StackWrapper direction="row">
              <Typography
                sx={{ fontSize: "12px", fontWeight: 400, width: "230px" }}
              >
                22. Any other remarks :
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                ..............{data?.remarks}
                ..............
              </Typography>
            </StackWrapper>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ marginTop: "106px", marginBottom: "1em" }}
            >
              <Typography className="bottomContent">
                Class Teacher <br />
                Name and Signature
              </Typography>

              <Typography className="bottomContent">
                Checked By <br />
                Name and Signature
              </Typography>
              <Typography className="bottomContent">
                Principal <br />
                Name and Signature
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Typography sx={{ fontSize: "10px", ml: 2, mb: 1 }}>
          Note: If this T.C. issued by the officiating/Incharge Principal, of
          should be invariably counter signed by the chairman School Management
          Committee.
        </Typography>
      </Wrapper>
    </>
  );
};

export default TcTemplate1;

const Wrapper = styled("div")`
  /* border: 1px solid #000000; */
  padding: 20px;

  .upperTypo {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }
  .border {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 12px;
  }
  .upperContent {
    justify-content: space-between;
    /* padding: 15px; */
  }
  .leftTypo {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }
  .rightTypo {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    margin-left: 4em;
    margin-right: 2em;
  }
  .bottomContent {
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    margin-left: 1em;
    margin-right: 3em;
  }
`;
const StackWrapper = styled(Stack)`
  margin-top: 8px;
`;
