import { LoadingButton } from "@mui/lab";
import {
  Container,
  Stack,
  Typography,
  styled,
  Select,
  MenuItem,
  Box,
  IconButton,
  DialogContent,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchool,
} from "app/modules/schools/selectors";
import {
  createAndViewMonthlyPayroll,
  deletePayroll,
  getPayrollPageData,
} from "app/services/hr";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { queryClient } from "App";
import CustomLoader from "app/components/common/CustomLoader";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import appRoutes from "app/config/routes";
import { blue, green, orange, red } from "@mui/material/colors";
import CommonTable1 from "app/components/Tables/CommonTable1";
import ExportDialog2 from "app/components/common/exportDialog/index2";
import dayjs from "dayjs";
import { DeleteOutline } from "@mui/icons-material";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import PaymentModeComponent2 from "app/components/common/PaymentModeComponent2";
import { paySalaryInPartPayment } from "app/services/schoolService/hr.service";
import TableActionButtons from "app/components/common/TableActionButtons";

const PayrollPage = () => {
  const selectedSession = useSelector(selectActiveSession);
  const dt = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [state, setState] = useState({
    data: [],
    year: "",
    month: "",
    payrollId: "",
    deletePayrollDocId: "",
    deleteDialog: false,
    deletePayrollDialog: false,
    monthPayrollData: {},
    summaryData: {},
    payDialog: false,
    payData: {},
  });
  const [searchParams] = useSearchParams();
  const handlePayFormSubmit = (data) => {
    const newData = {
      payrollDocId: state.payrollId,
      balanceAmount: data?.balanceAmount,
      transactionDate: data?.transactionDate,
      transactionList: data?.transactionList,
    };
    paySalaryInPartPaymentMutate(newData);
  };

  const years = [
    1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949, 1950, 1951,
    1955, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976,
    1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988,
    1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000,
    2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012,
    2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
    2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036,
    2037, 2038, 2039, 2040,
  ];

  const Columns = [
    {
      header: "Sr.No",
      accessorKey: "srNo",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            <Typography>{original?.srNo}</Typography>
          </>
        );
      },
      maxSize: 30,
      Header: ({ column }) => (
        <div style={{ minWidth: "20px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Employee ID",
      accessorKey: "id",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            <Typography>{original?.id}</Typography>
          </>
        );
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Employee name",
      accessorKey: "name",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <LinkText
            to={`/${appRoutes.staffProfile}/${original?.staffDocId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {original?.name} &#8599;
          </LinkText>
        );
      },
      size: 70,
      Header: ({ column }) => (
        <div style={{ minWidth: "100px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Month-Year",
      accessorKey: "monthYear",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.monthYear}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Department",
      accessorKey: "dept",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.dept}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Designation",
      accessorKey: "desg",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.desg}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Contact No.",
      accessorKey: "contact",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.mobile}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Net Pay",
      accessorKey: "netPay",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.netPay}</Typography>;
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ minWidth: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },
    {
      header: "Paid",
      accessorKey: "paidAmount",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.paidAmount}</Typography>;
      },
      size: 40,
    },
    {
      header: "Status",
      accessorKey: "status",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography
            sx={{
              color: ["PAID", "Paid"].includes(original?.status)
                ? green[800]
                : original?.status === "REJECTED"
                ? red[500]
                : original?.status === "APPROVED"
                ? green[400]
                : original?.status === "CREATED"
                ? orange[500]
                : original?.status === "Not Created"
                ? blue[500]
                : original?.status === "Partially Paid"
                ? green["A700"]
                : "#000",
              fontWeight: "bold",
            }}
          >
            {original?.status}
          </Typography>
        );
      },
      size: 40,
      Header: ({ column }) => (
        <div style={{ width: "50px" }}>
          <span style={{ wordWrap: "break-word" }}>
            {column.columnDef.header}
          </span>
        </div>
      ),
    },

    {
      header: "Actions",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            {[]}
            <Stack direction="column" gap={1} alignItems={"center"}>
              <Box>
                {["approved", "partially paid"].includes(
                  String(original?.status).toLocaleLowerCase()
                ) && (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ width: "120px" }}
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        payData: original?.data?.salaryDetail,
                        payDialog: true,
                        payrollId: original?.payrollId,
                      }));
                    }}
                  >
                    Pay
                  </Button>
                )}
              </Box>
              <TableActionButtons
                showView={
                  !["not created"].includes(
                    String(original?.status).toLocaleLowerCase()
                  )
                }
                showEdit={
                  !["not created", "paid"].includes(
                    String(original?.status).toLocaleLowerCase()
                  )
                }
                showDelete={
                  !["not created"].includes(
                    String(original?.status).toLocaleLowerCase()
                  )
                }
                handleEdit={() => {
                  navigate(
                    `/hr/payroll/create/${original?.data?.salaryDetail?._id}?month=${state.month}`
                  );
                }}
                handleDelete={() => {
                  setState((prev) => ({
                    ...prev,
                    deletePayrollDialog: true,
                    deletePayrollDocId: original?.payrollId,
                    deleteDialog: true,
                    payrollId: original?.payrollId,
                  }));
                }}
                handleView={() => {
                  navigate(
                    `/hr/payroll/print-slip/${original?.data?.salaryDetail?._id}?month=${state.month}`
                  );
                }}
              />
            </Stack>

            {original?.status === "Not Created" && (
              <Typography
                sx={{ color: "blue", cursor: "pointer" }}
                onClick={() =>
                  createMonthlyPayrollMutate({
                    session: selectedSession,
                    staffDocId: original?.data?.staffDetail?._id,
                    month: state.month,
                    year: state.year,
                  })
                }
              >
                Create
              </Typography>
            )}
          </>
        );
      },
      size: 60,
    },
  ];

  const tableData = state?.data?.map((item, index) => ({
    srNo: index + 1 < 10 ? `0${index + 1}` : index + 1,
    id: item?.employeeId || "--",
    name:
      `${item?.staffDetail?.firstName} ${item?.staffDetail?.middleName} ${item?.staffDetail?.lastName}` ||
      "--",
    monthYear: `${item?.month ?? "--"} ${item?.year ?? "--"}`,
    dept: item?.departmentName || "--",
    desg: item?.designation || "--",
    mobile: item?.staffDetail?.mobileNumber || "--",
    netPay: item?.salaryDetail?.netPay || 0,
    paidAmount: item?.salaryDetail?.paidAmount || 0,
    status: item?.salaryDetail?.status || "--",
    data: item,
    staffDocId: item?.staffDetail?._id,
    payrollId: item?.salaryDetail?._id,
  }));

  let header = [
    "Sr No",
    "Employee Id",
    "Employee Name",
    "Month-Year",
    "Department Name",
    "Designation",
    "Mobile Number",
    "Gender",
    "PAN",
    "Gross Salary",
    "Gross Salary Remarks",
    "Deductions",
    "Deduction Remarks",
    "Net Payable",
    "Paid",
    "Balance",
    "Status",
    "Cash",
    "NEFT",
    "Cheque",
    "Card",
    "UPI",
    "Working",
    "Present",
    "Absent",
    "Half Day",
    "Leave Day",
  ];

  let exportArray = tableData?.map((item) => {
    return {
      "Sr No": item?.srNo,
      "Employee Id": item?.id,
      "Employee Name": item?.name,
      "Month-Year": item?.monthYear,
      "Department Name": item?.data?.departmentName,
      Designation: item?.data?.designation,
      "Mobile Number": item?.data?.staffDetail?.mobileNumber,
      Gender: item?.data?.staffDetail?.gender,
      PAN: item?.data?.staffDetail?.PAN,
      "Gross Salary": item?.data?.salaryDetail?.totalEarnings,
      "Gross Salary Remarks": item?.data?.salaryDetail?.earningRemarks,
      Deductions: item?.data?.salaryDetail?.totalDeductions,
      "Deduction Remarks": item?.data?.salaryDetail?.deductionRemarks,
      "Net Payable": item?.data?.salaryDetail?.netPay,
      Paid: item?.data?.salaryDetail?.paidAmount,
      Balance: item?.data?.salaryDetail?.balanceAmount,
      Status: item?.data?.salaryDetail?.status,
      Cash: item?.data?.salaryDetail?.t_CashMode ?? "-",
      NEFT: item?.data?.salaryDetail?.t_NEFTMode ?? "-",
      Cheque: item?.data?.salaryDetail?.t_ChequeMode ?? "-",
      Card: item?.data?.salaryDetail?.t_CardMode ?? "-",
      UPI: item?.data?.salaryDetail?.t_UpiMode ?? "-",
      Working: item?.data?.salaryDetail?.totalDays,
      Present: item?.data?.salaryDetail?.presentDays,
      Absent: item?.data?.salaryDetail?.absentDays,
      "Half Day": item?.data?.salaryDetail?.halfDays,
      "Leave Day": item?.data?.salaryDetail?.leaveDays,
    };
  });
  const [openCheckBox, setOpenCheckBox] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [worksheetData, setWorksheetData] = useState([]);
  const [dataPoints, setDataPoints] = useState([]);
  const selectedSchool = useSelector(selectSelectedSchool);
  const session = useSelector(selectActiveSession);
  const navigate = useNavigate();
  const handleClosePayDialog = () => {
    setState((prev) => ({
      ...prev,
      payDialog: false,
      payData: {},
      payrollId: "",
    }));
  };

  const handleCloseDeletePayrollDialog = () => {
    setState((prev) => ({
      ...prev,
      deletePayrollDialog: false,
      deletePayrollDocId: "",
    }));
  };
  const {
    mutate: paySalaryInPartPaymentMutate,
    isLoading: paySalaryInPartPaymentLoading,
  } = useMutation(paySalaryInPartPayment, {
    onSuccess: (res) => {
      getPayrollRefetch();
      handleClosePayDialog();
      setState((prev) => ({ ...prev, pay: false }));
      toast.success(res?.data?.message);
      navigate(`/${appRoutes.payroll}?month=${searchParams.get("month")}`);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const {
    isLoading: getPayrollLoading,
    isFetching: getPayrollFetching,
    refetch: getPayrollRefetch,
  } = useQuery({
    queryKey: ["getPayrollData"],
    queryFn: () =>
      getPayrollPageData({
        month: state?.month,
        year: state?.year,
      }),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        data: res?.data?.list,
        summaryData: res?.data?.payrollSummary,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: state.month && state.year ? true : false,
  });

  const {
    mutate: createMonthlyPayrollMutate,
    isLoading: createMonthlyPayrollLoading,
  } = useMutation(createAndViewMonthlyPayroll, {
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, monthPayrollData: res?.data?.obj }));
      queryClient.invalidateQueries({ queryKey: "getPayrollData" });
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: deletePayrollMutate, isLoading: deletePayrollLoading } =
    useMutation(deletePayroll, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        handleCloseDeletePayrollDialog();
        queryClient.invalidateQueries({ queryKey: "getPayrollData" });
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  useEffect(() => {
    if (openCheckBox === true) {
      const tempFilteredExportArray = exportArray.map((item) => {
        const filteredItem = {};
        selectedOptions.forEach((option) => {
          filteredItem[option] = item[option];
        });
        return filteredItem;
      });
      const tempWorksheetData = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        Object.keys(tempFilteredExportArray[0] || {}).map((key) => key),
        ...tempFilteredExportArray.map((item) => Object.values(item)),
      ];
      setWorksheetData(tempWorksheetData);
      const tempDataPoints = [
        { data: `School Name: ${selectedSchool?.schoolName}`, origin: "E1" },
        { data: `Session: ${session}`, origin: "D2" },
        {
          data: `Downloaded On: ${dayjs(new Date()).format("DD-MM-YYYY")}`,
          origin: "H2",
        },
        { data: `Payroll Summary`, origin: "E4" },
        { data: `Month: ${state.month}`, origin: "D5" },
        { data: `Year: ${state.year}`, origin: "H5" },
        { data: `Total Amount`, origin: "D6" },
        { data: `Requested `, origin: "E6" },
        { data: `Approved`, origin: "F6" },
        { data: `Paid`, origin: "G6" },
        { data: `Not Created`, origin: "H6" },
        { data: `Rejected`, origin: "I6" },
        {
          data: `${IndianCurrency(state?.summaryData?.totalAmount)}`,
          origin: "D7",
        },
        {
          data: `${IndianCurrency(state?.summaryData?.REQUESTED)}`,
          origin: "E7",
        },
        {
          data: `${IndianCurrency(state?.summaryData?.APPROVED)}`,
          origin: "F7",
        },
        { data: `${IndianCurrency(state?.summaryData?.PAID)}`, origin: "G7" },
        {
          data: `${IndianCurrency(state?.summaryData?.Not_Created)}`,
          origin: "H7",
        },
        {
          data: `${IndianCurrency(state?.summaryData?.REJECTED)}`,
          origin: "I7",
        },
      ];
      setDataPoints(tempDataPoints);
    }
  }, [openCheckBox]);
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      year: dt.getFullYear(),
      month: searchParams.get("month")
        ? searchParams.get("month")
        : months[dt.getMonth()],
    }));
  }, []);
  return (
    <>
      <CustomLoader
        show={
          getPayrollLoading || getPayrollFetching || createMonthlyPayrollLoading
        }
      />
      <CommonDialog
        title="Pay Payroll"
        open={state.payDialog}
        onClose={handleClosePayDialog}
        minWidth="700px"
      >
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <PaymentModeComponent2
              onClickSubmit={handlePayFormSubmit}
              data={state?.payData}
              loading={paySalaryInPartPaymentLoading}
            />
          </Box>
        </DialogContent>
      </CommonDialog>
      {/* Delete Dialog Confirmation */}
      <DeleteDialog
        open={state.deletePayrollDialog}
        handleClose={handleCloseDeletePayrollDialog}
        handleDelete={() => {
          deletePayrollMutate(state.deletePayrollDocId);
        }}
        loading={deletePayrollLoading}
      />
      <ExportDialog2
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        fileName="Payroll"
        worksheetData={worksheetData}
        dataPoints={dataPoints}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mt: 2 }}
        >
          <Stack direction="row" gap={2} marginRight={2} sx={{ width: "15vw" }}>
            <CustomSelect
              MenuProps={MenuProps}
              value={state.year}
              displayEmpty
              onChange={(e) =>
                setState((prev) => ({ ...prev, year: e.target.value }))
              }
            >
              <MenuItem disabled value="">
                Select Year
              </MenuItem>
              {years.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </CustomSelect>

            <CustomSelect
              MenuProps={MenuProps}
              value={state.month}
              displayEmpty
              onChange={(e) =>
                setState((prev) => ({ ...prev, month: e.target.value }))
              }
            >
              <MenuItem disabled value="">
                Select Month
              </MenuItem>
              {months.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </CustomSelect>

            <LoadingButton
              variant="contained"
              color="secondary"
              size="large"
              sx={{ px: 2, textTransform: "uppercase" }}
              loading={getPayrollLoading || getPayrollFetching}
              onClick={() => getPayrollRefetch()}
            >
              View
            </LoadingButton>
          </Stack>

          <ExportButtonGroup
            handleClickExcel={() => setOpenCheckBox(true)}
            showPdf={false}
          />
        </Stack>
        <FeeBox>
          <Stack direction="row" alignItems={"center"} gap={2}>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Total Amount
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.totalAmount)}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Requested
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.REQUESTED)}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Approved
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.APPROVED)}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Paid
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.PAID)}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Not Created
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.Not_Created)}
              </Typography>
            </Box>
            <Box sx={{ mr: "1rem" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Rejected
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: "15px" }}>
                {IndianCurrency(state?.summaryData?.REJECTED)}
              </Typography>
            </Box>
          </Stack>
        </FeeBox>
        <Stack py={1}>
          <CommonTable1
            columns={Columns}
            data={tableData || []}
            maxHeight="56vh"
          />
        </Stack>
      </Container>
    </>
  );
};

export default PayrollPage;
const CustomSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  minWidth: "15rem",
  backgroundColor: "#fff",
  color: "#333",
  borderRadius: "7px",
}));
const LinkText = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: underline;
    color: blue;
  }
`;
const FeeBox = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(19, 115, 229, 0.2);
  background: rgba(39, 137, 253, 0.02);
  padding: 10px;
  display: inline-block;
  margin-top: 10px;
`;
