import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { selectIsAuthorised } from "app/modules/auth/selectors";

/**
 * @param  {object} {props}
 * Private Route manages authentication at route level.
 * If session becomes invalid due to any reason, this component
 * ensures that a redirection is made to passed {redirectPath} or "/" (by default)
 * @returns {JSX} Private Route
 */
const PrivateRoute = ({ children }) => {
  const { pathname } = useLocation();
  const isAuthenticated = useSelector(selectIsAuthorised);
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/validate-session" state={{ from: pathname }} />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
