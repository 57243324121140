import React, { useState, useEffect } from "react";
import CommonDialog from "../../CommonDialog";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  Grid,
  Select,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import {
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import * as yup from "yup";
import { IndianCurrency } from "app/utils/helper";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import { toast } from "react-toastify";
import {
  deleteUtilityInvoice,
  getTransactionDetail,
  payInstallment,
} from "app/services/ledgerAccount";
import { Delete, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import PaymentModeComponent from "app/components/common/PaymentModeComponent";
import CustomLoader from "app/components/common/CustomLoader";
import DeleteConfirmationDialog from "../../DeleteConfirmationDialog";
const SaleBookPaymentDetail = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
}) => {
  const selectedSession = useSelector(selectActiveSession);

  const isMobile = useMediaQuery("(max-width: 768px)");
  const [dialogs, setDialogs] = useState({
    add: false,
    tPaid: false,
    installment: false,
    payment: false,
    export: false,
  });
  const [installmentGst, setInstallmentGst] = useState({
    gstNumber: "",
    igst: "",
    cgst: "",
    sgst: "",
    hsn: "",
  });

  const [state, setState] = useState({
    tableData: [],
    dialogData: {},
    installmentData: {},
    add: {
      class: "",
      section: "",
      student: "",
      bookType: "",
      bookTitle: "",
      collectionDate: new Date(),
      entityArr: [],
      payment: {
        totalAmount: 0,
        discountRemark: "",
        taxableAmount: 0,
        balance: 0,
        balanceRemark: "",
      },
    },
    book: "",
    selectedClass: "",
    section: "",
    collectionDate: new Date(),

    deleteInvoiceDId: "",
  });
  const handledeleteInvoiceMutate = () => {
    deleteUtilityInvoiceMutate(state.deleteInvoiceDId);
  };
  const handleCloseDeleteInvoiceDialog = () => {
    setState((prev) => ({ ...prev, deleteInvoiceDId: "" }));
  };
  const payFormik = useFormik({
    initialValues: {
      edRemarks: "",
      payingAmt: 0,
      bRemarks: "",
    },
    validationSchema: yup.object({
      payingAmt: yup.number().required("Please Enter Paying Amount"),
    }),
    onSubmit: (values) => {
      const obj = {
        session: selectedSession,
        studentDocId: state?.installmentData?.studentDocId,
        firstInvoiceNumber: state?.installmentData?.firstInvoiceNumber,
        collectionDate: new Date(state?.collectionDate),
        // collectionDate: state?.installmentData?.collectionDate,
        taxableAmount: Number(values.payingAmt),
        balanceAmount: state?.installmentData?.balance,
        balanceRemark: values.bRemarks,
        paymentDetails: state?.installmentData?.paymentDetails,
      };
      const newObj =
        installmentGst.igst || installmentGst.cgst
          ? {
              ...obj,
              payerGSTNumber: installmentGst.gstNumber,
              hsn: {
                hsncode: installmentGst.hsn,
                igst: installmentGst.igst || "0",
                cgst: installmentGst.cgst || "0",
                sgst: installmentGst.cgst || "0",
              },
            }
          : obj;

      payInstallmentMutate(newObj);
    },
  });

  const balanceAmount = (amount) =>
    state?.installmentData?.balanceAmount + amount;

  const dataRows = state?.dialogData?.invoiceList?.map((item, index) => ({
    id: index + 1,
    invoiceDocId: item._id,
    number: item?.invoiceNumber || "--",
    dues: item?.previousDuesAmount || "--",
    tAmount: item?.totalAmount || "--",
    pAmount: item?.paidAmount || "--",
    balance: item?.balanceAmount || "--",
    date: dayjs(item?.collectionDate).format("DD MMM, YYYY") || "--",
    created: dayjs(item?.createdOn).format("DD MMM, YYYY") || "--",
    status: item?.status ?? "--",
  }));
  const {
    mutate: deleteUtilityInvoiceMutate,
    isLoading: deleteUtilityInvoiceLoading,
  } = useMutation(deleteUtilityInvoice, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      handleCloseDeleteInvoiceDialog();
      getTransactionDetailRefetch();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const { mutate: payInstallmentMutate, isLoading: payInstallmentLoading } =
    useMutation(payInstallment, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        setDialogs((prev) => ({ ...prev, installment: false }));
        payFormik.resetForm();
        refetch();
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  const {
    isLoading: getTransactionDetailLoading,
    isFetching: getTransactionDetailFetching,
    refetch: getTransactionDetailRefetch,
  } = useQuery({
    queryKey: ["getTransactionDetail"],
    queryFn: () => getTransactionDetail({ firstInvoiceNumber: open }),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        dialogData: res?.data?.obj,
        installmentData: {
          ...res?.data?.obj?.invoiceList[
            res?.data?.obj?.invoiceList?.length - 1
          ],
          studentDocId: res?.data?.obj?.studentDetail?.studentDocId,
        },
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: open ? true : false,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      collectionDate: new Date(),
    }));
  }, [dialogs?.installment]);

  return (
    <>
      <CustomLoader
        show={getTransactionDetailLoading || getTransactionDetailFetching}
      />
      {/* Delete confirmation */}
      <DeleteConfirmationDialog
        open={state.deleteInvoiceDId}
        handleDelete={handledeleteInvoiceMutate}
        onClose={handleCloseDeleteInvoiceDialog}
        loading={deleteUtilityInvoiceLoading}
      />
      {/* payment summary dialog */}
      <CommonDialog
        open={open}
        onClose={onClose}
        minWidth="900px"
        title="Install Payment Summary"
      >
        <DialogContent>
          <Stack
            direction={{ xs: "column-reverse", md: "row" }}
            gap={{ xs: 1, md: 0.3 }}
          >
            <Stack flex={3}>
              <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={6}>
                  <Label sx={{ fontWeight: "500" }}>Student Name</Label>
                  <Label>
                    {state?.dialogData?.studentDetail?.studentName || "--"}
                  </Label>
                </Grid>
                <Grid item xs={6}>
                  <Label sx={{ fontWeight: "500" }}>Father's Name</Label>
                  <Label>
                    {state?.dialogData?.studentDetail?.fatherName || "--"}
                  </Label>
                </Grid>
                <Grid item xs={6}>
                  <Label sx={{ fontWeight: "500" }}>Gender</Label>
                  <Label>
                    {state?.dialogData?.studentDetail?.gender || "--"}
                  </Label>
                </Grid>
                <Grid item xs={6}>
                  <Label sx={{ fontWeight: "500" }}>Mobile Number</Label>
                  <Label>
                    {state?.dialogData?.studentDetail?.studentMobileNumber ||
                      "--"}
                  </Label>
                </Grid>
                <Grid item xs={6}>
                  <Label sx={{ fontWeight: "500" }}>Class-Stream-Section</Label>
                  <Label>
                    {`${state?.dialogData?.studentDetail?.className} ${state?.dialogData?.studentDetail?.stream} ${state?.dialogData?.studentDetail?.section}` ||
                      "--"}
                  </Label>
                </Grid>
                <Grid item xs={6}>
                  <Label sx={{ fontWeight: "500" }}>Reg Number</Label>
                  <Label>
                    {state?.dialogData?.studentDetail?.registrationNumber ||
                      "--"}
                  </Label>
                </Grid>
                <Grid item xs={6}>
                  <Label sx={{ fontWeight: "500" }}>Admission Number</Label>
                  <Label>
                    {state?.dialogData?.studentDetail?.admissionNumber || "--"}
                  </Label>
                </Grid>
                <Grid item xs={6}>
                  <Label sx={{ fontWeight: "500" }}>Roll Number</Label>
                  <Label>
                    {state?.dialogData?.studentDetail?.rollNumber || "--"}
                  </Label>
                </Grid>
              </Grid>
            </Stack>
            <Stack flex={1}>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => {
                  setDialogs((prev) => ({
                    ...prev,
                    tPaid: false,
                    installment: true,
                  }));
                }}
              >
                + Pay More
              </Button>
            </Stack>
          </Stack>
          <TableContainer
            component={Paper}
            sx={{
              maxWidth: "100%",
              borderRadius: "10px",
              border: "1px solid #B6C8D6",
            }}
          >
            <Table
              sx={{ borderRadius: "10px", textAlign: "left" }}
              aria-label="simple table"
            >
              <TableHead
                style={{ background: "#F6F8FC", borderRadius: "10px" }}
              >
                <TableRow>
                  <TableCell>Sr no.</TableCell>
                  <TableCell>Invoice Number</TableCell>
                  <TableCell>Previous Dues</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Paid Amount</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Collection Date</TableCell>
                  <TableCell>Created On</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataRows?.length !== 0 ? (
                  dataRows?.map((row) => (
                    <TableRow
                      key={row?.desc}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.id}
                      </TableCell>
                      <TableCell sx={{ cursor: "pointer", fontWeight: "bold" }}>
                        <Link
                          to={`/ledger-n-account/books/pay-slip/${String(
                            row?.number
                          ).replace(/\//g, " ")}`}
                          target="_blank"
                          rel="noreferrer onopener"
                        >
                          {row?.number}
                        </Link>
                      </TableCell>
                      <TableCell>{IndianCurrency(Number(row?.dues))}</TableCell>
                      <TableCell>
                        {IndianCurrency(Number(row?.tAmount))}
                      </TableCell>
                      <TableCell>
                        {IndianCurrency(Number(row?.pAmount))}
                      </TableCell>
                      <TableCell>
                        {IndianCurrency(Number(row?.balance))}
                      </TableCell>
                      <TableCell>{row?.date}</TableCell>
                      <TableCell>{row?.created}</TableCell>
                      <TableCell
                        sx={{
                          color:
                            row.status === "ACTIVE" ? "#1DCF00" : "#EA1C1C",
                        }}
                      >
                        {row?.status}
                      </TableCell>

                      <TableCell>
                        {row?.status === "ACTIVE" && (
                          <Delete
                            sx={{ color: "#EA1C1C", cursor: "pointer" }}
                            onClick={() =>
                              setState((prev) => ({
                                ...prev,
                                deleteInvoiceDId: row?.invoiceDocId,
                              }))
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>no earning</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" size="large">
            Submit
          </Button>
        </DialogActions>
      </CommonDialog>
      {/* insatallment payment dialog */}
      <CommonDialog
        open={dialogs?.installment}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, installment: false }));
          payFormik.resetForm();
        }}
        minWidth="700px"
        title="Installment Payment"
      >
        <DialogContent>
          <form onSubmit={payFormik.handleSubmit} id="payForm">
            <Grid container spacing={1}>
              <Grid item xs={6} md={4}>
                <Label sx={{ fontWeight: "500", opacity: 0.75 }}>
                  Previous Dues
                </Label>
                <Label>
                  {IndianCurrency(state?.installmentData?.balanceAmount)}
                </Label>
              </Grid>
              <Grid item xs={6} md={4}>
                <Label sx={{ fontWeight: "500", opacity: 0.75 }}>
                  Previous Dues Remarks
                </Label>
                <Label>--</Label>
              </Grid>
              <Grid item xs={6} md={4}>
                <Label sx={{ fontWeight: "500", opacity: 0.75 }}>
                  Collection Date
                </Label>
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  name="collectionDate"
                  value={state.collectionDate}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      collectionDate: e,
                    }));
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select"
                      sx={{ width: isMobile ? "auto" : "17rem" }}
                      size="small"
                    />
                  )}
                />
                {/* <CustomTextFieldDisabled
                  value={
                    state.installmentData.collectionDate
                      ? dayjs(state?.installmentData?.collectionDate).format(
                          "DD MMM, YYYY"
                        )
                      : ""
                  }
                /> */}
              </Grid>
              <Grid item xs={6} md={4}>
                <Label sx={{ fontWeight: "500", opacity: 0.75 }}>
                  Paying Amount
                </Label>
                <CustomTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography>&#8377;</Typography>
                      </InputAdornment>
                    ),
                  }}
                  name="payingAmt"
                  type="number"
                  value={payFormik.values.payingAmt}
                  onChange={(e) => {
                    payFormik.handleChange(e);
                    setState((prev) => ({
                      ...prev,
                      installmentData: {
                        ...prev.installmentData,
                        amount: e.target.value,
                        taxableAmount: Number(e.target.value),
                        balance: balanceAmount(Number(e.target.value)),
                      },
                    }));
                  }}
                />
                {payFormik.errors.payingAmt && payFormik.touched.payingAmt && (
                  <Typography sx={{ color: "red" }}>
                    {payFormik.errors.payingAmt}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} md={4}>
                <Label sx={{ fontWeight: "500", opacity: 0.75 }}>
                  Balance Amt.(Without Tax)
                </Label>
                <CustomTextFieldDisabled
                  placeholder="0"
                  value={IndianCurrency(state?.installmentData?.balance)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <Label sx={{ fontWeight: "500", opacity: 0.75 }}>
                  Balance Remarks
                </Label>
                <CustomTextField
                  placeholder="Enter Balance Remarks"
                  name="bRemarks"
                  value={payFormik.values.bRemarks}
                  onChange={(e) => {
                    payFormik.handleChange(e);
                    setState((prev) => ({
                      ...prev,
                      installmentData: {
                        ...prev.installmentData,
                        balanceRemark: e.target.value,
                      },
                    }));
                  }}
                />
                {payFormik.errors.bRemarks && payFormik.touched.bRemarks && (
                  <Typography sx={{ color: "red" }}>
                    {payFormik.errors.bRemarks}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <hr />
            <Stack direction="row" alignItems="center" gap={2}>
              <Stack flex={3}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Title>GST Information</Title>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      <Grid item>
                        <Label>Payer's GST Number</Label>
                        <CustomTextField
                          size="small"
                          fullWidth
                          onChange={(e) =>
                            setInstallmentGst((prev) => ({
                              ...prev,
                              gstNumber: e.target.value,
                            }))
                          }
                          value={installmentGst.gstNumber}
                        />
                      </Grid>
                      <Grid item>
                        <Label>HSN code</Label>
                        <CustomTextField
                          size="small"
                          fullWidth
                          value={installmentGst.hsn}
                          onChange={(e) =>
                            setInstallmentGst((prev) => ({
                              ...prev,
                              hsn: e.target.value,
                            }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Add IGST %</Label>
                        <CustomTextField
                          type="number"
                          size="small"
                          fullWidth
                          value={installmentGst.igst}
                          onChange={(e) =>
                            setInstallmentGst((prev) => ({
                              ...prev,
                              igst: e.target.value,
                              cgst: "",
                              sgst: "",
                            }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Amount IGST</Label>
                        <CustomTextFieldDisabled
                          value={IndianCurrency(
                            Number(payFormik.values.payingAmt) *
                              (Number(installmentGst.igst) / 100)
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Label>Add SGST %</Label>
                        <CustomTextField
                          type="number"
                          value={installmentGst.sgst}
                          onChange={(e) =>
                            setInstallmentGst((prev) => ({
                              ...prev,
                              sgst: e.target.value,
                              igst: "",
                            }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Amount SGST</Label>
                        <CustomTextFieldDisabled
                          value={IndianCurrency(
                            Number(payFormik.values.payingAmt) *
                              (Number(installmentGst.sgst) / 100)
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Label>Add CGST %</Label>
                        <CustomTextField
                          type="number"
                          value={installmentGst.cgst}
                          onChange={(e) =>
                            setInstallmentGst((prev) => ({
                              ...prev,
                              cgst: e.target.value,
                              igst: "",
                            }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Amount CGST</Label>
                        <CustomTextFieldDisabled
                          value={IndianCurrency(
                            Number(payFormik.values.payingAmt) *
                              (Number(installmentGst.cgst) / 100)
                          )}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Stack>
              <Stack flex={1} direction="column" gap={1}>
                <Stack>
                  <Label>Taxable Amount</Label>
                  <Typography>
                    {IndianCurrency(Number(payFormik.values.payingAmt))}
                  </Typography>
                </Stack>
                <Stack>
                  <Label>Tax Amount</Label>
                  <Typography>
                    {IndianCurrency(
                      Number(payFormik.values.payingAmt) *
                        (Number(installmentGst.igst) / 100) +
                        Number(payFormik.values.payingAmt) *
                          (Number(installmentGst.sgst) / 100) +
                        Number(payFormik.values.payingAmt) *
                          (Number(installmentGst.cgst) / 100)
                    )}
                  </Typography>
                </Stack>
                <Stack>
                  <Label>Paid Amount</Label>
                  <Typography>
                    {IndianCurrency(
                      Number(payFormik.values.payingAmt) *
                        (Number(installmentGst.igst) / 100) +
                        Number(payFormik.values.payingAmt) *
                          (Number(installmentGst.sgst) / 100) +
                        Number(payFormik.values.payingAmt) *
                          (Number(installmentGst.cgst) / 100) +
                        Number(payFormik.values.payingAmt)
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </form>

          <Stack mt={2}>
            <PaymentModeComponent
              formId="pay-form-pay"
              amount={
                Number(payFormik.values.payingAmt) *
                  (Number(installmentGst.igst) / 100) +
                Number(payFormik.values.payingAmt) *
                  (Number(installmentGst.sgst) / 100) +
                Number(payFormik.values.payingAmt) *
                  (Number(installmentGst.cgst) / 100) +
                Number(payFormik.values.payingAmt)
              }
              onClickSubmit={(data) => {
                setState((prev) => ({
                  ...prev,
                  installmentData: {
                    ...prev.installmentData,
                    paymentDetails: data,
                  },
                }));
                payFormik.handleSubmit();
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            form="pay-form-pay"
            loading={payInstallmentLoading}
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default SaleBookPaymentDetail;
const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 600,
  color: "#0C2F49",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  lineHeight: "1.4rem",
  fontWeight: 600,
  color: "#0C2F49",
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  width: "200px",
  backgroundColor: "#fff",
  color: "#333",
  borderRadius: "7px",
}));

const GrayBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#f8fbff",
  border: "1px solid rgba(61, 57, 137, 0.1)",
  boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.05)",
  borderRadius: "20px",
}));

const NumberField = styled("input")`
  max-width: 60px;
  border-radius: 10px;
  padding: 2px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: block;
    opacity: 1;
  }
`;
