import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import DialogHeader from "../common/DialogHeader";

const RejectDialog = ({ open, handleClose, handleReject, loading }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { minWidth: "25vw" },
      }}
    >
      <DialogHeader title="Reject Confirmation" handleClose={handleClose} />
      <DialogContent>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: "500",
              color: "text.primary",
            }}
          >
            Are you sure you want to Reject this?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} justifyContent={"center"} gap={2}>
          <LoadingButton
            color="secondary"
            variant="outlined"
            size="medium"
            type="submit"
            onClick={() => handleClose()}
          >
            CANCEL
          </LoadingButton>
          <LoadingButton
            color="error"
            loading={loading}
            variant="contained"
            size="medium"
            type="submit"
            onClick={() => handleReject()}
          >
            CONFIRM
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default RejectDialog;
