import {
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { Field, useField, useFormikContext } from "formik";

export default function RadioComponent({
  name,
  options,
  setRadioValues,
  ...otherProps
}) {
  const { setFieldValue } = useFormikContext();
  const [field, metaData] = useField(name);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
    setRadioValues(e.target.value);
  };

  const RadioConfig = {
    ...field,
    ...otherProps,
    type: "radio",
    value: field.value,
    variant: "outlined",
    onChange: handleChange,
  };

  if (metaData && metaData.touched && metaData.error) {
    RadioConfig.helpertext = metaData.error;
  }
  return (
    <RadioGroup {...RadioConfig}>
      {options &&
        options?.map((option, index) => {
          return (
            <FormControlLabel
              value={option}
              name={option}
              control={<Radio />}
              label={option}
            ></FormControlLabel>
          );
        })}
    </RadioGroup>
  );
}
