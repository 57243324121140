import { Stack, Typography, Box, useMediaQuery } from "@mui/material";
import moment from "moment";
import styled from "styled-components";
const NameComponent = ({ label, text }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Box sx={{ flex: 1 }}>
      <Typography fontSize={12} color="primary" sx={{ opacity: 0.75 }}>
        {label}
      </Typography>
      <Typography fontSize={isMobile ? 13 : 14} fontWeight={600} color="primary">
        {text && text !== "undefined" && text !== undefined
          ? String(text).replace(/undefined/g, "")
          : "--"}
      </Typography>
    </Box>
  );
};
export default function PersonalInfo({ data }) {
  const TypoConfigBold = {
    fontWeight: "bold",
    fontSize: "14px",
  };
  const TypoConfig = {
    fontWeight: "semi-bold",
    fontSize: "14px",
  };
  return (
    <MainWrapper>
      <NameComponent
        label="Full Name"
        text={`${data?.firstName} ${data?.lastName}`}
      />
      <NameComponent label="Aadhaar Number" text={data?.AADHAR} />
      <NameComponent label="Gender" text={data?.gender} />
      <NameComponent
        label="Date Of Birth"
        text={moment(new Date(data?.DOB)).format("DD-MMM-yyyy")}
      />
      <NameComponent label="Email Id" text={data?.email} />
      <NameComponent label="Mobile Number" text={data?.mobileNumber} />
      <NameComponent label="Community" text={data?.community} />
      <NameComponent label="Category" text={data?.category} />
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;
