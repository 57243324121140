import { TextField } from "@mui/material";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { useField } from "formik";

export default function TextFieldComponent({ name, ...otherProps }) {
  const [field, metaData] = useField(name);

  const configTextField = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (metaData && metaData.touched && metaData.error) {
    configTextField.error = true;
    configTextField.helperText = metaData.error;
  }
  return <CustomTextField {...configTextField} size="small" fullWidth />;
}
