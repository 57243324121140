import React, { useEffect, useRef } from "react";
import {
  Container,
  Stack,
  Box,
  Typography,
  Grid,
  Button,
  DialogContent,
  Dialog,
  styled,
  DialogActions,
  DialogTitle,
  colors,
} from "@mui/material";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useState } from "react";
import {
  getPayrollHeads,
  getPayrollPageData,
  getStaffDetailByStaffDocId,
  getLastThreeMonthSortedDataStaffAttendance,
  updateMonthlyPayroll,
  getPayrollTransaction,
} from "app/services/hr";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import DialogHeader from "app/components/common/DialogHeader";
import { selectActiveSession } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import CustomLoader from "app/components/common/CustomLoader";
import LabelTextComponent from "app/components/common/LabelTextComponent";
import { IndianCurrency } from "app/utils/helper";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import ViewEmploymentDialog from "../EmploymentDialogs/ViewEmploymentDialog";
import appRoutes from "app/config/routes";
import AddNewDebtToStaffFreshDialog from "app/components/common/Dialogs/hr/payroll/AddNewDebtToStaffFreshDialog";
import {
  getStaffCalculatedDebt,
  updatePayrollTransaction,
} from "app/services/schoolService/hr.service";
import { Helmet } from "react-helmet";
import TableForEarningDeduction from "./TableForEarningDeduction";
import PayrollDrawer from "./PayrollDrawer";

const CreatePayroll = () => {
  const selectedSession = useSelector(selectActiveSession);
  const isRendered = useRef(false);
  const { id: payrollDocId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [debtState, setDebtState] = useState({
    withdrawalAmount: 0,
    depositAmount: 0,
    t_balance: 0,
  });
  const [falseState, setFalseState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    attendaceIndex: 0,
    openDrawer: false,
    drawerData: {},
    earningdHead: [],
    deductionsHead: [],
    data: {},
    keys: [],
    values: [],
    threeMonths: [],
    activeValues: {},
    activeTableData: {},
    activeHeadData: null,
    add: {},
    heads: [],
    selectedHead: {},
    transactions: [],
    updateTransaction: {},
    employmentInfo: {},
    earningData: [],
    deductionData: [],
    payrollHead: "",
    deletPayrollTransDocId: "",
    totalDays: "",
    presentDays: "",
    absentDays: "",
    halfDays: "",
    leaveDays: "",
    lopDay: "",
    holidays: "",
    attendanceDialog: false,
    flag: 0,
    debtDialog: false,
  });
  const [dialogs, setDialogs] = useState({
    updateAttendance: false,
    viewEmployment: false,
    updateEmployment: false,
  });
  useEffect(() => {
    if (state.attendanceDialog === true) {
      setState((prev) => ({
        ...prev,
        flag: 1,
      }));
    }
  }, [state.attendanceDialog]);

  const activeMonthYear2 = `${state?.data?.month}`;
  const totalEarnings = state.earningData?.reduce(
    (sum, value) => sum + Number(value.amount),
    0
  );
  const totalDeductions = state.deductionData?.reduce(
    (sum, value) => sum + Number(value.amount),
    0
  );

  // get last three months attendance
  const {
    isLoading: getLastThreeMonthSortedDataStaffAttendanceLoading,
    isFetching: getLastThreeMonthSortedDataStaffAttendanceFetching,
  } = useQuery({
    queryKey: ["getLastThreeMonthSortedDataStaffAttendance"],
    queryFn: () =>
      getLastThreeMonthSortedDataStaffAttendance({
        staffDocId: state.data?.employmentInfo?.staffDetail?._id,
        date: new Date(`02-${state.data?.month}-${state.data?.year}`),
      }),
    onSuccess: (res) => {
      isRendered.current = true;
      let attendanceObj;
      const filteredData = res?.data?.list?.filter(
        (item) => item?.month === activeMonthYear2
      );
      const att =
        Number(state?.data?.absentDays) +
        Number(state?.data?.presentDays) +
        Number(state?.data?.halfDays) +
        Number(state?.data?.holidays) +
        Number(state?.data?.lopDay) +
        Number(state?.data?.leaveDays);
      if (att > 0) {
        attendanceObj = {
          totalDays: state?.data?.totalDays,
          presentDays: state?.data?.presentDays,
          absentDays: state?.data?.absentDays,
          halfDays: state?.data?.halfDays,
          leaveDays: state?.data?.leaveDays,
          lopDay: state?.data?.lopDay,
          holidays: state?.data?.holidays,
        };
      } else {
        attendanceObj = {
          totalDays: filteredData?.totalDays,
          presentDays: filteredData?.presentDays,
          absentDays: filteredData?.absentDays,
          halfDays: filteredData?.halfDays,
          leaveDays: filteredData?.leaveDays,
          lopDay: filteredData?.lopDay,
          holidays: filteredData?.holidays,
        };
        setState((prev) => ({
          ...prev,
          threeMonths: res?.data?.list,
        }));
      }
      setState((prev) => ({
        ...prev,
        threeMonths: res?.data?.list,
        ...attendanceObj,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled:
      state.data?.employmentInfo?.staffDetail?._id && !isRendered.current
        ? true
        : false,
  });

  // fetching payroll by Doc Id
  const {
    isLoading: getPayrollDataLoading,
    isFetching: getPayrollDataFetching,
    refetch: getPayrollDataRefetch,
  } = useQuery({
    queryKey: ["getPayrollData"],
    queryFn: () =>
      getPayrollPageData({
        payrollDocId,
      }),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        data: res?.data?.obj,
        earningData: res?.data?.obj?.earnings,
        deductionData: res?.data?.obj?.deductions,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  // get All Payroll Head
  useQuery({
    queryKey: ["getPayrollHead"],
    queryFn: () => getPayrollHeads(),
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        earningdHead: success?.data?.list?.filter(
          (item) => item?.headType === "Earning"
        ),
        deductionsHead: success?.data?.list?.filter(
          (item) => item?.headType === "Deduction"
        ),
      }));
    },
  });
  // get payroll trasaction
  const {
    isLoading: getPayrollTransactionLoading,
    isFetching: getPayrollTransactionFetching,
    refetch: getPayrollTransactionRefetch,
  } = useQuery({
    queryKey: ["getPayrollTransaction"],
    queryFn: () =>
      getPayrollTransaction({
        month: state.data?.month,
        year: state.data?.year,
        staffDocId: state.data?.employmentInfo?.staffDetail?._id,
      }),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        earningData: success?.data?.list?.filter(
          (item) => item?.headType === "Earning"
        ),
        deductionData: success?.data?.list?.filter(
          (item) => item?.headType === "Deduction"
        ),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: false,
  });

  // get staff Debt detail
  const {
    isLoading: getStaffCalculatedDebtLoading,
    isFetching: getStaffCalculatedDebtFetching,
    refetch: getStaffCalculatedDebtRefetch,
    data: getStaffCalculatedDebtData,
  } = useQuery({
    queryKey: [
      "getStaffCalculatedDebt",
      state.data?.employmentInfo?.staffDetail?._id,
    ],
    queryFn: () =>
      getStaffCalculatedDebt({
        staffDocId: state.data?.employmentInfo?.staffDetail?._id,
      }),
    onSuccess: (success) => {
      setDebtState((prev) => ({
        ...prev,
        withdrawalAmount: success?.data?.withdrawalAmount,
        depositAmount: success?.data?.depositAmount,
        t_balance: success?.data?.t_balance,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: state.data?.employmentInfo?.staffDetail?._id ? true : false,
  });

  // mutate monthly payroll
  const {
    mutate: updateMonthlyPayrollMutate,
    isLoading: updateMonthlyPayrollLoading,
  } = useMutation(updateMonthlyPayroll, {
    onSuccess: (res) => {
      navigate(`/${appRoutes.payroll}?month=${searchParams.get("month")}`);
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  // Fetching employee information
  useQuery({
    queryKey: ["getEmployeeInfo"],
    queryFn: () =>
      getStaffDetailByStaffDocId(state.data?.employmentInfo?.staffDetail?._id),
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, employmentInfo: res?.data?.obj }));
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: dialogs.viewEmployment ? true : false,
  });

  const handleUpdateAttendance = () => {
    setState((prev) => ({
      ...prev,
      attendanceDialog: false,
    }));
  };

  const handleUpdatePayroll = async () => {
    setLoading(true);

    const body = {
      payrollDocId: payrollDocId,
      session: selectedSession,
      year: state.data?.year,
      month: state.data?.month,
      totalDays: Number(state.totalDays ?? 0),
      presentDays: Number(state.presentDays ?? 0),
      absentDays: Number(state.absentDays ?? 0),
      halfDays: Number(state.halfDays ?? 0),
      earnings: state.earningData?.map((item) => ({
        session: selectedSession,
        headType: item?.headType,
        payrollHeadDocId: item?._id,
        staffDocId: state.data?.employmentInfo?.staffDetail?._id,
        month: state.data?.month,
        amount: item?.amount,
        remarks: item?.remarks,
      })),
      deductions: state.deductionData?.map((item) => ({
        session: selectedSession,
        headType: item?.headType,
        payrollHeadDocId: item?._id,
        staffDocId: state.data?.employmentInfo?.staffDetail?._id,
        month: state.data?.month,
        amount: item?.amount,
        remarks: item?.remarks,
      })),
      totalEarnings: totalEarnings,
      totalDeductions: totalDeductions,
      netPay: Number(totalEarnings) - Number(totalDeductions),
    };
    const filteredData = state?.deductionData?.find(
      (res) => res?.headName === "Sort Attendance"
    );

    try {
      await updatePayrollTransaction({
        payrollTransactionDocId: filteredData?._id,
        session: selectedSession,
        amount: filteredData?.amount,
        year: state?.data?.year,
        month: state?.data?.month,
        remarks: filteredData?.remarks,
      });
      updateMonthlyPayrollMutate(body);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoading(false);
  };
  const checkAttendanceDays = () => {
    const total =
      Number(state.presentDays) +
      Number(state.absentDays) +
      Number(state.halfDays);
    if (total > Number(state.totalDays)) {
      return "-> Total Attendance days more than Total days";
    }

    return "";
  };
  useEffect(() => {
    if (state.deductionData?.length > 0) {
      const data = state.deductionData?.map((item) => {
        if (item?.headName === "Sort Attendance") {
          const d = {
            ...item,
            amount:
              Number(state.data?.employmentInfo?.salaryPerDay ?? 0) *
                Number(state.absentDays ?? 0) +
              Number(state.lopDay ?? 0),
          };
          return d;
        }
        return item;
      });
      setState((prev) => ({ ...prev, deductionData: data }));
    }
  }, [state.absentDays, state.earningData]);

  return (
    <>
      <Helmet>
        <title>Payroll | Create Payroll</title>
      </Helmet>
      <CustomLoader
        show={
          getPayrollDataLoading ||
          getPayrollDataFetching ||
          getStaffCalculatedDebtLoading ||
          getStaffCalculatedDebtFetching ||
          getPayrollTransactionLoading ||
          getPayrollTransactionFetching ||
          getLastThreeMonthSortedDataStaffAttendanceLoading ||
          getLastThreeMonthSortedDataStaffAttendanceFetching ||
          loading ||
          updateMonthlyPayrollLoading
        }
      />
      {/* Debt dialog */}
      <AddNewDebtToStaffFreshDialog
        open={state.debtDialog}
        onClose={() => setState((prev) => ({ ...prev, debtDialog: false }))}
        refetch={() => {
          getStaffCalculatedDebtRefetch();
          getPayrollTransactionRefetch();
        }}
        staffDetail={{
          _id: {
            staffDocId: state.data?.employmentInfo?.staffDetail?._id,
          },
        }}
      />
      {/* Drawer  */}
      <PayrollDrawer
        open={state.openDrawer}
        handleClose={() => {
          setState((prev) => ({
            ...prev,
            openDrawer: false,
            drawerData: {},
          }));
        }}
        data={state.drawerData}
        staffData={state?.data?.employmentInfo}
      />
      {/* update staff attendance dialog */}
      <Dialog
        open={state.attendanceDialog}
        onClose={() => {
          setState((prev) => ({ ...prev, attendanceDialog: false }));
        }}
        PaperProps={{
          sx: { maxWidth: { xs: "95%", md: 550 } },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Update Attendance"
            handleClose={() => {
              setState((prev) => ({ ...prev, attendanceDialog: false }));
            }}
          />
        </DialogTitle>

        <DialogContent>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={6}>
              <TextFieldLabel title="Total Days" />
              <CustomTextField
                size="small"
                fullWidth
                type="number"
                value={state?.totalDays}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    totalDays: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Present Days" />
              <CustomTextField
                size="small"
                fullWidth
                type="number"
                value={state?.presentDays}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    presentDays: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Absent Days" />
              <CustomTextField
                size="small"
                fullWidth
                type="number"
                value={state?.absentDays}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    absentDays: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel title="Half Days" />
              <CustomTextField
                size="small"
                fullWidth
                type="number"
                value={state?.halfDays}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    halfDays: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>
          <Typography
            sx={{
              color: colors.red[500],
              fontSize: "14px",
              fontWeight: 500,
              mt: 2,
            }}
          >
            {checkAttendanceDays()}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            onClick={() => handleUpdateAttendance()}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* View employment dialog */}
      <ViewEmploymentDialog
        handleCloseView={() =>
          setDialogs((prev) => ({ ...prev, viewEmployment: false }))
        }
        viewOpen={dialogs.viewEmployment}
        staffDocId={state?.data?.employmentInfo?.staffDetail?._id}
        refetch={getPayrollDataRefetch}
      />

      <Container maxWidth="xl">
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Button
            variant="outlined"
            size="large"
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              backgroundColor: "#fff",
              fontSize: "14px",
            }}
            color="secondary"
            onClick={() =>
              setDialogs((prev) => ({ ...prev, viewEmployment: true }))
            }
          >
            View Employment
          </Button>
        </Stack>
        <Title>Staff Information</Title>
        <CustomBox style={{ padding: "20px" }}>
          <Grid container spacing={1} columns={10}>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Employee Id"
                text={state?.data?.employmentInfo?.employeeId}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography fontSize={14} color="primary" sx={{ opacity: 0.75 }}>
                Name
              </Typography>
              <LinkText
                to={`/${appRoutes.staffProfile}/${state?.data?.employmentInfo?.staffDetail?._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${state?.data?.employmentInfo?.staffDetail?.firstName} ${state?.data?.employmentInfo?.staffDetail?.middleName} ${state?.data?.employmentInfo?.staffDetail?.lastName}` ||
                  "--"}{" "}
              </LinkText>
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Department"
                text={state?.data?.employmentInfo?.departmentName}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Designation"
                text={state?.data?.employmentInfo?.designation}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="PAN Number"
                text={state?.data?.employmentInfo?.staffDetail?.PAN}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="EPF Number"
                text={state?.data?.employmentInfo?.epfNumber}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="EPS Number"
                text={state?.data?.employmentInfo?.epsNumber}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Basic salary"
                text={state?.data?.employmentInfo?.basicSalary}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Salary / Day"
                text={state?.data?.employmentInfo?.salaryPerDay}
              />
            </Grid>
            <Grid item xs={2}>
              <LabelTextComponent
                label="Salary Month-Year"
                text={state?.data?.month + "-" + state?.data?.year}
              />
            </Grid>
          </Grid>
        </CustomBox>
        <CustomBox sx={{ my: 2, width: "fit-content" }}>
          <Stack direction={"row"} alignItems={"center"} gap={4}>
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Total Debt / Loan
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {IndianCurrency(debtState.withdrawalAmount)}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Total Adjusted
              </Typography>
              <Stack direction={"row"} gap={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  {IndianCurrency(debtState.depositAmount)}
                </Typography>
                {/* <Box
                  sx={{
                    background: "#fefefe",
                    height: "20px",
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setState((prev) => ({ ...prev, debtDialog: true }))
                  }
                >
                  <Add />
                </Box> */}
              </Stack>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Total Balance
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {IndianCurrency(
                  Number(debtState.depositAmount ?? 0) +
                    Number(debtState.withdrawalAmount ?? 0)
                )}
              </Typography>
            </Box>
          </Stack>
        </CustomBox>
        <Grid container spacing={3}>
          {/* earining or deduction */}
          <Grid item xs={8}>
            <Box>
              <Title>Earnings</Title>
              <TableForEarningDeduction
                getStaffCalculatedDebtData={getStaffCalculatedDebtData?.data}
                setDebtState={setDebtState}
                setFalseState={setFalseState}
                tableData={state.earningData}
                headType="Earning"
                refetch={() => {
                  getPayrollTransactionRefetch();
                  getStaffCalculatedDebtRefetch();
                }}
                staffDocId={state.data?.employmentInfo?.staffDetail?._id}
                payrollHead={state.earningdHead}
                total={IndianCurrency(totalEarnings)}
                totalText="Total Earning"
                month={state?.data?.month}
                year={state?.data?.year}
              />
            </Box>

            <Box mt={2}>
              <Title>Deductions</Title>
              <TableForEarningDeduction
                getStaffCalculatedDebtData={getStaffCalculatedDebtData?.data}
                setDebtState={setDebtState}
                setFalseState={setFalseState}
                tableData={state.deductionData}
                headType="Deduction"
                refetch={() => {
                  getPayrollTransactionRefetch();
                  getStaffCalculatedDebtRefetch();
                }}
                staffDocId={state.data?.employmentInfo?.staffDetail?._id}
                payrollHead={state.deductionsHead}
                total={IndianCurrency(totalDeductions)}
                totalText="Total Deduction"
                month={state?.data?.month}
                year={state?.data?.year}
              />
            </Box>
            <CustomBox
              style={{
                display: "flex",
                marginTop: "15px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Title sx={{ my: 1 }}>Net Pay</Title>
              <Title sx={{ my: 1 }}>Total Earning - Total Deduction</Title>

              <Title sx={{ my: 1 }}>
                {IndianCurrency(
                  Number(totalEarnings) - Number(totalDeductions)
                )}{" "}
                /-
              </Title>
            </CustomBox>
            <Stack
              justifyContent={"space-between"}
              margin={2}
              direction={"row"}
            >
              <Typography>
                Note : These below detail is showing as per last updated
                Employment Info
              </Typography>
              <LoadingButton
                loading={updateMonthlyPayrollLoading}
                onClick={() => handleUpdatePayroll()}
                variant="contained"
                color="secondary"
                sx={{ fontSize: "1.2rem", padding: "10px 30px" }}
              >
                Update
              </LoadingButton>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Title>Last 3 months attendance</Title>
            <CustomBox>
              <Stack
                direction="row"
                gap={2}
                alignItems={"center"}
                flexWrap={"wrap"}
                justifyContent={"space-evenly"}
              >
                {state.threeMonths?.map((item, i) => (
                  <Card key={i}>
                    <div className="header">
                      <Typography
                        sx={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            openDrawer: true,
                            drawerData: item,
                          }));
                          console.log("item", item);
                        }}
                      >
                        {item?.month}-{item?.year} :{" " + item?.totalDays} Day
                      </Typography>
                    </div>
                    <Grid container spacing={1} sx={{ p: 1, width: "100%" }}>
                      <Grid item xs={5}>
                        <Typography className="sub-title">Present</Typography>
                        <Typography className="sub-title-content">
                          {item?.presentDays}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography className="sub-title">Absent</Typography>
                        <Typography className="sub-title-content">
                          {item?.absentDays}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography className="sub-title">Half Day</Typography>
                        <Typography className="sub-title-content">
                          {item?.halfDays}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography className="sub-title">
                          Leave + Holiday
                        </Typography>
                        <Typography className="sub-title-content">
                          {item?.leaveDays} + {item?.holidays}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
                <Box>
                  <Card>
                    <div className="header">
                      <Typography fontSize={12} fontWeight={600}>
                        Payroll Attendance
                      </Typography>
                      <Stack
                        sx={{
                          cursor: "pointer",
                          padding: "5px",
                          background: "#fff",
                          color: "#333",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,

                            attendanceDialog: true,
                          }));
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                      </Stack>
                    </div>
                    <Grid container spacing={1} sx={{ p: 1, width: "100%" }}>
                      <Grid item xs={5}>
                        <Typography className="sub-title">Present</Typography>
                        <Typography className="sub-title-content">
                          {state?.presentDays}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography className="sub-title">
                          Absent + LOP
                        </Typography>
                        <Typography className="sub-title-content">
                          {state?.absentDays} + {state?.lopDay}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography className="sub-title">Half Day</Typography>
                        <Typography className="sub-title-content">
                          {state?.halfDays}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography className="sub-title">
                          Leave + Holiday
                        </Typography>
                        <Typography className="sub-title-content">
                          {state?.leaveDays} + {state?.holidays}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Box>
              </Stack>
            </CustomBox>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CreatePayroll;

const CustomBox = styled("div")`
  /* background: rgba(208, 208, 226, 0.08); */
  background: #fff;
  /* border: 1px solid #d0d0e2; */
  /* border-radius: 15px; */
  border-radius: 5px;
  width: 100%;
  padding: 10px;

  .sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
    opacity: 0.75;
  }

  .sub-title-content {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

const Card = styled("div")`
  background: #ffffff;
  border: 1px solid #d0d0e2;
  border-radius: 10px;
  width: 18rem;
  /* height: 12rem; */

  .sub-title {
    font-size: 12px;
    line-height: 15px;
  }
  .sub-title-content {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  .header {
    height: 30px;
    background: #2789fd;
    color: #fff;
    border-radius: 10px 10px 0 0;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header2 {
    height: 30px;
    width: "100%";
    background: #2789fd;
    color: #fff;
    border-radius: 10px 10px 0 0;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: #0c2f49;
  margin-bottom: 10px;
  margin-left: 10px;
`;
const LinkText = styled(Link)`
  font-size: 16px;
  font-weight: 600;
  color: blue;
`;
