import React, { useState } from "react";
import CommonDialog from "../../CommonDialog";
import {
  Box,
  Button,
  Stack,
  styled,
  Typography,
  Grid,
  Select,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  InputAdornment,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import * as yup from "yup";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import { useMutation, useQuery } from "react-query";
import { getStudents1 } from "app/services/management";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import { toast } from "react-toastify";
import {
  getBookGroup,
  getSingleBooksForSale,
  saleBook,
} from "app/services/ledgerAccount";
import { DatePicker } from "@mui/x-date-pickers";
import { Delete, ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import PaymentModeComponent from "app/components/common/PaymentModeComponent";
import StudentSearchInput3 from "app/components/common/StudentSearchInput3";
import StudentCard from "./components/StudentCard";
import appRoutes from "app/config/routes";
const SaleBookDialog = ({
  open = false,
  onClose = () => {},
  classData = [],
  refetch = () => {},
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [dialogs, setDialogs] = useState({
    payment: false,
  });

  const [gst, setGst] = useState({
    gstNumber: "",
    igst: "",
    cgst: "",
    sgst: "",
    hsn: "",
    amountCgst: "",
    amountSgst: "",
    amountIgst: "",
  });
  const [state, setState] = useState({
    tableData: [],
    dialogData: {},
    installmentData: {},
    add: {
      class: "",
      section: "",
      student: "",
      bookType: "",
      bookTitle: "",
      collectionDate: new Date(),
      entityArr: [],
      payment: {
        totalAmount: 0,
        discountRemark: "",
        taxableAmount: 0,
        balance: 0,
        balanceRemark: "",
      },
    },
    book: "",
    selectedClass: "",
    section: "",
  });

  const payDetailFormik = useFormik({
    initialValues: {
      edRemarks: "",
      taxableAmount: 0,
      bRemarks: "",
    },
    validationSchema: yup.object({
      taxableAmount: yup.number().required("Please Enter Paying Amount"),
    }),
    onSubmit: (values) => {
      const obj = {
        session: selectedSession,
        studentDocId: newBookSellFormik.values.student,
        collectionDate: newBookSellFormik.values.collectionDate,
        invoiceItems: newBookSellFormik.values.entityArr?.map((item) => ({
          bookDocId: item._id,
          // description: item.bookTitle,
          // UOM: "Nos",
          // rate: item?.sellingRate,
          quantity: item?.newQuantity,
          discountAmount: item?.rupeesDiscount || 0,
          // itemAmount: item?.amount,
        })),

        totalAmount: state?.add?.payment?.totalAmount,
        discountRemark: values.edRemarks,
        taxableAmount: values.taxableAmount,
        balanceAmount:
          Number(values.taxableAmount) -
          Number(state?.add?.payment?.totalAmount),
        balanceRemark: values.bRemarks,
        paymentDetails: values.paymentDetails,
      };
      const newObj =
        gst.igst || gst.cgst || gst.sgst
          ? {
              ...obj,
              payerGSTNumber: gst.gstNumber,
              hsn: {
                hsncode: gst.hsn,
                igst: gst.igst || "0",
                cgst: gst.cgst || "0",
                sgst: gst.cgst || "0",
              },
            }
          : obj;
      saleBookMutate(newObj);
    },
  });

  const newBookSellFormik = useFormik({
    initialValues: {
      class: "",
      section: "",
      student: "",
      bookType: "",
      bookTitle: "",
      entityArr: [],
      collectionDate: new Date(),
      sections: [],
      students: [],
      selectedStudent: "",
    },
    validationSchema: yup.object({
      class: yup.mixed().required("Class Selection is required"),
      section: yup.string().required("Section Selection is required"),
      student: yup.mixed().required("Student Selection is required"),
      bookType: yup.mixed().required("Book Type Selection is required"),
      bookTitle: yup.mixed().required("Book Title Selection is required"),
      collectionDate: yup.string().required("Collection Date is required"),
    }),
    onSubmit: (values) => {
      setDialogs((prev) => ({ ...prev, add: false, payment: true }));
      setState((prev) => ({
        ...prev,
        add: {
          ...prev.add,
          payment: { ...prev.add.payment, totalAmount: totalAmount },
        },
      }));
      newBookSellFormik.setValues((prev) => ({
        ...prev,
        totalAmount: totalAmount,
      }));
    },
  });

  const totalAmount = newBookSellFormik.values.entityArr?.reduce(
    (sum, value) => sum + value.amount,
    0
  );
  const totalRate = newBookSellFormik.values.entityArr?.reduce(
    (sum, value) => sum + value.sellingRate * value.newQuantity,
    0
  );
  const handleClose = () => {
    onClose();
  };
  const classes = classData?.map((item) => ({
    name: item?.className_stream,
    classDocId: item?.classDocId,
  }));

  const sections = (data) => {
    return classData?.filter((item) => item.classDocId === data)[0]?.sections;
  };
  const handleShowInvoiceInNewTab = (invoiceNumber) => {
    setTimeout(() => {
      window.open(
        `${window.location.origin}/${appRoutes.paySlip}/${String(
          invoiceNumber
        ).replace(/\//g, " ")}`,
        "_blank"
      );
    }, 1000);
  };

  const { mutate: saleBookMutate, isLoading: saleBookLoading } = useMutation(
    saleBook,
    {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        setDialogs((prev) => ({ ...prev, payment: false }));
        handleClose();
        payDetailFormik.resetForm();
        newBookSellFormik.resetForm();
        handleClose();
        refetch();
        handleShowInvoiceInNewTab(res?.data?.invoiceNumber);
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    }
  );
  // get group books
  const {
    refetch: getBookGroupRefetch,
    isLoading: getBookGraoupLoading,
    isFetching: getBookGroupFetching,
  } = useQuery({
    queryKey: ["getBookGroup", newBookSellFormik.values.section],
    queryFn: () =>
      getBookGroup({
        classDocId: newBookSellFormik.values.class,
        section: newBookSellFormik.values.section,
      }),
    onSuccess: (res) => {
      newBookSellFormik.setValues((prev) => ({
        ...prev,
        books: res?.data?.list,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: false,
  });
  // get single book
  const {
    refetch: getBooksRefetch,
    isLoading: getSingleBookForSale,
    isFetching: getSingleBookForSaleFetching,
  } = useQuery({
    queryKey: ["getSingleBooksForSale", newBookSellFormik.values.section],
    queryFn: () =>
      getSingleBooksForSale({
        classDocId: newBookSellFormik.values.class,
        section: newBookSellFormik.values.section,
      }),
    onSuccess: (res) => {
      newBookSellFormik.setValues((prev) => ({
        ...prev,
        books: res?.data?.list,
      }));
      setState((prev) => ({ ...prev, books: res?.data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: false,
  });
  const { mutate: getsingleBookGroupData } = useMutation(getBookGroup, {
    onSuccess: (res) => {
      newBookSellFormik.setValues((prev) => ({
        ...prev,
        entityArr: [
          ...res?.data?.obj?.bookForSaleDocList?.map((item) => ({
            ...item,
            newQuantity: 1,
            amount: item?.sellingRate,
            rupeesDiscount: 0,
          })),
        ],
      }));
      toast.success(res?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const { isLoading: getStudentLoading, isFetching: getStudentFetching } =
    useQuery({
      queryFn: () =>
        getStudents1({
          classDocId: newBookSellFormik.values.class,
          section: newBookSellFormik.values.section,
          session: selectedSession,
        }),
      queryKey: ["getStudentForBook", newBookSellFormik.values.section],
      onSuccess: (res) => {
        newBookSellFormik.setValues((prev) => ({
          ...prev,
          students: res?.data?.list,
        }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: newBookSellFormik.values.section ? true : false,
    });

  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="700px"
        title="Add New Book Sale"
      >
        <form id="newBookSellForm" onSubmit={newBookSellFormik.handleSubmit}>
          <DialogContent>
            <Stack direction={isMobile ? "column" : "row"}>
              <Stack flex={1}>
                <TextFieldLabel
                  title="Class-Stream"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <CustomSelectBox
                  displayEmpty
                  sx={{ width: isMobile ? "100%" : "17rem" }}
                  name="class"
                  MenuProps={MenuProps}
                  value={newBookSellFormik.values.class}
                  onChange={(e) => {
                    newBookSellFormik.setValues((prev) => ({
                      ...prev,
                      class: e.target.value,
                      sections: sections(e.target.value),
                      student: "",
                      section: "",
                      bookTitle: "",
                      entityArr: [],
                      bookType: "",
                    }));
                  }}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {classes?.map((item) => (
                    <MenuItem value={item.classDocId}>{item.name}</MenuItem>
                  ))}
                </CustomSelectBox>
                {newBookSellFormik.touched.class &&
                  newBookSellFormik.errors.class && (
                    <Typography sx={{ color: "red" }}>
                      {newBookSellFormik.errors.class}
                    </Typography>
                  )}
              </Stack>
              <Stack flex={1}>
                <TextFieldLabel
                  title="Section"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <CustomSelectBox
                  disabled={!newBookSellFormik.values.class}
                  sx={{ width: isMobile ? "100%" : "17rem" }}
                  name="section"
                  displayEmpty
                  MenuProps={MenuProps}
                  value={newBookSellFormik.values.section}
                  onChange={(e) => {
                    newBookSellFormik.setValues((prev) => ({
                      ...prev,
                      section: e.target.value,
                      student: "",
                      bookTitle: "",
                      entityArr: [],
                      bookType: "",
                    }));
                  }}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {newBookSellFormik.values.sections?.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </CustomSelectBox>
                {newBookSellFormik.touched.section &&
                  newBookSellFormik.errors.section && (
                    <Typography sx={{ color: "red" }}>
                      {newBookSellFormik.errors.section}
                    </Typography>
                  )}
              </Stack>

              <Stack flex={1}>
                <TextFieldLabel
                  title="Student Name"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                {/* by name search */}
                {/* <Stack
                  sx={{
                    mr: 0.2,
                  }}
                >
                  <StudentSearchInput3
                    returnStudentDocId={(studentId) => {
                      newBookSellFormik.setValues((prev) => ({
                        ...prev,
                        student: studentId,
                      }));
                    }}
                    prevState={setState}
                    prevSession={"2024-2025"}
                  />
                </Stack> */}

                <CustomSelectBox
                  sx={{ width: isMobile ? "100%" : "17rem" }}
                  endAdornment={
                    (getStudentLoading || getStudentFetching) && (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    )
                  }
                  disabled={
                    !newBookSellFormik.values.section &&
                    !newBookSellFormik.values.class
                  }
                  name="student"
                  displayEmpty
                  renderValue={() =>
                    newBookSellFormik?.values?.student
                      ? newBookSellFormik?.values?.selectedStudent?.firstName
                      : "Select Student"
                  }
                  MenuProps={MenuProps}
                  value={newBookSellFormik.values.student}
                  onChange={(e) => {
                    newBookSellFormik.setValues((prev) => ({
                      ...prev,
                      student: e.target.value?._id,
                      selectedStudent: e.target.value,
                    }));
                  }}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {newBookSellFormik.values.students?.map((student) => (
                    <MenuItem value={student}>
                      <StudentCard item={student} />
                    </MenuItem>
                  ))}
                </CustomSelectBox>

                {newBookSellFormik.touched.student &&
                  newBookSellFormik.errors.student && (
                    <Typography sx={{ color: "red" }}>
                      {newBookSellFormik.errors.student}
                    </Typography>
                  )}
              </Stack>
            </Stack>

            <Stack direction={isMobile ? "column" : "row"} mt={1}>
              <Stack flex={1}>
                <TextFieldLabel
                  title="Book Group Type"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <CustomSelectBox
                  displayEmpty
                  sx={{ width: isMobile ? "100%" : "17rem" }}
                  disabled={!newBookSellFormik.values.section}
                  MenuProps={MenuProps}
                  name="bookType"
                  value={newBookSellFormik.values.bookType}
                  onChange={(e) => {
                    newBookSellFormik.setValues((prev) => ({
                      ...prev,
                      bookType: e.target.value,
                    }));
                    e.target.value === "single"
                      ? getBooksRefetch()
                      : getBookGroupRefetch();
                  }}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  <MenuItem value="single">Single</MenuItem>
                  <MenuItem value="set">Book Set</MenuItem>
                </CustomSelectBox>
                {newBookSellFormik.touched.bookType &&
                  newBookSellFormik.errors.bookType && (
                    <Typography sx={{ color: "red" }}>
                      {newBookSellFormik.errors.bookType}
                    </Typography>
                  )}
              </Stack>
              <Stack flex={1}>
                <TextFieldLabel
                  title="Book Set/Book Title"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                {newBookSellFormik.values.bookType === "set" ? (
                  <>
                    <CustomSelectBox
                      endAdornment={
                        (getSingleBookForSale ||
                          getSingleBookForSaleFetching ||
                          getBookGraoupLoading ||
                          getBookGroupFetching) && (
                          <InputAdornment>
                            <CircularProgress size={20} />
                          </InputAdornment>
                        )
                      }
                      sx={{ width: isMobile ? "100%" : "17rem" }}
                      disabled={!newBookSellFormik.values.bookType}
                      name="bookTitle"
                      displayEmpty
                      MenuProps={MenuProps}
                      value={newBookSellFormik.values.bookTitle}
                      onChange={(e) => {
                        newBookSellFormik.setValues((prev) => ({
                          ...prev,
                          bookTitle: e.target.value,
                        }));
                        getsingleBookGroupData({
                          bookGroupDocId: e.target.value,
                        });
                      }}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {newBookSellFormik.values.books?.map((book) => (
                        <MenuItem value={book._id}>
                          {book.bookGroupName}
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                    {newBookSellFormik.touched.bookTitle &&
                      newBookSellFormik.errors.bookTitle && (
                        <Typography sx={{ color: "red" }}>
                          {newBookSellFormik.errors.bookTitle}
                        </Typography>
                      )}
                  </>
                ) : (
                  <>
                    <CustomSelectBox
                      disabled={!newBookSellFormik.values.bookType}
                      sx={{ width: isMobile ? "100%" : "17rem" }}
                      name="bookTitle"
                      displayEmpty
                      MenuProps={MenuProps}
                      value={newBookSellFormik.values.bookTitle}
                      onChange={(e) => {
                        newBookSellFormik.setValues((prev) => ({
                          ...prev,
                          bookTitle: e.target.value,
                          entityArr: [
                            {
                              ...e.target.value,
                              newQuantity: 1,
                              amount: e.target.value?.sellingRate,
                              rupeesDiscount: 0,
                            },
                          ],
                        }));
                      }}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {newBookSellFormik.values.books?.map((book) => (
                        <MenuItem value={book}>{book.bookTitle}</MenuItem>
                      ))}
                    </CustomSelectBox>
                    {newBookSellFormik.touched.bookTitle &&
                      newBookSellFormik.errors.bookTitle && (
                        <Typography sx={{ color: "red" }}>
                          {newBookSellFormik.errors.bookTitle}
                        </Typography>
                      )}
                  </>
                )}
              </Stack>
              <Stack flex={1}>
                <TextFieldLabel
                  title="Collection Date"
                  styleProps={{ fontSize: isMobile && "12px" }}
                />
                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  name="collectionDate"
                  value={newBookSellFormik.values.collectionDate}
                  onChange={(e) => {
                    newBookSellFormik.setValues((prev) => ({
                      ...prev,
                      collectionDate: e,
                    }));
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select"
                      sx={{ width: isMobile ? "100%" : "17rem" }}
                      size="small"
                    />
                  )}
                />
              </Stack>
            </Stack>
            <Stack mt={2}>
              <Label>Invoice Entity</Label>
              <GrayBox p={2} sx={{ width: "100%", overflowX: "auto" }}>
                <Box sx={{ width: { xs: "550px", md: "100%" } }}>
                  <table
                    style={{
                      padding: "20px",
                      width: "100%",
                      display: isMobile && "flex",
                      flexWrap: isMobile && "wrap",
                      flexDirection: isMobile && "column",
                    }}
                  >
                    <thead>
                      <tr
                        style={{
                          display: isMobile && "flex",
                          gap: isMobile && "12px",
                          flexWrap: isMobile && "wrap",
                          marginBottom: isMobile && "10px",
                        }}
                      >
                        <th>Sr. No.</th>
                        <th>Description</th>
                        <th>UOM</th>
                        <th>Qty</th>
                        <th>Rate</th>
                        {/* <th>% Dis.</th> */}
                        <th>&#8377; Dis.</th>
                        <th>Amount</th>
                      </tr>
                    </thead>

                    <tbody>
                      {newBookSellFormik.values.entityArr?.map(
                        (item, index) => (
                          <tr
                            style={{
                              display: isMobile && "flex",
                              flexWrap: isMobile && "wrap",
                              gap: isMobile && "10px",
                              marginTop: isMobile && "5px",
                            }}
                          >
                            {/* Sr. No */}
                            <td>{index + 1}</td>
                            {/* Description */}
                            <td>{item?.bookTitle}</td>
                            {/* UOM */}
                            <td>Nos</td>
                            {/* Qty */}
                            <td>
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={0.2}
                              >
                                <NumberField
                                  type="number"
                                  style={{
                                    color:
                                      Number(item?.newQuantity) >
                                        Number(item?.balanceQuantity) && "red",
                                  }}
                                  value={item?.newQuantity}
                                  onChange={(e) => {
                                    item["newQuantity"] = e.target.value;
                                    if (e.target.value >= 1) {
                                      item["amount"] =
                                        Number(e.target.value) *
                                        item.sellingRate;
                                    }
                                    newBookSellFormik.setValues((prev) => ({
                                      ...prev,
                                    }));
                                  }}
                                />
                                <Typography sx={{ fontSize: "10px" }}>
                                  ({item?.balanceQuantity})
                                </Typography>
                              </Stack>
                            </td>
                            {/* Rate */}
                            <td>{IndianCurrency(item?.sellingRate)}</td>

                            {/* % Dis. */}
                            {/* <td>
                              <NumberField
                                type="number"
                                value={item?.discount}
                                onChange={(e) => {
                                  item["discount"] = e.target.value;
                                  if (e.target.value >= 1) {
                                    item["rupeesDiscount"] = Number(
                                      item?.newQuantity *
                                        item?.sellingRate *
                                        (Number(e.target.value) / 100)
                                    ).toFixed(2);
                                    item["amount"] =
                                      item?.newQuantity * item?.sellingRate -
                                      item?.newQuantity *
                                        item?.sellingRate *
                                        (Number(e.target.value) / 100);
                                  } else {
                                    item["rupeesDiscount"] = 0;
                                    item["amount"] =
                                      item?.newQuantity * item?.sellingRate;
                                  }
                                  newBookSellFormik.setValues((prev) => ({
                                    ...prev,
                                  }));
                                }}
                              />
                            </td> */}

                            {/* Amount */}
                            <td>
                              <input
                                style={{ maxWidth: "60px" }}
                                type="number"
                                value={item?.rupeesDiscount}
                                onChange={(e) => {
                                  let dis =
                                    (100 * e.target.value) /
                                    (item?.newQuantity * item?.sellingRate);
                                  item["rupeesDiscount"] = e.target.value;
                                  item["discount"] = Number(dis).toFixed(2);
                                  item["amount"] =
                                    item?.newQuantity * item?.sellingRate -
                                    e.target.value;
                                  newBookSellFormik.setValues((prev) => ({
                                    ...prev,
                                  }));
                                }}
                              />
                            </td>
                            <td>{IndianCurrency(item?.amount)}</td>
                            <td>
                              <Button
                                onClick={() => {
                                  newBookSellFormik.setValues((prev) => ({
                                    ...prev,
                                    entityArr: prev.entityArr.filter(
                                      (item, i) => index !== i
                                    ),
                                  }));
                                }}
                              >
                                <Delete sx={{ color: "red" }} />
                              </Button>
                            </td>
                          </tr>
                        )
                      )}
                      <tr>
                        <td colSpan={7}>
                          <Stack
                            direction="row"
                            my={1}
                            alignItems="center"
                            gap={0.8}
                          >
                            <CustomSelectBox
                              displayEmpty
                              MenuProps={MenuProps}
                              sx={{ height: "30px", width: "120px" }}
                              value={state?.book}
                              onChange={(e) => {
                                setState((prev) => ({
                                  ...prev,
                                  book: e.target.value,
                                }));
                              }}
                            >
                              <MenuItem value="" disabled>
                                Select Book
                              </MenuItem>
                              {state?.books?.map((book) => (
                                <MenuItem value={book}>
                                  {book?.bookTitle}
                                </MenuItem>
                              ))}
                            </CustomSelectBox>
                            <Typography
                              sx={{
                                color: " #2789FD",
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                state.book
                                  ? newBookSellFormik.setValues((prev) => ({
                                      ...prev,
                                      entityArr: [
                                        ...prev.entityArr,
                                        {
                                          bookTitle: state?.book?.bookTitle,
                                          _id: state?.book?._id,
                                          newQuantity: 1,
                                          quantity: 0,
                                          sellingRate: state?.book?.sellingRate,
                                          discount: 0,
                                          amount: state?.book?.sellingRate,
                                        },
                                      ],
                                    }))
                                  : null
                              }
                            >
                              + Add
                            </Typography>
                          </Stack>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </GrayBox>
              <Stack alignSelf="flex-end" mt={2}>
                <Typography>
                  Total Amount {IndianCurrency(totalAmount)}
                </Typography>
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
            >
              Next
            </Button>
          </DialogActions>
        </form>
      </CommonDialog>
      {/* payment detail dialog */}
      <CommonDialog
        open={dialogs?.payment}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, payment: false }));
          newBookSellFormik.resetForm();
          payDetailFormik.resetForm();
        }}
        minWidth="700px"
        title="Payment Detail"
      >
        <DialogContent>
          <form onSubmit={payDetailFormik.handleSubmit}>
            <Stack direction="row">
              <Stack flex={3}>
                <Stack direction="row" gap={2}>
                  <Label sx={{ fontWeight: "500" }}>Calculated Amount</Label>
                  <Label>
                    {IndianCurrency(state?.add?.payment?.totalAmount)}
                  </Label>
                </Stack>
                <Stack direction="row" gap={2} mt={2}>
                  <Stack flex={1}>
                    <Label sx={{ fontWeight: "500" }}>Total Discount</Label>
                    <CustomTextFieldDisabled
                      name="extraDiscount"
                      value={IndianCurrency(totalRate - totalAmount)}
                    />
                  </Stack>
                  <Stack flex={1}>
                    <Label sx={{ fontWeight: "500" }}>Discount Remarks</Label>
                    <CustomTextField
                      onBlur={payDetailFormik.handleBlur}
                      name="edRemarks"
                      placeholder="Enter Discount Remarks"
                      value={payDetailFormik.values.edRemarks}
                      onChange={(e) => {
                        payDetailFormik.setValues((prev) => ({
                          ...prev,
                          edRemarks: e.target.value,
                        }));
                      }}
                    />
                    {payDetailFormik.errors.edRemarks &&
                      payDetailFormik.touched.edRemarks && (
                        <Typography sx={{ color: "red" }}>
                          {payDetailFormik.errors.edRemarks}
                        </Typography>
                      )}
                  </Stack>
                  <Stack flex={1}>
                    <Label sx={{ fontWeight: "500" }}>Paying Amount</Label>
                    <CustomTextField
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography>&#8377;</Typography>
                          </InputAdornment>
                        ),
                      }}
                      value={payDetailFormik.values.taxableAmount}
                      onChange={(e) => {
                        payDetailFormik.setValues((prev) => ({
                          ...prev,
                          taxableAmount: e.target.value,
                        }));
                      }}
                    />
                    {payDetailFormik.errors.taxableAmount &&
                      payDetailFormik.touched.taxableAmount && (
                        <Typography sx={{ color: "red" }}>
                          {payDetailFormik.errors.taxableAmount}
                        </Typography>
                      )}
                  </Stack>
                </Stack>
                <Stack direction="row" gap={4} mt={2}>
                  <Stack>
                    <Label sx={{ fontWeight: "500" }}>
                      Balance Amount(Without Tax)
                    </Label>
                    <CustomTextFieldDisabled
                      value={IndianCurrency(
                        Number(state?.add?.payment?.totalAmount) -
                          Number(payDetailFormik.values.taxableAmount)
                      )}
                    />
                  </Stack>
                  <Stack>
                    <Label sx={{ fontWeight: "500" }}>Balance Remarks</Label>
                    <CustomTextField
                      onBlur={payDetailFormik.handleBlur}
                      name="bRemarks"
                      placeholder="Enter Balance Remarks"
                      value={payDetailFormik.values.bRemarks}
                      onChange={(e) => {
                        payDetailFormik.setValues((prev) => ({
                          ...prev,
                          bRemarks: e.target.value,
                        }));
                      }}
                    />
                    {payDetailFormik.errors.bRemarks &&
                      payDetailFormik.touched.bRemarks && (
                        <Typography sx={{ color: "red" }}>
                          {payDetailFormik.errors.bRemarks}
                        </Typography>
                      )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <hr />
            <Stack direction="row" alignItems="center" gap={2}>
              <Stack flex={3}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Title>GST Information</Title>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      <Grid item>
                        <Label>Payer's GST Number</Label>
                        <CustomTextField
                          placeholder="Type gst number"
                          onChange={(e) =>
                            setGst((prev) => ({
                              ...prev,
                              gstNumber: e.target.value,
                            }))
                          }
                          value={gst.gstNumber}
                        />
                      </Grid>
                      <Grid item>
                        <Label>HSN code</Label>
                        <CustomTextField
                          fullWidth
                          size="small"
                          value={gst.hsn}
                          onChange={(e) =>
                            setGst((prev) => ({ ...prev, hsn: e.target.value }))
                          }
                          placeholder="Type gst number"
                        />
                      </Grid>
                      <Grid item>
                        <Label>Add IGST %</Label>
                        <CustomTextField
                          placeholder="Type IGST"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography>%</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={gst.igst}
                          onChange={(e) =>
                            setGst((prev) => ({
                              ...prev,
                              igst: e.target.value,
                              sgst: "",
                              cgst: "",
                              amountCgst: "",
                              amountSgst: "",
                              amountIgst:
                                Number(payDetailFormik.values.taxableAmount) *
                                (Number(e.target.value) / 100),
                            }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Amount IGST</Label>
                        <CustomTextFieldDisabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography>&#8377;</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={
                            Number(payDetailFormik.values.taxableAmount) *
                            (Number(gst.igst) / 100)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Add SGST %</Label>
                        <CustomTextField
                          placeholder="Type SGST"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography>%</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={gst.sgst}
                          onChange={(e) =>
                            setGst((prev) => ({
                              ...prev,
                              sgst: e.target.value,
                              igst: "",
                              amountIgst: "",
                              amountSgst:
                                Number(payDetailFormik.values.taxableAmount) *
                                (Number(e.target.value) / 100),
                            }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Amount SGST</Label>
                        <CustomTextFieldDisabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography>&#8377;</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={
                            Number(payDetailFormik.values.taxableAmount) *
                            (Number(gst.sgst) / 100)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Add CGST %</Label>
                        <CustomTextField
                          placeholder="Type CGST"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography>%</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={gst.cgst}
                          onChange={(e) =>
                            setGst((prev) => ({
                              ...prev,
                              cgst: e.target.value,
                              igst: "",
                              amountIgst: "",
                              amountCgst:
                                Number(payDetailFormik.values.taxableAmount) *
                                (Number(e.target.value) / 100),
                            }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Amount CGST</Label>
                        <CustomTextFieldDisabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography>&#8377;</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={
                            Number(payDetailFormik.values.taxableAmount) *
                            (Number(gst.cgst) / 100)
                          }
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Stack>
              <Stack flex={1} direction="column" gap={1}>
                <Stack>
                  <Label>Taxable Amount</Label>
                  <Typography>
                    {IndianCurrency(
                      Number(payDetailFormik.values.taxableAmount)
                    )}
                  </Typography>
                </Stack>
                <Stack>
                  <Label>Tax Amount</Label>
                  <Typography>
                    {IndianCurrency(
                      Number(gst.amountCgst) +
                        Number(gst.amountIgst) +
                        Number(gst.amountSgst)
                    )}
                  </Typography>
                </Stack>
                <Stack>
                  <Label>Paid Amount</Label>
                  <Typography>
                    {IndianCurrency(
                      Number(payDetailFormik.values.taxableAmount) +
                        Number(gst.amountCgst) +
                        Number(gst.amountIgst) +
                        Number(gst.amountSgst)
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </form>

          <Stack mt={2}>
            <PaymentModeComponent
              formId="pay-detail-form"
              amount={
                Number(payDetailFormik.values.taxableAmount) +
                Number(gst.amountCgst) +
                Number(gst.amountIgst) +
                Number(gst.amountSgst)
              }
              onClickSubmit={(data) => {
                payDetailFormik.setValues((prev) => ({
                  ...prev,
                  paymentDetails: data,
                }));
                payDetailFormik.handleSubmit();
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              setDialogs((prev) => ({ ...prev, add: true, payment: false }));
            }}
          >
            Previous
          </Button>
          <LoadingButton
            loading={saleBookLoading}
            form="pay-detail-form"
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default SaleBookDialog;
const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 600,
  color: "#0C2F49",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  lineHeight: "1.4rem",
  fontWeight: 600,
  color: "#0C2F49",
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  width: "200px",
  backgroundColor: "#fff",
  color: "#333",
  borderRadius: "7px",
}));

const GrayBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#f8fbff",
  border: "1px solid rgba(61, 57, 137, 0.1)",
  boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.05)",
  borderRadius: "20px",
}));

const NumberField = styled("input")`
  max-width: 60px;
  border-radius: 10px;
  padding: 2px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: block;
    opacity: 1;
  }
`;
