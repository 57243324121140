import API_ENDPOINTS from "app/config/endpoints";
import axios from "axios";

// Get Auth Access Token from localstorage
const authAccessToken = localStorage.getItem("authAccessToken");

// get School Access token from localstorage
const schoolAccessToken = localStorage.getItem("schoolToken");

export const schoolTokenAxios = axios.create({});
export const authTokenAxios = axios.create({});

// set Axios header with previous access tokens
if (authAccessToken) {
  authTokenAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${authAccessToken}`;
}

if (schoolAccessToken) {
  schoolTokenAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${schoolAccessToken}`;
}

authTokenAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const refreshResponse = await axios.post(
        API_ENDPOINTS.auth.refreshToken,
        {
          refreshToken: localStorage.getItem("authRefreshToken"),
        }
      );
      prevRequest.headers[
        "Authorization"
      ] = `Bearer ${refreshResponse.data.accessToken}`;

      authTokenAxios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${refreshResponse.data.accessToken}`;

      localStorage.setItem(
        "authRefreshToken",
        refreshResponse.data.refreshToken
      );

      return authTokenAxios(prevRequest);
    }
    return Promise.reject(error);
  }
);

schoolTokenAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const refreshResponse = await axios.get(
        API_ENDPOINTS.auth.schoolRefreshToken,
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("schoolRefreshToken"),
          },
        }
      );
      prevRequest.headers[
        "Authorization"
      ] = `Bearer ${refreshResponse.data.obj.accessToken}`;

      schoolTokenAxios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${refreshResponse.data.obj.accessToken}`;

      localStorage.setItem(
        "shoolRefreshToken",
        refreshResponse.data.obj.refreshToken
      );

      return schoolTokenAxios(prevRequest);
    }
    return Promise.reject(error);
  }
);
