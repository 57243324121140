import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { getAllSessions } from "app/services/management";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { toast } from "react-toastify";
import DialogHeader from "app/components/common/DialogHeader";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import { IndianCurrency } from "app/utils/helper";
import {
  CustomTextField,
  CustomTextFieldDisabled,
  TabsWrapper,
} from "assets/styles/globalStyledComponent";

import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";

import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Delete } from "@mui/icons-material";
import {
  getFineRate,
  updateFineRate,
} from "app/services/schoolService/newFee.service";
import CustomLoader from "app/components/common/CustomLoader";
const FineDetail = ({ formik }) => {
  const getErrorFine = (name, index) => {
    try {
      if (
        formik.touched?.slotWiseData[index][name] &&
        Boolean(formik.errors?.slotWiseData[index][name])
      ) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const getTouchedFine = (name, index) => {
    try {
      if (formik.touched?.slotWiseData[index][name]) {
        return formik.errors?.slotWiseData[index][name];
      }
    } catch (error) {
      return "";
    }
  };
  return (
    <>
      <Typography sx={{ fontWeight: 600 }}>
        Fine Calculation Rate : &nbsp; &nbsp;
      </Typography>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Radio
              color="secondary"
              checked={
                formik.values.fineCalculationRate === "fixedAmount"
                  ? true
                  : false
              }
              onClick={() =>
                formik.setValues((prev) => ({
                  ...prev,
                  fineCalculationRate: "fixedAmount",
                }))
              }
            />
          }
          label="Fixed Amount"
        />
        <FormControlLabel
          control={
            <Radio
              color="secondary"
              checked={
                formik.values.fineCalculationRate === "slotWise" ? true : false
              }
              onClick={() =>
                formik.setValues((prev) => ({
                  ...prev,
                  fineCalculationRate: "slotWise",
                }))
              }
            />
          }
          label="Slot wise (Per Day)"
        />
        <FormControlLabel
          control={
            <Radio
              color="secondary"
              checked={
                formik.values.fineCalculationRate === "slotWiseFixed"
                  ? true
                  : false
              }
              onClick={() =>
                formik.setValues((prev) => ({
                  ...prev,
                  fineCalculationRate: "slotWiseFixed",
                }))
              }
            />
          }
          label="Slot wise (Fixed Amount)"
        />
      </Stack>
      {formik.values.fineCalculationRate === "fixedAmount" && (
        <>
          <CustomTextField
            sx={{ width: "200px", mt: 1 }}
            fullWidth
            size="small"
            placeholder="Type amount"
            value={formik.values.fixedAmount}
            name="fixedAmount"
            onChange={formik.handleChange}
            type="number"
            error={
              formik.touched.fixedAmount && Boolean(formik.errors.fixedAmount)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>&#8377;</Typography>
                </InputAdornment>
              ),
            }}
          />
          {formik.touched.fixedAmount && Boolean(formik.errors.fixedAmount) && (
            <Typography color="error">{formik.errors.fixedAmount}</Typography>
          )}
        </>
      )}
      {formik.values.fineCalculationRate !== "fixedAmount" && (
        <>
          <Box sx={{ width: "70%" }}>
            <Grid container spacing={1} columns={8}>
              <Grid item xs={2}>
                <Head1>Day</Head1>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <Head1>Day</Head1>
              </Grid>
              <Grid item xs={2}>
                <Head1>Amount</Head1>
              </Grid>
              <Grid item xs={1} />
            </Grid>
            {formik.values?.slotWiseData?.map((item, i) => (
              <Grid
                container
                spacing={1}
                columns={8}
                key={i}
                display={"flex"}
                alignItems={"center"}
                sx={{ pt: 0.5 }}
              >
                <Grid item xs={2}>
                  <CustomTextField
                    error={
                      getErrorFine("fromDay", i) && getTouchedFine("fromDay", i)
                    }
                    fullWidth
                    size="small"
                    placeholder="Type day"
                    value={item?.fromDay}
                    type="number"
                    onChange={(e) => {
                      item["fromDay"] = e.target.value;
                      formik.setValues((prev) => ({ ...prev }));
                    }}
                  />
                  {getErrorFine("fromDay", i) &&
                    getTouchedFine("fromDay", i) && (
                      <Typography color="red">required</Typography>
                    )}
                </Grid>
                <Grid item xs={1}>
                  <Head1 textAlign={"center"}>To</Head1>
                </Grid>
                <Grid item xs={2}>
                  <CustomTextField
                    error={
                      getErrorFine("toDay", i) && getTouchedFine("toDay", i)
                    }
                    fullWidth
                    size="small"
                    placeholder="Type day"
                    value={item?.toDay}
                    type="number"
                    onChange={(e) => {
                      item["toDay"] = e.target.value;
                      formik.setValues((prev) => ({ ...prev }));
                    }}
                  />
                  {getErrorFine("toDay", i) && getTouchedFine("toDay", i) && (
                    <Typography color="red">required</Typography>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <CustomTextField
                    error={
                      getErrorFine("amount", i) && getTouchedFine("amount", i)
                    }
                    fullWidth
                    size="small"
                    placeholder="Type amount"
                    value={item?.amount}
                    onChange={(e) => {
                      item["amount"] = e.target.value;
                      formik.setValues((prev) => ({ ...prev }));
                    }}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography>&#8377;</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {getErrorFine("amount", i) && getTouchedFine("amount", i) && (
                    <Typography color="red">required</Typography>
                  )}
                </Grid>
                <Grid item xs={1}>
                  {i !== 0 && (
                    <Delete
                      sx={{ cursor: "pointer" }}
                      color="error"
                      onClick={() =>
                        formik.setValues((prev) => ({
                          ...prev,
                          slotWiseData: prev.slotWiseData.filter(
                            (_, ind) => i !== ind
                          ),
                        }))
                      }
                    />
                  )}
                </Grid>
              </Grid>
            ))}
            <Grid
              container
              spacing={1}
              columns={8}
              display={"flex"}
              alignItems={"center"}
              sx={{ mt: 0.5 }}
            >
              <Grid item xs={3}>
                <Head1 textAlign={"center"} fontSize={16}>
                  Greater Than
                </Head1>
              </Grid>

              <Grid item xs={2}>
                <CustomTextFieldDisabled
                  fullWidth
                  size="small"
                  value={
                    formik.values.slotWiseData[
                      formik.values.slotWiseData?.length - 1
                    ].toDay
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <CustomTextField
                  fullWidth
                  size="small"
                  placeholder="amount"
                  value={formik.values.graterThanAmount}
                  error={
                    formik.touched.graterThanAmount &&
                    Boolean(formik.errors.graterThanAmount)
                  }
                  onChange={(e) => {
                    formik.setValues((prev) => ({
                      ...prev,
                      graterThanAmount: e.target.value,
                    }));
                  }}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography>&#8377;</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                {formik.touched.graterThanAmount &&
                  Boolean(formik.errors.graterThanAmount) && (
                    <Typography color="error">
                      {formik.errors.graterThanAmount}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={1} />
            </Grid>
            <AddMoreButton
              onClick={() =>
                formik.setValues((prev) => ({
                  ...prev,
                  slotWiseData: [
                    ...prev.slotWiseData,
                    {
                      fromDay:
                        prev.slotWiseData[prev.slotWiseData?.length - 1].toDay,
                      toDay:
                        Number(
                          prev.slotWiseData[prev.slotWiseData?.length - 1].toDay
                        ) + 1,
                      amount: 0,
                    },
                  ],
                }))
              }
            >
              + Add More
            </AddMoreButton>
          </Box>
        </>
      )}
    </>
  );
};
const ViewFineDetailDialog = ({
  refetch,
  open,
  handleClose,
  feeStructureGroupDocId,
  feeOccurrence,
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const fineFormik = useFormik({
    initialValues: {
      edit: false,
      fineDetailViewDialog: false,
      reoccuringData: [],
      oneTimeData: [],
      oneTimeDateValue: 1,
      tabValue: "1",
      fineCalculationRate: "fixedAmount",
      fixedAmount: 0,
      slotWiseData: [{ fromDay: 1, toDay: 2, amount: 0 }],
      graterThanAmount: 0,
    },
    validationSchema: Yup.object({
      graterThanAmount: Yup.string().when("fineCalculationRate", {
        is: (values) =>
          values === "slotWise" || values === "slotWiseFixed" ? true : false,
        then: Yup.string().required("required"),
      }),
      fixedAmount: Yup.string().when("fineCalculationRate", {
        is: "fixedAmount",
        then: Yup.string().required("required"),
      }),
      slotWiseData: Yup.array().when("fineCalculationRate", {
        is: (value) => (value !== "fixedAmount" ? true : false),
        then: Yup.array().of(
          Yup.object().shape({
            fromDay: Yup.string().required("required"),
            toDay: Yup.string().required("required"),
            amount: Yup.string().required("required"),
          })
        ),
      }),
    }),
    onSubmit: (values) => {
      let monthYearList = [];
      if (feeOccurrence === "oneTime") {
        const data = values.oneTimeData?.find(
          (item) => item?.id === values.oneTimeDateValue
        );
        monthYearList.push({
          year: dayjs(data?.label).format("YYYY"),
          month: dayjs(data?.label).format("MMMM"),
          dueDate: dayjs(data?.value).format("YYYY-MM-DD"),
        });
      } else {
        values.reoccuringData?.forEach((item) => {
          if (item.checked) {
            monthYearList.push({
              year: dayjs(item?.label).format("YYYY"),
              month: dayjs(item?.label).format("MMMM"),
              dueDate: dayjs(item?.value).format("YYYY-MM-DD"),
            });
          }
        });
      }
      const body = {
        feeStructureGroupDocId: feeStructureGroupDocId,
        monthYearList,
        isFixedAmount:
          values.fineCalculationRate === "fixedAmount" ? true : false,
        is_slotWise_perDay:
          values.fineCalculationRate === "slotWise" ? true : false,
        is_slotWise_fixedAmount:
          values.fineCalculationRate === "slotWiseFixed" ? true : false,
        fixedAmount:
          values.fineCalculationRate === "fixedAmount" ? values.fixedAmount : 0,
        slotWise:
          values.fineCalculationRate === "fixedAmount"
            ? null
            : {
                slotWiseFineRate: values.slotWiseData?.map((item) => ({
                  from: Number(item?.fromDay),
                  upTo: Number(item?.toDay),
                  amount: Number(item?.amount),
                })),
                amount: Number(values.graterThanAmount),
                greaterThanDay:
                  values.slotWiseData[values.slotWiseData?.length - 1].toDay,
              },
      };
      updateFineRateMutate(body);
    },
  });
  const calculateMinimumDate = (date) => {
    const dte = new Date(date);
    return new Date(`${dte.getMonth() + 1}-01-${dte.getFullYear()}`);
  };
  const sameAsAll = (value) => {
    const sameDate = new Date(value);
    fineFormik.setValues((prev) => ({
      ...prev,
      reoccuringData: prev?.reoccuringData?.map((res) => ({
        ...res,
        value: new Date(res?.value).setDate(sameDate.getDate()),
      })),
    }));
  };
  const handleCloseFineDetailDialog = () => {
    fineFormik.resetForm();
    handleClose();
  };
  const {
    isLoading: loadingSessions,
    isSuccess: sessionsLoaded,
    data: sessionsResponse,
    isFetched: sessionFetched,
  } = useQuery({
    queryKey: ["getAllSessions"],
    queryFn: () => getAllSessions(),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    isLoading: getFineRateLoading,
    isFetching: getFineRateFetching,
    isFetched: getFineRateFetched,
    data: getFineRateData,
  } = useQuery({
    queryKey: ["getAllSessions", feeStructureGroupDocId],
    queryFn: () => getFineRate({ feeStructureGroupDocId }),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: feeStructureGroupDocId ? true : false,
  });
  const { mutate: updateFineRateMutate, isLoading: updateFineRateLoadign } =
    useMutation(updateFineRate, {
      onSuccess: (success) => {
        toast.success(success.data?.message);
        refetch();
        fineFormik.resetForm();
        handleClose();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });
  useEffect(() => {
    if (sessionFetched && open) {
      const filteredData = sessionsResponse?.data?.list?.find(
        (item) => item?.sessionName === selectedSession
      );
      if (filteredData !== undefined) {
        const sessionStartDate = dayjs(filteredData.startDate);
        const sessionEndDate = dayjs(filteredData.endDate);
        const oneTimeData = [];
        const reoccuringData = [];
        const dt = new Date(sessionStartDate);
        for (
          let index = 0;
          index < sessionEndDate.diff(sessionStartDate, "M") + 1;
          index++
        ) {
          const month =
            dt.getMonth() + 1 < 10
              ? `0${dt.getMonth() + 1}`
              : dt.getMonth() + 1;

          oneTimeData.push({
            id: index + 1,
            value: new Date(`${month}-01-${dt.getFullYear()}`),
            label: new Date(`${month}-01-${dt.getFullYear()}`),
          });
          reoccuringData.push({
            id: index + 1,
            value: new Date(`${month}-01-${dt.getFullYear()}`),
            label: new Date(`${month}-01-${dt.getFullYear()}`),
            checked: false,
            // checked: index === 0 ? true : false,
          });
          dt.setMonth(dt.getMonth() + 1);
        }
        fineFormik.setValues((prev) => ({
          ...prev,
          reoccuringData,
          oneTimeData,
        }));
      }
    }
  }, [sessionFetched, selectedSession, open]);
  useEffect(() => {
    if (getFineRateFetched && fineFormik?.values.edit) {
      if (getFineRateData?.data) {
        const fineData = {
          fineCalculationRate: getFineRateData?.data?.obj?.isFixedAmount
            ? "fixedAmount"
            : getFineRateData?.data?.obj?.is_slotWise_fixedAmount
            ? "slotWiseFixed"
            : "slotWise",
          fixedAmount: getFineRateData?.data?.obj?.fixedAmount,
          graterThanAmount: getFineRateData?.data?.obj?.slotWise?.amount,
          slotWiseData: getFineRateData?.data?.obj?.slotWise
            ? getFineRateData?.data?.obj?.slotWise?.slotWiseFineRate?.map(
                (res) => ({
                  fromDay: res?.from,
                  toDay: res?.upTo,
                  amount: res?.amount,
                })
              )
            : [{ fromDay: 1, toDay: 2, amount: 0 }],
        };
        if (feeOccurrence === "oneTime") {
          const findedValue = fineFormik.values.oneTimeData?.find(
            (item) =>
              dayjs(item.value).format("MMMM") ===
              getFineRateData?.data?.obj?.monthYearList[0]?.month
          );

          fineFormik.setValues((prev) => ({
            ...prev,
            oneTimeDateValue: findedValue?.id,
            oneTimeData: prev.oneTimeData?.map((res) => {
              if (res?.id === findedValue?.id) {
                return {
                  ...res,
                  value: getFineRateData?.data?.obj?.monthYearList[0]?.dueDate,
                };
              } else {
                return res;
              }
            }),
            ...fineData,
          }));
        } else {
          const data = getFineRateData?.data?.obj?.monthYearList?.map(
            (res) => res?.month
          );

          const finalData = fineFormik.values?.reoccuringData.map((item) => {
            const findedData = getFineRateData?.data?.obj?.monthYearList?.find(
              (res) => dayjs(item?.label).format("MMMM") === res?.month
            );
            if (data.includes(dayjs(item?.label).format("MMMM"))) {
              return {
                ...item,
                value: findedData?.dueDate,
                checked: true,
              };
            } else {
              return item;
            }
          });
          fineFormik.setValues((prev) => ({
            ...prev,
            ...fineData,
            reoccuringData: finalData,
          }));
        }
      }
    }
  }, [getFineRateFetched, fineFormik?.values.edit]);
  return (
    <>
      <CustomLoader
        show={
          getFineRateLoading || getFineRateFetching || updateFineRateLoadign
        }
      />
      <Dialog
        open={open}
        onClose={handleCloseFineDetailDialog}
        PaperProps={{
          sx: { minWidth: "700px" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title={
              fineFormik?.values?.edit
                ? "Update Due Date and Fine Rate"
                : "Due Dates and Fine"
            }
            handleClose={handleCloseFineDetailDialog}
          />
        </DialogTitle>
        <DialogContent>
          {fineFormik?.values.edit || !getFineRateData?.data?.obj ? (
            <Box sx={{ mt: 1 }}>
              <TabContext value={fineFormik.values.tabValue}>
                <TabsWrapper>
                  <TabList
                    onChange={(e, value) =>
                      fineFormik.setValues((prev) => ({
                        ...prev,
                        tabValue: value,
                      }))
                    }
                  >
                    <Tab label="Due Dates" value="1" />
                    <Tab label="Fine Detail" value="2" />
                  </TabList>
                </TabsWrapper>
                <TabPanel value="1" sx={{ p: "0 !important" }}>
                  <Box sx={{ mt: 1 }}>
                    {feeOccurrence === "oneTime"
                      ? fineFormik.values?.oneTimeData?.map((item, i) => (
                          <Stack
                            direction="row"
                            gap={2}
                            justifyContent={"center"}
                            sx={{ pt: 0.5 }}
                          >
                            <Box sx={{ width: "200px" }}>
                              <FormControlLabel
                                control={
                                  <Radio
                                    color="secondary"
                                    checked={
                                      fineFormik.values.oneTimeDateValue ===
                                      item?.id
                                        ? true
                                        : false
                                    }
                                    onClick={() =>
                                      fineFormik.setValues((prev) => ({
                                        ...prev,
                                        oneTimeDateValue: item?.id,
                                      }))
                                    }
                                  />
                                }
                                label={dayjs(item?.label).format("MMMM-YYYY")}
                              />
                            </Box>
                            <DesktopDatePicker
                              // maxDate={calculateMaximumDate(item.value)}
                              minDate={calculateMinimumDate(item.value)}
                              disabled={
                                fineFormik.values.oneTimeDateValue === item?.id
                                  ? false
                                  : true
                              }
                              inputFormat="DD MMM, YYYY"
                              onChange={(e) => {
                                item["value"] = e;
                                fineFormik.setValues((prev) => ({ ...prev }));
                              }}
                              value={item.value}
                              renderInput={(params) =>
                                fineFormik.values.oneTimeDateValue ===
                                item?.id ? (
                                  <CustomTextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                    sx={{ width: "200px" }}
                                  />
                                ) : (
                                  <CustomTextFieldDisabled
                                    {...params}
                                    size="small"
                                    fullWidth
                                    sx={{ width: "200px" }}
                                  />
                                )
                              }
                            />
                          </Stack>
                        ))
                      : fineFormik.values?.reoccuringData?.map((item, i) => (
                          <Stack
                            direction="row"
                            gap={2}
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{ pt: 0.5 }}
                          >
                            <Box sx={{ width: "200px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="secondary"
                                    checked={item?.checked}
                                    onClick={() => {
                                      item.checked = !item.checked;
                                      fineFormik.setValues((prev) => ({
                                        ...prev,
                                      }));
                                    }}
                                  />
                                }
                                label={dayjs(item?.label).format("MMMM-YYYY")}
                              />
                            </Box>
                            <DesktopDatePicker
                              // maxDate={calculateMaximumDate(item.value)}
                              minDate={calculateMinimumDate(item.value)}
                              disabled={item.checked ? false : true}
                              inputFormat="DD MMM, YYYY"
                              onChange={(e) => {
                                item["value"] = e;
                                fineFormik.setValues((prev) => ({ ...prev }));
                              }}
                              value={item.value}
                              renderInput={(params) =>
                                item.checked ? (
                                  <CustomTextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                    sx={{ width: "200px" }}
                                  />
                                ) : (
                                  <CustomTextFieldDisabled
                                    {...params}
                                    size="small"
                                    fullWidth
                                    sx={{ width: "200px" }}
                                  />
                                )
                              }
                            />
                            <Box sx={{ width: "80px" }}>
                              <Typography
                                onClick={() => sameAsAll(item.value)}
                                sx={{
                                  color: "blue",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                same as all
                              </Typography>
                            </Box>
                          </Stack>
                        ))}

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-end"}
                      pt={1}
                      gap={2}
                    >
                      <Button
                        onClick={(e) =>
                          fineFormik.setValues((prev) => ({
                            ...prev,
                            tabValue: "2",
                          }))
                        }
                        variant="outlined"
                        size="large"
                        color="secondary"
                        sx={{
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          width: "20%",
                        }}
                      >
                        Next
                      </Button>
                    </Stack>
                  </Box>
                </TabPanel>
                <TabPanel value="2" sx={{ p: "0 !important" }}>
                  <FineDetail formik={fineFormik} />
                  <Box sx={{ mt: 1 }}>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-end"}
                      pt={1}
                      gap={2}
                    >
                      <Button
                        onClick={(e) =>
                          fineFormik.setValues((prev) => ({
                            ...prev,
                            tabValue: "1",
                          }))
                        }
                        variant="outlined"
                        size="large"
                        color="secondary"
                        sx={{
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          width: "20%",
                        }}
                      >
                        Previous
                      </Button>
                      <LoadingButton
                        // loading={addFeeStructureGroupLoadign}
                        onClick={fineFormik.handleSubmit}
                        variant="contained"
                        size="large"
                        color="secondary"
                        sx={{
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          fontSize: "14px",
                          width: "20%",
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          ) : (
            <>
              <Box sx={{ mt: 1, width: "80%" }}>
                <Stack direction={"row"} sx={{ pt: 1 }}>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 600, flex: 1 }}
                  >
                    Month Year
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 600, flex: 1 }}
                  >
                    Due Date
                  </Typography>
                </Stack>

                {getFineRateData?.data?.obj?.monthYearList?.map((item, i) => (
                  <Stack direction={"row"} sx={{ py: 0.3 }} key={i}>
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: 600, flex: 1 }}
                    >
                      {item?.month}-{item?.year}
                    </Typography>
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: 600, flex: 1 }}
                    >
                      {dayjs(item?.dueDate).format("DD MMM, YYYY")}
                    </Typography>
                  </Stack>
                ))}
                <Typography
                  variant="h5"
                  fontWeight={600}
                  mt={1.5}
                  sx={{ color: "#999" }}
                >
                  Fine Detail
                </Typography>
                <Box ml={1}>
                  <Typography variant="h6" my={1}>
                    &mdash;{" "}
                    {getFineRateData?.data?.obj?.isFixedAmount
                      ? "Fixed Amount"
                      : getFineRateData?.data?.obj?.is_slotWise_perDay
                      ? "Slot Wise (Per Day)"
                      : "Slot Wise"}
                  </Typography>
                  {!getFineRateData?.data?.obj?.isFixedAmount ? (
                    <>
                      <Box sx={{ width: "70%" }}>
                        <Grid container spacing={1} columns={8}>
                          <Grid item xs={2}>
                            <Head1>Day</Head1>
                          </Grid>
                          <Grid item xs={1} />
                          <Grid item xs={2}>
                            <Head1>Day</Head1>
                          </Grid>
                          <Grid item xs={2}>
                            <Head1>Amount</Head1>
                          </Grid>
                        </Grid>
                        {getFineRateData?.data?.obj?.slotWise?.slotWiseFineRate?.map(
                          (item, i) => (
                            <Grid
                              container
                              spacing={1}
                              columns={8}
                              key={i}
                              display={"flex"}
                              alignItems={"center"}
                              sx={{ pt: 0.5 }}
                            >
                              <Grid item xs={2}>
                                <Head1>{item?.from}</Head1>
                              </Grid>
                              <Grid item xs={1}>
                                <Head1 textAlign={"left"}>To</Head1>
                              </Grid>
                              <Grid item xs={2}>
                                <Head1>{item?.upTo}</Head1>
                              </Grid>
                              <Grid item xs={2}>
                                <Head1>&#8377;{item?.amount}</Head1>
                              </Grid>
                              <Grid item xs={1}></Grid>
                            </Grid>
                          )
                        )}
                        <Grid
                          container
                          spacing={1}
                          columns={8}
                          display={"flex"}
                          alignItems={"center"}
                          sx={{ mt: 0.5 }}
                        >
                          <Grid item xs={3}>
                            <Head1 textAlign={"center"} fontSize={16}>
                              Greater Than
                            </Head1>
                          </Grid>

                          <Grid item xs={2}>
                            <Head1>
                              {
                                getFineRateData?.data?.obj?.slotWise
                                  ?.greaterThanDay
                              }
                            </Head1>
                          </Grid>
                          <Grid item xs={2}>
                            <Head1>
                              &#8377;
                              {getFineRateData?.data?.obj?.slotWise?.amount}
                            </Head1>
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    <Head1>
                      &#8377;{getFineRateData?.data?.obj?.fixedAmount}
                    </Head1>
                  )}
                </Box>
              </Box>
              <Divider
                sx={{
                  background: (theme) => theme.palette.primary.main,
                  my: 1,
                }}
              />
              {/* <Box>
                {getFineRateData?.data?.obj?.isFixedAmount && (
                  <>
                    <Typography sx={{ fontSize: "15px", fontWeight: 400 }}>
                      Fixed Amount from Due Date
                    </Typography>
                    <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                      {IndianCurrency(getFineRateData?.data?.obj?.fixedAmount)}
                    </Typography>
                  </>
                )}
              </Box> */}
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  onClick={() =>
                    fineFormik.setValues((prev) => ({ ...prev, edit: true }))
                  }
                >
                  Edit
                </Button>
              </Stack>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewFineDetailDialog;
const Head1 = styled(Typography)`
  font-weight: 600;
  font-size: 14;
`;
const AddMoreButton = styled(Typography)`
  color: #2789fd;

  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  cursor: pointer;
  display: inline;
  margin-top: 10px;
`;
