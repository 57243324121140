import { Check, VerifiedUser } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import appRoutes from "app/config/routes";
import CustomLoader from "app/components/common/CustomLoader";
import { THEME } from "app/config/constants";
import { landingPageURL } from "app/config/externalLink";
import { updateStatusOfLegal } from "app/services/schoolService/school.service";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import { LoadingButton } from "@mui/lab";
import DialogHeader from "app/components/common/DialogHeader";

const Policies = ({
  open = false,
  onClose = {},
  legals = [],
  refetch = () => {},
}) => {
  const [state, setState] = useState({
    data: {},
    dialog: false,
    title: "",
  });

  const handleCloseConfirmationDialog = () => {
    setState((prev) => ({ ...prev, data: {}, dialog: false }));
  };
  const handleMutate = () => {
    mutate(state.data);
  };
  const { mutate, isLoading } = useMutation(
    "updateStatusOfLegal",
    updateStatusOfLegal,
    {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        refetch();
        handleCloseConfirmationDialog();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
  return (
    <>
      {/* confirmation Dialog */}
      <Dialog
        open={state.dialog}
        PaperProps={{
          sx: { minWidth: "300px", p: 1 },
        }}
        onClose={handleCloseConfirmationDialog}
      >
        <Stack direction="column" alignItems={"center"}>
          <VerifiedUser
            sx={{ fontSize: "60px", color: colors.green[600], mb: 2 }}
          />
          <Typography sx={{ mb: 0.5, fontSize: "16px", fontWeight: 600 }}>
            Are You Sure Want to Accept
          </Typography>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 600, fontStyle: "italic" }}
          >
            " {state.title} "
          </Typography>
          <Stack direction="row" sx={{ width: "100%" }} gap={2} pt={2}>
            <Button
              size="large"
              variant="outlined"
              sx={{ width: "100%" }}
              onClick={handleCloseConfirmationDialog}
            >
              Cancel
            </Button>
            <LoadingButton
              size="large"
              variant="contained"
              sx={{ width: "100%" }}
              color="secondary"
              onClick={() => handleMutate()}
              loading={isLoading}
            >
              Confirm
            </LoadingButton>
          </Stack>
        </Stack>
      </Dialog>
      <Dialog open={open}>
        <DialogHeader handleClose={onClose} title="Policies" />
        <DeactivateWrapper>
          <Stack direction="column" sx={{ p: 2 }}>
            <Typography className="account_text">
              Ekalsutra intends to integrate a Payment Gateway and has updated
              specific policies. Please review them and confirm your acceptance
              to avoid any disruption of service.
            </Typography>
            {/* <Typography className="update_text">
              Please Accept Our Policies!
            </Typography> */}
            {legals?.map((item, i) => (
              <Box key={i} pl={2}>
                {item?.contentName === "TnC" && (
                  <Stack direction="row" py={1} alignItems={"center"} gap={1}>
                    <Typography className="account_text">
                      &#8226; &nbsp; Terms and Conditions
                    </Typography>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      className="link_text"
                      href={landingPageURL.term_and_condition}
                    >
                      View
                    </a>
                    {item?.accepted ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: colors.green[600],
                          fontWeight: 600,
                        }}
                      >
                        Accepted
                      </Typography>
                    ) : (
                      <Typography
                        className="accept_text"
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            data: {
                              contentName: item?.contentName,
                              accepted: true,
                            },
                            dialog: true,
                            title: "Term and Conditions",
                          }))
                        }
                      >
                        Accept
                      </Typography>
                    )}
                  </Stack>
                )}
                {item?.contentName === "privacyPolicy" && (
                  <Stack direction="row" py={1} alignItems={"center"} gap={1}>
                    <Typography className="account_text">
                      &#8226; &nbsp; Privacy Policy
                    </Typography>
                    <a
                      className="link_text"
                      target="_blank"
                      rel="noreferrer noopener"
                      href={landingPageURL.privacyPolicy}
                    >
                      View
                    </a>
                    {item?.accepted ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: colors.green[600],
                          fontWeight: 600,
                        }}
                      >
                        Accepted
                      </Typography>
                    ) : (
                      <Typography
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            data: {
                              contentName: item?.contentName,
                              accepted: true,
                            },
                            dialog: true,
                            title: "Privacy Policy",
                          }))
                        }
                        className="accept_text"
                      >
                        Accept
                      </Typography>
                    )}
                  </Stack>
                )}
                {item?.contentName === "refundPolicy" && (
                  <Stack direction="row" py={1} alignItems={"center"} gap={1}>
                    <Typography className="account_text">
                      &#8226; &nbsp; Refund Policy
                    </Typography>
                    <a
                      className="link_text"
                      target="_blank"
                      rel="noreferrer noopener"
                      href={landingPageURL.refundPolicy}
                    >
                      View
                    </a>
                    {item?.accepted ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: colors.green[600],
                          fontWeight: 600,
                        }}
                      >
                        Accepted
                      </Typography>
                    ) : (
                      <Typography
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            data: {
                              contentName: item?.contentName,
                              accepted: true,
                            },
                            dialog: true,
                            title: "Refund Policy",
                          }))
                        }
                        className="accept_text"
                      >
                        Accept
                      </Typography>
                    )}
                  </Stack>
                )}
                {item?.contentName === "paymentTerms" && (
                  <Stack direction="row" py={1} alignItems={"center"} gap={1}>
                    <Typography className="account_text">
                      &#8226; &nbsp; Due Payment and Due Date
                    </Typography>
                    <Link
                      className="link_text"
                      to={`/${appRoutes.schoolProfile}?tab=transaction`}
                    >
                      View
                    </Link>
                    {item?.accepted ? (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: colors.green[600],
                          fontWeight: 600,
                        }}
                      >
                        Accepted
                      </Typography>
                    ) : (
                      <Typography
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            data: {
                              contentName: item?.contentName,
                              accepted: true,
                            },
                            dialog: true,
                            title: "Payment Term and Conditions",
                          }))
                        }
                        className="accept_text"
                      >
                        Accept
                      </Typography>
                    )}
                  </Stack>
                )}
              </Box>
            ))}
            <Box className="account_contact__box">
              <Typography className="contact_text">Contact Detail</Typography>
              <Typography className="contact_number__text">
                support@ekalsutra.com
                <br /> +91 97926 69957
              </Typography>
            </Box>
          </Stack>
        </DeactivateWrapper>
      </Dialog>
    </>
  );
};

export default Policies;
const DeactivateWrapper = styled.div`
  width: 450px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: ${THEME.boxShadow1};
  .update_profile__text {
    font-size: 18px;
    color: #0d2553;
  }

  .update_text {
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0c2f49;
    margin: 10px auto;
  }
  .account_text {
    font-weight: 600;
    font-size: 16px;
  }
  .link_text {
    font-weight: 600;
    font-size: 14px;
    color: ${colors.blue[500]};
  }
  .accept_text {
    font-weight: 600;
    font-size: 14px;
    color: ${colors.blue[500]};
    text-decoration: underline;
    cursor: pointer;
  }
  .account_contact__box {
    background: rgba(39, 137, 253, 0.1);
    border: 1px solid #ffffff;
    border-radius: 20px;
    padding: 20px;
    width: 80%;
    margin-top: 10px;
    margin: auto;
  }
  .contact_text {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0c2f49;
  }
  .contact_number__text {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #232360;
    opacity: 0.5;
  }
`;
