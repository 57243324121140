import LogoutIcon from "@mui/icons-material/Logout";
import LoopIcon from "@mui/icons-material/Loop";
import { Close, Info, Key } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import appRoutes from "app/config/routes";
import { resetAuthSlice } from "app/modules/auth/operations";
import { resetSchoolSlice } from "app/modules/schools/operations";
import {
  schoolListSelector,
  userDetailSelector,
  userNameSelector,
  userPrimaryRoleSelector,
} from "app/modules/schools/selectors";
import { changePasswordWithoutOtp } from "app/services/ledgerAccount";
import { persistor } from "app/store";
import { generateNameInitial } from "app/utils/helper";
import { useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CancelPresentationSharpIcon from "@mui/icons-material/CancelPresentationSharp";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { THEME } from "app/config/constants";
import DialogHeader from "app/components/common/DialogHeader";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
const UserProfileDrawer = ({ setState, state }) => {
  const [passwordShow, setpasswordShow] = useState(false);
  const [inputPassword, setinputPassword] = useState("");
  const [inputNewPassword, setinputNewPassword] = useState("");
  const [inputConfirmNewPassword, setinputConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schoolList = useSelector(schoolListSelector);
  const userFullName = useSelector(userNameSelector);
  const userDetail = useSelector(userDetailSelector);
  const primaryRole = useSelector(userPrimaryRoleSelector);
  const validationSchema = yup.object().shape({
    currentPassoword: yup.string().required(),
    newPassword: yup.string().required(),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required(),
  });
  const {
    mutate,
    isLoading: changePasswordWithoutOtpLoading,
    error: changePasswordWithoutOtpError,
    isSuccess: changePasswordWithoutOtpSuccess,
    data: changePasswordWithoutOtpData,
  } = useMutation(changePasswordWithoutOtp, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      setpasswordShow(false);
      localStorage.removeItem("schoolToken");
      localStorage.removeItem("schoolRefreshToken");
      localStorage.removeItem("authRefreshToken");
      localStorage.removeItem("selectedSchoolId");
      navigate("/login", { replace: true });
    },

    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      currentPassoword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema,
    onSubmit: (formValues) => {
      let query = {
        confirmPassword: formValues?.confirmNewPassword,
        currentPassword: formValues?.currentPassoword,
        password: formValues?.newPassword,
      };
      mutate(query);
    },
  });

  const navigateToProfile = () => {
    setState((prev) => ({ ...prev, showSidebar: false }));
    navigate(`/${appRoutes.userProfile}`);
  };
  const handleChangePassword = () => {
    setpasswordShow(true);
  };
  const handleClosePassword = () => {
    setpasswordShow(false);
  };
  const submitPassword = () => {
    if (!inputPassword || !inputNewPassword || !inputConfirmNewPassword) {
      setErrorMessage("Please fill in all fields.");
      return;
    } else {
      setErrorMessage("");
      let query = {
        confirmPassword: inputConfirmNewPassword,
        currentPassword: inputPassword,
        password: inputNewPassword,
      };

      mutate(query);
    }
  };
  return (
    <>
      {/* change password dialog */}
      <Dialog
        open={passwordShow}
        onClose={() => setpasswordShow(false)}
        PaperProps={{
          sx: { minWidth: "20vw" },
        }}
      >
        {/* <Stack
          sx={{
            padding: "1em",
            backgroundColor: "#f5f5f5",
          }}
        > */}
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Change Password"
            handleClose={() => setpasswordShow(false)}
          />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} id="changePassword">
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12}>
                <Typography className="heading">Current Password</Typography>
                <CustomTextField
                  size="small"
                  fullWidth
                  name="currentPassoword"
                  value={values.currentPassoword}
                  onChange={handleChange}
                  placeholder="Type Current Password"
                  error={
                    Boolean(errors.currentPassoword) && touched.currentPassoword
                  }
                  helperText={
                    touched.currentPassoword && errors.currentPassoword
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="heading">New Password</Typography>
                <CustomTextField
                  size="small"
                  fullWidth
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  placeholder="Type New Password"
                  error={Boolean(errors.newPassword) && touched.newPassword}
                  helperText={touched.newPassword && errors.newPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="heading">
                  Confirm New Password
                </Typography>
                <CustomTextField
                  size="small"
                  fullWidth
                  name="confirmNewPassword"
                  value={values.confirmNewPassword}
                  onChange={handleChange}
                  placeholder="Confirm New Password"
                  error={
                    Boolean(errors.confirmNewPassword) &&
                    touched.confirmNewPassword
                  }
                  helperText={
                    touched.confirmNewPassword && errors.confirmNewPassword
                  }
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            form="changePassword"
            type="submit"
            loading={changePasswordWithoutOtpLoading}
            variant="contained"
            color="secondary"
            sx={{ fontSize: "14px", textTransform: "capitalize", px: 3 }}
          >
            UPDATE
          </LoadingButton>
        </DialogActions>
        {/* <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              padding: "15px",
              gap: "5px",
            }}
          >
            <Typography sx={{ whiteSpace: "nowrap" }}>
              Current Password
            </Typography>
            <span style={{ color: "red" }}>*</span>
            <CustomTextField
              fullWidth
              size="small"
              sx={{ marginTop: "8px" }}
              id="fullWidth"
              onChange={(e) => {
                setinputPassword(e.target.value);
              }}
            />
          </Stack>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              padding: "15px",
            }}
          >
            {" "}
            <Typography sx={{ whiteSpace: "nowrap" }}>New Password</Typography>
            <span style={{ color: "red" }}>*</span>
            <CustomTextField
              fullWidth
              sx={{ marginTop: "8px" }}
              id="fullWidth"
              type="number"
              onChange={(e) => {
                setinputNewPassword(e.target.value);
              }}
            />
          </Stack>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              padding: "15px",
            }}
          >
            {" "}
            <Typography sx={{ whiteSpace: "nowrap" }}>
              Confirm New Password
            </Typography>
            <span style={{ color: "red" }}>*</span>
            <CustomTextField
              fullWidth
              sx={{ marginTop: "8px" }}
              id="fullWidth"
              onChange={(e) => {
                setinputConfirmNewPassword(e.target.value);
              }}
            />
          </Stack>
          {errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          <Stack>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#2789FD",
                color: "white",
                marginLeft: "auto",
              }}
              onClick={submitPassword}
            >
              Reset Password
            </Button>
          </Stack>
        </Stack> */}
      </Dialog>
      <Drawer
        anchor="right"
        onClose={() => setState((prev) => ({ ...prev, showSidebar: false }))}
        open={state.showSidebar}
        PaperProps={{
          sx: {
            minWidth: "300px",
            backgroundColor: THEME.primary,
            color: THEME.white,
          },
        }}
      >
        <Stack direction="row" justifyContent="flex-start">
          <IconButton
            onClick={() =>
              setState((prev) => ({ ...prev, showSidebar: false }))
            }
          >
            <Close sx={{ color: THEME.white }} />
          </IconButton>
        </Stack>
        <FlexBoxCol onClick={navigateToProfile}>
          {userDetail?.profilePic ? (
            <ProfileImg alt="Profile" src={userDetail?.profilePic}></ProfileImg>
          ) : (
            <span className="avatar avatar-24 bg-gray rounded-circle text-color p-4 fw-bold">
              {generateNameInitial(userDetail?.firstName, userDetail?.lastName)}
            </span>
          )}
          {/* Onclick navigate to Staff management */}
          <Typography
            sx={{
              fontWeight: 600,
            }}
          >
            {userFullName}
          </Typography>
        </FlexBoxCol>
        <Stack
          sx={{ mt: 2, height: "100%", mb: 4 }}
          direction="column"
          justifyContent="space-between"
        >
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{ cursor: "pointer", px: 3, py: 1 }}
            >
              <Key />
              <Typography
                onClick={handleChangePassword}
                sx={{ fontSize: "14px" }}
              >
                Change Password
              </Typography>
            </Stack>

            {/* Switch schools button */}

            {schoolList?.length > 1 && (
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{ cursor: "pointer", px: 3, py: 1 }}
                onClick={() => {
                  localStorage.removeItem("schoolToken");
                  localStorage.removeItem("schoolRefreshToken");
                  localStorage.removeItem("selectedSchoolId");
                  navigate("/schools", { replace: true });
                }}
              >
                <LoopIcon />
                <Typography sx={{ fontSize: "14px" }}>
                  Switch Schools
                </Typography>
              </Stack>
            )}

            {/* Logout button */}
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{ cursor: "pointer", px: 3, py: 1 }}
              onClick={() => {
                localStorage.removeItem("schoolToken");
                localStorage.removeItem("schoolRefreshToken");
                localStorage.removeItem("authRefreshToken");
                localStorage.removeItem("selectedSchoolId");
                setState((prev) => ({ ...prev, showSidebar: false }));
                persistor.purge();

                // Reset Auth slice
                dispatch(resetAuthSlice());

                // Reset School Slice
                dispatch(resetSchoolSlice());
                navigate("/login", { replace: true });
              }}
            >
              <LogoutIcon />
              <Typography sx={{ fontSize: "14px" }}>Logout</Typography>
            </Stack>
          </Box>
          <Box>
            {["ROLE_ADMIN", "ROLE_MANAGING_DIRECTOR"].includes(primaryRole) && (
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{ cursor: "pointer", px: 3, py: 1 }}
                onClick={() => {
                  navigate(`/${appRoutes.schoolProfile}`);
                  setState((prev) => ({ ...prev, showSidebar: false }));
                }}
              >
                <Info />
                <Typography sx={{ fontSize: "14px" }}>
                  School Profile
                </Typography>
              </Stack>
            )}
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{ cursor: "pointer", px: 3, py: 1 }}
              onClick={() => {
                navigate(`/${appRoutes.aboutSchool}`);
                setState((prev) => ({ ...prev, showSidebar: false }));
              }}
            >
              <Info />
              <Typography sx={{ fontSize: "14px" }}>About School</Typography>
            </Stack>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};
export default UserProfileDrawer;
const FlexBoxCol = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  p {
    display: flex;
    font-size: 15px;
    margin: auto 20px;
    align-items: center;
  }
`;
const ProfileImg = styled("img")`
  height: 70px;
  width: 70px;
  margin: auto 20px;
  border-radius: 40px;
`;
