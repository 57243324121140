import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Helmet } from "react-helmet";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import { Container, useMediaQuery } from "@mui/material";
import SchoolProfileChild from "./SchoolProfileChild";
import FirmSchoolProfile from "./FirmSchoolProfile";
import Transaction from "./Transaction";
import { useSearchParams } from "react-router-dom";

const SchoolProfile = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [value, setValue] = React.useState("1");
  const [getSearchParams] = useSearchParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (getSearchParams.getAll("tab")[0] === "transaction") {
      setValue("3");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>School Profile</title>
      </Helmet>
      <Container maxWidth="xl" disableGutters={isMobile ? true : false}>
        <TabContext value={value}>
          <TabsWrapper>
            <TabList onChange={handleChange}>
              <Tab label="School Profile" value="1" />
              <Tab label="Firm Profile" value="2" />
              <Tab label="Transaction" value="3" />
            </TabList>
          </TabsWrapper>
          <TabPanel value="1" sx={{ p: "0 !important" }}>
            <Box sx={{ py: 1.5 }}>
              <SchoolProfileChild />
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ p: "0 !important" }}>
            <Box sx={{ py: 1.5 }}>
              <FirmSchoolProfile />
            </Box>
          </TabPanel>
          <TabPanel value="3" sx={{ p: "0 !important" }}>
            <Box sx={{ py: 1.5 }}>
              <Transaction />
            </Box>
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
};

export default SchoolProfile;
