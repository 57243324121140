import dayjs from "dayjs";
import React from "react";

const Footer = ({ doc, data, userName, totalPagesExp }) => {
  // Add pagination to the footer
  let str = "Page " + doc.internal.getNumberOfPages();
  // Total page number plugin only available in jspdf v2.3.0 and above
  if (typeof doc.putTotalPages === "function") {
    str = str + " of " + totalPagesExp;
  }
  doc.setFontSize(10);

  // Define the coordinates and dimensions of the box
  let x = data.settings.margin.left;
  let y = doc.internal.pageSize.height - 8; // Adjust as needed
  let width = doc.internal.pageSize.width - 2 * data.settings.margin.left; // Adjust as needed
  let height = 6; // Adjust as needed

  // Draw the box
  doc.rect(x, y, width, height);

  doc.text(
    `Desinged By: EkalSutra`,
    data.settings.margin.left + 10,
    doc.internal.pageSize.height - 4
  );
  doc.text(
    `Report Created By : ${userName}`,
    data.settings.margin.left + 70,
    doc.internal.pageSize.height - 4
  );
  doc.text(
    `Report Created on: ${dayjs(new Date()).format("LLL")}`,
    data.settings.margin.left + 150,
    doc.internal.pageSize.height - 4
  );
  doc.text(
    str,
    data.settings.margin.left + 240,
    doc.internal.pageSize.height - 4
  );
};

export default Footer;
