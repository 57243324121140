import { createAsyncThunk } from "@reduxjs/toolkit";
import API_ENDPOINTS from "app/config/endpoints";
import axios from "axios";

export const getVisitorInfoLogic = createAsyncThunk(
  "reception/getVisitorInfo",
  async (payload, { dispatch }) => {
    try {
      const value = payload;
      const { data } = await axios.get(
        `${API_ENDPOINTS.reception.getVisitorInfo}?date=${value}`
      );
      return data;
    } catch (err) {}
  }
);

export const getVisitorInfoByRangeLogic = createAsyncThunk(
  "reception/getVisitorInfo",
  async (payload, { dispatch }) => {
    try {
      const { dateFrom, dateTo } = payload;
      const { data } = await axios.get(
        `${API_ENDPOINTS.reception.getVisitorInfo}?from=${dateFrom}&to=${dateTo}`
      );
      return data;
    } catch (err) {}
  }
);

export const addVisitorLogic = createAsyncThunk(
  "reception/addVisitorData",
  async (payload, { getState, dispatch }) => {
    try {
      const sessionDraft = payload;
      if (sessionDraft.roleOfMeetTo === "Student") {
        const { data } = await axios.post(
          `${API_ENDPOINTS.reception.addVisitor}`,
          {
            visitorName: sessionDraft.visitorName,
            mobileNumber: sessionDraft.mobileNumber,
            from: sessionDraft.from,
            numberOfPersons: sessionDraft.numberOfPersons,
            roleOfMeetTo: sessionDraft.roleOfMeetTo,
            classDocId: "62558fae8585d123633f9665",
            className: "Nursery",
            stream: "ALL",
            section: "A",
            meetToName: sessionDraft.meetToName,
            note: sessionDraft.note,
            meetingPurpose: sessionDraft.meetingPurpose,
          }
        );
        return data;
      }
      if (sessionDraft.roleOfMeetTo === "Director") {
        const { data } = await axios.post(
          `${API_ENDPOINTS.reception.addVisitor}`,
          {
            visitorName: sessionDraft.visitorName,
            mobileNumber: sessionDraft.mobileNumber,
            from: sessionDraft.from,
            numberOfPersons: sessionDraft.numberOfPersons,
            roleOfMeetTo: sessionDraft.roleOfMeetTo,
            classDocId: "",
            className: "",
            stream: "",
            section: "",
            meetToName: sessionDraft.meetToName,
            note: sessionDraft.note,
            meetingPurpose: sessionDraft.meetingPurpose,
          }
        );

        return data;
      }
      if (sessionDraft.roleOfMeetTo === "Staff") {
        const { data } = await axios.post(
          `${API_ENDPOINTS.reception.addVisitor}`,
          {
            visitorName: sessionDraft.visitorName,
            mobileNumber: sessionDraft.mobileNumber,
            from: sessionDraft.from,
            numberOfPersons: sessionDraft.numberOfPersons,
            roleOfMeetTo: sessionDraft.roleOfMeetTo,
            departmentDocId: "6207ad092091666c7d6e7a16",
            departmentName: sessionDraft.departmentName,
            meetToName: sessionDraft.meetToName,
            note: sessionDraft.note,
            meetingPurpose: sessionDraft.meetingPurpose,
          }
        );

        return data;
      }
    } catch (err) {
      alert("error", err.response.data.message);
    }
  }
);

export const exitVisitorLogic = createAsyncThunk(
  "reception/exitVisitors",
  async (payload, { dispatch }) => {
    try {
      const { id } = payload;
      const { data } = await axios.put(
        `${API_ENDPOINTS.reception.exitVisitor}/${id}`
      );
      return data;
    } catch (err) {}
  }
);
