import React, { useState } from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import {
  Button,
  Checkbox,
  DialogActions,
  Grid,
  Input,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import * as yup from "yup";
import { useMutation } from "react-query";
import { States, Distrct } from "app/config/administrativeConstants";
import { updateSchoolProfile } from "app/services/management";
import { updatePic } from "app/services/management";
import { boards } from "app/config/administrativeConstants";
import { Box, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import { getSchoolProfile } from "app/services/management";
import { useQuery } from "react-query";
import uploadFile from "app/utils/uploadFile";
import { toast } from "react-toastify";
import {
  CustomSelectBox,
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import TextFieldLabel from "../../../../components/common/TextFieldLabel";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DialogHeader from "app/components/common/DialogHeader";
import { LoadingButton } from "@mui/lab";
import { MenuProps } from "app/utils/helper";
import dayjs from "dayjs";
import { Edit } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { queryClient } from "App";
import UpdateSchoolProfileDiaolog from "app/components/common/Dialogs/school/UpdateSchoolProfileDiaolog";

const SchoolProfileChild = () => {
  const [open, setOpen] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [schoolProfile, setSchoolProfile] = useState();
  const [signature, setSignature] = useState({
    dialog: false,
    localPic: "",
    loading: false,
  });

  const { mutate: uploadPicture, isLoading: isLoadingPic } = useMutation(
    updatePic,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        queryClient.invalidateQueries({ queryKey: ["getSchoolProfile"] });
        setOpenUploadDialog(false);
        setSignature((prev) => ({
          ...prev,
          loading: false,
          localPic: "",
          dialog: false,
        }));
      },
      onError: (error) => {
        toast.error(error.response.data?.message);
      },
    }
  );

  const handleUploadSignature = async () => {
    setSignature((prev) => ({ ...prev, loading: true }));
    if (signature.localPic) {
      let s3FilePath = `student/${schoolProfile?._id}/${signature?.localPic?.name}`;
      let attachmentUrl = await uploadFile(
        signature?.localPic,
        s3FilePath,
        signature?.localPic?.type
      );

      // formik.setValues((prev) => ({
      //   ...prev,
      //   schoolLogo: attachmentUrl.fileURL,
      // }));
      uploadPicture({ signatureForIdCard: attachmentUrl?.fileURL });
    }
    setSignature((prev) => ({ ...prev, loading: false }));
  };

  const { isLoading: loadingSchoolProfile, isFetching } = useQuery({
    queryKey: ["getSchoolProfile"],
    queryFn: () => getSchoolProfile(),
    onSuccess: ({ data }) => {
      setSchoolProfile(data?.obj);
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    },
  });

  return (
    <>
      <Dialog
        open={signature.dialog}
        onClose={() =>
          setSignature((prev) => ({
            ...prev,
            dialog: false,
            localPic: false,
            loading: false,
          }))
        }
      >
        <DialogHeader
          title="Digital Signature"
          handleClose={() =>
            setSignature((prev) => ({
              ...prev,
              dialog: false,
              localPic: false,
              loading: false,
            }))
          }
        />
        <DialogContent>
          <Box sx={{ my: 1 }}>
            {signature.localPic && (
              <Box
                sx={{
                  width: "220px",
                  height: "70px",
                  background: "rgba(208, 208, 226, 0.2)",
                  border: " 1px solid #B6C8D6",
                  borderRadius: "8px",
                  padding: "3px",
                }}
              >
                <img
                  src={URL.createObjectURL(signature.localPic)}
                  alt="ekalsutra-digital-signature"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            )}
          </Box>
          <Box>
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                setSignature((prev) => ({
                  ...prev,
                  localPic: e.target.files[0],
                }))
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={signature.loading || isLoadingPic}
            variant="contained"
            color="secondary"
            onClick={handleUploadSignature}
          >
            Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Update school profile dialog */}
      <UpdateSchoolProfileDiaolog
        open={open}
        onClose={() => setOpen(false)}
        data={schoolProfile}
      />
      <Helmet>
        <title>School Profile</title>
      </Helmet>

      <Wrapper>
        {(loadingSchoolProfile || isFetching) && (
          <Stack direction="row" justifyContent="center" sx={{ py: 2 }}>
            <CircularProgress />
          </Stack>
        )}

        <Card sx={{ width: { md: "80%" }, m: "auto" }}>
          <CoverPhoto
            src={
              schoolProfile?.coverPhoto
                ? schoolProfile?.coverPhoto
                : "/banner-placeholder.png"
            }
          />

          <Stack
            direction="row"
            justifyContent="center"
            sx={{ mt: "-40px", position: "relative" }}
          >
            <LogoContainer>
              <LogoContainerImg
                src={
                  schoolProfile?.schoolLogo
                    ? schoolProfile?.schoolLogo
                    : "/image-placeholder.jpeg"
                }
              />
            </LogoContainer>
            <EditButtonWrapper onClick={() => setOpen(true)}>
              <Edit sx={{ color: "#fff" }} />
            </EditButtonWrapper>
          </Stack>

          <Typography variant="h4" textAlign={"center"} my={2}>
            {schoolProfile?.schoolName}
          </Typography>
          <Grid container spacing={1} px={1}>
            <Grid item xs={12} md={6}>
              <Typography className="header">School Initial : </Typography>
              <Typography className="content">
                {schoolProfile?.schoolInitials}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="header">School Name :</Typography>
              <Typography className="content">
                {schoolProfile?.schoolName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="header">School Address :</Typography>
              <Typography className="content">
                {schoolProfile?.schoolAddress?.address},{" "}
                {schoolProfile?.schoolAddress?.dist},{" "}
                {schoolProfile?.schoolAddress?.state} (
                {schoolProfile?.schoolAddress?.pinCode})
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="header">Affiliated By : </Typography>
              <Typography className="content">
                {schoolProfile?.affiliatedTo}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">Mediums : </Typography>
              <Stack direction="row">
                {schoolProfile?.medium?.map((stream, idx) => {
                  if (idx === schoolProfile?.medium.length - 1) {
                    return (
                      <Typography className="content">{stream}</Typography>
                    );
                  } else {
                    return (
                      <Typography className="content">{stream},</Typography>
                    );
                  }
                })}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">Education Type : </Typography>
              <Typography className="content">
                {schoolProfile?.educationType}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">
                School Registration Number :{" "}
              </Typography>
              <Typography className="content">
                {schoolProfile?.schoolRegisterNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">Affiliation Upto : </Typography>
              <Typography className="content">
                {schoolProfile?.affiliationUpTo
                  ? dayjs(schoolProfile?.affiliationUpTo).format("DD-MM-YYYY")
                  : "--"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">Affiliation No : </Typography>
              <Typography className="content">
                {schoolProfile?.affiliationNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">Establishment Date : </Typography>
              <Typography className="content">
                {schoolProfile?.establishmentDate
                  ? dayjs(schoolProfile?.establishmentDate).format("DD-MM-YYYY")
                  : "--"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">
                School Registration Date :{" "}
              </Typography>
              <Typography className="content">
                {schoolProfile?.schoolRegisterDate
                  ? moment(new Date(schoolProfile?.schoolRegisterDate)).format(
                      "DD-MM-YYYY"
                    )
                  : "--"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">Email Address :</Typography>
              <Typography className="content">
                {schoolProfile?.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">School Website :</Typography>
              <Typography className="content">
                {schoolProfile?.website}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">
                School Contact Number :
              </Typography>
              <Typography className="content">
                {schoolProfile?.schoolContactNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">School Mobile Number :</Typography>
              <Typography className="content">
                {schoolProfile?.mobileNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">School GST Number :</Typography>
              <Typography className="content">
                {schoolProfile?.gstNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">UDISE Code</Typography>
              <Typography className="content">
                {schoolProfile?.udiseCode ?? "--"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">License Number</Typography>
              <Typography className="content">
                {schoolProfile?.ekalRefNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">License Date</Typography>
              <Typography className="content">
                {schoolProfile?.licenseDate
                  ? dayjs(schoolProfile?.licenseDate).format("DD-MM-YYYY")
                  : "--"}
              </Typography>
            </Grid>
          </Grid>
          <Box px={1}>
            <Typography className="header">Signature For ID Card</Typography>
            <Stack direction="row" gap={2} alignItems="center">
              {schoolProfile?.signatureForIdCard && (
                <Box
                  sx={{
                    width: "220px",
                    height: "70px",
                    background: "rgba(208, 208, 226, 0.2)",
                    border: " 1px solid #B6C8D6",
                    borderRadius: "8px",
                    padding: "3px",
                    mt: 1,
                  }}
                >
                  <img
                    src={schoolProfile?.signatureForIdCard}
                    alt="ekalsutra-pricipal-signature"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              )}
              <Button
                onClick={() =>
                  setSignature((prev) => ({ ...prev, dialog: true }))
                }
                variant="outlined"
                color="secondary"
                sx={{
                  borderRadius: 10,
                  width: "10rem",
                  textTransform: "capitalize",
                }}
                component="label"
              >
                {schoolProfile?.signatureForIdCard
                  ? "Change "
                  : "Add Digital Signature"}
              </Button>
            </Stack>
          </Box>
        </Card>
      </Wrapper>
    </>
  );
};

export default SchoolProfileChild;

const Card = styled(Box)`
  border-radius: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #a7a7a7;
  background-color: white;
`;

const CoverPhoto = styled.img`
  height: 25rem;
  border-radius: 10px 10px 0px 0px;
`;

const Wrapper = styled.div`
  .header {
    font-weight: 400;
    color: #0c2f49;
    font-size: 14px;
  }

  .content {
    font-weight: 600;
    color: #0c2f49;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 10px;
  .header-button {
    height: 28px;
    width: 28px;
    border-radius: 10px;
    border: 1.7915px solid #0c2f49;
  }
`;
const LogoContainer = styled.div`
  width: 82.17px;
  height: 82.17px;
  background: #f6f8fc;
  border: 1px solid rgba(182, 200, 214, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoContainerImg = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 50%;
`;

const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28.09px;
  height: 28.09px;
  background: #2789fd;
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  margin-bottom: -10px;
  margin-left: 50px;
  cursor: pointer;
`;

const UploadBtn = styled.button`
  height: 50px;
  width: 10rem;
  background: #2789fd;
  margin: 5px;
  border-radius: 5px;
  color: white;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor:pointer &:hover {
    background-color: white;
    border: 2px solid #2789fd;
    color: #2789fd;
    transition: 100ms all ease-in-out;
  }
`;
