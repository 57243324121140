import React from "react";
import { ChevronDown } from "react-bootstrap-icons";

const CustomToggle = React.forwardRef(({ children, onClick, classes }, ref) => (
  <span
    role="button"
    className="text-white body-18 fw-bold"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <ChevronDown size={15} className="ms-3" />
  </span>
));

export default CustomToggle;
