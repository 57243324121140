import React, { useState, useEffect } from "react";
import {
  Typography,
  Modal,
  Box,
  Button,
  Drawer,
  Card,
  TextField,
} from "@mui/material";
import { Formik, Form, Field, useFormikContext } from "formik";
import styled from "styled-components";

import { X } from "react-bootstrap-icons";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

//import for our files
import TextFieldComponent from "./FormUi/TextfieldComponent";
import SelectComponent from "./FormUi/SelectComponent";
import MultipleSelectComponent from "./FormUi/MuiltipleSelectComponent";
import { FeeIncrementTypeUnit } from "../../../../config/administrativeConstants";
import {
  Category,
  CategoryForFee,
  GenderChoice,
} from "../../../../config/administrativeConstants";
import RadioComponent from "./FormUi/RadioComponent";
import FieldArrayComponent from "./FormUi/FieldArrayComponent";
import {
  addNewFeeStructure,
  updateFeeStructure,
} from "../../../../services/management/index";
import {
  selectedMediumSelector,
  selectActiveSession,
} from "app/modules/schools/selectors";
import { mobile } from "app/modules/reception/components/responsive";
import { LoadingButton } from "@mui/lab";
import EditFeeSelectComponent from "./FormUi/EditFeeSelectComponent";
import { toast } from "react-toastify";
import { queryClient } from "App";

const VALIDATION_SCHEMA = Yup.object().shape({
  feeType: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
  feeRelaxationCategory: Yup.string().required("Required"),
  dueDay: Yup.number().typeError("Enter Numbers only").required("Required"),
  fineIncremeantUnit: Yup.string().required("Required"),
  fineRate: Yup.number().typeError("Enter Numbers only").required("Required"),
  feeDetails: Yup.array().of(
    Yup.object().shape({
      feeCategory: Yup.string().required("Fee Category required"),
      description: Yup.string().required("Fee Category required"),
      amount: Yup.number()
        .typeError("Enter Numbers only")
        .required("Amount required"),
    })
  ),
});

export default function EditFeeModal({
  show,
  setShow,
  classOptions,
  classStreamObj,
  isEditing,
  editingValues,
  className,
}) {
  const [radioValues, setRadioValues] = useState("");
  const session = useSelector(selectActiveSession);
  const mainData = editingValues?._doc;
  const INITIAL_STATE = {
    // session: "",
    // feeType: "",
    // category: "",
    // feeAmount: "",
    // feeRelaxationCategory: "",
    // dueDate: "",
    // fineIncrementUnit: "",
    // fineIncrementUnitType: "",
    // fineRate: "",
    // class: [],
    // feeDetails: [
    //   {
    //     feeCategory: "",
    //     description: "",
    //     amount: "",
    //   },
    // ],
    ...mainData,
    fineIncrementUnitType: mainData?.fineIncreamentUnit,
  };

  const {
    mutate,
    isLoading: addingFeeStructure,
    isSuccess: SuccessAdd,
  } = useMutation(updateFeeStructure, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      setShow(false);
      queryClient.invalidateQueries({ queryKey: ["filterAllFeeStructure"] });
    },

    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  // let finalClassValueObj = [];
  const handleClose = () => {
    setShow(false);
  };

  const addClassDocIdToClassName = (values, setStatus) => {
    let tempArray = [];
    values.map((obj) => {
      classStreamObj?.map((option) => {
        if (option?.classStreamSection === obj) {
          const newElement = {
            section: option?.section,
            classDocId: option?.classDocId,
          };
          tempArray.push(newElement);
        }
      });
    });

    return tempArray;
  };

  const handleAddFeeStructure = (obj) => {
    mutate(obj);
  };

  // useEffect(() => {
  //   if (SuccessAdd) {
  //   } else if (feeStructureError) {
  //   }
  // }, [SuccessAdd, feeStructureError, addingFeeStructure, data]);

  return (
    <ModalWrapper>
      <NotificationContainer />
      <Drawer
        open={show}
        onClose={handleClose}
        anchor="right"
        sx={{
          width: 500,
          height: "100%",
        }}
      >
        <Box>
          <MainWrapper>
            <Formik
              initialValues={{
                ...INITIAL_STATE,
              }}
              onSubmit={(values, { resetForm }) => {
                let sum = 0;

                values.feeDetails?.map((detail) => {
                  sum += parseInt(detail.amount);
                });

                if (values.fineIncreamentUnit === "fixedAmount") {
                  values.fineIncrementUnitType = "";
                }

                const feeIncrementTypeUnit =
                  values.fineIncreamentUnit === "fixedAmount"
                    ? FeeIncrementTypeUnit.FixedAmount
                    : values.fineIncrementUnitType === "perDayFixedRate"
                    ? FeeIncrementTypeUnit.PerDayFixedRate
                    : FeeIncrementTypeUnit.PerDayPercent;

                const newFeeStructure = {
                  feeStructureDocId: mainData?._id,
                  //   medium: editingValues?.medium,
                  session: session,
                  classDocId: mainData?.classDocId,
                  section: mainData?.section,
                  feeType: values.feeType,
                  category: values.category,
                  feeAmount: parseInt(sum),
                  feeRelaxationCategory: values.feeRelaxationCategory,
                  dueDay: parseInt(values.dueDay),
                  fineIncreamentUnit: feeIncrementTypeUnit,
                  fineRate: parseInt(values.fineRate),
                  //   classList: addClassDocIdToClassName(values.class),
                  feeDetails: values.feeDetails,
                };

                handleAddFeeStructure(newFeeStructure);
                if (SuccessAdd) {
                  resetForm();
                }
              }}
              //   validationSchema={VALIDATION_SCHEMA}
            >
              {(formik) => {
                return (
                  <Form>
                    <FormWrapper>
                      <FormHeaderWrapper>
                        <h3>Fee Details: {session}</h3>
                        <button
                          type="button"
                          style={{
                            width: "25px",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0",
                            margin: "0",
                            borderRadius: "4px",
                            background: "transparent",
                          }}
                        >
                          <X onClick={() => handleClose()} />
                        </button>
                      </FormHeaderWrapper>
                      <hr
                        style={{
                          width: "100%",
                        }}
                      />
                      <Wrapper>
                        <div
                          style={{
                            width: "30%",
                          }}
                        >
                          <Typography
                            marginBottom={2}
                            marginTop={2}
                            fontWeight={500}
                            fontSize={14}
                          >
                            Fee Type
                          </Typography>
                          <SelectComponent
                            name="feeType"
                            label="Fee Type"
                            options={[
                              "Monthly Fee",
                              "Other Fee",
                              "Admission Fee",
                              "Hostel Fee",
                              "Exam Fee",
                            ]}
                          />
                        </div>
                        <div
                          style={{
                            width: "30%",
                          }}
                        >
                          <Typography
                            marginBottom={2}
                            marginTop={2}
                            fontWeight={500}
                            fontSize={14}
                          >
                            Caste Category
                          </Typography>
                          <SelectComponent
                            name="category"
                            label="Caste Category"
                            options={CategoryForFee}
                          />
                        </div>
                        <div
                          style={{
                            width: "30%",
                          }}
                        >
                          <Typography
                            marginBottom={2}
                            marginTop={2}
                            fontWeight={500}
                            fontSize={14}
                          >
                            Relaxation Category
                          </Typography>
                          <TextFieldComponent
                            name="feeRelaxationCategory"
                            label="Relaxation Category"
                          />
                        </div>

                        <div
                          style={{
                            width: "30%",
                          }}
                        >
                          <Typography
                            marginBottom={2}
                            marginTop={2}
                            fontWeight={500}
                            fontSize={14}
                          >
                            Class-Stream
                          </Typography>
                          {/* <MultipleSelectComponent
                            name="class"
                            label="Class Stream Section"
                            classOptions={classOptions}
                          /> */}
                          <TextField
                            disabled={true}
                            value={editingValues?.class_stream_sec}
                          />
                        </div>
                      </Wrapper>

                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            alignItems: "end",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "30%",
                            }}
                          >
                            <Typography
                              marginBottom={2}
                              marginTop={2}
                              fontWeight={500}
                              fontSize={14}
                            >
                              Due Day
                            </Typography>
                            <TextFieldComponent
                              name="dueDay"
                              label="Due Date"
                            />
                          </div>
                          <div>
                            <Typography
                              marginBottom={2}
                              marginTop={2}
                              fontWeight={500}
                              fontSize={14}
                            >
                              Fine Rate
                            </Typography>
                            <RadioComponent
                              name="fineIncreamentUnit"
                              label="fineIncreamentUnit"
                              options={["fixedAmount", "perDay"]}
                              defaultValue={
                                formik.values.fineIncreamentUnit ===
                                "fixedAmount"
                                  ? formik.values.fineIncreamentUnit
                                  : "perDay"
                              }
                              setRadioValues={setRadioValues}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                              }}
                            />
                          </div>
                          {formik.values.fineIncreamentUnit ===
                          "fixedAmount" ? (
                            ""
                          ) : (
                            <EditFeeSelectComponent
                              name="fineIncrementUnitType"
                              label="Rate/day"
                              options={["Fixed Rate/day", "Percentage/day"]}
                              style={{
                                width: "22%",
                              }}
                            />
                          )}
                          <TextFieldComponent
                            name="fineRate"
                            label="Fine Rate"
                            style={{
                              width: "20%",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            width: "100%",
                          }}
                        ></div>
                        <FeeDetailsWrapper>
                          <Typography
                            marginBottom={2}
                            marginTop={2}
                            fontWeight={500}
                            fontSize={14}
                          >
                            Fee Details
                          </Typography>
                          <FieldArrayComponent name="feeDetails" />
                        </FeeDetailsWrapper>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "end",
                          gap: "15px",
                        }}
                      >
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          loading={addingFeeStructure}
                        >
                          Submit
                        </LoadingButton>

                        <Button variant="outlined" onClick={handleClose}>
                          Cancel
                        </Button>
                      </div>
                    </FormWrapper>
                  </Form>
                );
              }}
            </Formik>
          </MainWrapper>
        </Box>
      </Drawer>
    </ModalWrapper>
  );
}

const ModalWrapper = styled.div`
  top: 20vh;
  overflow: auto;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`;

const FeeDetailsWrapper = styled.div`
  width: 100%;
  border: 1px solid #b6c8d6;
  border-radius: 15px;
  padding: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
`;

const FormHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  line-height: 22px;
  color: #0c2f49;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vw;
  color: #0c2f49;
  ${mobile({ width: "100%", paddingLeft: "2vw", paddingRight: "2vw" })}
`;

const MainWrapper = styled.div`
  width: 70vw;
  height: 100%;
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({ width: "100%" })}
`;
